import { useState } from 'react'
import { useWeb3 } from 'hooks/useWeb3'
import ClaimRewardModal from './modals/ClaimRewardModal'
import { PButton } from 'components/Button'

const TotalRewards = ({
  nativeTokenSymbol,
  wrappedTokenSymbol,
  processedData,
  chainId,
  rewardRouterAddress,
  disabled,
}) => {
  const { active, account, library } = useWeb3()
  const [isModalVisible, setIsModalVisible] = useState(false)

  return (
    <>
      <ClaimRewardModal
        active={active}
        account={account}
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        rewardRouterAddress={rewardRouterAddress}
        processedData={processedData}
        wrappedTokenSymbol={wrappedTokenSymbol}
        nativeTokenSymbol={nativeTokenSymbol}
        library={library}
        chainId={chainId}
      />

      <PButton onClick={() => setIsModalVisible(true)} disabled={disabled}>
        Claim Reward
      </PButton>
    </>
  )
}

export default TotalRewards
