import cx from 'classnames'
import { ConnectWalletButton } from 'components/Common/Button'
import FaucetBUSD from 'components/FaucetBUSD'
import { AnimatePresence, motion } from 'framer-motion'
import logoImg from 'img/tfx_64x64.svg'
import { useEffect, useState } from 'react'
import { AiFillBug } from 'react-icons/ai'
import { FaTimes } from 'react-icons/fa'
import { FiX } from 'react-icons/fi'
import { MdDarkMode, MdOutlineKeyboardArrowDown, MdOutlineWbSunny, MdSettings } from 'react-icons/md'
import { RiMenuLine } from 'react-icons/ri'
import { NavLink } from 'react-router-dom'
import { Sticky } from 'react-sticky'
import { getEnv, isLocal } from 'utils'

export const AppHeaderLinks = ({
  HeaderLink,
  small,
  openSettings,
  clickCloseIcon,
  theme,
  toggleTheme,
}: {
  HeaderLink: any
  small?: boolean
  openSettings?: any
  clickCloseIcon: () => void
  theme: 'dark' | 'light'
  toggleTheme: () => void
}) => {
  return (
    <div className="App-header-links">
      {small && (
        <div className="App-header-links-header">
          <div className="App-header-menu-icon-block" onClick={() => clickCloseIcon()}>
            <FiX className="App-header-menu-icon text-white" />
          </div>
          <HeaderLink className="App-header-link-main" to="/">
            <img src={logoImg} alt="TFX Logo" />
          </HeaderLink>
          <div className="flex items-center gap-4 ml-auto">
            <FaucetBUSD />
            <div onClick={toggleTheme} className="cursor-pointer px-2 mr-3 hover:!bg-transparent menu-item float-right">
              {theme === 'light' ? <MdOutlineWbSunny size={24} className="text-white" /> : <MdDarkMode size={24} />}
            </div>
          </div>
        </div>
      )}
      <div className="App-header-link-container" onClick={() => clickCloseIcon()}>
        <HeaderLink to="/dashboard">Dashboard</HeaderLink>
      </div>
      <div className="App-header-link-container" onClick={() => clickCloseIcon()}>
        <HeaderLink to="/pool">Pool</HeaderLink>
      </div>
      {isLocal() && (
        <>
          <div className="App-header-link-container" onClick={() => clickCloseIcon()}>
            <HeaderLink to="/referrals">Referrals</HeaderLink>
          </div>
        </>
      )}
      <div className="App-header-link-container" onClick={() => clickCloseIcon()}>
        <HeaderLink to="/explorer">Explorer</HeaderLink>
      </div>

      <div className="App-header-link-container" onClick={() => clickCloseIcon()}>
        <HeaderLink to="/stats">Stats</HeaderLink>
      </div>

      <div className="App-header-link-container" onClick={() => clickCloseIcon()}>
        <a href={getEnv('REACT_APP_LEADERBOARD_URL')}>Leaderboard</a>
      </div>

      <div className="App-header-link-container group relative dropdown">
        <div className="link">
          <div className="flex items-center justify-between">
            More
            <MdOutlineKeyboardArrowDown />
          </div>
        </div>

        <div className="group-hover:block dropdown-menu lg:absolute hidden h-auto">
          <ul className="top-0 w-full lg:w-[250px] lg:shadow-xl lg:border border-[#ffffff45] dark:border-[#6d6d6d45] rounded-xl p-1 lg:bg-[#ffffff9b]">
            <li className="py-1" onClick={() => clickCloseIcon()}>
              <a
                href="https://tfx-market.atlassian.net/servicedesk/customer/portal/1"
                target="_blank"
                rel="noopener noreferrer"
                className="!flex items-center gap-4"
              >
                <AiFillBug className="text-[#d95cff] text-[2.8rem]" />{' '}
                <span className="leading-5 dark:text-[#000]">
                  Report a Bug <br /> <span className="text-[10px]">See an issue? Report it to the team</span>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      {small && openSettings && (
        <div className="App-header-link-container">
          {/* eslint-disable-next-line */}
          <a href="#" onClick={openSettings}>
            Settings
          </a>
        </div>
      )}
    </div>
  )
}

interface AppHeaderUserProps {
  theme: 'dark' | 'light'
  toggleTheme: () => void
  active: boolean
  HeaderLink: any
  openSettings?: () => void
}
export const AppHeaderUser = ({ HeaderLink, openSettings, theme, toggleTheme, active }: AppHeaderUserProps) => {
  return (
    <div className="App-header-user">
      <div className="mr-4">
        <HeaderLink
          className="py-[.8rem] h-[34px] px-8 text-xl font-bold rounded-2xl
          bg-bg-color-6 text-white border-0 cursor-pointer"
          to="/trade"
        >
          Trade
        </HeaderLink>
      </div>

      <div className="mr-4 hidden md:inline-block">
        <FaucetBUSD />
      </div>

      <ConnectWalletButton theme={theme} />

      <div
        onClick={toggleTheme}
        className="cursor-pointer ml-4 items-center rounded-full border border-transparent p-1.5 text-white shadow-sm hover:opacity-100 hover:bg-bg-dark-color-2 !hidden md:!inline-flex"
      >
        {theme === 'light' ? <MdOutlineWbSunny className="text-gray-600" size={24} /> : <MdDarkMode size={24} />}
      </div>

      {active && openSettings && (
        <div
          className="cursor-pointer ml-2 items-center rounded-full border border-transparent p-1.5 text-white shadow-sm hover:opacity-100 hover:bg-bg-dark-color-2 !hidden md:!inline-flex"
          onClick={openSettings}
        >
          <MdSettings size={24} className="text-gray-600 dark:text-bg-color-1" />
        </div>
      )}
    </div>
  )
}

interface NavbarProps {
  openSettings?: () => void
  theme: 'dark' | 'light'
  toggleTheme: () => void
  active: boolean
}
export const Navbar = ({ openSettings, theme, toggleTheme, active }: NavbarProps) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const [isNativeSelectorModalVisible, setisNativeSelectorModalVisible] = useState(false)

  useEffect(() => {
    if (isDrawerVisible) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isDrawerVisible])

  const fadeVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  }
  const slideVariants = {
    hidden: { x: '-100%' },
    visible: { x: 0 },
  }

  const HeaderLink = ({ className, exact, to, children }) => {
    return (
      <NavLink activeClassName="active" className={cx(className)} exact={exact} to={to}>
        {children}
      </NavLink>
    )
  }

  return (
    <Sticky>
      {({ style }) => (
        <div style={style} className="z-50 fixed t-0 l-0 w-screen">
          {isDrawerVisible && (
            <AnimatePresence>
              {isDrawerVisible && (
                <motion.div
                  className="App-header-backdrop"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={fadeVariants}
                  transition={{ duration: 0.2 }}
                  onClick={() => setIsDrawerVisible(!isDrawerVisible)}
                ></motion.div>
              )}
            </AnimatePresence>
          )}
          {isNativeSelectorModalVisible && (
            <AnimatePresence>
              {isNativeSelectorModalVisible && (
                <motion.div
                  className="selector-backdrop"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={fadeVariants}
                  transition={{ duration: 0.2 }}
                  onClick={() => setisNativeSelectorModalVisible(!isNativeSelectorModalVisible)}
                ></motion.div>
              )}
            </AnimatePresence>
          )}

          <header>
            <div className="App-header large">
              <div className="App-header-container-left">
                <a className="App-header-link-main flex items-center gap-2" href="/">
                  <img src={logoImg} className="big" alt="TFX Logo" />
                  <img src={logoImg} className="small" alt="TFX Logo" />
                  <span className="NavTitle font-bold text-[2.2rem]">TFX</span>
                  <span className="NavTitle font-bold text-[1.5rem]"> (beta)</span>
                </a>
                <AppHeaderLinks
                  HeaderLink={HeaderLink}
                  openSettings={openSettings}
                  clickCloseIcon={() => {}}
                  theme={theme}
                  toggleTheme={toggleTheme}
                />
              </div>
              <div className="App-header-container-right">
                <AppHeaderUser
                  HeaderLink={HeaderLink}
                  openSettings={openSettings}
                  theme={theme}
                  toggleTheme={toggleTheme}
                  active={active}
                />
              </div>
            </div>
            <div className={cx('App-header', 'small', { active: isDrawerVisible })}>
              <div
                className={cx('App-header-link-container', 'App-header-top', {
                  active: isDrawerVisible,
                })}
              >
                <div className="App-header-container-left">
                  <div className="App-header-menu-icon-block" onClick={() => setIsDrawerVisible(!isDrawerVisible)}>
                    {!isDrawerVisible && <RiMenuLine className="App-header-menu-icon" />}
                    {isDrawerVisible && <FaTimes className="App-header-menu-icon" />}
                  </div>
                  <div
                    className="App-header-link-main clickable items-end"
                    onClick={() => setIsDrawerVisible(!isDrawerVisible)}
                  >
                    <img src={logoImg} className="big" alt="TFX Logo" />
                    <img src={logoImg} className="small" alt="TFX Logo" />
                    <span className="NavTitle font-bold text-[1.5rem] ml-[-5px]">(beta)</span>
                  </div>
                </div>
                <div className="App-header-container-right">
                  <AppHeaderUser
                    HeaderLink={HeaderLink}
                    openSettings={openSettings}
                    theme={theme}
                    toggleTheme={toggleTheme}
                    active={active}
                  />
                </div>
              </div>
            </div>
          </header>

          <AnimatePresence>
            {isDrawerVisible && (
              <>
                <div
                  className="bg-red-300 w-screen h-screen opacity-10 absolute top-0 left-0 z-0"
                  onClick={() => setIsDrawerVisible(false)}
                />
                <motion.div
                  // onClick={() => setIsDrawerVisible(false)}
                  className="App-header-links-container App-header-drawer z-10"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={slideVariants}
                  transition={{ duration: 0.2 }}
                >
                  <AppHeaderLinks
                    HeaderLink={HeaderLink}
                    small
                    openSettings={openSettings}
                    clickCloseIcon={() => setIsDrawerVisible(false)}
                    theme={theme}
                    toggleTheme={toggleTheme}
                  />
                </motion.div>
              </>
            )}
          </AnimatePresence>
        </div>
      )}
    </Sticky>
  )
}
