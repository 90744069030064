import Card from 'components/Card'
import { RiLoader5Fill } from 'react-icons/ri'

const ChartWrapper = (props) => {
  const { title, loading } = props

  return (
    <Card className="h-full">
      <div>
        <h3 className="text-3xl font-bold pb-10">{title}</h3>
      </div>
      {loading && <RiLoader5Fill size="3em" className="loader" />}
      {props.children}
    </Card>
  )
}

export default ChartWrapper
