import { useState } from 'react'
import { MdKeyboardArrowUp } from 'react-icons/md'

const ScrollToTopButton = () => {
  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 300) {
      setVisible(true)
    } else if (scrolled <= 300) {
      setVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  window.addEventListener('scroll', toggleVisible)

  return (
    <button
      onClick={scrollToTop}
      style={{
        display: visible ? 'inline' : 'none',
      }}
      type="button"
      className="fixed z-50 bottom-40 right-8 md:right-16 rounded-full border border-transparent bg-blue-600 p-3 text-white shadow-2xl hover:bg-blue-700 focus:outline-none"
    >
      <MdKeyboardArrowUp aria-hidden="true" size="20px" />
    </button>
  )
}

export default ScrollToTopButton
