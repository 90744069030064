import { useState } from 'react'
import { Link } from 'react-router-dom'
import useSWR from 'swr'
import TooltipComponent from 'components/Tooltip/Popper'
import { ethers } from 'ethers'

import { getWhitelistedTokens, getTokenBySymbol } from '@tfx/tokens'
import { getFeeHistory } from 'config/Fees'
import ScrollToTopButton from 'components/ScrollToTopButton/ScrollToTopButton'
import {
  fetcher,
  formatAmount,
  expandDecimals,
  bigNumberify,
  getServerUrl,
  arrayTextURLFetcher,
  useChainId,
  USD_DECIMALS,
  XLP_DECIMALS,
  BASIS_POINTS_DIVISOR,
  getPageTitle,
  arrayURLFetcher,
  getVolumeInfo,
  ACTIVE_CHAIN_IDS,
  DEV_TESTNET,
} from 'utils'
import { useInfoTokens } from 'utils/api'
import ParticlesBox from 'pages/Dashboard/Particles'
import { getContract } from '@tfx/addresses'

import VaultV2 from 'abis/Vault.json'
import ReaderV2 from 'abis/Reader.json'
import XlpManager from 'abis/GlpManager.json'
import { Footer } from '@tfx/ui'

import SEO from 'components/Common/SEO'
import { TooltipCardRow } from './TooltipCard'
import useTotalVolume from 'hooks/useTotalVolume'
import TokenList from './TokenList'
import LpStats from './LpStats'
import HeroSection from './HeroSectionV2'
import StatsOverview from './StatsOverview'
import { useWeb3 } from 'hooks/useWeb3'

import './Dashboard.css'

const { AddressZero } = ethers.constants

function getPositionStats(positionStats) {
  if (!positionStats || positionStats.length === 0) {
    return null
  }

  return positionStats.reduce(
    (acc, cv, i) => {
      acc.totalLongPositionSizes = acc.totalLongPositionSizes.add(cv.totalLongPositionSizes)
      acc.totalShortPositionSizes = acc.totalShortPositionSizes.add(cv.totalShortPositionSizes)
      acc.totalUniqueCount = acc.totalUniqueCount.add(cv.uniqueCount)
      acc[ACTIVE_CHAIN_IDS[i]] = cv
      return acc
    },
    {
      totalLongPositionSizes: bigNumberify(0),
      totalShortPositionSizes: bigNumberify(0),
      totalUniqueCount: bigNumberify(0),
    },
  )
}

function getCurrentFeesUsd(tokenAddresses, fees, infoTokens) {
  if (!fees || !infoTokens) {
    return bigNumberify(0)
  }

  let currentFeesUsd = bigNumberify(0)
  for (let i = 0; i < tokenAddresses.length; i++) {
    const tokenAddress = tokenAddresses[i]
    const tokenInfo = infoTokens[tokenAddress]
    if (!tokenInfo || !tokenInfo.contractMinPrice) {
      continue
    }

    const feeUsd = fees[i].mul(tokenInfo.contractMinPrice).div(expandDecimals(1, tokenInfo.decimals))
    currentFeesUsd = currentFeesUsd.add(feeUsd)
  }

  return currentFeesUsd
}

export default function DashboardV2() {
  const { active, library } = useWeb3()
  const { chainId } = useChainId()
  const totalVolume = useTotalVolume()

  const { data: positionStats } = useSWR(
    ACTIVE_CHAIN_IDS.map((chainId) => getServerUrl(chainId, `/position_stats?chain=${chainId}`)),
    {
      fetcher: arrayURLFetcher,
    },
  )

  const { data: hourlyVolumes } = useSWR(
    ACTIVE_CHAIN_IDS.map((chainId) => getServerUrl(chainId, `/hourly_volume?chain=${chainId}`)),
    {
      fetcher: arrayTextURLFetcher,
    },
  )

  const currentVolumeInfo = getVolumeInfo(hourlyVolumes)
  const currentTotalVolumeInfo = getVolumeInfo(totalVolume)
  const positionStatsInfo = getPositionStats(positionStats)

  let openInterest = bigNumberify(0)
  if (positionStatsInfo && positionStatsInfo?.totalLongPositionSizes && positionStatsInfo?.totalShortPositionSizes) {
    openInterest = openInterest.add(positionStatsInfo.totalLongPositionSizes)
    openInterest = openInterest.add(positionStatsInfo.totalShortPositionSizes)
  }

  let totalUsers = bigNumberify(0)
  if (positionStatsInfo && positionStatsInfo.totalUniqueCount) {
    totalUsers = totalUsers.add(positionStatsInfo.totalUniqueCount)
  }

  function getWhitelistedTokenAddresses(chainId) {
    const whitelistedTokens = getWhitelistedTokens(chainId)
    return whitelistedTokens.map((token) => token.address)
  }

  const whitelistedTokens = getWhitelistedTokens(chainId)
  const whitelistedTokenAddresses = whitelistedTokens.map((token) => token.address)
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped)
  const visibleTokens = tokenList.filter((t) => !t.isTempHidden)

  const readerAddress = getContract(chainId, 'Reader')
  const vaultAddress = getContract(chainId, 'Vault')
  const xlpManagerAddress = getContract(chainId, 'XlpManager')

  const xlpAddress = getContract(chainId, 'XLP')
  const usdxAddress = getContract(chainId, 'USDX')

  const tokensForSupplyQuery = [xlpAddress, usdxAddress]

  const { data: aums } = useSWR([`Dashboard:getAums:${active}`, chainId, xlpManagerAddress, 'getAums'], {
    fetcher: fetcher(library, XlpManager),
  })

  const { data: fees } = useSWR([`Dashboard:fees:${active}`, chainId, readerAddress, 'getFees', vaultAddress], {
    fetcher: fetcher(library, ReaderV2, [whitelistedTokenAddresses]),
  })

  const { data: totalSupplies } = useSWR(
    [`Dashboard:totalSupplies:${active}`, chainId, readerAddress, 'getTokenBalancesWithSupplies', AddressZero],
    {
      fetcher: fetcher(library, ReaderV2, [tokensForSupplyQuery]),
    },
  )

  const { data: totalTokenWeights } = useSWR(
    [`XlpSwap:totalTokenWeights:${active}`, chainId, vaultAddress, 'totalTokenWeights'],
    {
      fetcher: fetcher(library, VaultV2),
    },
  )

  const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined)
  const { infoTokens: infoTokensBinance } = useInfoTokens(null, DEV_TESTNET, active, undefined, undefined)

  const { data: currentFees } = useSWR(
    infoTokensBinance[AddressZero].contractMinPrice ? 'Dashboard:currentFees' : null,
    {
      fetcher: () => {
        return Promise.all(
          ACTIVE_CHAIN_IDS.map((chainId) =>
            fetcher(null, ReaderV2, [getWhitelistedTokenAddresses(chainId)])(
              `Dashboard:fees:${chainId}`,
              chainId,
              getContract(chainId, 'Reader'),
              'getFees',
              getContract(chainId, 'Vault'),
            ),
          ),
        ).then((fees) => {
          return fees.reduce(
            (acc, cv, i) => {
              const feeUSD = getCurrentFeesUsd(getWhitelistedTokenAddresses(ACTIVE_CHAIN_IDS[i]), cv, infoTokensBinance)
              acc[ACTIVE_CHAIN_IDS[i]] = feeUSD
              acc.total = acc.total.add(feeUSD)
              return acc
            },
            { total: bigNumberify(0) },
          )
        })
      },
    },
  )

  const eth = infoTokens[getTokenBySymbol(chainId, 'ETH').address]
  const currentFeesUsd = getCurrentFeesUsd(whitelistedTokenAddresses, fees, infoTokens)
  const feeHistory = getFeeHistory(chainId)
  const shouldIncludeCurrrentFees = feeHistory.length && parseInt(Date.now() / 1000) - feeHistory[0].to > 60 * 60
  let totalFeesDistributed = shouldIncludeCurrrentFees
    ? parseFloat(bigNumberify(formatAmount(currentFeesUsd, USD_DECIMALS - 2, 0, false)).toNumber()) / 100
    : 0

  const totalFees = ACTIVE_CHAIN_IDS.map((chainId) =>
    getFeeHistory(chainId).reduce((acc, fee) => acc + parseFloat(fee.feeUsd), totalFeesDistributed),
  )
    .map((v) => Math.round(v))
    .reduce(
      (acc, cv, i) => {
        acc[ACTIVE_CHAIN_IDS[i]] = cv
        acc.total = acc.total + cv
        return acc
      },
      { total: 0 },
    )

  let aum

  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2)
  }

  let xlpPrice
  let xlpSupply
  let xlpMarketCap
  if (aum && totalSupplies && totalSupplies[3]) {
    xlpSupply = totalSupplies[3]
    xlpPrice =
      aum && aum.gt(0) && xlpSupply.gt(0)
        ? aum.mul(expandDecimals(1, XLP_DECIMALS)).div(xlpSupply)
        : expandDecimals(1, USD_DECIMALS)
    xlpMarketCap = xlpPrice.mul(xlpSupply).div(expandDecimals(1, XLP_DECIMALS))
  }

  let tvl
  if (xlpMarketCap) {
    tvl = xlpMarketCap
  }

  const ethFloorPriceFund = expandDecimals(350 + 148 + 384, 18)
  const glpFloorPriceFund = expandDecimals(660001, 18)
  const usdcFloorPriceFund = expandDecimals(784598 + 200000, 30)

  let totalFloorPriceFundUsd

  if (eth && eth.contractMinPrice && xlpPrice) {
    const ethFloorPriceFundUsd = ethFloorPriceFund.mul(eth.contractMinPrice).div(expandDecimals(1, eth.decimals))
    const glpFloorPriceFundUsd = glpFloorPriceFund.mul(xlpPrice).div(expandDecimals(1, 18))

    totalFloorPriceFundUsd = ethFloorPriceFundUsd.add(glpFloorPriceFundUsd).add(usdcFloorPriceFund)
  }

  let adjustedUsdgSupply = bigNumberify(0)

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i]
    const tokenInfo = infoTokens[token.address]
    if (tokenInfo && tokenInfo.usdgAmount) {
      adjustedUsdgSupply = adjustedUsdgSupply.add(tokenInfo.usdgAmount)
    }
  }

  const getWeightText = (tokenInfo) => {
    if (
      !tokenInfo.weight ||
      !tokenInfo.usdgAmount ||
      !adjustedUsdgSupply ||
      adjustedUsdgSupply.eq(0) ||
      !totalTokenWeights
    ) {
      return '...'
    }

    const currentWeightBps = tokenInfo.usdgAmount.mul(BASIS_POINTS_DIVISOR).div(adjustedUsdgSupply)
    // use add(1).div(10).mul(10) to round numbers up
    const targetWeightBps = tokenInfo.weight.mul(BASIS_POINTS_DIVISOR).div(totalTokenWeights).add(1).div(10).mul(10)

    const weightText = `${formatAmount(currentWeightBps, 2, 2, false)}% / ${formatAmount(
      targetWeightBps,
      2,
      2,
      false,
    )}%`

    return (
      <TooltipComponent
        handle={weightText}
        renderContent={() => {
          return (
            <>
              <TooltipCardRow
                label="Current Weight"
                amount={`${formatAmount(currentWeightBps, 2, 2, false)}%`}
                showDollar={false}
              />
              <TooltipCardRow
                label="Target Weight"
                amount={`${formatAmount(targetWeightBps, 2, 2, false)}%`}
                showDollar={false}
              />
              <div className="my-5">
                {currentWeightBps.lt(targetWeightBps) && (
                  <div>
                    <div>{tokenInfo.symbol} is below its target weight.</div>
                    Get lower fees to{' '}
                    <Link to="/buy_xlp" target="_blank" rel="noopener noreferrer">
                      buy xLP
                    </Link>{' '}
                    with {tokenInfo.symbol},&nbsp; and to{' '}
                    <Link to="/trade" target="_blank" rel="noopener noreferrer">
                      swap
                    </Link>{' '}
                    {tokenInfo.symbol} for other tokens.
                  </div>
                )}
                {currentWeightBps.gt(targetWeightBps) && (
                  <div>
                    <div>{tokenInfo.symbol} is above its target weight.</div>
                    Get lower fees to{' '}
                    <Link to="/trade" target="_blank" rel="noopener noreferrer">
                      swap
                    </Link>{' '}
                    tokens for {tokenInfo.symbol}.
                  </div>
                )}
              </div>
              <div>
                <a
                  href="https://tfx-market.gitbook.io/tfx/xlp"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline"
                >
                  More Info
                </a>
              </div>
            </>
          )
        }}
      />
    )
  }

  const totalStatsStartDate = '26 June 2023'

  let stableGlp = 0
  let totalGlp = 0

  let xlpPool = tokenList.map((token) => {
    const tokenInfo = infoTokens[token.address]
    if (tokenInfo.usdgAmount && !adjustedUsdgSupply.isZero()) {
      const currentWeightBps = tokenInfo?.usdgAmount?.mul(BASIS_POINTS_DIVISOR)?.div(adjustedUsdgSupply)
      if (tokenInfo.isStable) {
        stableGlp += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`)
      }
      totalGlp += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`)
      return {
        fullname: token.name,
        name: token.symbol,
        value: parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`),
      }
    }
    return null
  })

  let stablePercentage = totalGlp > 0 ? ((stableGlp * 100) / totalGlp).toFixed(2) : '0.0'

  xlpPool = xlpPool.filter(function (element) {
    return element !== null
  })

  xlpPool = xlpPool.sort(function (a, b) {
    if (a.value < b.value) return 1
    else return -1
  })

  const [glpActiveIndex, setGLPActiveIndex] = useState(null)

  const onXLPPoolChartEnter = (_, index) => {
    setGLPActiveIndex(index)
  }

  const onXLPPoolChartLeave = (_, index) => {
    setGLPActiveIndex(null)
  }

  return (
    <SEO title={getPageTitle('Dashboard')}>
      <ParticlesBox />

      <HeroSection
        totalVolume={currentTotalVolumeInfo?.totalVolume ?? 0}
        openInterest={openInterest}
        totalUsers={totalUsers}
      />

      <div className="default-container page-layout">
        <StatsOverview
          chainId={chainId}
          tvl={tvl}
          aum={aum}
          currentVolumeInfo={currentVolumeInfo}
          positionStatsInfo={positionStatsInfo}
          currentFees={currentFees}
          feeHistory={feeHistory}
          totalFees={totalFees}
          currentTotalVolumeInfo={currentTotalVolumeInfo}
          totalFloorPriceFundUsd={totalFloorPriceFundUsd}
          totalStatsStartDate={totalStatsStartDate}
        />

        <div className="mt-40 py-10">
          <h1 className="text-[4.5rem] leading-[4.5rem] sm:text-[5.0rem] sm:leading-[5.5rem] font-bold mt-[1rem] mb-[6rem]">
            Provide liquidity to <br /> Owned protocol fees
          </h1>

          <LpStats
            chainId={chainId}
            xlpPrice={xlpPrice}
            xlpSupply={xlpSupply}
            xlpMarketCap={xlpMarketCap}
            stablePercentage={stablePercentage}
            xlpPool={xlpPool}
            onXLPPoolChartLeave={onXLPPoolChartLeave}
            onXLPPoolChartEnter={onXLPPoolChartEnter}
            glpActiveIndex={glpActiveIndex}
            infoTokens={infoTokens}
          />

          <TokenList visibleTokens={visibleTokens} infoTokens={infoTokens} getWeightText={getWeightText} />
        </div>
      </div>
      <ScrollToTopButton />

      <Footer />
    </SEO>
  )
}
