import Tooltip from '../Tooltip/Popper'

interface Props {
  label: string
  data: any
  tooltipText?: string
  toolTipPosition?: string
}

function InfoCard({ label, data, tooltipText }: Props) {
  return (
    <div className="flex flex-col border-b border-gray-100 dark:border-bg-color-2 p-6 text-center py-10 sm:border-0 sm:border-r last:border-0 gap-3">
      <dt className="order-2 mt-2 text-2xl font-medium leading-6 text-gray-500 dark:text-white">
        {tooltipText ? <Tooltip handle={label} renderContent={() => tooltipText} /> : label}
      </dt>
      <dd className="order-1 text-5xl font-bold tracking-tight">{data}</dd>
    </div>
  )
}

export default InfoCard
