import TokenWeightText from 'components/TokenWeightText'
import Tooltip from 'components/Tooltip/Tooltip'
import AssetDropdown from 'pages/Dashboard/AssetDropdown'
import {
  USDX_DECIMALS,
  USD_DECIMALS,
  adjustForDecimals,
  expandDecimals,
  formatAmount,
  formatKeyAmount,
  getBuyGlpFromAmount,
  getSellGlpFromAmount,
  getTokenInfo,
} from 'utils'
import { getCoinSGV } from 'utils/image'

const Fees = ({ isBuying, isCapReached, managedUsd, tokenFeeBps, symbol, address }) => {
  const swapUrl = `https://app.uniswap.org/#/swap?inputCurrency=${address}`
  switch (true) {
    case (isBuying && isCapReached) || (!isBuying && managedUsd?.lt(1)):
      return (
        <Tooltip
          handle="NA"
          renderContent={() => (
            <div>
              Max pool capacity reached for {symbol}
              <br />
              <br />
              Please mint xLP using another token
              <br />
              <p>
                <a href={swapUrl} target="_blank" rel="noreferrer">
                  Swap on Uniswap
                </a>
              </p>
            </div>
          )}
        />
      )
    case (isBuying && !isCapReached) || (!isBuying && managedUsd?.gt(0)):
      return `${formatAmount(tokenFeeBps, 2, 2, true, '-')}${
        tokenFeeBps !== undefined && tokenFeeBps.toString().length > 0 ? '%' : ''
      }`
    default:
      return ''
  }
}

const Item = ({
  tokenImage,
  tokenInfo,
  token,
  isBuying,
  amountLeftToDeposit,
  managedUsd,
  availableAmountUsd,
  isCapReached,
  tokenFeeBps,
}) => {
  return (
    <div
      key={token.symbol}
      className="grid border border-bg-color-2 lg:items-center lg:divide-y-0 lg:grid-cols-[1.5fr,.7fr,1fr,1fr,.6fr] lg:gap-4 w-full bg-bg-color-5 p-4 py-1 mb-4 rounded-3xl relative grid-cols-2 divide-y divide-stone-300 dark:bg-bg-dark-color-3 items-stretch"
    >
      <div className="lg:col-auto col-span-2 p-3 text-2xl">
        <div className="token-symbol-wrapper">
          <div className="App-card-title-info">
            <div className="App-card-title-info-icon">
              <img src={tokenImage} alt={token.symbol} width="40px" />
            </div>
            <div className="App-card-title-info-text">
              <div className="App-card-info-title">{token.name}</div>
              <div className="App-card-info-subtitle">{token.symbol}</div>
            </div>
            <div>
              <AssetDropdown assetSymbol={token.symbol} assetInfo={token} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-auto p-3 text-2xl">
        <div className="text-[10px] font-bold text-txt-color-1 block lg:hidden dark:text-white">Price: </div>
        {formatKeyAmount(tokenInfo, 'minPrice', USD_DECIMALS, 2, true)}
      </div>
      <div className="col-auto p-3 text-2xl">
        <div className="text-[10px] font-bold text-txt-color-1 block lg:hidden dark:text-white">Available: </div>
        {isBuying && (
          <div>
            <Tooltip
              handle={
                amountLeftToDeposit && amountLeftToDeposit.lt(0)
                  ? '$0.00'
                  : `$${formatAmount(amountLeftToDeposit, USD_DECIMALS, 2, true)}`
              }
              renderContent={() => {
                return (
                  <>
                    Current Pool Amount: ${formatAmount(managedUsd, USD_DECIMALS, 2, true)} (
                    {formatKeyAmount(tokenInfo, 'poolAmount', token.decimals, 2, true)} {token.symbol})
                    <br />
                    <br />
                    Max Pool Capacity: ${formatAmount(tokenInfo.maxUsdgAmount, 18, 0, true)}
                  </>
                )
              }}
            />
          </div>
        )}
        {!isBuying && (
          <div>
            <Tooltip
              handle={
                amountLeftToDeposit && amountLeftToDeposit.lt(0)
                  ? '$0.00'
                  : `$${formatAmount(availableAmountUsd, USD_DECIMALS, 2, true)}`
              }
              renderContent={() => {
                return (
                  <>
                    Current Pool Amount: {formatKeyAmount(tokenInfo, 'poolAmount', token.decimals, 2, true)}
                    {token.symbol}
                    <br />
                    <br />
                    Max Pool Capacity: ${formatAmount(tokenInfo.maxUsdgAmount, 18, 0, true)}
                  </>
                )
              }}
            />
          </div>
        )}
      </div>
      <div className="col-auto p-3 text-2xl">
        <div className="text-[10px] font-bold text-txt-color-1 block lg:hidden dark:text-white">Weight: </div>
        <TokenWeightText tokenInfo={tokenInfo} />
      </div>
      <div className="col-auto p-3 text-2xl">
        <div className="text-[10px] font-bold text-txt-color-1 block lg:hidden dark:text-white">Fees: </div>
        <Fees
          isBuying={isBuying}
          isCapReached={isCapReached}
          managedUsd={managedUsd}
          tokenFeeBps={tokenFeeBps}
          symbol={tokenInfo.symbol}
          address={tokenInfo.address}
        />
      </div>
    </div>
  )
}

const ItemList = ({
  items,
  isBuying,
  xlpAmount,
  infoTokens,
  xlpPrice,
  usdxSupply,
  totalTokenWeights,
  mintBurnFeeBasisPoints,
  taxBasisPoints,
}) => {
  return items.map((token) => {
    let tokenFeeBps
    if (isBuying) {
      const { feeBasisPoints: feeBps } = getBuyGlpFromAmount(
        xlpAmount,
        token.address,
        infoTokens,
        xlpPrice,
        usdxSupply,
        totalTokenWeights,
        mintBurnFeeBasisPoints,
        taxBasisPoints,
      )
      tokenFeeBps = feeBps
    } else {
      const { feeBasisPoints: feeBps } = getSellGlpFromAmount(
        xlpAmount,
        token.address,
        infoTokens,
        xlpPrice,
        usdxSupply,
        totalTokenWeights,
        mintBurnFeeBasisPoints,
        taxBasisPoints,
      )
      tokenFeeBps = feeBps
    }
    const tokenInfo = getTokenInfo(infoTokens, token.address)
    let managedUsd
    if (tokenInfo && tokenInfo.managedUsd) {
      managedUsd = tokenInfo.managedUsd
    }
    let availableAmountUsd
    if (tokenInfo && tokenInfo.minPrice && tokenInfo.availableAmount) {
      availableAmountUsd = tokenInfo.availableAmount.mul(tokenInfo.minPrice).div(expandDecimals(1, token.decimals))
    }

    const tokenImage = getCoinSGV(token.symbol)
    let isCapReached = tokenInfo.managedAmount?.gt(tokenInfo.maxUsdgAmount)

    let amountLeftToDeposit
    if (tokenInfo.maxUsdgAmount && tokenInfo.maxUsdgAmount.gt(0)) {
      amountLeftToDeposit = adjustForDecimals(tokenInfo.maxUsdgAmount, USDX_DECIMALS, USD_DECIMALS).sub(
        tokenInfo.managedUsd,
      )
    }

    return (
      <Item
        tokenImage={tokenImage}
        tokenInfo={tokenInfo}
        token={token}
        isBuying={isBuying}
        amountLeftToDeposit={amountLeftToDeposit}
        managedUsd={managedUsd}
        availableAmountUsd={availableAmountUsd}
        isCapReached={isCapReached}
        tokenFeeBps={tokenFeeBps}
        key={token.address}
      />
    )
  })
}

const TokenList = ({
  isBuying,
  visibleTokens,
  xlpAmount,
  infoTokens,
  xlpPrice,
  usdxSupply,
  totalTokenWeights,
  mintBurnFeeBasisPoints,
  taxBasisPoints,
}) => {
  return (
    <div className="w-full text-2xl bg-white rounded-[1.75rem] border dark:bg-bg-dark-color-2 dark:border-bg-dark-color-2 relative px-6 py-6">
      <div className="grid-cols-[1.5fr,.7fr,1fr,1fr,.6fr] gap-4 w-full px-4 mb-4 hidden lg:grid">
        <div className="col-auto text-left p-3">Token</div>
        <div className="col-auto text-left p-3">Price</div>
        <div className="col-auto text-left p-3">
          {isBuying ? (
            <Tooltip handle={'Available'} renderContent={() => 'Available amount to deposit into xLP.'} />
          ) : (
            <Tooltip
              handle={'Available'}
              renderContent={() => {
                return (
                  <>
                    <div>Available amount to withdraw from xLP.</div>
                    <div>Funds not utilized by current open positions.</div>
                  </>
                )
              }}
            />
          )}
        </div>
        <div className="col-auto text-left p-3">Weight</div>
        <div className="col-auto text-left p-3">
          <Tooltip
            handle={'Fees'}
            renderContent={() => {
              return (
                <>
                  <div>Fees will be shown once you have entered an amount in the order form.</div>
                </>
              )
            }}
          />
        </div>
      </div>

      <ItemList
        items={visibleTokens}
        isBuying={isBuying}
        xlpAmount={xlpAmount}
        infoTokens={infoTokens}
        xlpPrice={xlpPrice}
        usdxSupply={usdxSupply}
        totalTokenWeights={totalTokenWeights}
        mintBurnFeeBasisPoints={mintBurnFeeBasisPoints}
        taxBasisPoints={taxBasisPoints}
      />
    </div>
  )
}

export default TokenList
