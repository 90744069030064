import SEO from 'components/Common/SEO'
import { Footer } from '@tfx/ui'
import { getPageTitle } from 'utils'
import logoImg from 'img/tfx_64x64.svg'
import { Link } from 'react-router-dom'
import './PageNotFound.css'

function PageNotFound() {
  return (
    <SEO title={getPageTitle('Page not found')}>
      <div className="page-layout">
        <div className="page-not-found-container">
          <img src={logoImg} alt="TFX logo" className="w-[130px]" />
          <div className="page-not-found">
            <h2 className="text-[4rem] font-bold my-3">Page not found</h2>
            <p className="go-back text-[2rem]">
              <span>Return to </span>
              <Link className="underline" to="/">
                Homepage
              </Link>{' '}
              <span>or </span>
              <Link className="underline" to="/trade">
                Trade
              </Link>
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </SEO>
  )
}

export default PageNotFound
