import { ethers } from 'ethers';
import { ChainId } from '@tfx/chains';
import { getTokenAddress, getTokenInfo } from '@tfx/tokens';

// src/constant.ts
var bigNumberify = (n) => {
  try {
    return ethers.BigNumber.from(n);
  } catch (e) {
    console.error("bigNumberify error", e);
    return void 0;
  }
};
var expandDecimals = (n, decimals) => {
  var _a, _b;
  return (_b = bigNumberify(n)) == null ? void 0 : _b.mul(((_a = bigNumberify(10)) == null ? void 0 : _a.pow(decimals)) ?? 0);
};
var formatAmount = (amount, tokenDecimals, displayDecimals, useCommas, defaultValue) => {
  if (!defaultValue) {
    defaultValue = "...";
  }
  if (amount === void 0 || amount.toString().length === 0) {
    return defaultValue;
  }
  if (displayDecimals === void 0) {
    displayDecimals = 4;
  }
  let amountStr = ethers.utils.formatUnits(amount, tokenDecimals);
  if (displayDecimals > 0) {
    if (parseFloat(amountStr) < 0.01 && displayDecimals < 5) {
      displayDecimals = 5;
    } else if (parseFloat(amountStr) < 0.1 && displayDecimals < 4) {
      displayDecimals = 4;
    } else if (parseFloat(amountStr) < 2 && displayDecimals < 3) {
      displayDecimals = 3;
    }
  }
  amountStr = limitDecimals(amountStr, displayDecimals);
  if (displayDecimals !== 0) {
    amountStr = padDecimals(amountStr, displayDecimals);
  }
  if (useCommas) {
    return numberWithCommas(amountStr);
  }
  return amountStr;
};
var limitDecimals = (amount, maxDecimals) => {
  let amountStr = amount.toString();
  if (maxDecimals === void 0) {
    return amountStr;
  }
  if (maxDecimals === 0) {
    return amountStr.split(".")[0];
  }
  const dotIndex = amountStr.indexOf(".");
  if (dotIndex !== -1) {
    let decimals = amountStr.length - dotIndex - 1;
    if (decimals > maxDecimals) {
      amountStr = amountStr.substr(0, amountStr.length - (decimals - maxDecimals));
    }
  }
  return amountStr;
};
var padDecimals = (amount, minDecimals) => {
  let amountStr = amount.toString();
  const dotIndex = amountStr.indexOf(".");
  if (dotIndex !== -1) {
    const decimals = amountStr.length - dotIndex - 1;
    if (decimals < minDecimals) {
      amountStr = amountStr.padEnd(amountStr.length + (minDecimals - decimals), "0");
    }
  } else {
    amountStr = amountStr + ".0000";
  }
  return amountStr;
};
function numberWithCommas(value) {
  if (!value) {
    return "...";
  }
  var parts = value.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

// src/constant.ts
var { parseEther } = ethers.utils;
var BASIS_POINTS_DIVISOR = 10000n;
var FUNDING_RATE_PRECISION = 1000000n;
var USD_DECIMALS = 30;
var USD_DECIMAL_BASE = 1e30;
var DEFAULT_MAX_USDG_AMOUNT = expandDecimals(200 * 1e3 * 1e3, 18);
var UPDATED_POSITION_VALID_DURATION = 60 * 1e3;
var PENDING_POSITION_VALID_DURATION = 600 * 1e3;
var constants = {
  [ChainId.DEV_TESTNET]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "WETH",
    defaultCollateralSymbol: "USDC",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 9,
    v2: true,
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001")
  },
  [ChainId.ARBITRUM_SEPOLIA]: {
    nativeTokenSymbol: "ETH",
    wrappedTokenSymbol: "WETH",
    defaultCollateralSymbol: "USDC",
    defaultFlagOrdersEnabled: true,
    positionReaderPropsLength: 9,
    v2: true,
    SWAP_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    INCREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.0003"),
    // contract requires that execution fee be strictly greater than instead of gte
    DECREASE_ORDER_EXECUTION_GAS_FEE: parseEther("0.000300001")
  }
};
var getConstant = (chainId, key) => {
  if (!constants[chainId]) {
    throw new Error(`Unsupported chainId ${chainId}`);
  }
  if (!(key in constants[chainId])) {
    throw new Error(`Key ${key} does not exist for chainId ${chainId}`);
  }
  return constants[chainId][key];
};

// src/liquidation.ts
function getLiquidationPrice({
  isLong,
  size,
  collateral,
  averagePrice,
  entryFundingRate,
  cumulativeFundingRate,
  sizeDelta,
  collateralDelta,
  increaseCollateral,
  increaseSize,
  delta,
  hasProfit,
  includeDelta,
  marginFeeBasisPoints,
  liquidationFee,
  maxLeverage
}) {
  if (!size || !collateral || !averagePrice) {
    return;
  }
  let nextSize = size ? size : bigNumberify(0);
  let remainingCollateral = collateral;
  if (sizeDelta) {
    if (increaseSize) {
      nextSize = size.add(sizeDelta);
    } else {
      if (sizeDelta.gte(size)) {
        return;
      }
      nextSize = size.sub(sizeDelta);
    }
    if (delta && includeDelta && !hasProfit) {
      const adjustedDelta = sizeDelta.mul(delta).div(size);
      remainingCollateral = remainingCollateral.sub(adjustedDelta);
    }
  }
  if (collateralDelta) {
    if (increaseCollateral) {
      remainingCollateral = remainingCollateral.add(collateralDelta);
    } else {
      if (collateralDelta.gte(remainingCollateral)) {
        return;
      }
      remainingCollateral = remainingCollateral.sub(collateralDelta);
    }
  }
  let positionFee = getMarginFee(size, marginFeeBasisPoints).add(liquidationFee);
  if (entryFundingRate && cumulativeFundingRate) {
    const fundingFee = size.mul(cumulativeFundingRate.sub(entryFundingRate)).div(FUNDING_RATE_PRECISION);
    positionFee = positionFee.add(fundingFee);
  }
  const liquidationPriceForFees = getLiquidationPriceFromDelta({
    liquidationAmount: positionFee,
    size: nextSize,
    collateral: remainingCollateral,
    averagePrice,
    isLong
  });
  const liquidationPriceForMaxLeverage = getLiquidationPriceFromDelta({
    liquidationAmount: nextSize.mul(BASIS_POINTS_DIVISOR).div(maxLeverage),
    size: nextSize,
    collateral: remainingCollateral,
    averagePrice,
    isLong
  });
  if (!liquidationPriceForFees) {
    return liquidationPriceForMaxLeverage;
  }
  if (!liquidationPriceForMaxLeverage) {
    return liquidationPriceForFees;
  }
  if (isLong) {
    return liquidationPriceForFees.gt(liquidationPriceForMaxLeverage) ? liquidationPriceForFees : liquidationPriceForMaxLeverage;
  }
  return liquidationPriceForFees.lt(liquidationPriceForMaxLeverage) ? liquidationPriceForFees : liquidationPriceForMaxLeverage;
}
function getMarginFee(sizeDelta, marginFeeBasisPoints) {
  if (!sizeDelta || !marginFeeBasisPoints) {
    return bigNumberify(0);
  }
  const afterFeeUsd = sizeDelta.mul(BASIS_POINTS_DIVISOR - BigInt(marginFeeBasisPoints)).div(BASIS_POINTS_DIVISOR);
  return sizeDelta.sub(afterFeeUsd);
}
function getLiquidationPriceFromDelta({
  liquidationAmount,
  size,
  collateral,
  averagePrice,
  isLong
}) {
  if (!size || size.eq(0)) {
    return;
  }
  if (liquidationAmount.gt(collateral)) {
    const liquidationDelta2 = liquidationAmount.sub(collateral);
    const priceDelta2 = liquidationDelta2.mul(averagePrice).div(size);
    return isLong ? averagePrice.add(priceDelta2) : averagePrice.sub(priceDelta2);
  }
  const liquidationDelta = collateral.sub(liquidationAmount);
  const priceDelta = liquidationDelta.mul(averagePrice).div(size);
  return isLong ? averagePrice.sub(priceDelta) : averagePrice.add(priceDelta);
}
var contracts = {
  [ChainId.DEV_TESTNET]: {
    Vault: "0x4A753dE182cE4287432791c6d56D126B4BD221c9",
    VaultPositionController: "0x2346B80cAE8713677B34DAD5aF4BD6a3b38aBEAA",
    Router: "0xc1EB971614f0c9B7629eCDa38E673042b4F09C2B",
    Reader: "0x1f88Ee02f1e316C8C912151245CCf3eE8B858E50",
    RewardRouter: "0xd2Ee541759c27bA078E774c7ec73f6C4E8BA769C",
    RewardReader: "0x446764C69f12D2ee2565Cdc8f7A4DFcf97Af71b0",
    GMX: "0x00",
    XLP: "0x2D8d03e4E96BCa78c02F4d8190b1dE1253053430",
    OrderBook: "0x0Bbf0F7a0669C73F4801205ae06E30524592F405",
    OrderBookReader: "0x8b03979805A7315E4D13ecd14DB6005a6677E12c",
    USDX: "0xe2e92dF7F4A5B85d440b253bd0eE1c20De6756Fd",
    NATIVE_TOKEN: "0x078c04b8cfC949101905fdd5912D31Aad0a244Cb",
    StakedXlpDistributor: "0x773ECA5D8Ebf839a7A22Fc8636d02e6b24681a15",
    PositionRouter: "0xD33227F77F04e967f324A42e8eE3B03DF0bb7409",
    PositionManager: "0x00D60CCb6a66C4c962942753D16d1227B423De9e",
    PancakeGmxBnbPool: "0x00",
    ReferralStorage: "0x3111dBaccB884A21d125ADF2f2EFcbfacA4F3aB5",
    ReferralReader: "0x0089cA4792cecd09E6c27360d4F57B0EE406D223",
    FeeXlpTracker: "0xc5d9C951aF4c90bca07a65d3e10566bceF22d066",
    XlpManager: "0xc499BB911B4B009924d27B6e6136642357a44743",
    VaultReader: "0x7c831712da375C7ae98eDd621740911533996A41",
    XOracle: "0xCaa766A36Ea93c581299719934404D099E65478f",
    XlpManagerReader: "0x0c719AD6CDEa9B2E7D7F759D120a261ba2906c43",
    FullFillController: "0xB39cE44F8Ad7F1d47991e3AD227202Fb98D71C9F",
    FaucetBUSD: "0x00"
  },
  [ChainId.ARBITRUM_SEPOLIA]: {
    Vault: "0x50410C7FC9Edc9abe7f33f2b14e40630Bda7Fd93",
    VaultPositionController: "0xa828F3b5e0Fe2B370612c437897f5281769059CC",
    Router: "0x2E576bb26c220FF639B7A694E71f4b33f108a124",
    Reader: "0x4407C2D00aBe3f0a033e0921A9De37b894A04A26",
    RewardRouter: "0xdF2CA045c1F3Aa7e5974b6410262A12F549497B6",
    RewardReader: "0x53a79E66AeEB8b2b76D8F676D1A90E71A3d499d4",
    GMX: "0x00",
    XLP: "0x9eCB70FaB37b4415D64bb4bFf400d6B9B17Ad4d9",
    OrderBook: "0xfdb93d9420cc10c83BF5B5Bab7545bce0B4F746d",
    OrderBookReader: "0x25E585b09028945a0E1c61a69137d24c34629722",
    USDX: "0x89A6C053f5013e9c8C035db65C8409a6Af1a963c",
    NATIVE_TOKEN: "0x980B62Da83eFf3D4576C647993b0c1D7faf17c73",
    StakedXlpDistributor: "0x99f00629678C1347294eb2B490294833b42Fdf60",
    PositionRouter: "0x0B37a7Ba0ecd6B2246BCe4892B0BDe7Fd3813E8C",
    PositionManager: "0xd4A8a852aC6Ce633b560AA255F85CEC95a01306f",
    PancakeGmxBnbPool: "0x00",
    ReferralStorage: "0x586CF575d920FC4206F05869BfD5EF9dA051ef6C",
    ReferralReader: "0xE4B65e8Bd1A467c0DCe4468E14DB90Ae7790BfdF",
    FeeXlpTracker: "0x10ADd7c54b53e777368a000bCDb5AeB6FaADfE96",
    XlpManager: "0xC6B0Dea190677a2174403474cb1d7CD4d35ae90C",
    VaultReader: "0x81E77F6d9F67F6613AA86DD06953F2C996606b33",
    XOracle: "0xa3B16ad55513d91c8650Ef7D218A5299d59265d7",
    XlpManagerReader: "0xea5bB66c84D13C937E280f2Ee47edA1286C6e531",
    FullFillController: "0x9248b38F7A55C6d80f5F55954d294690Be93D267",
    FaucetBUSD: "0x00"
  }
};
var addresses_default = contracts;
function getContract(chainId, name) {
  const chainContracts = addresses_default[chainId];
  if (!chainContracts) {
    throw new Error(`Unknown chainId ${chainId}`);
  }
  const chainContractName = chainContracts[name];
  if (!chainContractName) {
    throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
  }
  return chainContractName;
}
var { AddressZero } = ethers.constants;
function getPositionQuery(tokens, nativeTokenAddress) {
  const collateralTokens = [];
  const indexTokens = [];
  const isLong = [];
  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    if (token.isStable) {
      continue;
    }
    if (token.isWrapped) {
      continue;
    }
    collateralTokens.push(getTokenAddress(token, nativeTokenAddress));
    indexTokens.push(getTokenAddress(token, nativeTokenAddress));
    isLong.push(true);
  }
  for (let i = 0; i < tokens.length; i++) {
    const stableToken = tokens[i];
    if (!stableToken.isStable) {
      continue;
    }
    for (let j = 0; j < tokens.length; j++) {
      const token = tokens[j];
      if (token.isStable) {
        continue;
      }
      if (token.isWrapped) {
        continue;
      }
      collateralTokens.push(stableToken.address);
      indexTokens.push(getTokenAddress(token, nativeTokenAddress));
      isLong.push(false);
    }
  }
  return { collateralTokens, indexTokens, isLong };
}
function getPositionKey(account, collateralTokenAddress, indexTokenAddress, isLong, nativeTokenAddress) {
  const tokenAddress0 = collateralTokenAddress === AddressZero ? nativeTokenAddress : collateralTokenAddress;
  const tokenAddress1 = indexTokenAddress === AddressZero ? nativeTokenAddress : indexTokenAddress;
  return account + ":" + tokenAddress0 + ":" + tokenAddress1 + ":" + isLong;
}
function getPositionContractKey(account, collateralToken, indexToken, isLong) {
  return ethers.utils.solidityKeccak256(
    ["address", "address", "address", "bool"],
    [account, collateralToken, indexToken, isLong]
  );
}
function getFundingFee(data) {
  let { entryFundingRate, cumulativeFundingRate, size } = data;
  if (entryFundingRate && cumulativeFundingRate) {
    return size.mul(cumulativeFundingRate.sub(entryFundingRate)).div(FUNDING_RATE_PRECISION);
  }
  return;
}
function getDeltaStr({
  delta,
  deltaPercentage,
  hasProfit
}) {
  let deltaStr;
  let deltaPercentageStr;
  if (delta.gt(0)) {
    deltaStr = hasProfit ? "+" : "-";
    deltaPercentageStr = hasProfit ? "+" : "-";
  } else {
    deltaStr = "";
    deltaPercentageStr = "";
  }
  deltaStr += `$${formatAmount(delta, USD_DECIMALS, 2, true)}`;
  deltaPercentageStr += `${formatAmount(deltaPercentage, 2, 2)}%`;
  return { deltaStr, deltaPercentageStr };
}
function getPositions(chainId, positionQuery, positionData, infoTokens, includeDelta, showPnlAfterFees, account, pendingPositions, updatedPositions, marginFeeBasisPoints, liquidationFee, maxLeverage) {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const propsLength = getConstant(chainId, "positionReaderPropsLength");
  const positions = [];
  const positionsMap = {};
  if (!positionData) {
    return { positions, positionsMap };
  }
  const { collateralTokens, indexTokens, isLong } = positionQuery;
  for (let i = 0; i < collateralTokens.length; i++) {
    const collateralToken = getTokenInfo(infoTokens, collateralTokens[i], true, getContract(chainId, "NATIVE_TOKEN"));
    const indexToken = getTokenInfo(infoTokens, indexTokens[i], true, getContract(chainId, "NATIVE_TOKEN"));
    const key = getPositionKey(account, collateralTokens[i], indexTokens[i], isLong[i]);
    let contractKey;
    if (account) {
      contractKey = getPositionContractKey(account, collateralTokens[i], indexTokens[i], isLong[i]);
    }
    const position = {
      key,
      contractKey,
      collateralToken,
      indexToken,
      isLong: isLong[i],
      size: positionData[i * propsLength],
      collateral: positionData[i * propsLength + 1],
      averagePrice: positionData[i * propsLength + 2],
      entryFundingRate: positionData[i * propsLength + 3],
      cumulativeFundingRate: collateralToken.cumulativeFundingRate,
      hasRealisedProfit: positionData[i * propsLength + 4].eq(1),
      realisedPnl: positionData[i * propsLength + 5],
      lastIncreasedTime: positionData[i * propsLength + 6].toNumber(),
      hasProfit: positionData[i * propsLength + 7].eq(1),
      delta: positionData[i * propsLength + 8],
      markPrice: isLong[i] ? indexToken.minPrice : indexToken.maxPrice,
      fundingFee: bigNumberify(0),
      collateralAfterFee: bigNumberify(0),
      closingFee: bigNumberify(0),
      positionFee: bigNumberify(0),
      totalFees: bigNumberify(0),
      pendingDelta: bigNumberify(0),
      hasLowCollateral: null,
      deltaPercentage: bigNumberify(0),
      deltaStr: "",
      deltaPercentageStr: "",
      deltaBeforeFeesStr: "",
      hasProfitAfterFees: false,
      pendingDeltaAfterFees: bigNumberify(0),
      deltaPercentageAfterFees: bigNumberify(0),
      deltaAfterFeesStr: "",
      deltaAfterFeesPercentageStr: "",
      netValue: bigNumberify(0),
      leverage: "",
      hasPendingChanges: false,
      marginFeeBasisPoints,
      liquidationFee,
      maxLeverage
    };
    if (updatedPositions && updatedPositions[key] && updatedPositions[key].updatedAt && updatedPositions[key].updatedAt + UPDATED_POSITION_VALID_DURATION > Date.now()) {
      const updatedPosition = updatedPositions[key];
      position.size = updatedPosition.size;
      position.collateral = updatedPosition.collateral;
      position.averagePrice = updatedPosition.averagePrice;
      position.entryFundingRate = updatedPosition.entryFundingRate;
    }
    let fundingFee = getFundingFee(position);
    position.fundingFee = fundingFee ? fundingFee : bigNumberify(0);
    position.collateralAfterFee = position.collateral.sub(position.fundingFee);
    position.closingFee = position.size.mul(marginFeeBasisPoints).div(BASIS_POINTS_DIVISOR);
    position.positionFee = position.size.mul(marginFeeBasisPoints).mul(2).div(BASIS_POINTS_DIVISOR);
    position.totalFees = (_a = position.positionFee) == null ? void 0 : _a.add((position == null ? void 0 : position.fundingFee) ?? 0);
    position.pendingDelta = position.delta;
    if (position.collateral.gt(0)) {
      position.hasLowCollateral = ((_b = position.collateralAfterFee) == null ? void 0 : _b.lt(0)) || position.size.div((_c = position.collateralAfterFee) == null ? void 0 : _c.abs()).gt(50);
      if (position.averagePrice && position.markPrice) {
        const priceDelta = position.averagePrice.gt(position.markPrice) ? position.averagePrice.sub(position.markPrice) : position.markPrice.sub(position.averagePrice);
        position.pendingDelta = position.size.mul(priceDelta).div(position.averagePrice);
        position.delta = position.pendingDelta;
        if (position.isLong) {
          position.hasProfit = position.markPrice.gte(position.averagePrice);
        } else {
          position.hasProfit = position.markPrice.lte(position.averagePrice);
        }
      }
      position.deltaPercentage = (_d = position.pendingDelta) == null ? void 0 : _d.mul(BASIS_POINTS_DIVISOR).div(position.collateral);
      const { deltaStr, deltaPercentageStr } = getDeltaStr({
        delta: position.pendingDelta ?? bigNumberify(0),
        deltaPercentage: position.deltaPercentage ?? bigNumberify(0),
        hasProfit: position.hasProfit
      });
      position.deltaStr = deltaStr;
      position.deltaPercentageStr = deltaPercentageStr;
      position.deltaBeforeFeesStr = deltaStr;
      let hasProfitAfterFees;
      let pendingDeltaAfterFees;
      if (position.hasProfit) {
        if ((_e = position.pendingDelta) == null ? void 0 : _e.gt(position == null ? void 0 : position.totalFees)) {
          hasProfitAfterFees = true;
          pendingDeltaAfterFees = position.pendingDelta.sub(position.totalFees);
        } else {
          hasProfitAfterFees = false;
          pendingDeltaAfterFees = (_f = position.totalFees) == null ? void 0 : _f.sub(position.pendingDelta);
        }
      } else {
        hasProfitAfterFees = false;
        pendingDeltaAfterFees = (_g = position.pendingDelta) == null ? void 0 : _g.add(position.totalFees);
      }
      position.hasProfitAfterFees = hasProfitAfterFees;
      position.pendingDeltaAfterFees = pendingDeltaAfterFees;
      position.deltaPercentageAfterFees = (_h = position.pendingDeltaAfterFees) == null ? void 0 : _h.mul(BASIS_POINTS_DIVISOR).div(position.collateral);
      const { deltaStr: deltaAfterFeesStr, deltaPercentageStr: deltaAfterFeesPercentageStr } = getDeltaStr({
        delta: position.pendingDeltaAfterFees,
        deltaPercentage: position.deltaPercentageAfterFees,
        hasProfit: hasProfitAfterFees
      });
      position.deltaAfterFeesStr = deltaAfterFeesStr;
      position.deltaAfterFeesPercentageStr = deltaAfterFeesPercentageStr;
      if (showPnlAfterFees) {
        position.deltaStr = position.deltaAfterFeesStr;
        position.deltaPercentageStr = position.deltaAfterFeesPercentageStr;
      }
      let netValue = position.hasProfit ? position.collateral.add(position.pendingDelta) : position.collateral.sub(position.pendingDelta);
      netValue = netValue.sub(position.fundingFee);
      if (showPnlAfterFees) {
        netValue = netValue.sub(position.closingFee);
      }
      position.netValue = netValue;
    }
    position.leverage = getLeverage({
      size: position.size,
      collateral: position.collateral,
      entryFundingRate: position.entryFundingRate,
      cumulativeFundingRate: position.cumulativeFundingRate,
      hasProfit: position.hasProfit,
      delta: position.delta,
      includeDelta,
      marginFeeBasisPoints: position.marginFeeBasisPoints
    });
    positionsMap[key] = position;
    applyPendingChanges(position, pendingPositions);
    if (position.size.gt(0) || position.hasPendingChanges) {
      positions.push(position);
    }
  }
  return { positions, positionsMap };
}
function getLeverage({
  size,
  sizeDelta,
  increaseSize,
  collateral,
  collateralDelta,
  increaseCollateral,
  entryFundingRate,
  cumulativeFundingRate,
  hasProfit,
  delta,
  includeDelta,
  marginFeeBasisPoints
}) {
  if (!size && !sizeDelta) {
    return;
  }
  if (!collateral && !collateralDelta) {
    return;
  }
  if (!marginFeeBasisPoints) {
    return;
  }
  let nextSize = size ? size : bigNumberify(0);
  if (sizeDelta) {
    if (increaseSize) {
      nextSize = size.add(sizeDelta);
    } else {
      if (sizeDelta.gte(size)) {
        return;
      }
      nextSize = size.sub(sizeDelta);
    }
  }
  let remainingCollateral = collateral ? collateral : bigNumberify(0);
  if (collateralDelta) {
    if (increaseCollateral) {
      remainingCollateral = collateral.add(collateralDelta);
    } else {
      if (collateralDelta.gte(collateral)) {
        return;
      }
      remainingCollateral = collateral.sub(collateralDelta);
    }
  }
  if (delta && includeDelta) {
    if (hasProfit) {
      remainingCollateral = remainingCollateral.add(delta);
    } else {
      if (delta.gt(remainingCollateral)) {
        return;
      }
      remainingCollateral = remainingCollateral.sub(delta);
    }
  }
  if (remainingCollateral.eq(0)) {
    return;
  }
  remainingCollateral = sizeDelta ? remainingCollateral.mul(BASIS_POINTS_DIVISOR - BigInt(marginFeeBasisPoints)).div(BASIS_POINTS_DIVISOR) : remainingCollateral;
  if (entryFundingRate && cumulativeFundingRate) {
    const fundingFee = size.mul(cumulativeFundingRate.sub(entryFundingRate)).div(FUNDING_RATE_PRECISION);
    remainingCollateral = remainingCollateral.sub(fundingFee);
  }
  return nextSize.mul(BASIS_POINTS_DIVISOR).div(remainingCollateral);
}
function applyPendingChanges(position, pendingPositions) {
  if (!pendingPositions) {
    return;
  }
  const { key } = position;
  if (pendingPositions[key] && pendingPositions[key].updatedAt && pendingPositions[key].pendingChanges && pendingPositions[key].updatedAt + PENDING_POSITION_VALID_DURATION > Date.now()) {
    const { pendingChanges } = pendingPositions[key];
    if (pendingChanges.size && position.size.eq(pendingChanges.size)) {
      return;
    }
    if (pendingChanges.expectingCollateralChange && !position.collateral.eq(pendingChanges.collateralSnapshot)) {
      return;
    }
    position.hasPendingChanges = true;
    position.pendingChanges = pendingChanges;
  }
}

// src/token.ts
function getInfoTokens(chainId, tokens, tokenBalances, whitelistedTokens, vaultTokenInfo, fundingRateInfo, vaultPropsLength, indexPrices = {}) {
  if (!vaultPropsLength) {
    vaultPropsLength = 15;
  }
  if (!indexPrices) {
    return;
  }
  const USDG_ADDRESS = getContract(chainId, "USDX");
  const fundingRatePropsLength = 2;
  const infoTokens = {};
  for (let i = 0; i < tokens.length; i++) {
    const token = JSON.parse(JSON.stringify(tokens[i]));
    if (tokenBalances) {
      token.balance = tokenBalances[i];
    }
    if (token.address === USDG_ADDRESS) {
      token.minPrice = expandDecimals(1, USD_DECIMALS);
      token.maxPrice = expandDecimals(1, USD_DECIMALS);
    }
    infoTokens[token.address] = token;
  }
  for (let i = 0; i < whitelistedTokens.length; i++) {
    const token = JSON.parse(JSON.stringify(whitelistedTokens[i]));
    const tokenpriceFeedIndex = token.priceFeedIndex;
    const indexPrice = indexPrices[tokenpriceFeedIndex];
    if (vaultTokenInfo) {
      const indexPriceBn = indexPrice ? bigNumberify(indexPrice) : bigNumberify(expandDecimals(1, USD_DECIMALS));
      token.poolAmount = vaultTokenInfo[i * vaultPropsLength] || 0;
      token.reservedAmount = vaultTokenInfo[i * vaultPropsLength + 1];
      token.availableAmount = token.poolAmount.sub(token.reservedAmount);
      token.usdgAmount = vaultTokenInfo[i * vaultPropsLength + 2];
      token.redemptionAmount = vaultTokenInfo[i * vaultPropsLength + 3];
      token.weight = vaultTokenInfo[i * vaultPropsLength + 4];
      token.bufferAmount = vaultTokenInfo[i * vaultPropsLength + 5];
      token.maxUsdgAmount = vaultTokenInfo[i * vaultPropsLength + 6];
      token.globalShortSize = vaultTokenInfo[i * vaultPropsLength + 7];
      token.maxGlobalShortSize = vaultTokenInfo[i * vaultPropsLength + 8];
      token.maxGlobalLongSize = vaultTokenInfo[i * vaultPropsLength + 9];
      token.minPrice = indexPriceBn;
      token.maxPrice = indexPriceBn;
      token.guaranteedUsd = vaultTokenInfo[i * vaultPropsLength + 12];
      token.maxPrimaryPrice = indexPriceBn;
      token.minPrimaryPrice = indexPriceBn;
      token.contractMinPrice = token.minPrice;
      token.contractMaxPrice = token.maxPrice;
      token.maxAvailableShort = bigNumberify(0);
      token.hasMaxAvailableShort = false;
      if (token.maxGlobalShortSize.gt(0)) {
        token.hasMaxAvailableShort = true;
        if (token.maxGlobalShortSize.gt(token.globalShortSize)) {
          token.maxAvailableShort = token.maxGlobalShortSize.sub(token.globalShortSize);
        }
      }
      if (token.maxUsdgAmount.eq(0)) {
        token.maxUsdgAmount = DEFAULT_MAX_USDG_AMOUNT;
      }
      token.availableUsd = token.isStable ? token.poolAmount.mul(token.minPrice).div(expandDecimals(1, token.decimals)) : token.availableAmount.mul(token.minPrice).div(expandDecimals(1, token.decimals));
      token.maxAvailableLong = bigNumberify(0);
      token.hasMaxAvailableLong = false;
      if (token.maxGlobalLongSize.gt(0)) {
        token.hasMaxAvailableLong = true;
        if (token.maxGlobalLongSize.gt(token.guaranteedUsd)) {
          const remainingLongSize = token.maxGlobalLongSize.sub(token.guaranteedUsd);
          token.maxAvailableLong = remainingLongSize.lt(token.availableUsd) ? remainingLongSize : token.availableUsd;
        }
      } else {
        token.maxAvailableLong = token.availableUsd;
      }
      token.maxLongCapacity = token.maxGlobalLongSize.gt(0) && token.maxGlobalLongSize.lt(token.availableUsd) ? token.maxGlobalLongSize : token.availableUsd;
      token.managedUsd = token.availableUsd.add(token.guaranteedUsd);
      token.managedAmount = token.managedUsd.mul(expandDecimals(1, token.decimals)).div(token.minPrice);
    }
    if (fundingRateInfo) {
      token.fundingRate = fundingRateInfo[i * fundingRatePropsLength];
      token.cumulativeFundingRate = fundingRateInfo[i * fundingRatePropsLength + 1];
    }
    if (infoTokens[token.address]) {
      token.balance = infoTokens[token.address].balance;
    }
    infoTokens[token.address] = token;
  }
  return infoTokens;
}

// src/trade.ts
var eventDates = [
  {
    start: 1697378400,
    end: 1698847200
  },
  {
    start: 1698847200,
    end: 1701439200
  }
];
var TOKEN_SYMBOL = /* @__PURE__ */ ((TOKEN_SYMBOL2) => {
  TOKEN_SYMBOL2["WBNB"] = "WBNB";
  TOKEN_SYMBOL2["USDG"] = "USDG";
  TOKEN_SYMBOL2["BUSD"] = "BUSD";
  TOKEN_SYMBOL2["BNB"] = "BNB";
  TOKEN_SYMBOL2["ETH"] = "ETH";
  TOKEN_SYMBOL2["WETH"] = "WETH";
  TOKEN_SYMBOL2["BTC"] = "BTC";
  TOKEN_SYMBOL2["UNI"] = "UNI";
  TOKEN_SYMBOL2["LINK"] = "LINK";
  TOKEN_SYMBOL2["AVAX"] = "AVAX";
  TOKEN_SYMBOL2["USDT"] = "USDT";
  TOKEN_SYMBOL2["DAI"] = "DAI";
  TOKEN_SYMBOL2["USDC"] = "USDC";
  TOKEN_SYMBOL2["FRAX"] = "FRAX";
  TOKEN_SYMBOL2["MIM"] = "MIM";
  TOKEN_SYMBOL2["DOGE"] = "DOGE";
  TOKEN_SYMBOL2["MATIC"] = "MATIC";
  TOKEN_SYMBOL2["OP"] = "OP";
  TOKEN_SYMBOL2["ARB"] = "ARB";
  return TOKEN_SYMBOL2;
})(TOKEN_SYMBOL || {});
var TradeStatus = /* @__PURE__ */ ((TradeStatus2) => {
  TradeStatus2["OPEN"] = "open";
  TradeStatus2["CLOSED"] = "closed";
  TradeStatus2["LIQUIDATED"] = "liquidated";
  return TradeStatus2;
})(TradeStatus || {});

export { BASIS_POINTS_DIVISOR, DEFAULT_MAX_USDG_AMOUNT, FUNDING_RATE_PRECISION, PENDING_POSITION_VALID_DURATION, TOKEN_SYMBOL, TradeStatus, UPDATED_POSITION_VALID_DURATION, USD_DECIMALS, USD_DECIMAL_BASE, bigNumberify, eventDates, expandDecimals, formatAmount, getConstant, getDeltaStr, getInfoTokens, getLeverage, getLiquidationPrice, getLiquidationPriceFromDelta, getMarginFee, getPositionContractKey, getPositionKey, getPositionQuery, getPositions, limitDecimals, numberWithCommas, padDecimals };
