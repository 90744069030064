import useSWR from 'swr'
import { utils } from 'ethers'
import { pricefeedApiUrl } from './common'

export const usePrices = () => {
  const {
    data,
    mutate: updatePrices,
    error: pricesError,
  } = useSWR('xoracle', {
    fetcher: priceFetching,
  })

  return [data ?? {}, updatePrices, pricesError]
}

export const usePrice = (index: string) => {
  const {
    data,
    mutate: updatePrice,
    error: pricesError,
  } = useSWR(`xoracle:${index}`, {
    fetcher: async () => {
      const resp = await priceFetching()
      return resp[index]
    },
  })

  return [data, updatePrice, pricesError]
}

export const priceFetching = async () => {
  const xoraclePriceUrl = `${pricefeedApiUrl}/prices/xoracle`
  return fetch(xoraclePriceUrl)
    .then(async (res) => {
      const json = await res.json()
      const ret = {}

      for (const key of Object.keys(json)) {
        const convertedTo30dcm = utils.parseUnits(json[key], 22)
        ret[key.toLowerCase()] = convertedTo30dcm
      }
      return ret
    })
    .catch(() => ({}))
}
