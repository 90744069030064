import cx from 'classnames'
import { useState } from 'react'

import { getLeverage, getLiquidationPrice } from '@tfx/tfx-sdk'
import { PButton } from 'components/Button'
import { ImSpinner2 } from 'react-icons/im'
import {
  bigNumberify,
  DECREASE,
  formatAmount,
  FUNDING_RATE_PRECISION,
  getOrderError,
  getUsd,
  helperToast,
  INCREASE,
  LONG,
  SHORT,
  SWAP,
  USD_DECIMALS,
} from 'utils'
import { useAccount } from 'wagmi'
import Tooltip from '../Tooltip/Popper'
import OrdersToa from './OrdersToa'
import PositionEditor from './PositionEditor'
import PositionSeller from './PositionSeller'

const getOrdersForPosition = (account, position, orders, nativeTokenAddress) => {
  if (!orders || orders.length === 0) {
    return []
  }
  /* eslint-disable array-callback-return */
  return orders
    .filter((order) => {
      if (order.type === SWAP) {
        return false
      }
      const hasMatchingIndexToken =
        order.indexToken === nativeTokenAddress
          ? position.indexToken.isNative
          : order.indexToken === position.indexToken.address
      const hasMatchingCollateralToken =
        order.collateralToken === nativeTokenAddress
          ? position.collateralToken.isNative
          : order.collateralToken === position.collateralToken.address
      if (order.isLong === position.isLong && hasMatchingIndexToken && hasMatchingCollateralToken) {
        return true
      }
    })
    .map((order) => {
      order.error = getOrderError(account, order, undefined, position)
      if (order.type === DECREASE && order.sizeDelta.gt(position.size)) {
        order.error = 'Order size is bigger than position, will only be executable if position increases'
      }
      return order
    })
}

export default function PositionsList(props) {
  const {
    pendingPositions,
    setPendingPositions,
    positions,
    positionsDataIsLoading,
    positionsMap,
    infoTokens,
    active,
    account,
    library,
    pendingTxns,
    setPendingTxns,
    setListSection,
    flagOrdersEnabled,
    savedIsPnlInLeverage,
    chainId,
    nativeTokenAddress,
    orders,
    setIsWaitingForPluginApproval,
    approveOrderBook,
    isPluginApproving,
    isWaitingForPluginApproval,
    orderBookApproved,
    positionRouterApproved,
    isWaitingForPositionRouterApproval,
    isPositionRouterApproving,
    approvePositionRouter,
    showPnlAfterFees,
    setMarket,
    minExecutionFee,
    minExecutionFeeUSD,
    minExecutionFeeErrorMessage,
  } = props

  const { chain } = useAccount()
  const [positionToEditKey, setPositionToEditKey] = useState(undefined)
  const [positionToSellKey, setPositionToSellKey] = useState(undefined)
  const [isPositionEditorVisible, setIsPositionEditorVisible] = useState(undefined)
  const [isPositionSellerVisible, setIsPositionSellerVisible] = useState(undefined)
  const [collateralTokenAddress, setCollateralTokenAddress] = useState(undefined)
  const [ordersToaOpen, setOrdersToaOpen] = useState(false)
  const [isHigherSlippageAllowed, setIsHigherSlippageAllowed] = useState(false)

  const editPosition = (position) => {
    setCollateralTokenAddress(position.collateralToken.address)
    setPositionToEditKey(position.key)
    setIsPositionEditorVisible(true)
  }

  const sellPosition = (position) => {
    setPositionToSellKey(position.key)
    setIsPositionSellerVisible(true)
    setIsHigherSlippageAllowed(false)
  }

  const onPositionClick = (position) => {
    helperToast.success(`${position.isLong ? 'Long' : 'Short'} ${position.indexToken.symbol} market selected`)
    setMarket(position.isLong ? LONG : SHORT, position.indexToken.address)
  }

  return (
    <div className="PositionsList">
      <PositionEditor
        pendingPositions={pendingPositions}
        setPendingPositions={setPendingPositions}
        positionsMap={positionsMap}
        positionKey={positionToEditKey}
        isVisible={isPositionEditorVisible}
        setIsVisible={setIsPositionEditorVisible}
        infoTokens={infoTokens}
        active={active}
        account={account}
        library={library}
        collateralTokenAddress={collateralTokenAddress}
        pendingTxns={pendingTxns}
        setPendingTxns={setPendingTxns}
        getUsd={getUsd}
        getLeverage={getLeverage}
        savedIsPnlInLeverage={savedIsPnlInLeverage}
        positionRouterApproved={positionRouterApproved}
        isPositionRouterApproving={isPositionRouterApproving}
        isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
        approvePositionRouter={approvePositionRouter}
        chainId={chainId}
        minExecutionFee={minExecutionFee}
        minExecutionFeeUSD={minExecutionFeeUSD}
        minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
      />
      {ordersToaOpen && (
        <OrdersToa
          setIsVisible={setOrdersToaOpen}
          approveOrderBook={approveOrderBook}
          isPluginApproving={isPluginApproving}
        />
      )}
      {isPositionSellerVisible && (
        <PositionSeller
          pendingPositions={pendingPositions}
          setPendingPositions={setPendingPositions}
          setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
          approveOrderBook={approveOrderBook}
          isPluginApproving={isPluginApproving}
          isWaitingForPluginApproval={isWaitingForPluginApproval}
          orderBookApproved={orderBookApproved}
          positionsMap={positionsMap}
          positionKey={positionToSellKey}
          isVisible={isPositionSellerVisible}
          setIsVisible={setIsPositionSellerVisible}
          infoTokens={infoTokens}
          active={active}
          account={account}
          orders={orders}
          library={library}
          pendingTxns={pendingTxns}
          setPendingTxns={setPendingTxns}
          flagOrdersEnabled={flagOrdersEnabled}
          savedIsPnlInLeverage={savedIsPnlInLeverage}
          chainId={chainId}
          nativeTokenAddress={nativeTokenAddress}
          setOrdersToaOpen={setOrdersToaOpen}
          positionRouterApproved={positionRouterApproved}
          isPositionRouterApproving={isPositionRouterApproving}
          isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
          approvePositionRouter={approvePositionRouter}
          isHigherSlippageAllowed={isHigherSlippageAllowed}
          setIsHigherSlippageAllowed={setIsHigherSlippageAllowed}
          minExecutionFee={minExecutionFee}
          minExecutionFeeUSD={minExecutionFeeUSD}
          minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
        />
      )}

      <div className="w-full p-4 text-xl">
        <div className="grid-cols-8 gap-4 w-full mb-6 hidden md:grid">
          <div className="col-auto text-left p-3">Position</div>
          <div className="col-auto text-left p-3">Net Value</div>
          <div className="col-auto text-left p-3">Size</div>
          <div className="col-auto text-left p-3">Collateral</div>
          <div className="col-auto text-left p-3">Mark Price</div>
          <div className="col-auto text-left p-3">Entry Price</div>
          <div className="col-auto text-left p-3">Liq. Price</div>
          <div className="col-auto text-left p-3"></div>
        </div>

        <div className="w-full">
          {positions.length === 0 && positionsDataIsLoading && (
            <div className="text-center py-[8rem] border-t dark:border-bg-dark-color-2">Loading...</div>
          )}

          {positions.length === 0 && !positionsDataIsLoading && chain && (
            <div className="text-center py-[8rem] border-t dark:border-bg-dark-color-2">No open positions</div>
          )}

          {!chain && (
            <div className="text-center py-[8rem] border-t dark:border-bg-dark-color-2">No open positions</div>
          )}

          {chain &&
            positions.map((position) => {
              const liquidationPrice = getLiquidationPrice(position) || bigNumberify(0)
              const positionOrders = getOrdersForPosition(account, position, orders, nativeTokenAddress)
              // const hasOrderError = !!positionOrders.find((order) => order.error);
              const hasPositionProfit = position[showPnlAfterFees ? 'hasProfitAfterFees' : 'hasProfit']
              const positionDelta =
                position[showPnlAfterFees ? 'pendingDeltaAfterFees' : 'pendingDelta'] || bigNumberify(0)
              let borrowFeeText
              if (position.collateralToken && position.collateralToken.fundingRate) {
                const borrowFeeRate = position.collateralToken.fundingRate
                  .mul(position.size)
                  .mul(24)
                  .div(FUNDING_RATE_PRECISION)
                borrowFeeText = `Borrow Fee / Day: $${formatAmount(borrowFeeRate, USD_DECIMALS, 2, true)}`
              }

              return (
                <div
                  className={cx(
                    'grid md:divide-y-0 md:grid-cols-8 md:gap-4 w-full bg-white pl-0 p-2 mb-4 rounded-3xl border-l-4 relative',
                    'grid-cols-2 divide-y dark:bg-bg-dark-color-3',
                    {
                      'border-green-600': position.isLong,
                      'border-red-500': !position.isLong,
                    },
                  )}
                  key={position.key}
                >
                  <div className="clickable col-auto p-3" onClick={() => onPositionClick(position)}>
                    <div>
                      <div className="text-[10px] font-bold text-txt-color-1 block md:hidden dark:text-white">
                        Position:{' '}
                      </div>
                      {position.indexToken.symbol}
                      {position.hasPendingChanges && <ImSpinner2 className="spin position-loading-icon" />}
                    </div>

                    <div>
                      {position.leverage && (
                        <span className="muted">{formatAmount(position.leverage, 4, 2, true)}x&nbsp;</span>
                      )}
                      <span
                        className={cx({
                          positive: position.isLong,
                          negative: !position.isLong,
                        })}
                      >
                        {position.isLong ? 'Long' : 'Short'}
                      </span>
                    </div>
                  </div>

                  <div
                    className="clickable col-auto p-3"
                    style={{ borderTopWidth: 'inherit' }}
                    onClick={() => onPositionClick(position)}
                  >
                    <div className="text-[10px] font-bold text-txt-color-1 block md:hidden dark:text-white">
                      Net Value:{' '}
                    </div>
                    <div>
                      {!position.netValue && 'Opening...'}
                      {position.netValue && (
                        <Tooltip
                          handle={`$${formatAmount(position.netValue, USD_DECIMALS, 2, true)}`}
                          renderContent={() => {
                            return (
                              <>
                                Net Value:{' '}
                                {showPnlAfterFees
                                  ? 'Initial Collateral - Fees + PnL'
                                  : 'Initial Collateral - Borrow Fee + PnL'}
                                <br />
                                <br />
                                Initial Collateral: ${formatAmount(position.collateral, USD_DECIMALS, 2, true)}
                                <br />
                                PnL: {position.deltaBeforeFeesStr}
                                <br />
                                Borrow Fee: ${formatAmount(position.fundingFee, USD_DECIMALS, 2, true)}
                                <br />
                                Open + Close Fees: ${formatAmount(position.positionFee, USD_DECIMALS, 2, true)}
                                <br />
                                <br />
                                PnL After Fees: {position.deltaAfterFeesStr} ({position.deltaAfterFeesPercentageStr})
                              </>
                            )
                          }}
                        />
                      )}
                    </div>
                    {position.deltaStr && (
                      <div
                        className={cx('Exchange-list-info-label', {
                          positive: hasPositionProfit && positionDelta.gt(0),
                          negative: !hasPositionProfit && positionDelta.gt(0),
                          muted: positionDelta.eq(0),
                        })}
                      >
                        {position.deltaStr} ({position.deltaPercentageStr})
                      </div>
                    )}
                  </div>

                  <div className="clickable col-auto p-3" onClick={() => onPositionClick(position)}>
                    <div className="text-[10px] font-bold text-txt-color-1 block md:hidden dark:text-white">Size: </div>
                    <div>${formatAmount(position.size, USD_DECIMALS, 2, true)}</div>
                    {positionOrders.length > 0 && (
                      <div onClick={() => setListSection && setListSection('Orders')}>
                        <Tooltip
                          handle={`Orders (${positionOrders.length})`}
                          renderContent={() => {
                            return (
                              <>
                                <strong>Active Orders</strong>
                                {positionOrders.map((order) => {
                                  return (
                                    <div
                                      key={`${order.isLong}-${order.type}-${order.index}`}
                                      className="Position-list-order"
                                    >
                                      {order.triggerAboveThreshold ? '>' : '<'}{' '}
                                      {formatAmount(order.triggerPrice, 30, 2, true)}:
                                      {order.type === INCREASE ? ' +' : ' -'}$
                                      {formatAmount(order.sizeDelta, 30, 2, true)}
                                      {order.error && (
                                        <>
                                          , <span className="negative">{order.error}</span>
                                        </>
                                      )}
                                    </div>
                                  )
                                })}
                              </>
                            )
                          }}
                        />
                      </div>
                    )}
                  </div>

                  <div className="clickable col-auto p-3" onClick={() => onPositionClick(position)}>
                    <div className="text-[10px] font-bold text-txt-color-1 block md:hidden dark:text-white">
                      Collateral:{' '}
                    </div>
                    <Tooltip
                      handle={`$${formatAmount(position.collateralAfterFee, USD_DECIMALS, 2, true)}`}
                      renderContent={() => {
                        return (
                          <>
                            {position.hasLowCollateral && (
                              <div>
                                WARNING: This position has a low amount of collateral after deducting borrowing fees,
                                deposit more collateral to reduce the position's liquidation risk.
                                <br />
                                <br />
                              </div>
                            )}
                            Initial Collateral: ${formatAmount(position.collateral, USD_DECIMALS, 2, true)}
                            <br />
                            Borrow Fee: ${formatAmount(position.fundingFee, USD_DECIMALS, 2, true)}
                            {borrowFeeText && <div>{borrowFeeText}</div>}
                            <br />
                            Use the "Edit" button to deposit or withdraw collateral.
                          </>
                        )
                      }}
                    />
                  </div>

                  <div className="clickable col-auto p-3" onClick={() => onPositionClick(position)}>
                    <div className="text-[10px] font-bold text-txt-color-1 block md:hidden dark:text-white">
                      Mark Price:{' '}
                    </div>
                    <Tooltip
                      handle={`$${formatAmount(position.markPrice, USD_DECIMALS, 2, true)}`}
                      renderContent={() => {
                        return (
                          <>
                            Click on a row to select the position's market, then use the swap box to increase your
                            position size if needed.
                            <br />
                            <br />
                            Use the "Close" button to reduce your position size, or to set stop-loss / take-profit
                            orders.
                          </>
                        )
                      }}
                    />
                  </div>

                  <div className="clickable col-auto p-3" onClick={() => onPositionClick(position)}>
                    <div className="text-[10px] font-bold text-txt-color-1 block md:hidden dark:text-white">
                      Entry Price:{' '}
                    </div>
                    ${formatAmount(position.averagePrice, USD_DECIMALS, 2, true)}
                  </div>

                  <div className="clickable col-span-2 md:col-auto p-3" onClick={() => onPositionClick(position)}>
                    <div className="text-[10px] font-bold text-txt-color-1 block md:hidden dark:text-white">
                      Liq. Price:{' '}
                    </div>
                    ${formatAmount(liquidationPrice, USD_DECIMALS, 2, true)}
                  </div>

                  <div
                    className="col-span-2 md:col-auto md:max-w-[90px] gap-1 md:gap-2 md:flex-col flex flex-row md:items-end p-3 justify-center"
                    style={{ borderTopWidth: 'inherit' }}
                  >
                    <PButton
                      size="sm"
                      onClick={() => editPosition(position)}
                      disabled={position.size.eq(0) || position.hasPendingChanges}
                    >
                      Edit
                    </PButton>

                    <PButton
                      size="sm"
                      onClick={() => sellPosition(position)}
                      disabled={position.size.eq(0) || position.hasPendingChanges}
                    >
                      Close
                    </PButton>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}
