import { useQuery } from '@tanstack/react-query'
import { getContract } from '@tfx/addresses'
import { ChainId } from '@tfx/chains'
import { ethers } from 'ethers'
import { bigNumberify, getProvider } from 'utils'

import PositionManager from 'abis/PositionManager.json'
import VaultV2 from 'abis/Vault.json'

export const useConstants = (chainId: ChainId, library) => {
  const provider = getProvider(library, chainId)

  const vaultAddress = getContract(chainId, 'Vault')
  const positionManagerAddress = getContract(chainId, 'PositionManager')

  const vault = new ethers.Contract(vaultAddress, VaultV2, provider)
  const positionManager = new ethers.Contract(positionManagerAddress, PositionManager, provider)

  return useQuery({
    queryKey: ['useConstants', chainId, vaultAddress, positionManagerAddress],
    queryFn: async () => {
      try {
        const data = await Promise.all(
          [
            'taxBasisPoints',
            'stableTaxBasisPoints',
            'mintBurnFeeBasisPoints',
            'swapFeeBasisPoints',
            'stableSwapFeeBasisPoints',
            'marginFeeBasisPoints',
            'liquidationFeeUsd',
            'maxLeverage',
          ].map(async (fn) => {
            try {
              return await vault[fn]()
            } catch (error) {
              console.error(error)
            }
          }),
        )

        const dataDepositFee = await positionManager.depositFee()

        return {
          TAX_BASIS_POINTS: Number(data[0]),
          STABLE_TAX_BASIS_POINTS: Number(data[1]),
          MINT_BURN_FEE_BASIS_POINTS: Number(data[2]),
          SWAP_FEE_BASIS_POINTS: Number(data[3]),
          STABLE_SWAP_FEE_BASIS_POINTS: Number(data[4]),
          MARGIN_FEE_BASIS_POINTS: Number(data[5]),
          LIQUIDATION_FEE: data[6],
          MAX_LEVERAGE: Number(data[7]),
          DEPOSIT_FEE: Number(dataDepositFee),
        }
      } catch (error) {
        console.error(error)
      }
    },
    initialData: () => ({
      TAX_BASIS_POINTS: 0,
      STABLE_TAX_BASIS_POINTS: 0,
      MINT_BURN_FEE_BASIS_POINTS: 0,
      SWAP_FEE_BASIS_POINTS: 0,
      STABLE_SWAP_FEE_BASIS_POINTS: 0,
      MARGIN_FEE_BASIS_POINTS: 0,
      LIQUIDATION_FEE: bigNumberify(0),
      MAX_LEVERAGE: 1,
      DEPOSIT_FEE: 0,
    }),
  })
}
