import { ethers } from 'ethers'
import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'

import { getContract } from '@tfx/addresses'
import { getPositionQuery, getPositions } from '@tfx/tfx-sdk'
import { getToken, getTokens, getWhitelistedTokens } from '@tfx/tokens'

import Reader from 'abis/Reader.json'

import { fetcher, formatAmount, getServerBaseUrl, getTokenInfo, useAccountOrders, useChainId } from 'utils'
import { cancelMultipleOrders, useInfoTokens } from 'utils/api'

import { useConstants } from 'hooks/useConstant'
import { usePendingTxns } from 'hooks/usePendingTxns'
import { useWeb3 } from 'hooks/useWeb3'

import OrdersList from 'components/Exchange/OrdersList'
import PositionsList from 'components/Exchange/PositionsList'
import TradeHistory from 'components/Exchange/TradeHistory'

import './Actions.css'

const USD_DECIMALS = 30

export default function Actions() {
  const { account } = useParams()
  const { active, library } = useWeb3()
  const [cancelOrderIdList, setCancelOrderIdList] = useState([])
  const [isCancelMultipleOrderProcessing, setIsCancelMultipleOrderProcessing] = useState(false)

  const { chainId } = useChainId()
  const nativeTokenAddress = getContract(chainId, 'NATIVE_TOKEN')
  const vaultAddress = getContract(chainId, 'Vault')
  const readerAddress = getContract(chainId, 'Reader')
  const vaultPositionController = getContract(chainId, 'VaultPositionController')

  const shouldShowPnl = false

  let checkSummedAccount = ''
  if (ethers.utils.isAddress(account)) {
    checkSummedAccount = ethers.utils.getAddress(account)
  }
  const pnlUrl = `${getServerBaseUrl(chainId)}/pnl?account=${checkSummedAccount}`
  const { data: pnlData } = useSWR([pnlUrl], {
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
  })

  const tokens = getTokens(chainId)
  const whitelistedTokens = getWhitelistedTokens(chainId)
  const positionQuery = getPositionQuery(whitelistedTokens, nativeTokenAddress)

  const whitelistedTokenAddresses = whitelistedTokens.map((token) => token.address)
  const tokenAddresses = tokens.map((token) => token.address)
  const { data: tokenBalances } = useSWR([active, chainId, readerAddress, 'getTokenBalances', account], {
    fetcher: fetcher(library, Reader, [tokenAddresses]),
  })

  const { data: positionData } = useSWR(
    [active, chainId, readerAddress, 'getPositions', vaultPositionController, account],
    {
      fetcher: fetcher(library, Reader, [
        positionQuery.collateralTokens,
        positionQuery.indexTokens,
        positionQuery.isLong,
      ]),
    },
  )

  const { data: fundingRateInfo } = useSWR([active, chainId, readerAddress, 'getFundingRates'], {
    fetcher: fetcher(library, Reader, [vaultAddress, nativeTokenAddress, whitelistedTokenAddresses]),
  })

  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, fundingRateInfo)

  const { data: constants } = useConstants(chainId, library)
  const marginFeeBasisPoints = constants.MARGIN_FEE_BASIS_POINTS
  const liquidationFee = constants.LIQUIDATION_FEE
  const maxLeverage = constants.MAX_LEVERAGE

  const { positions, positionsMap } = getPositions(
    chainId,
    positionQuery,
    positionData,
    infoTokens,
    false,
    false,
    checkSummedAccount,
    undefined,
    undefined,
    marginFeeBasisPoints,
    liquidationFee,
    maxLeverage,
  )

  const flagOrdersEnabled = true
  const [orders, updateOrders] = useAccountOrders(flagOrdersEnabled, checkSummedAccount)
  const { pendingTxns, setPendingTxns } = usePendingTxns()

  const onMultipleCancelClick = useCallback(
    async function () {
      setIsCancelMultipleOrderProcessing(true)
      try {
        const tx = await cancelMultipleOrders(chainId, library, cancelOrderIdList, {
          successMsg: 'Orders cancelled.',
          failMsg: 'Cancel failed.',
          sentMsg: 'Cancel submitted.',
          pendingTxns,
          setPendingTxns,
        })
        const receipt = await tx.wait()
        if (receipt.status === 1) {
          setCancelOrderIdList([])
        }
      } catch (error) {
        console.error(error)
      } finally {
        setIsCancelMultipleOrderProcessing(false)
      }
    },
    [
      chainId,
      library,
      pendingTxns,
      setPendingTxns,
      setCancelOrderIdList,
      cancelOrderIdList,
      setIsCancelMultipleOrderProcessing,
    ],
  )

  const renderCancelOrderButton = () => {
    const orderText = cancelOrderIdList.length > 1 ? 'orders' : 'order'
    if (cancelOrderIdList.length === 0) return
    return (
      <button
        className="text-xl underline mr-6 inline-block text-txt-color-2"
        disabled={isCancelMultipleOrderProcessing}
        type="button"
        onClick={onMultipleCancelClick}
      >
        Cancel {cancelOrderIdList.length} {orderText}
      </button>
    )
  }

  return (
    <div className="Actions">
      {checkSummedAccount.length > 0 && <div className="Actions-section">Account: {checkSummedAccount}</div>}
      {shouldShowPnl && (
        <div className="Actions-section">
          <div className="Actions-title">PnL</div>
          {(!pnlData || pnlData.length === 0) && <div>No PnLs found</div>}
          {pnlData &&
            pnlData.length > 0 &&
            pnlData.map((pnlRow, index) => {
              const token = getToken(chainId, pnlRow.data.indexToken)
              return (
                <div className="TradeHistory-row App-box App-box-border" key={index}>
                  <div>
                    {token.symbol} {pnlRow.data.isLong ? 'Long' : 'Short'} Profit:{' '}
                    {formatAmount(pnlRow.data.profit, USD_DECIMALS, 2, true)} USD
                  </div>
                  <div>
                    {token.symbol} {pnlRow.data.isLong ? 'Long' : 'Short'} Loss:{' '}
                    {formatAmount(pnlRow.data.loss, USD_DECIMALS, 2, true)} USD
                  </div>
                </div>
              )
            })}
        </div>
      )}
      {checkSummedAccount.length > 0 && (
        <div className="Actions-section">
          <div className="Actions-title">Positions</div>
          <PositionsList
            positions={positions}
            positionsMap={positionsMap}
            infoTokens={infoTokens}
            active={active}
            orders={orders}
            account={checkSummedAccount}
            library={library}
            flagOrdersEnabled={false}
            savedIsPnlInLeverage={false}
            chainId={chainId}
            nativeTokenAddress={nativeTokenAddress}
            showPnlAfterFees={false}
          />
        </div>
      )}
      {flagOrdersEnabled && checkSummedAccount.length > 0 && (
        <div className="Actions-section">
          <div className="flex Actions-title justify-between">
            <div className="">Orders</div>
            <div className="align-right Exchange-should-show-position-lines">{renderCancelOrderButton()}</div>
          </div>
          <OrdersList
            account={checkSummedAccount}
            infoTokens={infoTokens}
            positionsMap={positionsMap}
            chainId={chainId}
            orders={orders}
            updateOrders={updateOrders}
            cancelOrderIdList={cancelOrderIdList}
            setCancelOrderIdList={setCancelOrderIdList}
            hideActions
          />
        </div>
      )}
      <div className="Actions-section">
        <div className="Actions-title">Actions</div>
        <TradeHistory
          account={checkSummedAccount}
          infoTokens={infoTokens}
          getTokenInfo={getTokenInfo}
          chainId={chainId}
          nativeTokenAddress={nativeTokenAddress}
          forSingleAccount={checkSummedAccount.length > 0}
        />
      </div>
    </div>
  )
}
