import { ReactNode } from 'react'

type PanelProps = {
  children: ReactNode
  title?: string
  spaceTop?: boolean
}
const Panel = ({ children, title, spaceTop = true }: PanelProps) => {
  return (
    <div
      className={`bg-white rounded-[1.75rem] border p-6 dark:bg-bg-dark-color-2 dark:border-bg-dark-color-2 ${
        spaceTop ? 'mt-6' : ''
      }`}
    >
      {title && (
        <div>
          <div className="font-bold text-xl mb-6">{title}</div>
        </div>
      )}
      <div>{children}</div>
    </div>
  )
}

export default Panel
