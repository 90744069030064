import { css, cx } from '@emotion/css'
import { BiLinkExternal } from 'react-icons/bi'
interface Props extends React.ComponentProps<'button'> {
  loading?: boolean
  variant?: 'primary' | 'outline' | 'danger' | 'white'
  size?: 'tiny' | 'normal'
  colorLoading?: 'primary' | 'outline'
}

const Button = ({
  children,
  loading,
  className,
  variant = 'primary',
  size = 'normal',
  colorLoading,
  disabled,
  ...props
}: Props) => {
  return (
    <button
      type="button"
      {...props}
      className={cx(
        {
          'bg-blue-600 hover:bg-blue-700': variant === 'primary',
          [`h-6 px-2 ${css`
            font-size: 11px;
          `}`]: size === 'tiny',
          'px-8': size === 'normal',
          'cursor-not-allowed': disabled,
        },
        `inline-flex items-center p-4 px-8 border border-transparent text-xl text-white
        leading-4 font-bond rounded-full shadow-sm`,
        className,
      )}
    >
      {children}
    </button>
  )
}

export const PButton = ({ size, disabled, isColorStyle, isExternal, children, className, ...rest }: any) => {
  const initStyle = `rounded-2xl bg-blue-600 hover:bg-blue-700 text-white border-0 cursor-pointer flex justify-center items-center gap-2 transition ease-in shadow-sm w-full ${className}`
  const defaultStyle = `${initStyle} w-full py-[.8rem] h-[34px] px-8 text-xl font-bold`
  const disabledStyle = `${initStyle} cursor-no-drop hover:bg-blue-600 !opacity-60`
  const smSize = `${initStyle} text-xl h-[22px] px-2 leading-4 font-normal min-w-[80px]`
  const xlSize = `${initStyle} text-[1.4rem] p-[1.5rem] h-[51px]`

  const colorStyle = 'bg-bg-color-6'

  return (
    <button
      type="button"
      disabled={disabled}
      className={cx({
        [defaultStyle]: !size,
        [smSize]: size === 'sm',
        [xlSize]: size === 'xl',
        [colorStyle]: isColorStyle,
        [disabledStyle]: disabled,
      })}
      {...rest}
    >
      {isExternal ? (
        <>
          <div>{children}</div> <BiLinkExternal />
        </>
      ) : (
        children
      )}
    </button>
  )
}

export default Button
