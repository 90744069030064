import { useTheme } from 'hooks/useTheme'
import { Fragment } from 'react'
import TimezoneSelect, { allTimezones, ITimezoneOption } from 'react-timezone-select'

const TVChartTimezoneSelector = ({ tz = '', setTz }: { tz: string; setTz: (value: string) => void }) => {
  const { theme } = useTheme()

  const availableTimezone = [
    'GMT',
    'Pacific/Honolulu',
    'America/Juneau',
    'America/Los_Angeles',
    'America/Vancouver',
    'US/Mountain',
    'America/Phoenix',
    'America/Chicago',
    'America/Mexico_City',
    'America/El_Salvador',
    'America/Bogota',
    'America/Lima',
    'America/New_York',
    'America/Toronto',
    'America/Caracas',
    'America/Argentina/Buenos_Aires',
    'America/Santiago',
    'America/Sao_Paulo',
    'Europe/Dublin',
    'Europe/Lisbon',
    'Europe/London',
    'Atlantic/Reykjavik',
    'Europe/Amsterdam',
    'Europe/Belgrade',
    'Europe/Berlin',
    'Europe/Brussels',
    'Europe/Copenhagen',
    'Africa/Lagos',
    'Europe/Luxembourg',
    'Europe/Madrid',
    'Europe/Malta',
    'Europe/Oslo',
    'Europe/Paris',
    'Europe/Rome',
    'Europe/Stockholm',
    'Europe/Warsaw',
    'Europe/Zurich',
    'Europe/Athens',
    'Africa/Cairo',
    'Europe/Helsinki',
    'Asia/Jerusalem',
    'Africa/Johannesburg',
    'Europe/Riga',
    'Europe/Tallinn',
    'Europe/Vilnius',
    'Asia/Bahrain',
    'Europe/Istanbul',
    'Asia/Kuwait',
    'Europe/Moscow',
    'Asia/Qatar',
    'Asia/Riyadh',
    'Asia/Tehran',
    'Asia/Dubai',
    'Asia/Muscat',
    'Asia/Ashkhabad',
    'Asia/Kolkata',
    'Asia/Almaty',
    'Asia/Bangkok',
    'Asia/Ho_Chi_Minh',
    'Asia/Jakarta',
    'Asia/Chongqing',
    'Asia/Hong_Kong',
    'Australia/Perth',
    'Asia/Shanghai',
    'Asia/Singapore',
    'Asia/Taipei',
    'Asia/Seoul',
    'Asia/Tokyo',
    'Australia/Brisbane',
    'Australia/Adelaide',
    'Australia/Sydney',
    'Pacific/Norfolk',
    'Pacific/Auckland',
    'Pacific/Fakaofo',
    'Pacific/Chatham',
  ]

  const tradingviewTimezone = availableTimezone.reduce(
    (current, next) => (allTimezones[next] ? { ...current, [next]: allTimezones[next] } : current),
    {},
  )

  const getOffset = (timeZone = 'UTC', date = new Date()) => {
    const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }))
    const tzDate = new Date(date.toLocaleString('en-US', { timeZone }))
    const tzOffset = (tzDate.getTime() - utcDate.getTime()) / 6e4 / 60
    return tzOffset > 0 ? `+ ${tzOffset}` : tzOffset
  }
  const today = new Date(new Date().toLocaleString('en-US', { timeZone: tz }))
  const time = today.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })

  return (
    <Fragment>
      <div className="absolute right-[120px] sm:right-[135px] bottom-[16px] z-20">
        {time} (UTC{getOffset(tz) === 0 ? '' : getOffset(tz)})
      </div>
      <div className="TVChart-timezone cursor-pointer bg-none absolute right-[120px] sm:right-[115px] top-[100%] h-[38px] w-[160px] sm:w-[176px] z-50 mt-[-40px]">
        <TimezoneSelect
          // menuIsOpen
          className="text-black text-xl w-full m-0 p-0 z-50"
          value={tz}
          onChange={(data: ITimezoneOption) => {
            if (data.value === 'Etc/GMT') {
              setTz('Etc/UTC')
            } else {
              setTz(data.value)
            }
          }}
          labelStyle="original"
          timezones={tradingviewTimezone}
          menuPlacement="top"
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              opacity: 0,
              cursor: 'pointer',
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              width: '260px',
              right: 0,
              background: theme === 'dark' ? '#1e222d' : '#fff',
              color: theme === 'dark' ? '#fff' : '#1e222d',
              boxShadow: '0 2px 4px 0 #000',
            }),
            option: (baseStyles, state) => ({
              ...baseStyles,
              cursor: 'pointer',
              backgroundColor: state.isSelected
                ? theme === 'dark'
                  ? '#152a4a'
                  : '#dfdfdf'
                : state.isFocused
                ? theme === 'dark'
                  ? '#2a2e39'
                  : '#dfdfdf'
                : 'none',
              color: state.isSelected
                ? theme === 'dark'
                  ? '#fff'
                  : '#152a4a'
                : state.isFocused
                ? theme === 'dark'
                  ? '#fff'
                  : '#152a4a'
                : 'none',
              ':active': {
                backgroundColor: state.isSelected ? (theme === 'dark' ? '#152a4a' : '#dfdfdf') : 'none',
              },
            }),
          }}
        />
      </div>
    </Fragment>
  )
}

export default TVChartTimezoneSelector
