import { gql } from '@apollo/client'
import { useQuery } from '@tanstack/react-query'
import { request } from 'graphql-request'
import { DEFAULT_CHAIN_ID } from 'utils'
import { getGraphPathClient } from 'utils/client'

const fetcher = (endpoint, query) => request(endpoint, query)

const TASK_PROPS = `
  account
  executedTimestamp
  id
  message
  signature
  status
  success
  taskId
  timestamp,
  fullFillTask {
    account
    data
    id
    message
    signature
    success
    timestamp
    taskId,
    priceList {
      token,
      value
    }
  }
  txHashRequest
  txHashFullFill
`

const TASK_STAT = `
  taskStat(id: "total") {
    failCount
    pendingCount
    period
    taskCount
    id
  }
`

const getFilterQuery = (filter) => {
  const { address, status, method } = filter

  let whereStr = ''
  if (address) {
    whereStr += `{
      or: [
        {account: "${address}"},
        {txHashRequest: "${address}"},
        {txHashFullFill: "${address}"}
      ]
    },`
  }

  if (status && status === '1') {
    // status
    whereStr += `{status: "executed",success: true},`
  }
  if (status && status === '2') {
    whereStr += `{status: "pending"},`
  }
  if (status && status === '3') {
    whereStr += `{status: "executed",success: false},`
  }

  // method
  if (method) {
    whereStr += `{signature: "${method}"},`
  }

  return whereStr
}

export const useTaskData = (network, filter) => {
  const { first, skip } = filter

  const whereStr = getFilterQuery(filter)

  const query = gql(`{
    ${TASK_STAT}
    tasks(
      orderBy: timestamp,
      orderDirection: desc,
      first: ${first},
      skip: ${skip},
      ${
        !!whereStr
          ? `
          where: {
            and: [${whereStr}]
          }
        `
          : ''
      }
    ) {
      ${TASK_PROPS}
    }
  }`)

  return useQuery({
    queryKey: ['executedTask', filter],
    queryFn: async () => fetcher(getGraphPathClient(network), query),
    initialData: () => ({}),
  })
}

const recursiveQueryCount = async (filter, key, chain = DEFAULT_CHAIN_ID, total = 0, skip = 0) => {
  const skipIndex = 1000
  if (total !== 0) {
    skip = skip + skipIndex
  }

  const whereStr = getFilterQuery(filter)
  const query = gql(`{
    tasks(
      first: ${skipIndex},
      skip: ${skip},
      ${
        !!whereStr
          ? `
          where: {
            and: [${whereStr}]
          }
        `
          : ''
      }
    ) {
      timestamp
    }
  }`)

  const result: any = await fetcher(getGraphPathClient(chain), query)
  const data = result[key]
  total += data.length
  if (data.length >= skipIndex) {
    await recursiveQueryCount(query, key, chain, total, skip)
  }
  return total
}

export const getTaskFilterCount = async (network, filter) => {
  try {
    const total: number = await recursiveQueryCount(filter, 'tasks', network)
    return {
      totalData: total,
    }
  } catch (error) {
    console.error(error)
    return {
      totalData: 0,
    }
  }
}
