import { useState, useEffect } from 'react'
import cx from 'classnames'

import { formatAmount, expandDecimals, bigNumberify } from 'utils'

import { getToken } from '@tfx/tokens'

import { BiChevronDown, BiSearchAlt } from 'react-icons/bi'

import Modal from '../Modal/Modal'

import dropDownIcon from 'img/DROP_DOWN.svg'
import './TokenSelector.css'
import FieldInput from 'components/FieldInput'
import { getCoinSGV } from 'utils/image'

export default function TokenSelector(props) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [searchKeyword, setSearchKeyword] = useState('')
  const tokenInfo = getToken(props.chainId, props.tokenAddress)
  const {
    tokens,
    mintingCap,
    infoTokens,
    showMintingCap,
    disabled,
    showTokenImgInDropdown = false,
    showSymbolImage = false,
    showNewCaret = false,
  } = props

  const visibleTokens = tokens.filter((t) => !t.isTempHidden)

  const onSelectToken = (token) => {
    setIsModalVisible(false)
    props.onSelectToken(token)
  }

  useEffect(() => {
    if (isModalVisible) {
      setSearchKeyword('')
    }
  }, [isModalVisible])

  if (!tokenInfo) {
    return null
  }

  var tokenImage = null

  try {
    tokenImage = getCoinSGV(tokenInfo.symbol)
  } catch (error) {
    console.error(error)
  }

  const onSearchKeywordChange = (e) => {
    setSearchKeyword(e.target.value)
  }

  const filteredTokens = visibleTokens.filter((item) => {
    return (
      item.name.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1 ||
      item.symbol.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1
    )
  })

  const _handleKeyDown = (e) => {
    if (e.key === 'Enter' && filteredTokens.length > 0) {
      onSelectToken(filteredTokens[0])
    }
  }

  return (
    <div className={cx('TokenSelector', { disabled }, props.className)}>
      <Modal isVisible={isModalVisible} setIsVisible={setIsModalVisible} label={props.label}>
        <div className="TokenSelector-tokens">
          <div>
            <label className="relative block mb-6">
              <span className="sr-only">Search</span>
              <span className="absolute inset-y-0 left-0 flex items-center pl-4 z-10">
                <BiSearchAlt className="h-10 w-10 fill-slate-400" />
              </span>
              <FieldInput
                type="text"
                placeholder="Search Token"
                value={searchKeyword}
                onChange={(e) => onSearchKeywordChange(e)}
                onKeyDown={_handleKeyDown}
                className="font-xl placeholder:text-slate-500 block bg-gray-200 w-full
                rounded-xl py-5 pl-16 pr-3 focus:outline-none
                focus:border-bg-color-5 focus:ring-slate-400 focus:ring-1 m-1"
              />
            </label>
          </div>
          {filteredTokens.map((token) => {
            const tokenPopupImage = getCoinSGV(token.symbol)
            let info = infoTokens ? infoTokens[token.address] : {}
            let mintAmount
            let balance = info.balance
            if (showMintingCap && mintingCap && info.usdgAmount) {
              mintAmount = mintingCap.sub(info.usdgAmount)
            }
            if (mintAmount && mintAmount.lt(0)) {
              mintAmount = bigNumberify(0)
            }
            let balanceUsd
            if (balance && info.maxPrice) {
              balanceUsd = balance.mul(info.maxPrice).div(expandDecimals(1, token.decimals))
            }
            return (
              <div className="TokenSelector-token-row" onClick={() => onSelectToken(token)} key={token.address}>
                <div className="Token-info">
                  {showTokenImgInDropdown && <img src={tokenPopupImage} alt={token.name} className="token-logo" />}
                  <div className="Token-symbol">
                    <div className="Token-text">{token.symbol}</div>
                    <span className="text-accent">{token.name}</span>
                  </div>
                </div>
                <div className="Token-balance">
                  {balance && (
                    <div className="Token-text">
                      {balance.gt(0) && formatAmount(balance, token.decimals, 4, true)}
                      {balance.eq(0) && '-'}
                    </div>
                  )}
                  <span className="text-accent">
                    {mintAmount && <div>Mintable: {formatAmount(mintAmount, token.decimals, 2, true)} USDX</div>}
                    {showMintingCap && !mintAmount && <div>-</div>}
                    {!showMintingCap && balanceUsd && balanceUsd.gt(0) && (
                      <div>${formatAmount(balanceUsd, 30, 2, true)}</div>
                    )}
                  </span>
                </div>
              </div>
            )
          })}
        </div>
      </Modal>
      <div className="TokenSelector-box" onClick={() => setIsModalVisible(true)}>
        {tokenInfo.symbol}
        {showSymbolImage && <img src={tokenImage} alt={tokenInfo.symbol} className="TokenSelector-box-symbol" />}
        {showNewCaret && <img src={dropDownIcon} alt="dropDownIcon" className="TokenSelector-box-caret" />}
        {!showNewCaret && <BiChevronDown className="TokenSelector-caret" />}
      </div>
    </div>
  )
}
