import { useEffect, useState } from 'react'
import cx from 'classnames'
import TradingViewWidget from 'react-tradingview-widget'
import ChartLoading from 'components/Exchange/ChartLoading'
import {
  SWAP,
  CHART_PERIODS,
  getTokenInfo,
  useLocalStorageSerializeKey,
  numberWithCommas,
  formatAmount,
  USD_DECIMALS,
} from 'utils'
import { getTokens } from '@tfx/tokens'
import { capitalizeFirstLetter } from 'utils/capital'
import { useTheme } from 'hooks/useTheme'
import ChartTokenSelector from './ChartTokenSelector'
import TVChartTimezoneSelector from './TVChartTimezoneSelectorBasic'
import { useTokenData } from 'hooks/useToken'
import { usePrice } from 'utils/api/price'

export function getChartToken(swapOption, fromToken, toToken, chainId) {
  if (!fromToken || !toToken) {
    return
  }

  if (swapOption !== SWAP) {
    return toToken
  }

  if (fromToken.isUsdg && toToken.isUsdg) {
    return getTokens(chainId).find((t) => t.isStable)
  }
  if (fromToken.isUsdg) {
    return toToken
  }
  if (toToken.isUsdg) {
    return fromToken
  }

  if (fromToken.isStable && toToken.isStable) {
    return toToken
  }
  if (fromToken.isStable) {
    return toToken
  }
  if (toToken.isStable) {
    return fromToken
  }

  return toToken
}

const DEFAULT_PERIOD = '1d'
const DEFAULT_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone

export default function ExchangeTVChart(props) {
  const { swapOption, fromTokenAddress, toTokenAddress, infoTokens, chainId, setToTokenAddress } = props

  const { theme } = useTheme()

  const [tz, setTz] = useLocalStorageSerializeKey([chainId, 'Chart-timezone'], DEFAULT_TIMEZONE)
  let [period] = useLocalStorageSerializeKey([chainId, 'Chart-period'], DEFAULT_PERIOD)

  if (!(period in CHART_PERIODS)) {
    period = DEFAULT_PERIOD
  }

  const fromToken = getTokenInfo(infoTokens, fromTokenAddress)
  const toToken = getTokenInfo(infoTokens, toTokenAddress)

  const [chartToken, setChartToken] = useState({
    maxPrice: null,
    minPrice: null,
  })
  useEffect(() => {
    const tmp = getChartToken(swapOption, fromToken, toToken, chainId)
    setChartToken(tmp)
  }, [swapOption, fromToken, toToken, chainId])

  const onSelectToken = (token) => {
    const tmp = getTokenInfo(infoTokens, token.address)
    setChartToken(tmp)
    setToTokenAddress(swapOption, token.address)
  }

  const { data } = useTokenData(chartToken?.coingeckoId)
  const [currentPrice] = usePrice(chartToken?.priceFeedIndex)

  if (!chartToken) {
    return null
  }

  return (
    <div className="relative">
      <div className="p-6 bg-white border border-[#dfe3eb] rounded-t-3xl dark:bg-[#131722] dark:border-[#2a2e39]">
        <div className="ExchangeChart-top-inner">
          <div>
            <div className="ExchangeChart-title">
              <ChartTokenSelector
                chainId={chainId}
                selectedToken={chartToken}
                swapOption={swapOption}
                infoTokens={infoTokens}
                onSelectToken={onSelectToken}
                className="chart-token-selector"
              />
            </div>
          </div>
          <div>
            <div className="ExchangeChart-main-price">{formatAmount(currentPrice, USD_DECIMALS, 2, true)}</div>
          </div>
          <div>
            <div className="ExchangeChart-info-label">24h Change</div>
            <div
              className={cx({
                positive: data?.priceChangePercentage24h > 0,
                negative: data?.priceChangePercentage24h < 0,
              })}
            >
              {data && data.priceChangePercentage24h?.toFixed(2)}%
            </div>
          </div>
          <div className="ExchangeChart-additional-info">
            <div className="ExchangeChart-info-label">24h High</div>
            <div>
              {!data?.priceHigh24h && '-'}
              {data?.priceHigh24h && numberWithCommas(data?.priceHigh24h?.toFixed(2))}
            </div>
          </div>
          <div className="ExchangeChart-additional-info">
            <div className="ExchangeChart-info-label">24h Low</div>
            <div>
              {!data?.priceLow24h && '-'}
              {data?.priceLow24h && numberWithCommas(data?.priceLow24h?.toFixed(2))}
            </div>
          </div>
        </div>
      </div>

      <ChartLoading className="absolute w-full top-[20rem]" />
      {!!chartToken?.symbol && (
        <TradingViewWidget
          theme={capitalizeFirstLetter(theme)}
          allow_symbol_change={false}
          symbol={`${chartToken?.symbol === 'WBNB' ? 'BNB' : chartToken?.symbol}USD`}
          height={550}
          interval={15}
          hide_legend={true}
          withdateranges={true}
          hide_side_toolbar={false}
          timezone={tz}
          className="z-10"
          id="tv-chart"
          width="auto"
        />
      )}
      {tz && <TVChartTimezoneSelector tz={tz} setTz={setTz} />}
    </div>
  )
}
