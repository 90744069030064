import { getChainDisplayName } from '@tfx/chains'
import TooltipComponent from 'components/Tooltip/Popper'
import { Parallax } from 'react-scroll-parallax'
import { ARBITRUM_SEPOLIA, DEV_TESTNET, formatAmount, numberWithCommas, USD_DECIMALS } from 'utils'
import TooltipCard from './TooltipCard'

import totalIcon from 'img/ic_bag.png'
import overviewIcon from 'img/ic_piechart.png'
import bannerMobileImage from 'img/tfx-web-phone.png'

const StatsOverview = ({
  chainId,
  tvl,
  aum,
  currentVolumeInfo,
  positionStatsInfo,
  totalFees,
  currentTotalVolumeInfo,
  totalFloorPriceFundUsd,
  totalStatsStartDate,
}) => {
  const chainName = getChainDisplayName(chainId)

  if (!positionStatsInfo || !positionStatsInfo?.[chainId]) return null

  return (
    <div className="mt-[13rem] mb-[4rem]">
      <div className="flex gap-24 flex-col lg:flex-row items-center justify-center">
        <div className="-4">
          <Parallax easing="easeOutQuad" translateX={[-25, 0]}>
            <img src={bannerMobileImage} alt="why us?" className="w-[420px]" />
          </Parallax>
        </div>
        <div className="flex-1 w-full">
          <h4 className="text-[3rem] py-4 flex items-center gap-5">
            <img src={overviewIcon} alt="liquidity" className="h-[40px] w-[40px]" />
            <div>
              Overview
              <div className="text-3xl text-gray-400">
                {chainName} Total Stats start from {totalStatsStartDate}.
              </div>
            </div>
          </h4>

          <div className="flex my-10 divide-x-4 divide-gray-500 gap-5 flex-wrap">
            <div className="px-6 flex-1 text-[2.3rem] font-bold min-w-[218px] border-l-4 border-gray-500">
              <div className="tracking-tighter">
                <TooltipComponent
                  handle={`$${formatAmount(tvl, USD_DECIMALS, 0, true)}`}
                  renderContent={() => (
                    <span className="label">{`Assets Under Management: xLP pool (${chainName})`}</span>
                  )}
                />
              </div>
              <div className="tracking-tighter text-[1.5rem] text-gray-400">AUM</div>
            </div>
            <div className="px-6 flex-1 text-[2.3rem] font-bold min-w-[218px]">
              <div className="tracking-tighter">
                <TooltipComponent
                  handle={`$${formatAmount(aum, USD_DECIMALS, 0, true)}`}
                  renderContent={() => (
                    <span className="label">{`Total value of tokens in xLP pool (${chainName})`}</span>
                  )}
                />
              </div>
              <div className="tracking-tighter text-[1.5rem] text-gray-400">xLP Pool</div>
            </div>

            <div className="px-6 flex-1 text-[2.3rem] font-bold min-w-[218px]">
              <div className="tracking-tighter">
                <TooltipComponent
                  handle={`$${formatAmount(currentVolumeInfo?.[chainId]?.totalVolume, USD_DECIMALS, 0, true)}`}
                  renderContent={() => (
                    <TooltipCard
                      title="Volume"
                      arbitrumSepolia={currentVolumeInfo?.[ARBITRUM_SEPOLIA]?.totalVolume}
                      devTestnet={currentVolumeInfo?.[DEV_TESTNET]?.totalVolume}
                      total={currentVolumeInfo?.totalVolume}
                    />
                  )}
                />
              </div>
              <div className="tracking-tighter text-[1.5rem] text-gray-400">24h Volume</div>
            </div>

            <div className="px-6 flex-1 text-[2.3rem] font-bold min-w-[218px]">
              <div className="tracking-tighter">
                <TooltipComponent
                  handle={`$${formatAmount(
                    positionStatsInfo?.[chainId]?.totalLongPositionSizes,
                    USD_DECIMALS,
                    0,
                    true,
                  )}`}
                  renderContent={() => (
                    <TooltipCard
                      title="Long Positions"
                      arbitrumSepolia={positionStatsInfo?.[ARBITRUM_SEPOLIA]?.totalLongPositionSizes}
                      devTestnet={positionStatsInfo?.[DEV_TESTNET]?.totalLongPositionSizes}
                      total={positionStatsInfo?.totalLongPositionSizes}
                    />
                  )}
                />
              </div>
              <div className="tracking-tighter text-[1.5rem] text-gray-400">Long Positions</div>
            </div>
            <div className="px-6 flex-1 text-[2.3rem] font-bold min-w-[218px]">
              <div className="tracking-tighter">
                <TooltipComponent
                  handle={`$${formatAmount(
                    positionStatsInfo?.[chainId].totalShortPositionSizes,
                    USD_DECIMALS,
                    0,
                    true,
                  )}`}
                  renderContent={() => (
                    <TooltipCard
                      title="Short Positions"
                      arbitrumSepolia={positionStatsInfo?.[ARBITRUM_SEPOLIA]?.totalShortPositionSizes}
                      devTestnet={positionStatsInfo?.[DEV_TESTNET]?.totalShortPositionSizes}
                      total={positionStatsInfo?.totalShortPositionSizes}
                    />
                  )}
                />
              </div>
              <div className="tracking-tighter text-[1.5rem] text-gray-400">Short Positions</div>
            </div>
            <div className="px-6 flex-1 text-[2.3rem] font-bold min-w-[218px]" style={{ border: 0 }}>
              &nbsp;
              {/* <div className="tracking-tighter">
                <TooltipComponent
                  handle={`$${formatAmount(currentFees?.[chainId], USD_DECIMALS, 2, true)}`}
                  renderContent={() => (
                    <TooltipCard
                      title="Fees"
                      opBNBTestnet={currentFees?.[OPBNB_TESTNET]}
                      total={currentFees?.total}
                    />
                  )}
                />
              </div>
              <div className="tracking-tighter text-[1.5rem] text-gray-400">
                Fees since {formatDate(feeHistory[0]?.to)}
              </div> */}
            </div>
          </div>

          <h4 className="text-[3rem] flex items-center gap-5 pt-20 ">
            <img src={totalIcon} className="h-[40px] w-[40px]" alt="liquidity" />
            Total Stats
          </h4>

          <div className="flex my-10 divide-x-4 divide-gray-500 gap-5 flex-wrap">
            <div className="px-6 flex-1 text-[2.3rem] font-bold min-w-[218px] border-l-4 border-gray-500">
              <div className="tracking-tighter">
                <TooltipComponent
                  handle={`$${numberWithCommas(totalFees?.[chainId])}`}
                  renderContent={() => (
                    <TooltipCard
                      title="Total Fees"
                      arbitrumSepolia={totalFees?.[ARBITRUM_SEPOLIA]}
                      devTestnet={totalFees?.[DEV_TESTNET]}
                      total={totalFees?.total}
                      decimalsForConversion={0}
                    />
                  )}
                />
              </div>
              <div className="tracking-tighter text-[1.5rem] text-gray-400">Total Fees</div>
            </div>
            <div className="px-6 flex-1 text-[2.3rem] font-bold min-w-[218px]">
              <div className="tracking-tighter">
                <TooltipComponent
                  handle={`$${formatAmount(currentTotalVolumeInfo?.[chainId]?.totalVolume, USD_DECIMALS, 0, true)}`}
                  renderContent={() => (
                    <TooltipCard
                      title="Total Volume"
                      arbitrumSepolia={currentTotalVolumeInfo?.[ARBITRUM_SEPOLIA]?.totalVolume}
                      devTestnet={currentTotalVolumeInfo?.[DEV_TESTNET]?.totalVolume}
                      total={currentTotalVolumeInfo?.totalVolume}
                    />
                  )}
                />
              </div>
              <div className="tracking-tighter text-[1.5rem] text-gray-400">Total Volume</div>
            </div>
            <div className="px-6 flex-1 text-[2.3rem] font-bold min-w-[218px]">
              <div className="tracking-tighter">${formatAmount(totalFloorPriceFundUsd, 30, 0, true)}</div>
              <div className="tracking-tighter text-[1.5rem] text-gray-400">Floor Price Fund</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StatsOverview
