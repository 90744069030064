import React, { useState } from 'react'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { getContract } from '@tfx/addresses'
import { usePendingTxns } from 'hooks/usePendingTxns'
import { useWeb3 } from 'hooks/useWeb3'
import { useChainId } from 'utils'
import { claimDroplet } from 'utils/api/faucetBUSD'
import { useAnalytic } from 'components/Analytic'
import { GA_EVENT_KEY } from 'components/Analytic/constants'

const FaucetBUSD = () => {
  const { chainId, unsupported } = useChainId()
  const { library, active, account } = useWeb3()
  const faucetBUSDAddress = getContract(chainId, 'FaucetBUSD')

  const { openConnectModal: connectWallet } = useConnectModal()

  const [isClaiming, setIsClaiming] = useState(false)
  const { pendingTxns, setPendingTxns } = usePendingTxns()

  const { sendEvent } = useAnalytic()

  const handleClaim = async (event) => {
    event.stopPropagation()

    window.open('https://tfx-market.gitbook.io/tfx/basics/tfx-beta')
    return

    // eslint-disable-next-line no-unreachable
    if (!active) {
      await connectWallet?.()
      return
    }

    setIsClaiming(true)

    await claimDroplet(chainId, faucetBUSDAddress, {
      library,
      pendingTxns,
      setPendingTxns,
      sentMsg: 'Claim BUSD sent',
      failMsg: "You've already claimed, try again 8 hours later 😢",
    })
      .then(() => {
        sendEvent({
          category: GA_EVENT_KEY.NAVBAR.CATEGORY,
          action: GA_EVENT_KEY.NAVBAR.ACTION.SUCCESS_REDEEM_BUSD,
          label: account,
        })
      })
      .catch(() => {
        sendEvent({
          category: GA_EVENT_KEY.NAVBAR.CATEGORY,
          action: GA_EVENT_KEY.NAVBAR.ACTION.FAIL_REDEEM_BUSD,
          label: account,
        })
      })
      .finally(() => {
        setIsClaiming(false)
      })
  }

  if (!faucetBUSDAddress || unsupported) return null

  return (
    <button
      className="cursor-pointer py-[11px] w-[75px] text-center whitespace-nowrap font-bold text-xl border border-transparent leading-4 rounded-2xl shadow-sm text-white bg-blue-600 bg-opacity-70 hover:bg-blue-700"
      type="button"
      onClick={handleClaim}
    >
      {isClaiming ? 'Claiming...' : 'Faucet'}
    </button>
  )
}

export default FaucetBUSD
