import { Link } from 'react-router-dom'
import useSWR from 'swr'

import { getContract } from '@tfx/addresses'
import { useWeb3 } from 'hooks/useWeb3'
import { TooltipCardRow } from 'pages/Dashboard/TooltipCard'
import { BASIS_POINTS_DIVISOR, bigNumberify, fetcher, formatAmount, useChainId } from 'utils'
import TooltipComponent from '../Tooltip/Popper'

import { getWhitelistedTokens } from '@tfx/tokens'
import VaultV2 from 'abis/Vault.json'
import { useMemo } from 'react'
import { useInfoTokens } from 'utils/api'

const TokenWeightText = ({ tokenInfo }) => {
  const { active, library } = useWeb3()
  const { chainId } = useChainId()

  const vaultAddress = getContract(chainId, 'Vault')
  const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined)

  const { data: totalTokenWeights } = useSWR(
    [`XlpSwap:totalTokenWeights:${active}`, chainId, vaultAddress, 'totalTokenWeights'],
    {
      fetcher: fetcher(library, VaultV2),
    },
  )

  const whitelistedTokens = getWhitelistedTokens(chainId)
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped)

  const adjustedUsdgSupply = useMemo(() => {
    let value = bigNumberify(0)
    for (let i = 0; i < tokenList.length; i++) {
      const token = tokenList[i]
      if (infoTokens) {
        const tokenInfo = infoTokens[token.address]
        if (tokenInfo && tokenInfo.usdgAmount && value) {
          value = value.add(tokenInfo.usdgAmount)
        }
      }
    }
    return value
  }, [infoTokens, tokenList])

  if (!tokenInfo) return '...'

  if (
    !tokenInfo.weight ||
    !tokenInfo.usdgAmount ||
    !adjustedUsdgSupply ||
    adjustedUsdgSupply.eq(0) ||
    !totalTokenWeights
  ) {
    return '...'
  }

  const currentWeightBps = tokenInfo.usdgAmount.mul(BASIS_POINTS_DIVISOR).div(adjustedUsdgSupply)
  const targetWeightBps = tokenInfo.weight.mul(BASIS_POINTS_DIVISOR).div(totalTokenWeights).add(1).div(10).mul(10)
  const weightText = `${formatAmount(currentWeightBps, 2, 2, false)}% / ${formatAmount(targetWeightBps, 2, 2, false)}%`

  return (
    <TooltipComponent
      handle={weightText}
      renderContent={() => {
        return (
          <>
            <TooltipCardRow
              label="Current Weight"
              amount={`${formatAmount(currentWeightBps, 2, 2, false)}%`}
              showDollar={false}
            />
            <TooltipCardRow
              label="Target Weight"
              amount={`${formatAmount(targetWeightBps, 2, 2, false)}%`}
              showDollar={false}
            />
            <div className="my-5">
              {currentWeightBps.lt(targetWeightBps) && (
                <div>
                  <div>{tokenInfo.symbol} is below its target weight.</div>
                  Get lower fees to{' '}
                  <Link to="/buy_xlp" target="_blank" rel="noopener noreferrer">
                    buy xLP
                  </Link>{' '}
                  with {tokenInfo.symbol},&nbsp; and to{' '}
                  <Link to="/trade" target="_blank" rel="noopener noreferrer">
                    swap
                  </Link>{' '}
                  {tokenInfo.symbol} for other tokens.
                </div>
              )}
              {currentWeightBps.gt(targetWeightBps) && (
                <div>
                  <div>{tokenInfo.symbol} is above its target weight.</div>
                  Get lower fees to{' '}
                  <Link to="/trade" target="_blank" rel="noopener noreferrer">
                    swap
                  </Link>{' '}
                  tokens for {tokenInfo.symbol}.
                </div>
              )}
            </div>
            <div>
              <a
                href="https://tfx-market.gitbook.io/tfx/xlp"
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                More Info
              </a>
            </div>
          </>
        )
      }}
    />
  )
}

export default TokenWeightText
