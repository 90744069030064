import { getContract } from '@tfx/addresses'
import cx from 'classnames'
import Tooltip from 'components/Tooltip/Popper'
import { ethers } from 'ethers'
import { useConstants } from 'hooks/useConstant'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  BASIS_POINTS_DIVISOR,
  USD_DECIMALS,
  bigNumberify,
  deserialize,
  formatAmount,
  formatDateTime,
  getExchangeRateDisplay,
  getExplorerUrl,
  shortenAddress,
} from 'utils'
import { useLiquidationsData, useTradesFromGraph } from 'utils/api'
import { useAccount } from 'wagmi'

import Button from 'components/Button'
import './TradeHistory.css'

const { AddressZero } = ethers.constants

function getPositionDisplay(increase, indexToken, isLong, sizeDelta) {
  const symbol = indexToken ? (indexToken.isWrapped ? indexToken.baseSymbol : indexToken.symbol) : ''
  return `
    ${increase ? 'Increase' : 'Decrease'} ${symbol} ${isLong ? 'Long' : 'Short'}
    ${increase ? '+' : '-'}${formatAmount(sizeDelta, USD_DECIMALS, 2, true)} USD`
}

function getOrderActionTitle(action) {
  let actionDisplay

  if (action.startsWith('Create')) {
    actionDisplay = 'Create'
  } else if (action.startsWith('Cancel')) {
    actionDisplay = 'Cancel'
  } else {
    actionDisplay = 'Update'
  }

  return `${actionDisplay} Order`
}

function renderLiquidationTooltip(liquidationData, label, liquidationFee, maxLeverage) {
  const minCollateral = liquidationData.size.mul(BASIS_POINTS_DIVISOR).div(maxLeverage)
  const text =
    liquidationData.type === 'full'
      ? 'This position was liquidated as the max leverage of 50x was exceeded'
      : 'Max leverage of 50x was exceeded, the remaining collateral after deducting losses and fees have been sent back to your account'
  return (
    <Tooltip
      handle={label}
      renderContent={() => (
        <>
          {text}
          <br />
          Initial collateral: ${formatAmount(liquidationData.collateral, USD_DECIMALS, 2, true)}
          <br />
          Min required collateral: ${formatAmount(minCollateral, USD_DECIMALS, 2, true)}
          <br />
          Borrow Fee: ${formatAmount(liquidationData.borrowFee, USD_DECIMALS, 2, true)}
          <br />
          PnL: -${formatAmount(liquidationData.loss, USD_DECIMALS, 2, true)}
          {liquidationData.type === 'full' && <div>Liquidation Fee: ${formatAmount(liquidationFee, 30, 2, true)}</div>}
        </>
      )}
    />
  )
}

function getLiquidationData(liquidationsDataMap, key, timestamp) {
  return liquidationsDataMap && liquidationsDataMap[`${key}:${timestamp}`]
}

export default function TradeHistory(props) {
  const { account, infoTokens, getTokenInfo, chainId, nativeTokenAddress, forSingleAccount, library } = props
  const [paginate, setPaginate] = useState(10)
  const { data: constrants } = useConstants(chainId, library)

  const [trades, setTrades] = useState(undefined)
  Object.keys(infoTokens).forEach((k) => (infoTokens[k.toLowerCase()] = infoTokens[k]))

  const { chain } = useAccount()

  const { trades: tradesData, totalTrade } = useTradesFromGraph(chainId, account, forSingleAccount, paginate)

  useEffect(() => {
    if (tradesData && tradesData?.data?.actionDatas) {
      setTrades(tradesData)
    }
  }, [tradesData])

  const liquidationsData = useLiquidationsData(chainId, account)
  const liquidationsDataMap = useMemo(() => {
    if (!liquidationsData) {
      return null
    }
    return liquidationsData.reduce((memo, item) => {
      const liquidationKey = `${item.key}:${item.timestamp}`
      memo[liquidationKey] = item
      return memo
    }, {})
  }, [liquidationsData])

  const onClickMore = () => {
    setPaginate(paginate + 10)
  }

  const getMsg = useCallback(
    (trade) => {
      const tradeData = trade
      const actionName = tradeData.action
      const params = JSON.parse(tradeData.params)
      const defaultMsg = ''

      if (actionName === 'BuyUSDG') {
        const token = getTokenInfo(infoTokens, params.token, true, nativeTokenAddress)
        if (!token) {
          return defaultMsg
        }
        return `Swap ${formatAmount(params.tokenAmount, token.decimals, 4, true)} ${token.symbol} for ${formatAmount(
          params.usdgAmount,
          18,
          4,
          true,
        )} USDX`
      }

      if (actionName === 'SellUSDG') {
        const token = getTokenInfo(infoTokens, params.token, true, nativeTokenAddress)
        if (!token) {
          return defaultMsg
        }
        return `Swap ${formatAmount(params.usdgAmount, 18, 4, true)} USDX for ${formatAmount(
          params.tokenAmount,
          token.decimals,
          4,
          true,
        )} ${token.symbol}`
      }

      if (actionName === 'Swap') {
        const tokenIn = getTokenInfo(infoTokens, params.tokenIn, true, nativeTokenAddress)
        const tokenOut = getTokenInfo(infoTokens, params.tokenOut, true, nativeTokenAddress)
        if (!tokenIn || !tokenOut) {
          return defaultMsg
        }
        return `Swap ${formatAmount(params.amountIn, tokenIn.decimals, 4, true)} ${tokenIn.symbol} for ${formatAmount(
          params.amountOut,
          tokenOut.decimals,
          4,
          true,
        )} ${tokenOut.symbol}`
      }

      if (actionName === 'CreateIncreasePosition') {
        const indexToken = getTokenInfo(infoTokens, params.indexToken, true, nativeTokenAddress)
        if (!indexToken) {
          return defaultMsg
        }

        if (bigNumberify(params.sizeDelta).eq(0)) {
          return `Request deposit into ${indexToken.symbol} ${actionName.includes('Long') ? 'Long' : 'Short'}`
        }

        return `Request increase ${indexToken.symbol} ${
          actionName.includes('Long') ? 'Long' : 'Short'
        }, +${formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)} USD, Acceptable Price: ${
          params.isLong ? '<' : '>'
        } ${formatAmount(params.acceptablePrice, USD_DECIMALS, 2, true)} USD`
      }

      if (actionName === 'CreateDecreasePosition') {
        const indexToken = getTokenInfo(infoTokens, params.indexToken, true, nativeTokenAddress)
        if (!indexToken) {
          return defaultMsg
        }

        if (bigNumberify(params.sizeDelta).eq(0)) {
          return `Request withdrawal from ${indexToken.symbol} ${actionName.includes('Long') ? 'Long' : 'Short'}`
        }

        return `Request decrease ${indexToken.symbol} ${
          actionName.includes('Long') ? 'Long' : 'Short'
        }, -${formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)} USD, Acceptable Price: ${
          params.isLong ? '>' : '<'
        } ${formatAmount(params.acceptablePrice, USD_DECIMALS, 2, true)} USD`
      }

      if (actionName === 'CancelIncreasePosition') {
        const indexToken = getTokenInfo(infoTokens, params.indexToken, true, nativeTokenAddress)
        if (!indexToken) {
          return defaultMsg
        }

        if (bigNumberify(params.sizeDelta).eq(0)) {
          return (
            <>
              Could not execute deposit into {indexToken.symbol} {actionName.includes('Long') ? 'Long' : 'Short'}
            </>
          )
        }

        return (
          <>
            Could not increase {indexToken.symbol} {actionName.includes('Long') ? 'Long' : 'Short'},
            {`+${formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)}`} USD, Acceptable Price:&nbsp;
            {params.isLong ? '<' : '>'}&nbsp;
            <Tooltip
              handle={`${formatAmount(params.acceptablePrice, USD_DECIMALS, 2, true)} USD`}
              renderContent={() => <>Try increasing the "Allowed Slippage", under the Settings menu on the top right</>}
            />
          </>
        )
      }

      if (actionName === 'CancelDecreasePosition') {
        const indexToken = getTokenInfo(infoTokens, params.indexToken, true, nativeTokenAddress)
        if (!indexToken) {
          return defaultMsg
        }

        if (bigNumberify(params.sizeDelta).eq(0)) {
          return `Could not execute withdrawal from ${indexToken.symbol} ${
            actionName.includes('Long') ? 'Long' : 'Short'
          }`
        }

        return (
          <>
            Could not decrease {indexToken.symbol} {actionName.includes('Long') ? 'Long' : 'Short'},
            {`+${formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)}`} USD, Acceptable Price:&nbsp;
            {params.isLong ? '>' : '<'}&nbsp;
            <Tooltip
              handle={`${formatAmount(params.acceptablePrice, USD_DECIMALS, 2, true)} USD`}
              renderContent={() => <>Try increasing the "Allowed Slippage", under the Settings menu on the top right</>}
            />
          </>
        )
      }

      if (actionName === 'IncreasePosition-Long' || actionName === 'IncreasePosition-Short') {
        if (params.flags?.isOrderExecution) {
          return
        }

        const indexToken = getTokenInfo(infoTokens, params.indexToken, true, nativeTokenAddress)
        if (!indexToken) {
          return defaultMsg
        }
        if (bigNumberify(params.sizeDelta).eq(0)) {
          return `Deposit ${formatAmount(params.collateralDelta, USD_DECIMALS, 2, true)} USD into ${
            indexToken.symbol
          } ${actionName.includes('Long') ? 'Long' : 'Short'}`
        }
        return `Increase ${indexToken.symbol} ${actionName.includes('Long') ? 'Long' : 'Short'}, +${formatAmount(
          params.sizeDelta,
          USD_DECIMALS,
          2,
          true,
        )} USD, ${indexToken.symbol} Price: ${formatAmount(params.price, USD_DECIMALS, 2, true)} USD`
      }

      if (actionName === 'DecreasePosition-Long' || actionName === 'DecreasePosition-Short') {
        if (params.flags?.isOrderExecution) {
          return
        }

        const indexToken = getTokenInfo(infoTokens, params.indexToken, true, nativeTokenAddress)
        if (!indexToken) {
          return defaultMsg
        }
        if (bigNumberify(params.sizeDelta).eq(0)) {
          return `Withdraw ${formatAmount(params.collateralDelta, USD_DECIMALS, 2, true)} USD from ${
            indexToken.symbol
          } ${actionName.includes('Long') ? 'Long' : 'Short'}`
        }
        const isLiquidation = params.flags?.isLiquidation
        const liquidationData = getLiquidationData(liquidationsDataMap, params.key, tradeData.timestamp)

        if (isLiquidation && liquidationData) {
          return (
            <>
              {renderLiquidationTooltip(
                liquidationData,
                'Partial Liquidation',
                constrants.LIQUIDATION_FEE,
                constrants.MAX_LEVERAGE,
              )}{' '}
              {indexToken.symbol} {actionName.includes('Long') ? 'Long' : 'Short'}, -
              {formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)} USD, {indexToken.symbol}&nbsp; Price: $
              {formatAmount(params.price, USD_DECIMALS, 2, true)} USD
            </>
          )
        }
        const actionDisplay = isLiquidation ? 'Partially Liquidated' : 'Decreased'
        return `
        ${actionDisplay} ${indexToken.symbol} ${actionName.includes('Long') ? 'Long' : 'Short'},
        -${formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)} USD,
        ${indexToken.symbol} Price: ${formatAmount(params.price, USD_DECIMALS, 2, true)} USD
      `
      }

      if (actionName === 'LiquidatePosition-Long' || actionName === 'LiquidatePosition-Short') {
        const indexToken = getTokenInfo(infoTokens, params.indexToken, true, nativeTokenAddress)
        if (!indexToken) {
          return defaultMsg
        }
        const liquidationData = getLiquidationData(liquidationsDataMap, params.key, tradeData.timestamp)
        if (liquidationData) {
          return (
            <>
              {renderLiquidationTooltip(
                liquidationData,
                'Liquidated',
                constrants.LIQUIDATION_FEE,
                constrants.MAX_LEVERAGE,
              )}{' '}
              {indexToken.symbol} {actionName.includes('Long') ? 'Long' : 'Short'}, -
              {formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)} USD,&nbsp;
              {indexToken.symbol} Price: ${formatAmount(params.price, USD_DECIMALS, 2, true)} USD
            </>
          )
        }
        return `
        Liquidated ${indexToken.symbol} ${actionName.includes('Long') ? 'Long' : 'Short'},
        -${formatAmount(params.sizeDelta, USD_DECIMALS, 2, true)} USD,
        ${indexToken.symbol} Price: ${formatAmount(params.price, USD_DECIMALS, 2, true)} USD
      `
      }

      if (['ExecuteIncreaseOrder', 'ExecuteDecreaseOrder'].includes(tradeData.action)) {
        const order = deserialize(params.order)
        const indexToken = getTokenInfo(infoTokens, order.indexToken, true, nativeTokenAddress)
        if (!indexToken) {
          return defaultMsg
        }
        const longShortDisplay = order.isLong ? 'Long' : 'Short'
        const executionPriceDisplay = formatAmount(order.executionPrice, USD_DECIMALS, 2, true)
        const sizeDeltaDisplay = `${order.type === 'Increase' ? '+' : '-'}${formatAmount(
          order.sizeDelta,
          USD_DECIMALS,
          2,
          true,
        )}`

        return `
        Execute Order: ${order.type} ${indexToken.symbol} ${longShortDisplay}
        ${sizeDeltaDisplay} USD, Price: ${executionPriceDisplay} USD
      `
      }

      if (
        [
          'CreateIncreaseOrder',
          'CancelIncreaseOrder',
          'UpdateIncreaseOrder',
          'CreateDecreaseOrder',
          'CancelDecreaseOrder',
          'UpdateDecreaseOrder',
        ].includes(tradeData.action)
      ) {
        const order = deserialize(params.order)
        const indexToken = getTokenInfo(infoTokens, order.indexToken)
        if (!indexToken) {
          return defaultMsg
        }
        const increase = tradeData.action.includes('Increase')
        const priceDisplay = `${order.triggerAboveThreshold ? '>' : '<'} ${formatAmount(
          order.triggerPrice,
          USD_DECIMALS,
          2,
          true,
        )}`
        return `
        ${getOrderActionTitle(tradeData.action)}:
        ${getPositionDisplay(increase, indexToken, order.isLong, order.sizeDelta)},
        Price: ${priceDisplay}
      `
      }

      if (actionName === 'ExecuteSwapOrder') {
        const order = deserialize(params.order)
        const nativeTokenAddress = getContract(chainId, 'NATIVE_TOKEN')
        const fromToken = getTokenInfo(infoTokens, order.path[0] === nativeTokenAddress ? AddressZero : order.path[0])
        const toToken = getTokenInfo(infoTokens, order.shouldUnwrap ? AddressZero : order.path[order.path.length - 1])
        if (!fromToken || !toToken) {
          return defaultMsg
        }
        const fromAmountDisplay = formatAmount(order.amountIn, fromToken.decimals, fromToken.isStable ? 2 : 4, true)
        const toAmountDisplay = formatAmount(order.amountOut, toToken.decimals, toToken.isStable ? 2 : 4, true)
        return `
        Execute Order: Swap ${fromAmountDisplay} ${fromToken.symbol} for ${toAmountDisplay} ${toToken.symbol}
      `
      }

      if (['CreateSwapOrder', 'UpdateSwapOrder', 'CancelSwapOrder'].includes(tradeData.action)) {
        const order = deserialize(params.order)
        const nativeTokenAddress = getContract(chainId, 'NATIVE_TOKEN')
        const fromToken = getTokenInfo(infoTokens, order.path[0] === nativeTokenAddress ? AddressZero : order.path[0])
        const toToken = getTokenInfo(infoTokens, order.shouldUnwrap ? AddressZero : order.path[order.path.length - 1])
        if (!fromToken || !toToken) {
          return defaultMsg
        }
        const amountInDisplay = fromToken
          ? formatAmount(order.amountIn, fromToken.decimals, fromToken.isStable ? 2 : 4, true)
          : ''
        const minOutDisplay = toToken
          ? formatAmount(order.minOut, toToken.decimals, toToken.isStable ? 2 : 4, true)
          : ''

        return `
        ${getOrderActionTitle(tradeData.action)}:
        Swap ${amountInDisplay} ${fromToken?.symbol || ''} for ${minOutDisplay} ${toToken?.symbol || ''},
        Price: ${getExchangeRateDisplay(order.triggerRatio, fromToken, toToken)}`
      }
    },
    [getTokenInfo, infoTokens, nativeTokenAddress, chainId, liquidationsDataMap],
  )

  const tradesWithMessages = useMemo(() => {
    if (!trades) {
      return []
    }
    return trades.data.actionDatas
      .map((trade) => ({
        msg: getMsg(trade),
        ...trade,
      }))
      .filter((trade) => trade.msg)
  }, [trades, getMsg])

  return (
    <div className="w-full p-4 text-xl">
      <div
        className={cx('grid-cols-[160px,1fr] gap-4 w-full mb-6 hidden md:grid', {
          'md:grid-cols-[160px,120px,1fr]': !account || account.length === 0,
        })}
      >
        <div className="col-auto text-left p-2">Date</div>
        {(!account || account.length === 0) && <div className="col-auto p-3">Address</div>}
        <div className="col-auto text-left p-3">Action</div>
      </div>
      <div className="w-full">
        {tradesData === undefined && (
          <div className="text-center py-[8rem] border-t dark:border-bg-dark-color-2">Loading...</div>
        )}

        {chain && tradesWithMessages.length > 0 ? (
          tradesWithMessages.map((trade, index) => {
            const tradeData = trade
            const txUrl = getExplorerUrl(chainId) + 'tx/' + tradeData.txhash

            let msg = getMsg(trade)

            if (!msg) {
              return null
            }

            return (
              <div
                className={cx(
                  'grid bg-white md:divide-y-0 md:gap-4 w-fullbg-white pl-0 p-2 mb-4 items-center rounded-3xl border-l-4 relative grid-cols-1 divide-y dark:bg-bg-dark-color-3 dark:border-bg-dark-color-2',
                  {
                    'md:grid-cols-[160px,120px,1fr]': !account || account?.length === 0,

                    'md:grid-cols-[160px,1fr]': !(!account || account?.length === 0),
                  },
                )}
                key={index}
              >
                <div className="col-auto p-3">
                  <div className="muted TradeHistory-time">{formatDateTime(tradeData.timestamp)}</div>
                </div>
                {(!account || account.length === 0) && (
                  <div className="col-auto p-3">
                    <Link className="underline" to={`/actions/${tradeData.account}`}>
                      {shortenAddress(tradeData.account, 13)}
                    </Link>
                  </div>
                )}
                <div className="col-auto p-3">
                  <a className="underline" href={txUrl} target="_blank" rel="noopener noreferrer">
                    {msg}
                  </a>
                </div>
              </div>
            )
          })
        ) : tradesData !== undefined ? (
          <div className="text-center py-[8rem] border-t dark:border-bg-dark-color-2">No trade data</div>
        ) : null}

        <div className="flex justify-center">
          {tradesWithMessages?.length > 0 && chain && totalTrade > paginate && (
            <Button onClick={onClickMore} className="">
              Load more
            </Button>
          )}
          {tradesData && tradesWithMessages?.length > 0 && totalTrade === tradesWithMessages?.length && (
            <div className="p-4 text-xl">Nothing more to load</div>
          )}
        </div>
      </div>
    </div>
  )
}
