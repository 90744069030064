import './Referrals.css'
import { useCallback } from 'react'
import { useLocalStorage } from 'react-use'
import { useParams } from 'react-router-dom'
import SEO from 'components/Common/SEO'
import Tab from 'components/Tab/Tab'
import Loader from 'components/Common/Loader'
import { Footer } from '@tfx/ui'
import { useChainId, getPageTitle, useLocalStorageSerializeKey, isHashZero } from 'utils'
import { REFERRALS_SELECTED_TAB_KEY } from 'config/localStorage.ts'
import {
  useReferralsData,
  registerReferralCode,
  useCodeOwner,
  useReferrerTier,
  useUserReferralCode,
} from 'utils/api/referrals'
import JoinReferralCode from 'components/Referrals/JoinReferralCode'
import AffiliatesStats from 'components/Referrals/AffiliatesStats'
import TradersStats from 'components/Referrals/TradersStats'
import AddAffiliateCode from 'components/Referrals/AddAffiliateCode'
import { deserializeSampleStats, isRecentReferralCodeNotExpired } from 'components/Referrals/referralsHelper'
import { ethers } from 'ethers'
import { useWeb3 } from 'hooks/useWeb3'

const TRADERS = 'Traders'
const AFFILIATES = 'Affiliates'
const TAB_OPTIONS = [TRADERS, AFFILIATES]

function Referrals({ connectWallet, setPendingTxns, pendingTxns }) {
  const { active, account: walletAccount, library } = useWeb3()
  const { account: queryAccount } = useParams()
  let account
  if (queryAccount && ethers.utils.isAddress(queryAccount)) {
    account = ethers.utils.getAddress(queryAccount)
  } else {
    account = walletAccount
  }
  const { chainId } = useChainId()
  const [activeTab, setActiveTab] = useLocalStorage(REFERRALS_SELECTED_TAB_KEY, TRADERS)
  const { data: referralsData, loading } = useReferralsData(chainId, account)
  const [recentlyAddedCodes, setRecentlyAddedCodes] = useLocalStorageSerializeKey([chainId, 'REFERRAL', account], [], {
    deserializer: deserializeSampleStats,
  })

  const { userReferralCode, userReferralCodeString } = useUserReferralCode(library, chainId, account)
  const { codeOwner } = useCodeOwner(library, chainId, account, userReferralCode)
  const { referrerTier: traderTier } = useReferrerTier(library, chainId, codeOwner)

  const handleCreateReferralCode = useCallback(
    (referralCode) => {
      return registerReferralCode(chainId, referralCode, library, {
        sentMsg: 'Referral code submitted!',
        failMsg: 'Referral code creation failed.',
        pendingTxns,
      })
    },
    [chainId, library, pendingTxns],
  )

  const renderAffiliatesTab = useCallback(() => {
    const isReferralCodeAvailable =
      referralsData?.codes?.length > 0 || recentlyAddedCodes?.filter(isRecentReferralCodeNotExpired).length > 0
    if (loading) return <Loader />
    if (isReferralCodeAvailable) {
      return (
        <AffiliatesStats
          referralsData={referralsData}
          handleCreateReferralCode={handleCreateReferralCode}
          setRecentlyAddedCodes={setRecentlyAddedCodes}
          recentlyAddedCodes={recentlyAddedCodes}
          chainId={chainId}
        />
      )
    } else {
      return (
        <AddAffiliateCode
          handleCreateReferralCode={handleCreateReferralCode}
          active={active}
          connectWallet={connectWallet}
          recentlyAddedCodes={recentlyAddedCodes}
          setRecentlyAddedCodes={setRecentlyAddedCodes}
        />
      )
    }
  }, [
    active,
    chainId,
    connectWallet,
    handleCreateReferralCode,
    loading,
    recentlyAddedCodes,
    referralsData,
    setRecentlyAddedCodes,
  ])

  const renderTradersTab = useCallback(() => {
    if (loading) return <Loader />
    if (isHashZero(userReferralCode) || !account || !userReferralCode) {
      return (
        <JoinReferralCode
          connectWallet={connectWallet}
          active={active}
          setPendingTxns={setPendingTxns}
          pendingTxns={pendingTxns}
        />
      )
    }
    return (
      <TradersStats
        userReferralCodeString={userReferralCodeString}
        chainId={chainId}
        referralsData={referralsData}
        setPendingTxns={setPendingTxns}
        pendingTxns={pendingTxns}
        traderTier={traderTier}
      />
    )
  }, [
    account,
    active,
    chainId,
    connectWallet,
    loading,
    pendingTxns,
    referralsData,
    setPendingTxns,
    traderTier,
    userReferralCode,
    userReferralCodeString,
  ])

  return (
    <SEO title={getPageTitle('Referrals')}>
      <div className="default-container page-layout">
        <div className="section-title-block">
          <div className="section-title-icon"></div>
          <div className="section-title-content">
            <div className="Page-title">Referrals</div>
            <div className="Page-description">
              Get fee discounts and earn rebates through the TFX referral program.
              <br />
              For more information, please read the{' '}
              <a target="_blank" rel="noopener noreferrer" href="https://tfx-market.gitbook.io/tfx/referrals">
                referral program details
              </a>
              .
            </div>
          </div>
        </div>
        <div className="referral-tab-container">
          <Tab options={TAB_OPTIONS} option={activeTab} setOption={setActiveTab} onChange={setActiveTab} />
        </div>
        {activeTab === AFFILIATES ? renderAffiliatesTab() : renderTradersTab()}
        <Footer isPaddingX={false} />
      </div>
    </SEO>
  )
}

export default Referrals
