import React from 'react'
import Panel from 'components/Panel'
import { BigNumber } from 'ethers'
import { formatAmount, getExchangeRateDisplay, getExchangeRate, USD_DECIMALS } from 'utils'
import Tooltip from 'components/Tooltip/Popper'
import ExchangeInfoRow from './ExchangeInfoRow'

import { Token } from 'utils/types'

type SwapInfoProps = {
  fromToken: Token
  toToken: Token
  fromTokenInfo: any
  toTokenInfo: any
  maxSwapAmountUsd: BigNumber
  maxFromTokenIn: BigNumber
  maxFromTokenInUSD: BigNumber
  maxToTokenOut: BigNumber
  maxToTokenOutUSD: BigNumber
  isMarketOrder: boolean
}
const SwapInfoBox = ({
  fromTokenInfo,
  toTokenInfo,
  toToken,
  fromToken,
  maxSwapAmountUsd,
  maxFromTokenIn,
  maxFromTokenInUSD,
  maxToTokenOut,
  maxToTokenOutUSD,
  isMarketOrder,
}: SwapInfoProps) => {
  return (
    <Panel title="Swap">
      <div className="Exchange-info-row">
        <div className="Exchange-info-label">{fromToken.symbol} Price</div>
        <div className="align-right">
          {fromTokenInfo && formatAmount(fromTokenInfo.minPrice, USD_DECIMALS, 2, true)} USD
        </div>
      </div>
      <div className="Exchange-info-row">
        <div className="Exchange-info-label">{toToken.symbol} Price</div>
        <div className="align-right">
          {toTokenInfo && formatAmount(toTokenInfo.maxPrice, USD_DECIMALS, 2, true)} USD
        </div>
      </div>
      <div className="Exchange-info-row">
        <div className="Exchange-info-label">Available Liquidity:</div>
        <div className="align-right al-swap">
          <Tooltip
            handle={`${formatAmount(maxSwapAmountUsd, USD_DECIMALS, 2, true)} USD`}
            renderContent={() => {
              return (
                <div>
                  <div>
                    Max {fromTokenInfo.symbol} in: {formatAmount(maxFromTokenIn, fromTokenInfo.decimals, 2, true)}{' '}
                    {fromTokenInfo.symbol} <br />({'$ '}
                    {formatAmount(maxFromTokenInUSD, USD_DECIMALS, 2, true)})
                  </div>
                  <br />
                  <div>
                    Max {toTokenInfo.symbol} out: {formatAmount(maxToTokenOut, toTokenInfo.decimals, 2, true)}{' '}
                    {toTokenInfo.symbol} <br />({'$ '}
                    {formatAmount(maxToTokenOutUSD, USD_DECIMALS, 2, true)})
                  </div>
                </div>
              )
            }}
          />
        </div>
      </div>

      {!isMarketOrder && (
        <ExchangeInfoRow label="Price">
          {getExchangeRateDisplay(getExchangeRate(fromTokenInfo, toTokenInfo), fromToken, toToken)}
        </ExchangeInfoRow>
      )}
    </Panel>
  )
}

export default SwapInfoBox
