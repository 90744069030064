import React from 'react'
import { Area, Bar, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { tooltipFormatter, tooltipLabelFormatter, yaxisFormatter } from '../../helpers'
import { useChartViewState } from 'hooks/useChartViewState'
import ChartWrapper from '../ChartWrapper'
import { useTheme } from 'hooks/useTheme'

const convertToPercentsHandler = (data) => {
  return data.map((item) => {
    const total = item.profit + Math.abs(item.loss)

    const resultItem = {
      ...item,
      profit: (item.profit / total) * 100,
      loss: (Math.abs(item.loss) / total) * 100,
      all: 100,
    }

    return resultItem
  })
}

export default function TradersProfitLossChart({ syncId, tradersData, loading, yaxisWidth, chartHeight }) {
  const controls = {
    convertToPercents: convertToPercentsHandler,
  }

  const { viewState, togglePercentView, formattedData, ...viewSettings } = useChartViewState({
    controls,
    data: tradersData?.data,
  })

  const { theme } = useTheme()

  return (
    <ChartWrapper
      title="Traders Profit vs. Loss"
      loading={loading}
      data={formattedData}
      csvFields={[{ key: 'profit' }, { key: 'loss' }, { key: 'profitCumulative' }, { key: 'lossCumulative' }]}
      controls={controls}
      togglePercentView={togglePercentView}
      viewState={viewState}
    >
      {tradersData?.stats && (
        <ResponsiveContainer width="100%" height={chartHeight}>
          <ComposedChart data={formattedData} barGap={0} syncId={syncId}>
            <CartesianGrid strokeDasharray="2 2" stroke={theme === 'dark' ? '#585555' : '#e3dcdc'} />
            <XAxis
              dataKey="timestamp"
              tickFormatter={tooltipLabelFormatter}
              minTickGap={30}
              fontSize={13}
              tick={{ fill: theme === 'dark' ? '#c5c5c5' : '#888888' }}
              stroke={theme === 'dark' ? '#fff' : '#646464'}
            />

            {viewState.isPercentsView ? (
              <YAxis
                dataKey="all"
                tickFormatter={viewSettings.yaxisTickFormatter}
                width={yaxisWidth}
                stroke={theme === 'dark' ? '#e7e5e5' : '#716e6e'}
                fontSize={13}
                padding={{
                  bottom: 15,
                }}
                tick={{ fill: theme === 'dark' ? '#c5c5c5' : '#888888' }}
              />
            ) : (
              <YAxis
                domain={[-tradersData?.stats.maxProfitLoss * 1.05, tradersData?.stats.maxProfitLoss * 1.05]}
                tickFormatter={viewSettings.yaxisTickFormatter}
                width={yaxisWidth}
                stroke={theme === 'dark' ? '#e7e5e5' : '#716e6e'}
                fontSize={13}
                padding={{
                  bottom: 15,
                }}
                tick={{ fill: theme === 'dark' ? '#c5c5c5' : '#888888' }}
              />
            )}
            <YAxis
              domain={[-tradersData?.stats.maxProfitLoss * 1.05, tradersData?.stats.maxProfitLoss * 1.05]}
              tickFormatter={viewSettings.yaxisTickFormatter}
              width={yaxisWidth}
              stroke={theme === 'dark' ? '#e7e5e5' : '#716e6e'}
              fontSize={13}
              padding={{
                bottom: 15,
              }}
              tick={{ fill: theme === 'dark' ? '#c5c5c5' : '#888888' }}
            />
            <YAxis
              domain={[
                -tradersData?.stats.maxCurrentCumulativeProfitLoss * 1.1,
                tradersData?.stats.maxCurrentCumulativeProfitLoss * 1.1,
              ]}
              orientation="right"
              yAxisId="right"
              tickFormatter={yaxisFormatter}
              width={yaxisWidth}
              stroke={theme === 'dark' ? '#e7e5e5' : '#716e6e'}
              fontSize={13}
              padding={{
                bottom: 15,
              }}
              tick={{ fill: theme === 'dark' ? '#c5c5c5' : '#888888' }}
            />
            <Tooltip
              formatter={tooltipFormatter}
              labelFormatter={tooltipLabelFormatter}
              contentStyle={{
                background: theme === 'dark' ? '#e6e5e5' : '#ffffff',
                border: 0,
                opacity: 0.8,
              }}
              labelStyle={{ color: '#000', fontSize: '13px' }}
              itemStyle={{ fontSize: '13px' }}
            />
            <Legend />
            {!viewState.isPercentsView && (
              <>
                <Area
                  yAxisId="right"
                  type="monotone"
                  stroke={0}
                  fill="#f93333"
                  fillOpacity="0.4"
                  dataKey="currentLossCumulative"
                  name="Cumulative Loss"
                  isAnimationActive={false}
                />
                <Area
                  yAxisId="right"
                  type="monotone"
                  stroke={0}
                  fill="#22c761"
                  fillOpacity="0.4"
                  dataKey="currentProfitCumulative"
                  name="Cumulative Profit"
                  isAnimationActive={false}
                />
              </>
            )}
            {viewState.isPercentsView ? (
              <>
                <Bar
                  unit={viewSettings.itemsUnit}
                  type="monotone"
                  stackId="b"
                  fill="#f93333"
                  dot={true}
                  dataKey="loss"
                  name="Loss"
                  isAnimationActive={false}
                />
                <Bar
                  unit={viewSettings.itemsUnit}
                  type="monotone"
                  stackId="b"
                  fill="#22c761"
                  dot={true}
                  dataKey="profit"
                  name="Profit"
                  isAnimationActive={false}
                />
              </>
            ) : (
              <>
                <Bar type="monotone" fill="#f93333" dot={true} dataKey="loss" name="Loss" isAnimationActive={false} />
                <Bar
                  type="monotone"
                  fill="#22c761"
                  dot={true}
                  dataKey="profit"
                  name="Profit"
                  isAnimationActive={false}
                />
              </>
            )}
          </ComposedChart>
        </ResponsiveContainer>
      )}
      <div className="text-xl pl-10">
        <p>Considers settled (closed) positions, Fees are not factored into PnL</p>
      </div>
    </ChartWrapper>
  )
}
