import { useState } from 'react'

import Modal from 'components/Modal/Modal'
import { useWeb3 } from 'hooks/useWeb3'

import ClaimToWallet from './ClaimToWallet'
import Compound from './Compound'

import { AiOutlineCheck } from 'react-icons/ai'
import { IoWalletOutline } from 'react-icons/io5'
import { RiRefund2Line } from 'react-icons/ri'

const ClaimRewardModal = (props) => {
  const {
    isVisible,
    setIsVisible,
    rewardRouterAddress,
    library,
    chainId,
    processedData,
    nativeTokenSymbol,
    wrappedTokenSymbol,
    setPendingTxns,
  } = props
  const { active, account } = useWeb3()
  const [isCompound, setIsCompound] = useState(true)

  if (!processedData) return null

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label="Claim Rewards">
        <div className="w-full max-w-lg">
          <div id="accordion-collapse" data-accordion="collapse">
            <div
              id="accordion-collapse-heading-1"
              className="flex items-center w-full py-5 justify-between gap-4 cursor-pointer"
              data-accordion-target="#accordion-collapse-body-1"
              aria-expanded="true"
              aria-controls="accordion-collapse-body-1"
              onClick={() => setIsCompound(false)}
            >
              <div className="flex gap-3">
                <IoWalletOutline className="text-[28px] text-blue-600" />
                <div className="leading-6">
                  <div className="text-2xl font-bold">Claim to Wallet</div>
                  <div className="text-xl">Claim reward to your Wallet.</div>
                </div>
              </div>
              {!isCompound && <AiOutlineCheck className="text-[20px] text-green-600" />}
            </div>

            <div
              id="accordion-collapse-body-1"
              className={!isCompound ? '' : 'hidden'}
              aria-labelledby="accordion-collapse-heading-1"
            >
              <div className="p-5 pt-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                <ClaimToWallet
                  setIsVisible={setIsVisible}
                  setPendingTxns={setPendingTxns}
                  active={active}
                  account={account}
                  rewardRouterAddress={rewardRouterAddress}
                  totalVesterRewards={processedData.totalVesterRewards}
                  wrappedTokenSymbol={wrappedTokenSymbol}
                  nativeTokenSymbol={nativeTokenSymbol}
                  library={library}
                  chainId={chainId}
                />
              </div>
            </div>

            <div
              id="accordion-collapse-heading-2"
              className="flex items-center w-full py-5 justify-between gap-4 border-t-2 border-stone-100 cursor-pointer"
              data-accordion-target="#accordion-collapse-body-2"
              aria-expanded="true"
              aria-controls="accordion-collapse-body-2"
              onClick={() => setIsCompound(true)}
            >
              <div className="flex gap-3">
                <RiRefund2Line className="text-[28px] text-blue-600" />
                <div className="leading-6">
                  <div className="text-2xl font-bold">Claim and Restake</div>
                  <div className="text-xl">Compound your reward by incressing your stake.</div>
                </div>
              </div>
              {isCompound && <AiOutlineCheck className="text-[20px] text-green-600" />}
            </div>

            <div
              id="accordion-collapse-body-2"
              className={isCompound ? '' : 'hidden'}
              aria-labelledby="accordion-collapse-heading-2"
            >
              <div className="p-5 pt-0 border-gray-200 dark:border-gray-700">
                <Compound
                  setIsVisible={setIsVisible}
                  rewardRouterAddress={rewardRouterAddress}
                  library={library}
                  chainId={chainId}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ClaimRewardModal
