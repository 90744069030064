import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import './ChartTokenSelector.css'
import { LONG, SHORT, SWAP } from 'utils'
import { getTokens, getWhitelistedTokens } from '@tfx/tokens'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ChartTokenSelector(props) {
  const { chainId, selectedToken, onSelectToken, swapOption } = props

  const isLong = swapOption === LONG
  const isShort = swapOption === SHORT
  const isSwap = swapOption === SWAP

  let options = getTokens(chainId)
  const whitelistedTokens = getWhitelistedTokens(chainId)
  const indexTokens = whitelistedTokens.filter((token) => !token.isStable && !token.isWrapped)
  const shortableTokens = indexTokens.filter((token) => token.isShortable)

  if (isLong) {
    options = indexTokens
  }
  if (isShort) {
    options = shortableTokens
  }

  const onSelect = async (token) => {
    onSelectToken(token)
  }

  const value = selectedToken

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full items-center justify-center bg-transparent" disabled={isSwap}>
          {value.symbol} / USD
          {!isSwap && <ChevronDownIcon className="-mr-1 ml-2 h-8 w-8" aria-hidden="true" />}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-10 mt-3 w-[150px] origin-top-right rounded-md bg-bg-color-5 border dark:bg-gray-600 dark:border-gray-900 shadow-smfocus:outline-none">
          <div className="py-1">
            {options.map((option, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <span
                    onClick={() => {
                      value.symbol !== option.symbol && onSelect(option)
                    }}
                    className={classNames(
                      active || value.symbol === option.symbol ? 'text-gray-400' : '',
                      'block px-4 py-3 text-2xl cursor-pointer',
                    )}
                  >
                    {option.symbol} / USD
                  </span>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
