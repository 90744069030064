import { getContract } from '@tfx/addresses'
import { useCallback, useState } from 'react'
import { handleCancelOrder } from 'utils/api'
import {
  DECREASE,
  formatAmount,
  getExchangeRate,
  getExchangeRateDisplay,
  getOrderError,
  getPositionForOrder,
  getTokenInfo,
  getUsd,
  INCREASE,
  LONG,
  SHORT,
  SWAP,
  TRIGGER_PREFIX_ABOVE,
  TRIGGER_PREFIX_BELOW,
  USD_DECIMALS,
} from 'utils/index.js'
import { useAccount } from 'wagmi'

import Tooltip from '../Tooltip/Popper'
import OrderEditor from './OrderEditor'

import Button, { PButton } from 'components/Button'
import { MdSwapHoriz, MdTrendingDown, MdTrendingUp } from 'react-icons/md'
import Checkbox from '../Checkbox/Checkbox.js'
import './OrdersList.css'

const SWAP_ICONS = {
  [LONG]: MdTrendingUp,
  [SHORT]: MdTrendingDown,
  [SWAP]: MdSwapHoriz,
}

export default function OrdersList(props) {
  const {
    account,
    library,
    setPendingTxns,
    pendingTxns,
    infoTokens,
    positionsMap,
    totalTokenWeights,
    usdxSupply,
    orders,
    hideActions,
    chainId,
    savedShouldDisableOrderValidation,
    cancelOrderIdList,
    setCancelOrderIdList,
    onClickMoreOrder,
    isHasPaginate,
  } = props

  const [editingOrder, setEditingOrder] = useState(null)
  const { chain } = useAccount()
  const onCancelClick = useCallback(
    (order) => {
      handleCancelOrder(chainId, library, order, {
        pendingTxns,
        setPendingTxns,
      })
    },
    [library, pendingTxns, setPendingTxns, chainId],
  )

  const onEditClick = useCallback(
    (order) => {
      setEditingOrder(order)
    },
    [setEditingOrder],
  )

  const renderHead = useCallback(() => {
    const isAllOrdersSelected = cancelOrderIdList?.length > 0 && cancelOrderIdList?.length === orders.length

    return (
      <div className="col-auto grid-cols-[55px,80px,1fr,1fr,1fr,120px] gap-6 w-full mb-6 hidden md:grid px-2">
        <div className="text-left p-2">
          {orders.length > 0 && (
            <Checkbox
              isChecked={isAllOrdersSelected}
              setIsChecked={() => {
                if (isAllOrdersSelected) {
                  setCancelOrderIdList([])
                } else {
                  const allOrderIds = orders.map((o) => `${o.type}-${o.index}`)
                  setCancelOrderIdList(allOrderIds)
                }
              }}
            />
          )}
        </div>
        <div className="col-auto text-left p-2">Type</div>
        <div className="col-auto text-left p-2">Order</div>
        <div className="col-auto text-left p-2">Price</div>
        <div className="col-auto text-left p-2">Mark Price</div>
      </div>
    )
  }, [cancelOrderIdList, orders, setCancelOrderIdList])

  const renderEmptyRow = useCallback(() => {
    if (orders && orders.length) {
      return null
    }

    return <div className="text-center py-[8rem] border-t dark:border-bg-dark-color-2">No open orders</div>
  }, [orders])

  const renderActions = useCallback(
    (order) => {
      return (
        <div
          className="col-span-2 md:col-auto md:max-w-[90px] gap-1 md:gap-2 md:flex-col flex flex-row md:items-end p-3 justify-center"
          style={{ borderTopWidth: 'inherit' }}
        >
          <PButton size="sm" onClick={() => onEditClick(order)}>
            Edit
          </PButton>
          <PButton size="sm" onClick={() => onCancelClick(order)}>
            Close
          </PButton>
        </div>
      )
    },
    [onEditClick, onCancelClick],
  )

  const renderLargeList = useCallback(() => {
    if (!orders || !orders.length || !chain) {
      return null
    }

    return orders.map((order) => {
      if (order.type === SWAP) {
        const nativeTokenAddress = getContract(chainId, 'NATIVE_TOKEN')
        const fromTokenInfo = getTokenInfo(infoTokens, order.path[0], true, nativeTokenAddress)
        const toTokenInfo = getTokenInfo(
          infoTokens,
          order.path[order.path.length - 1],
          order.shouldUnwrap,
          nativeTokenAddress,
        )

        const markExchangeRate = getExchangeRate(fromTokenInfo, toTokenInfo)
        const orderId = `${order.type}-${order.index}`
        const Icon = SWAP_ICONS[SWAP]

        return (
          <div
            className="grid items-stretch md:divide-y-0 md:grid-cols-[55px,60px,1fr,1fr,1fr,120px] md:gap-6 w-full bg-white
              pl-0 p-2 mb-4 md:items-center rounded-3xl border-l-4 relative grid-cols-2 divide-y min-h-[58px]
              dark:bg-bg-dark-color-3 dark:border-bg-dark-color-2"
            key={orderId}
          >
            <div className="col-auto p-3">
              <div className="checkbox-inline">
                <Checkbox
                  isChecked={cancelOrderIdList?.includes(orderId)}
                  setIsChecked={() => {
                    setCancelOrderIdList((prevState) => {
                      if (prevState.includes(orderId)) {
                        return prevState.filter((i) => i !== orderId)
                      } else {
                        return prevState.concat(orderId)
                      }
                    })
                  }}
                />
              </div>
            </div>
            <div className="col-auto p-3" style={{ borderTopWidth: 'inherit' }}>
              <div className="text-[10px] font-bold text-txt-color-1 block md:hidden dark:text-white">Type: </div>
              Limit
            </div>
            <div className="col-auto md:flex items-center p-3">
              <div className="text-[10px] font-bold text-txt-color-1 block md:hidden dark:text-white">Order: </div>
              <Icon className="fill-bg-color-4 hidden md:block dark:fill-white h-[20px] w-[20px] mr-3" />
              <div>
                Swap{' '}
                {formatAmount(
                  order.amountIn,
                  fromTokenInfo.decimals,
                  fromTokenInfo.isStable || fromTokenInfo.isUsdg ? 2 : 4,
                  true,
                )}{' '}
                {fromTokenInfo.symbol} for{' '}
                {formatAmount(
                  order.minOut,
                  toTokenInfo.decimals,
                  toTokenInfo.isStable || toTokenInfo.isUsdg ? 2 : 4,
                  true,
                )}{' '}
                {toTokenInfo.symbol}
              </div>
            </div>
            <div className="col-auto p-3">
              <div className="text-[10px] font-bold text-txt-color-1 block md:hidden dark:text-white">Price: </div>
              <Tooltip
                handle={getExchangeRateDisplay(order.triggerRatio, fromTokenInfo, toTokenInfo)}
                renderContent={() => `
                  You will receive at least ${formatAmount(
                    order.minOut,
                    toTokenInfo.decimals,
                    toTokenInfo.isStable || toTokenInfo.isUsdg ? 2 : 4,
                    true,
                  )} ${toTokenInfo.symbol}
                if this order is executed. The execution
                price may vary depending on swap fees at the time the order is executed.
                `}
              />
            </div>
            <div className="p-3 pr-6 col-span-2 md:col-span-1">
              <div className="text-[10px] font-bold text-txt-color-1 block md:hidden dark:text-white">Mark Price: </div>
              {getExchangeRateDisplay(markExchangeRate, fromTokenInfo, toTokenInfo, true)}
            </div>
            {!hideActions && renderActions(order)}
          </div>
        )
      }

      const indexToken = getTokenInfo(infoTokens, order.indexToken)
      const maximisePrice = (order.type === INCREASE && order.isLong) || (order.type === DECREASE && !order.isLong)
      const markPrice = maximisePrice ? indexToken.contractMaxPrice : indexToken.contractMinPrice
      const triggerPricePrefix = order.triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW
      const indexTokenSymbol = indexToken.isWrapped ? indexToken.baseSymbol : indexToken.symbol

      const error = getOrderError(account, order, positionsMap)
      const orderId = `${order.type}-${order.index}`
      const orderText = (
        <>
          {order.type === INCREASE ? 'Increase' : 'Decrease'} {indexTokenSymbol} {order.isLong ? 'Long' : 'Short'}
          &nbsp;by ${formatAmount(order.sizeDelta, USD_DECIMALS, 2, true)}
          {error && <div className="Exchange-list-item-error">{error}</div>}
        </>
      )
      const Icon = order.isLong ? SWAP_ICONS[LONG] : SWAP_ICONS[SHORT]

      return (
        <div
          className="grid items-stretch md:divide-y-0 md:grid-cols-[55px,60px,1fr,1fr,1fr,120px] md:gap-6 w-full bg-white
              pl-0 p-2 mb-4 md:items-center rounded-3xl border-l-4 relative grid-cols-2 divide-y min-h-[58px]
              dark:bg-bg-dark-color-3 dark:border-bg-dark-color-2"
          key={`${order.isLong}-${order.type}-${order.index}`}
        >
          <div className="col-auto p-3">
            <div className="checkbox-inline">
              <Checkbox
                isChecked={cancelOrderIdList?.includes(orderId)}
                setIsChecked={() => {
                  setCancelOrderIdList((prevState) => {
                    if (prevState.includes(orderId)) {
                      return prevState.filter((i) => i !== orderId)
                    } else {
                      return prevState.concat(orderId)
                    }
                  })
                }}
              />
            </div>
          </div>
          <div className="col-auto p-3" style={{ borderTopWidth: 'inherit' }}>
            <div className="text-[10px] font-bold text-txt-color-1 block md:hidden dark:text-white">Type: </div>
            {order.type === INCREASE ? 'Limit' : 'Trigger'}
          </div>
          <div className="col-auto md:flex items-center p-3">
            <div className="text-[10px] font-bold text-txt-color-1 block md:hidden dark:text-white">Order: </div>
            {Icon && <Icon className="hidden md:block fill-bg-color-4 dark:fill-white h-[20px] w-[20px] mr-3" />}
            <div className="">
              {order.type === DECREASE ? (
                orderText
              ) : (
                <Tooltip
                  handle={orderText}
                  renderContent={() => {
                    const collateralTokenInfo = getTokenInfo(infoTokens, order.purchaseToken)
                    const collateralUSD = getUsd(order.purchaseTokenAmount, order.purchaseToken, false, infoTokens)
                    return (
                      <span>
                        Collateral: ${formatAmount(collateralUSD, USD_DECIMALS, 2, true)} (
                        {formatAmount(order.purchaseTokenAmount, collateralTokenInfo.decimals, 4, true)}{' '}
                        {collateralTokenInfo.baseSymbol || collateralTokenInfo.symbol})
                      </span>
                    )
                  }}
                />
              )}
            </div>
          </div>
          <div className="col-auto p-3">
            <div className="text-[10px] font-bold text-txt-color-1 block md:hidden dark:text-white">Price: </div>
            {triggerPricePrefix} {formatAmount(order.triggerPrice, USD_DECIMALS, 2, true)}
          </div>
          <div className="p-3 col-span-2 md:col-span-1">
            <div className="text-[10px] font-bold text-txt-color-1 block md:hidden dark:text-white">Mark Price: </div>
            <Tooltip
              handle={formatAmount(markPrice, USD_DECIMALS, 2, true)}
              renderContent={() => {
                return (
                  <>
                    The price that orders can be executed at may differ slightly from the chart price, as market orders
                    update oracle prices, while limit/trigger orders do not.
                  </>
                )
              }}
            />
          </div>
          {!hideActions && renderActions(order)}
        </div>
      )
    })
  }, [
    orders,
    renderActions,
    infoTokens,
    positionsMap,
    hideActions,
    chainId,
    account,
    cancelOrderIdList,
    setCancelOrderIdList,
    chain,
  ])

  return (
    <div>
      <div className="w-full text-xl">
        {renderHead()}
        {renderEmptyRow()}
        {renderLargeList()}
        {isHasPaginate && (
          <div className="flex justify-center">
            <Button onClick={onClickMoreOrder}>Load more</Button>
          </div>
        )}
      </div>

      {editingOrder && (
        <OrderEditor
          account={account}
          order={editingOrder}
          setEditingOrder={setEditingOrder}
          infoTokens={infoTokens}
          pendingTxns={pendingTxns}
          setPendingTxns={setPendingTxns}
          getPositionForOrder={getPositionForOrder}
          positionsMap={positionsMap}
          library={library}
          totalTokenWeights={totalTokenWeights}
          usdxSupply={usdxSupply}
          savedShouldDisableOrderValidation={savedShouldDisableOrderValidation}
        />
      )}
    </div>
  )
}
