import { useRef, useState } from 'react'
import { BiEditAlt } from 'react-icons/bi'
import Modal from '../Modal/Modal'
import Tooltip from '../Tooltip/Popper'
import { getNativeToken, getToken } from '@tfx/tokens'
import { formatAmount, formatDate, getExplorerUrl, shortenAddress } from 'utils'
import EmptyMessage from './EmptyMessage'
import InfoCard from './InfoCard'
import { getTierIdDisplay, getUSDValue, tierDiscountInfo } from './referralsHelper'
import { ReferralCodeForm } from './JoinReferralCode'

function TradersStats({ referralsData, traderTier, chainId, userReferralCodeString, setPendingTxns, pendingTxns }) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const editModalRef = useRef<HTMLInputElement>(null)

  const open = () => setIsEditModalOpen(true)
  const close = () => setIsEditModalOpen(false)

  return (
    <div>
      <Modal
        className="Connect-wallet-modal"
        isVisible={isEditModalOpen}
        setIsVisible={close}
        label="Edit Referral Code"
        onAfterOpen={() => editModalRef.current?.focus()}
      >
        <div className="edit-referral-modal w-full">
          <ReferralCodeForm
            userReferralCodeString={userReferralCodeString}
            setPendingTxns={setPendingTxns}
            pendingTxns={pendingTxns}
            type="edit"
            callAfterSuccess={() => setIsEditModalOpen(false)}
          />
        </div>
      </Modal>

      <div className="w-full bg-bg-color-5 dark:bg-bg-dark-color-3 border border-bg-color-2 p-8 rounded-3xl text-3xl">
        <div>
          <dl className="sm:grid sm:grid-cols-3 py-8 relative">
            <InfoCard
              label="Total Trading Volume"
              tooltipText="Volume traded by this account with an active referral code."
              data={getUSDValue(referralsData?.referralTotalStats?.volume)}
            />
            <InfoCard
              label="Total Rebates"
              tooltipText="Rebates earned by this account as a trader."
              data={getUSDValue(referralsData?.referralTotalStats?.discountUsd, 4)}
            />
            <InfoCard
              label="Active Referral Code"
              data={
                <div className="active-referral-code">
                  <div className="edit">
                    <span>{userReferralCodeString}</span>
                    <BiEditAlt size={18} onClick={open} />
                  </div>
                  {traderTier && (
                    <div className="tier text-[15px]">
                      <Tooltip
                        handle={`Tier ${getTierIdDisplay(traderTier)} (${tierDiscountInfo[traderTier]}% discount)`}
                        renderContent={() =>
                          `You will receive a ${tierDiscountInfo[traderTier]}% discount on your opening and closing fees, this discount will be airdropped to your account every Wednesday`
                        }
                      />
                    </div>
                  )}
                </div>
              }
            />
          </dl>
        </div>

        <div className="mb-8 py-6 flex justify-between items-center border-b border-t border-bg-color-2">
          <div className="font-bold text-3xl">Transactions</div>
        </div>

        <div className="grid-cols-[150px,1fr,1fr] gap-4 w-full px-4 mb-4 hidden md:grid dark:text-gray-300 text-2xl">
          <div className="col-auto text-left p-3">Date</div>
          <div className="col-auto text-left p-3">Amount</div>
          <div className="col-auto text-left p-3">Transaction</div>
        </div>

        {referralsData?.discountDistributions?.length <= 0 ? (
          <EmptyMessage message="No rebates distribution history yet." tooltipText="Rebates are airdropped weekly." />
        ) : (
          referralsData?.discountDistributions?.map((rebate, index) => {
            let tokenInfo
            try {
              tokenInfo = getToken(chainId, rebate.token)
            } catch {
              tokenInfo = getNativeToken(chainId)
            }
            const explorerURL = getExplorerUrl(chainId)
            return (
              <div
                key={index}
                className="grid border border-bg-color-2 items-center md:divide-y-0 md:grid-cols-[150px,1fr,1fr]
                      md:gap-4 w-full bg-[#dbe4fc] p-4 mt-3 rounded-2xl relative grid-cols-2 divide-y
                      divide-gray-700 dark:bg-[#0f172a]"
              >
                <div className="col-auto p-3 text-2xl">
                  <div className="text-[10px] mb-1 font-bold text-txt-color-1 block md:hidden dark:text-white">
                    Date:{' '}
                  </div>
                  {formatDate(rebate.timestamp)}
                </div>
                <div className="col-auto p-3 text-2xl" style={{ border: 0 }}>
                  <div className="text-[10px] mb-1 font-bold text-txt-color-1 block md:hidden dark:text-white">
                    Amount:{' '}
                  </div>
                  {formatAmount(rebate.amount, tokenInfo.decimals, 6, true)} {tokenInfo.symbol}
                </div>
                <div className="col-span-2 md:col-auto p-3 text-2xl">
                  <div className="text-[10px] mb-1 font-bold text-txt-color-1 block md:hidden dark:text-white">
                    Transaction:{' '}
                  </div>
                  <a target="_blank" rel="noopener noreferrer" href={explorerURL + `tx/${rebate.transactionHash}`}>
                    {shortenAddress(rebate.transactionHash, 20)}
                  </a>
                </div>
              </div>
            )
          })
        )}
      </div>
    </div>
  )
}

export default TradersStats
