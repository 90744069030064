import '@rainbow-me/rainbowkit/styles.css'

import { getDefaultConfig } from '@rainbow-me/rainbowkit'
import { arbitrumSepolia } from 'wagmi/chains'

// const devTestnet = {
//   id: 1112,
//   name: 'DEV Testnet',
//   network: 'dev-testnet',
//   nativeCurrency: {
//     name: 'ETH Coin',
//     symbol: 'ETH',
//     decimals: 18,
//   },
//   rpcUrls: {
//     default: {
//       http: ['https://develop-chain.0xnode.cloud'],
//       webSocket: [],
//     },
//     public: {
//       http: ['https://develop-chain.0xnode.cloud'],
//       webSocket: [],
//     },
//   },
//   blockExplorers: {
//     default: {
//       name: 'Dev Explorer',
//       url: 'https://develop-chain-explorer.0xnode.cloud/',
//     },
//   },
//   testnet: true,
//   iconUrl: 'https://assets.tfx.market/linea-logo-testnet.png',
// } as Chain

export const wagmiClientConfig = getDefaultConfig({
  appName: 'TFX.MARKET App',
  projectId: process.env.REACT_APP_WC_PROJECT_ID ?? '',
  chains: [arbitrumSepolia],
  ssr: true,
})
