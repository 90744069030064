import { ApolloClient, gql, HttpLink, InMemoryCache } from '@apollo/client'
import fetch from 'cross-fetch'
import * as ethers from 'ethers'
import { chain, find, maxBy, minBy, sortBy, sumBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'

import XlpManagerAbi from 'abis/GlpManager.json'
import RewardReader from 'abis/RewardReader.json'
import Token from 'abis/Token.json'

import { getContract } from '@tfx/addresses'
import { getTokenSymbols } from '@tfx/tokens'
import useSWR from 'swr'
import { ARBITRUM_SEPOLIA, DEFAULT_CHAIN_ID, DEV_TESTNET, getProvider, getServerUrl } from 'utils'
import { getGraphPathClient } from 'utils/client'
import { fillPeriods } from './helpers'

const subChain = {
  [DEV_TESTNET]: `chain=${DEV_TESTNET}`,
  [ARBITRUM_SEPOLIA]: `chain=${ARBITRUM_SEPOLIA}`,
}

const NOW_TS = Math.floor(Date.now() / 1000)
const FIRST_DATE_TS = Math.floor(+new Date(2023, 6, 1) / 1000)
const DEFAULT_CHAIN = DEFAULT_CHAIN_ID

function fillNa(arr, keys?: any) {
  const prevValues = {}
  if (!keys && arr.length > 0) {
    keys = Object.keys(arr[0])
    delete keys.timestamp
    delete keys.id
  }
  for (const el of arr) {
    for (const key of keys) {
      if (!el[key]) {
        if (prevValues[key]) {
          el[key] = prevValues[key]
        }
      } else {
        prevValues[key] = el[key]
      }
    }
  }
  return arr
}

export async function queryEarnData(chainId, account) {
  const provider = getProvider(null, chainId)
  const rewardReader = new ethers.Contract(getContract(chainId, 'RewardReader'), RewardReader, provider)
  const glpContract = new ethers.Contract(getContract(chainId, 'XLP'), Token, provider)
  const XlpManager = new ethers.Contract(getContract(chainId, 'XlpManager'), XlpManagerAbi, provider)

  const depositTokens = [
    '0x62edc0692BD897D2295872a9FFCac5425011c661',
    '0xFf1489227BbAAC61a9209A08929E4c2a526DdD17',
    '0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342',
    '0x908C4D94D34924765f1eDc22A1DD098397c59dD4',
    '0x8087a341D32D445d9aC8aCc9c14F5781E04A26d2',
    '0x01234181085565ed162a948b6a5e88758CD7c7b8',
  ]
  const rewardTrackersForDepositBalances = [
    '0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342',
    '0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342',
    '0x908C4D94D34924765f1eDc22A1DD098397c59dD4',
    '0x4d268a7d4C16ceB5a606c173Bd974984343fea13',
    '0x4d268a7d4C16ceB5a606c173Bd974984343fea13',
    '0xd2D1162512F927a7e282Ef43a362659E4F2a728F',
  ]
  const rewardTrackersForStakingInfo = [
    '0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342',
    '0x908C4D94D34924765f1eDc22A1DD098397c59dD4',
    '0x4d268a7d4C16ceB5a606c173Bd974984343fea13',
    '0x9e295B5B976a184B14aD8cd72413aD846C299660',
    '0xd2D1162512F927a7e282Ef43a362659E4F2a728F',
  ]

  const [balances, stakingInfo, glpTotalSupply, glpAum, gmxPrice] = await Promise.all([
    rewardReader.getDepositBalances(account, depositTokens, rewardTrackersForDepositBalances),
    rewardReader.getStakingInfo(account, rewardTrackersForStakingInfo).then((info) => {
      return rewardTrackersForStakingInfo.map((_, i) => {
        return info.slice(i * 5, (i + 1) * 5)
      })
    }),
    glpContract.totalSupply(),
    XlpManager.getAumInUsdg(true),
    fetch('https://api.coingecko.com/api/v3/simple/price?ids=gmx&vs_currencies=usd').then(async (res) => {
      const j = await res.json()
      return j['gmx']['usd']
    }),
  ])

  const xlpPrice = glpAum / 1e18 / (glpTotalSupply / 1e18)
  const now = new Date()

  return {
    XLP: {
      stakedGLP: balances[5] / 1e18,
      pendingETH: stakingInfo[4][0] / 1e18,
      pendingEsGMX: stakingInfo[3][0] / 1e18,
      xlpPrice,
    },
    GMX: {
      stakedGMX: balances[0] / 1e18,
      stakedEsGMX: balances[1] / 1e18,
      pendingETH: stakingInfo[2][0] / 1e18,
      pendingEsGMX: stakingInfo[0][0] / 1e18,
      gmxPrice,
    },
    timestamp: Math.floor(Number(now) / 1000),
    datetime: now.toISOString(),
  }
}

export const tokenDecimals = {
  '0x82af49447d8a07e3bd95bd0d56f35241523fbab1': 18, // WETH
  '0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f': 8, // BTC
  '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8': 6, // USDC
  '0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0': 18, // UNI
  '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9': 6, // USDT
  '0xf97f4df75117a78c1a5a0dbb814af92458539fb4': 18, // LINK
  '0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a': 18, // MIM
  '0x17fc002b466eec40dae837fc4be5c67993ddbd6f': 18, // FRAX
  '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1': 18, // DAI
}

export const tokenSymbols = {
  [DEV_TESTNET]: getTokenSymbols(DEV_TESTNET, true),
  [ARBITRUM_SEPOLIA]: getTokenSymbols(ARBITRUM_SEPOLIA, true),
}

const knownSwapSources = {
  // arbitrum: {
  //   '0xabbc5f99639c9b6bcb58544ddf04efa6802f4064': 'GMX', // Router
  //   '0x09f77e8a13de9a35a7231028187e9fd5db8a2acb': 'GMX', // Orderbook
  //   '0x98a00666cfcb2ba5a405415c2bf6547c63bf5491': 'GMX', // PositionManager old
  //   '0x87a4088bd721f83b6c2e5102e2fa47022cb1c831': 'GMX', // PositionManager
  //   '0x7257ac5d0a0aac04aa7ba2ac0a6eb742e332c3fb': 'GMX', // OrderExecutor
  //   '0x1a0ad27350cccd6f7f168e052100b4960efdb774': 'GMX', // FastPriceFeed
  //   '0x3b6067d4caa8a14c63fdbe6318f27a0bbc9f9237': 'Dodo',
  //   '0x11111112542d85b3ef69ae05771c2dccff4faa26': '1inch',
  //   '0x6352a56caadc4f1e25cd6c75970fa768a3304e64': 'OpenOcean', // OpenOceanExchangeProxy
  //   '0x4775af8fef4809fe10bf05867d2b038a4b5b2146': 'Gelato',
  //   '0x5a9fd7c39a6c488e715437d7b1f3c823d5596ed1': 'LiFiDiamond',
  //   '0x1d838be5d58cc131ae4a23359bc6ad2dddb8b75a': 'Vovo', // Vovo BTC UP USDC (vbuUSDC)
  //   '0xc4bed5eeeccbe84780c44c5472e800d3a5053454': 'Vovo', // Vovo ETH UP USDC (veuUSDC)
  //   '0xe40beb54ba00838abe076f6448b27528dd45e4f0': 'Vovo', // Vovo BTC UP USDC (vbuUSDC)
  //   '0x9ba57a1d3f6c61ff500f598f16b97007eb02e346': 'Vovo', // Vovo ETH UP USDC (veuUSDC)
  //   '0xfa82f1ba00b0697227e2ad6c668abb4c50ca0b1f': 'JonesDAO',
  //   '0x226cb17a52709034e2ec6abe0d2f0a9ebcec1059': 'WardenSwap',
  //   '0x1111111254fb6c44bac0bed2854e76f90643097d': '1inch',
  //   '0x6d7a3177f3500bea64914642a49d0b5c0a7dae6d': 'deBridge',
  //   '0xc30141b657f4216252dc59af2e7cdb9d8792e1b0': 'socket.tech',
  // },
  // avalanche: {
  //   '0x4296e307f108b2f583ff2f7b7270ee7831574ae5': 'GMX',
  //   '0x5f719c2f1095f7b9fc68a68e35b51194f4b6abe8': 'GMX',
  //   '0x7d9d108445f7e59a67da7c16a2ceb08c85b76a35': 'GMX', // FastPriceFeed
  //   '0xf2ec2e52c3b5f8b8bd5a3f93945d05628a233216': 'GMX', // PositionManager
  //   '0xc4729e56b831d74bbc18797e0e17a295fa77488c': 'Yak',
  //   '0x409e377a7affb1fd3369cfc24880ad58895d1dd9': 'Dodo',
  //   '0x6352a56caadc4f1e25cd6c75970fa768a3304e64': 'OpenOcean',
  //   '0x7c5c4af1618220c090a6863175de47afb20fa9df': 'Gelato',
  //   '0x1111111254fb6c44bac0bed2854e76f90643097d': '1inch',
  //   '0xdef171fe48cf0115b1d80b88dc8eab59176fee57': 'ParaSwap',
  //   '0x2ecf2a2e74b19aab2a62312167aff4b78e93b6c5': 'ParaSwap',
  // },
  // [chainNameKey.TESTNET]: {
  //   "0xA6524c909e4393A23561E9542bBb66Df3892936A": "GMX", // Router
  //   "0x86F4Bdd452cadDFd4085Bf4C4A8Bf026b0927636": "GMX", // Orderbook
  //   "0x98a00666cfcb2ba5a405415c2bf6547c63bf5491": "GMX", // PositionManager old Not usage.
  //   "0x97BF6b2ADe1f5b3136d5337EF0837b1d2B044D40": "GMX", // PositionManager
  //   "0x7257ac5D0a0aaC04AA7bA2AC0A6Eb742E332c3fB": "GMX", // OrderExecutor
  //   "0xd5565b49B06C43cAA48BD329D9cEb125B47f709e": "GMX", // FastPriceFeed
  // },
  [DEV_TESTNET]: {
    [getContract(DEV_TESTNET, 'Router')]: 'GMX', // Router
    [getContract(DEV_TESTNET, 'OrderBook')]: 'GMX', // Orderbook
    [getContract(DEV_TESTNET, 'PositionManager')]: 'GMX', // PositionManager
  },
  [ARBITRUM_SEPOLIA]: {
    [getContract(ARBITRUM_SEPOLIA, 'Router')]: 'GMX', // Router
    [getContract(ARBITRUM_SEPOLIA, 'OrderBook')]: 'GMX', // Orderbook
    [getContract(ARBITRUM_SEPOLIA, 'PositionManager')]: 'GMX', // PositionManager
  },
}

const defaultFetcher = (url) => fetch(url).then((res) => res.json())
const stringFetcher = (url) => fetch(url).then((res) => res.text())
export const useRequest = (url, fetcher = defaultFetcher): [any, boolean, any] => {
  const { data, error } = useSWR(url, fetcher)
  return [data, !data, error]
}

export function useCoingeckoPrices(symbol, { from = FIRST_DATE_TS } = {}) {
  // token ids https://api.coingecko.com/api/v3/coins
  const _symbol = {
    BTC: 'bitcoin',
    ETH: 'ethereum',
    LINK: 'chainlink',
    UNI: 'uniswap',
    AVAX: 'avalanche-2',
    BNB: 'binancecoin',
  }[symbol]

  const now = Date.now() / 1000
  const days = Math.ceil(now / 86400) - Math.ceil(from / 86400) - 1

  const url = `https://api.coingecko.com/api/v3/coins/${_symbol}/market_chart?vs_currency=usd&days=${days}&interval=daily`

  const [res, loading, error] = useRequest(url)

  const data = useMemo(() => {
    if (!res || res.length === 0) {
      return null
    }

    const ret = res.prices.map((item) => {
      // -1 is for shifting to previous day
      // because CG uses first price of the day, but for XLP we store last price of the day
      const timestamp = item[0] - 1
      const groupTs = Math.floor(timestamp / 1000 / 86400) * 86400
      return {
        timestamp: groupTs,
        value: item[1],
      }
    })
    return ret
  }, [res])

  return [data, loading, error]
}

function getImpermanentLoss(change) {
  return (2 * Math.sqrt(change)) / (1 + change) - 1
}

export const useGraph = (querySource, { subgraphUrl = '', chainId = DEFAULT_CHAIN } = {}): [any, boolean, any] => {
  const query = gql(querySource)

  subgraphUrl = getGraphPathClient(chainId) ?? ''

  const client = new ApolloClient({
    link: new HttpLink({ uri: subgraphUrl, fetch }),
    cache: new InMemoryCache(),
  })
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    setLoading(true)
  }, [querySource, setLoading])

  useEffect(() => {
    client
      .query({ query })
      .then((res) => {
        setData(res.data)
        setLoading(false)
      })
      .catch((ex) => {
        console.warn('Subgraph request failed error: %s subgraphUrl: %s', ex.message, subgraphUrl)
        setError(ex)
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySource, setData, setError, setLoading])

  return [data, loading, error]
}

export function useGambitVolumeData({ from, to }) {
  const [graphData, loading] = useGraph(
    `{
      volumeStats(
        first: 1000,
        where: { id_gte: ${from}, id_lte: ${to}, period: daily }
        orderBy: id
        orderDirection: desc
      ) {
        id
        margin
        swap
        liquidation
        mint
        burn
      }
    }`,
  )

  let data
  if (graphData) {
    data = sortBy(graphData.volumeStats, (item) => item.id).map(({ id, margin, swap, liquidation, mint, burn }) => {
      margin = margin / 1e30
      swap = swap / 1e30
      liquidation = liquidation / 1e30
      mint = mint / 1e30
      burn = burn / 1e30
      const all = margin + swap + liquidation + mint + burn
      return {
        timestamp: id,
        all,
        margin,
        swap,
        liquidation,
        mint,
        burn,
      }
    })
  }

  return [data, loading]
}

export function useGambitFeesData({ from, to }) {
  const [graphData, loading] = useGraph(
    `{
      feeStats(
        first: 1000,
        where: { id_gte: ${from}, id_lte: ${to}, period: daily }
        orderBy: id
        orderDirection: desc
      ) {
        id
        margin
        swap
        mint
        burn
        marginCumulative
        swapCumulative
        liquidationCumulative
        mintCumulative
        burnCumulative
      }
    }`,
  )

  let data
  if (graphData) {
    data = sortBy(graphData.feeStats, (item) => item.id).map(({ id, margin, swap, mint, burn }) => {
      margin = margin / 1e30
      swap = swap / 1e30
      const liquidation = 0
      mint = mint / 1e30
      burn = burn / 1e30
      const all = margin + swap + mint + burn
      return {
        timestamp: id,
        all,
        margin,
        swap,
        liquidation,
        mint,
        burn,
      }
    })
  }

  return [data, loading]
}

export function useGambitPoolStats({ from, to, groupPeriod }) {
  const [data, loading, error] = useGraph(
    `{
      poolStats (
        first: 1000,
        where: { id_gte: ${from}, id_lte: ${to} }
        orderBy: id
        orderDirection: desc
      ) {
        id,
        usdxSupply,
        BTC_usd,
        ETH_usd,
        BNB_usd,
        USDC_usd,
        USDT_usd,
        BUSD_usd
      }
    }`,
  )

  const ret = useMemo(() => {
    if (!data) {
      return null
    }
    let ret = data.poolStats.map((item) => {
      return Object.entries(item).reduce((memo: any, [key, value]: any) => {
        if (key === 'id') memo.timestamp = value
        else if (key === 'usdxSupply') memo.usdxSupply = value / 1e18
        else memo[key.substr(0, key.length - 4)] = value / 1e30
        return memo
      }, {})
    })

    ret = chain(ret)
      .sortBy('timestamp')
      .groupBy((item) => Math.floor(item.timestamp / groupPeriod) * groupPeriod)
      .map((values, timestamp) => {
        return {
          ...values[values.length - 1],
          timestamp,
        }
      })
      .value()

    return fillPeriods(ret, {
      period: groupPeriod,
      from,
      to,
      interpolate: false,
      extrapolate: true,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return [ret, loading, error]
}

export function useLastBlock(chainId = DEFAULT_CHAIN) {
  const [data, setData] = useState<any>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    getProvider(null, chainId)
      .getBlock('latest')
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [data, loading, error]
}

export function useLastSubgraphBlock(chainId = DEFAULT_CHAIN) {
  const [data, loading, error] = useGraph(
    `{
    _meta {
      block {
        number
      }
    }
  }`,
    { chainId },
  )
  const [block, setBlock] = useState<any>(null)

  useEffect(() => {
    if (!data) {
      return
    }

    getProvider(null, chainId)
      .getBlock(data._meta.block.number)
      .then((block) => {
        setBlock(block)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setBlock])

  return [block, loading, error]
}

export const useTradersData = ({ from = FIRST_DATE_TS, to = NOW_TS, chainId = DEFAULT_CHAIN } = {}): [any, boolean] => {
  const [closedPositionsData, loading] = useGraph(
    `{
    tradingStats(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: { period: "daily", timestamp_gte: ${from}, timestamp_lte: ${to} }
    ) {
      timestamp
      profit
      loss
      profitCumulative
      lossCumulative
      longOpenInterest
      shortOpenInterest
    }
  }`,
    { chainId },
  )

  // const [feesData] = useFeesData({ from, to, chainName });
  // const marginFeesByTs = useMemo(() => {
  //   if (!feesData) {
  //     return {};
  //   }

  //   let feesCumulative = 0;
  //   return feesData.reduce((memo, { timestamp, margin: fees }) => {
  //     feesCumulative += fees;
  //     memo[timestamp] = {
  //       fees,
  //       feesCumulative,
  //     };
  //     return memo;
  //   }, {});
  // }, [feesData]);

  let ret = {}
  let currentPnlCumulative = 0
  let currentProfitCumulative = 0
  let currentLossCumulative = 0
  const data = closedPositionsData
    ? sortBy(closedPositionsData.tradingStats, (i) => i.timestamp).map((dataItem) => {
        const longOpenInterest = dataItem.longOpenInterest / 1e30
        const shortOpenInterest = dataItem.shortOpenInterest / 1e30
        const openInterest = longOpenInterest + shortOpenInterest

        // const fees = (marginFeesByTs[dataItem.timestamp]?.fees || 0)
        // const feesCumulative = (marginFeesByTs[dataItem.timestamp]?.feesCumulative || 0)

        const profit = dataItem.profit / 1e30
        const loss = dataItem.loss / 1e30
        const profitCumulative = dataItem.profitCumulative / 1e30
        const lossCumulative = dataItem.lossCumulative / 1e30
        const pnlCumulative = profitCumulative - lossCumulative
        const pnl = profit - loss
        currentProfitCumulative += profit
        currentLossCumulative -= loss
        currentPnlCumulative += pnl
        return {
          longOpenInterest,
          shortOpenInterest,
          openInterest,
          profit,
          loss: -loss,
          profitCumulative,
          lossCumulative: -lossCumulative,
          pnl,
          pnlCumulative,
          timestamp: dataItem.timestamp,
          currentPnlCumulative,
          currentLossCumulative,
          currentProfitCumulative,
        }
      })
    : null

  if (data) {
    const maxProfit = maxBy(data, (item) => item.profit)?.profit ?? 0
    const maxLoss = minBy(data, (item) => item.loss)?.loss ?? 0
    const maxProfitLoss = Math.max(maxProfit, -maxLoss)

    const maxPnl = maxBy(data, (item) => item.pnl)?.pnl ?? 0
    const minPnl = minBy(data, (item) => item.pnl)?.pnl ?? 0
    const maxCurrentCumulativePnl = maxBy(data, (item) => item.currentPnlCumulative)?.currentPnlCumulative ?? 0
    const minCurrentCumulativePnl = minBy(data, (item) => item.currentPnlCumulative)?.currentPnlCumulative ?? 0

    const currentProfitCumulative = data[data.length - 1]?.currentProfitCumulative ?? 0
    const currentLossCumulative = data[data.length - 1]?.currentLossCumulative ?? 0
    const stats = {
      maxProfit,
      maxLoss,
      maxProfitLoss,
      currentProfitCumulative,
      currentLossCumulative,
      maxCurrentCumulativeProfitLoss: Math.max(currentProfitCumulative, -currentLossCumulative),

      maxAbsPnl: Math.max(Math.abs(maxPnl), Math.abs(minPnl)),
      maxAbsCumulativePnl: Math.max(Math.abs(maxCurrentCumulativePnl), Math.abs(minCurrentCumulativePnl)),
    }

    ret = {
      data,
      stats,
    }
  }

  return [ret, loading]
}

function getSwapSourcesFragment(skip = 0, from, to) {
  return `
    hourlyVolumeBySources(
      first: 1000
      skip: ${skip}
      orderBy: timestamp
      orderDirection: desc
      where: { timestamp_gte: ${from}, timestamp_lte: ${to} }
    ) {
      timestamp
      source
      swap
    }
  `
}
export function useSwapSources({ from = FIRST_DATE_TS, to = NOW_TS, chainId = DEFAULT_CHAIN } = {}) {
  const query = `{
    a: ${getSwapSourcesFragment(0, from, to)}
    b: ${getSwapSourcesFragment(1000, from, to)}
    c: ${getSwapSourcesFragment(2000, from, to)}
    d: ${getSwapSourcesFragment(3000, from, to)}
    e: ${getSwapSourcesFragment(4000, from, to)}
  }`
  const [graphData, loading, error] = useGraph(query, { chainId })

  let data = useMemo(() => {
    if (!graphData) {
      return null
    }

    const { a, b, c, d, e } = graphData
    const all = [...a, ...b, ...c, ...d, ...e]

    const totalVolumeBySource = a.reduce((acc, item) => {
      const source = knownSwapSources[chainId][item.source] || item.source
      if (!acc[source]) {
        acc[source] = 0
      }
      acc[source] += item.swap / 1e30
      return acc
    }, {})
    const topVolumeSources = new Set(
      Object.entries(totalVolumeBySource)
        .sort((a: any, b: any) => b[1] - a[1])
        .map((item) => item[0])
        .slice(0, 30),
    )

    let ret = chain(all)
      .groupBy((item) => parseInt((item.timestamp / 86400).toString()) * 86400)
      .map((values, timestamp) => {
        let all = 0
        const retItem = {
          timestamp: Number(timestamp),
          ...values.reduce((memo, item) => {
            let source = knownSwapSources[chainId][item.source] || item.source
            if (!topVolumeSources.has(source)) {
              source = 'Other'
            }
            if (item.swap !== 0) {
              const volume = item.swap / 1e30
              memo[source] = memo[source] || 0
              memo[source] += volume
              all += volume
            }
            return memo
          }, {}),
        }

        retItem.all = all

        return retItem
      })
      .sortBy((item) => item.timestamp)
      .value()

    return ret
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphData])

  return [data, loading, error]
}

export function useTotalVolumeFromServer() {
  const [data, loading] = useRequest('https://gmx-server-mainnet.uw.r.appspot.com/total_volume')

  return useMemo(() => {
    if (!data) {
      return [data, loading]
    }

    const total = data.reduce((memo, item) => {
      return memo + parseInt(item.data.volume) / 1e30
    }, 0)
    return [total, loading]
  }, [data, loading])
}

export function useTotalVolumeFromServerBSC(params) {
  const serverUrl = getServerUrl(params.chainId, `/total_volume?${subChain[params.chainId]}&from=${params.from}`)
  const [data, loading] = useRequest(serverUrl, stringFetcher)

  return useMemo(() => {
    if (!data) {
      return [data, loading]
    }
    const total = parseInt(data) / 1e30
    return [total, loading]
  }, [data, loading])
}

export function useVolumeDataRequest(url, defaultValue, from, to, fetcher = defaultFetcher) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [data, setData] = useState(defaultValue)

  useEffect(() => {
    try {
      setLoading(true)
      fetcher(url).then((data) => setData(data))
    } catch (ex) {
      console.error(ex)
      setError(ex)
    }
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, from, to])

  return [data, loading, error]
}

// export function useVolumeDataFromServer({ from = FIRST_DATE_TS, to = NOW_TS, chainId } = {}) {
//   const PROPS = "margin liquidation swap mint burn".split(" ");
//   const serverUrl = getServerUrl(chainId, `/daily_volume`)
//   const [data, loading] = useVolumeDataRequest(
//     serverUrl,
//     null,
//     from,
//     to,
//     async (url) => {
//       let after;
//       const ret: any[] = [];
//       while (true) {
//         const res = await (await fetch(url + (after ? `?after=${after}` : ""))).json();
//         if (res.length === 0) return ret;
//         for (const item of res) {
//           if (item.data.timestamp < from) {
//             return ret;
//           }
//           ret.push(item);
//         }
//         after = res[res.length - 1].id;
//       }
//     }
//   );

//   const ret = useMemo(() => {
//     if (!data) {
//       return null;
//     }

//     const tmp = data.reduce((memo, item) => {
//       const timestamp = item.data.timestamp;
//       if (timestamp < from || timestamp > to) {
//         return memo;
//       }

//       let type;
//       if (item.data.action === "Swap") {
//         type = "swap";
//       } else if (item.data.action === "SellUSDG") {
//         type = "burn";
//       } else if (item.data.action === "BuyUSDG") {
//         type = "mint";
//       } else if (item.data.action.includes("LiquidatePosition")) {
//         type = "liquidation";
//       } else {
//         type = "margin";
//       }
//       const volume = Number(item.data.volume) / 1e30;
//       memo[timestamp] = memo[timestamp] || {};
//       memo[timestamp][type] = memo[timestamp][type] || 0;
//       memo[timestamp][type] += volume;
//       return memo;
//     }, {});

//     let cumulative = 0;
//     const cumulativeByTs = {};
//     return Object.keys(tmp)
//       .sort()
//       .map((timestamp) => {
//         const item = tmp[timestamp];
//         let all = 0;

//         let movingAverageAll;
//         const movingAverageTs = Number(timestamp) - MOVING_AVERAGE_PERIOD;
//         if (movingAverageTs in cumulativeByTs) {
//           movingAverageAll = (cumulative - cumulativeByTs[movingAverageTs]) / MOVING_AVERAGE_DAYS;
//         }

//         PROPS.forEach((prop) => {
//           if (item[prop]) all += item[prop];
//         });
//         cumulative += all;
//         cumulativeByTs[timestamp] = cumulative;
//         return {
//           timestamp,
//           all,
//           cumulative,
//           movingAverageAll,
//           ...item,
//         };
//       });
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [data, from, to]);

//   return [ret, loading];
// }

export function useUsersData({ from = FIRST_DATE_TS, to = NOW_TS, chainId = DEFAULT_CHAIN } = {}) {
  const query = `{
    userStats(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: { period: "daily", timestamp_gte: ${from}, timestamp_lte: ${to} }
    ) {
      uniqueCount
      uniqueSwapCount
      uniqueMarginCount
      uniqueMintBurnCount
      uniqueCountCumulative
      uniqueSwapCountCumulative
      uniqueMarginCountCumulative
      uniqueMintBurnCountCumulative
      actionCount
      actionSwapCount
      actionMarginCount
      actionMintBurnCount
      timestamp
    }
  }`
  const [graphData, loading, error] = useGraph(query, { chainId })

  const prevUniqueCountCumulative = {}
  let cumulativeNewUserCount = 0
  const data = graphData
    ? sortBy(graphData.userStats, 'timestamp').map((item) => {
        const newCountData: any = ['', 'Swap', 'Margin', 'MintBurn'].reduce((memo, type) => {
          memo[`new${type}Count`] = prevUniqueCountCumulative[type]
            ? item[`unique${type}CountCumulative`] - prevUniqueCountCumulative[type]
            : item[`unique${type}Count`]
          if (memo[`new${type}Count`] < 0) {
            memo[`new${type}Count`] = 0
          }
          prevUniqueCountCumulative[type] = item[`unique${type}CountCumulative`]
          return memo
        }, {})
        cumulativeNewUserCount += newCountData.newCount
        const oldCount = item.uniqueCount - newCountData.newCount
        const oldPercent = ((oldCount / item.uniqueCount) * 100).toFixed(1)
        return {
          all: item.uniqueCount,
          uniqueSum: item.uniqueSwapCount + item.uniqueMarginCount + item.uniqueMintBurnCount,
          oldCount,
          oldPercent,
          cumulativeNewUserCount,
          ...newCountData,
          ...item,
        }
      })
    : null

  return [data, loading, error]
}

export function useFundingRateData({ from = FIRST_DATE_TS, to = NOW_TS, chainId = DEFAULT_CHAIN } = {}) {
  const query = `{
    fundingRates(
      first: 1000,
      orderBy: timestamp,
      orderDirection: desc,
      where: { period: "daily", id_gte: ${from}, id_lte: ${to} }
    ) {
      id,
      token,
      timestamp,
      startFundingRate,
      startTimestamp,
      endFundingRate,
      endTimestamp
    }
  }`
  const [graphData, loading, error] = useGraph(query, { chainId })

  const data = useMemo(() => {
    if (!graphData) {
      return null
    }

    const groups = graphData.fundingRates.reduce((memo, item) => {
      // const symbols = tokenSymbols[chainId].values();
      const symbol = tokenSymbols[chainId][item.token]
      memo[item.timestamp] = memo[item.timestamp] || {
        timestamp: item.timestamp,
      }
      const group = memo[item.timestamp]
      const timeDelta = Math.floor((item.endTimestamp - item.startTimestamp) / 3600) * 3600

      let fundingRate = 0
      if (item.endFundingRate && item.startFundingRate) {
        const fundingDelta = item.endFundingRate - item.startFundingRate
        const divisor = timeDelta / 86400
        fundingRate = (fundingDelta / divisor / 10000) * 365
      }
      group[symbol] = fundingRate
      if (symbol === 'WETH') {
        group['ETH'] = fundingRate
      }
      return memo
    }, {})

    return fillNa(sortBy(Object.values(groups), 'timestamp'), Object.values(tokenSymbols[chainId]))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphData])

  return [data, loading, error]
}

const MOVING_AVERAGE_DAYS = 7
const MOVING_AVERAGE_PERIOD = 86400 * MOVING_AVERAGE_DAYS

export function useVolumeData({ from = FIRST_DATE_TS, to = NOW_TS, chainId = DEFAULT_CHAIN } = {}) {
  const PROPS = 'margin liquidation swap mint burn'.split(' ')
  const timestampProp = 'timestamp'
  const query = `{
    volumeStats(
      first: 1000,
      orderBy: ${timestampProp},
      orderDirection: desc
      where: { period: daily, ${timestampProp}_gte: ${from}, ${timestampProp}_lte: ${to} }
    ) {
      ${timestampProp}
      ${PROPS.join('\n')}
    }
  }`
  const [graphData, loading, error] = useGraph(query, { chainId })

  const data = useMemo(() => {
    if (!graphData) {
      return null
    }

    let ret = sortBy(graphData.volumeStats, timestampProp).map((item) => {
      const ret: any = { timestamp: item[timestampProp] }
      let all = 0
      PROPS.forEach((prop) => {
        ret[prop] = item[prop] / 1e30
        all += ret[prop]
      })
      ret.all = all
      return ret
    })

    let cumulative = 0
    const cumulativeByTs = {}
    return ret.map((item) => {
      cumulative += item.all

      let movingAverageAll
      const movingAverageTs = item.timestamp - MOVING_AVERAGE_PERIOD
      if (movingAverageTs in cumulativeByTs) {
        movingAverageAll = (cumulative - cumulativeByTs[movingAverageTs]) / MOVING_AVERAGE_DAYS
      }

      return {
        movingAverageAll,
        cumulative,
        ...item,
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphData])

  return [data, loading, error]
}

export function useFeesData({ from = FIRST_DATE_TS, to = NOW_TS, chainId = DEFAULT_CHAIN } = {}) {
  const PROPS = 'margin liquidation swap mint burn'.split(' ')
  const feesQuery = `{
    feeStats(
      first: 1000
      orderBy: id
      orderDirection: desc
      where: { period: daily, id_gte: ${from}, id_lte: ${to} }
    ) {
      id
      margin
      marginAndLiquidation
      swap
      mint
      burn
    }
  }`

  let [feesData, loading, error] = useGraph(feesQuery, {
    chainId,
  })

  const feesChartData = useMemo(() => {
    if (!feesData) {
      return null
    }

    let chartData = sortBy(feesData.feeStats, 'id').map((item) => {
      const ret: any = { timestamp: item.timestamp || item.id }

      PROPS.forEach((prop) => {
        if (item[prop]) {
          ret[prop] = item[prop] / 1e30
        }
      })

      ret.liquidation = item.marginAndLiquidation / 1e30 - item.margin / 1e30
      ret.all = PROPS.reduce((memo, prop) => memo + ret[prop], 0)
      return ret
    })

    let cumulative = 0
    const cumulativeByTs = {}
    return chain(chartData)
      .groupBy((item) => item.timestamp)
      .map((values, timestamp) => {
        const all = sumBy(values, 'all')
        cumulative += all
        timestamp = timestamp.split(':')[0]

        let movingAverageAll
        const movingAverageTs = timestamp - MOVING_AVERAGE_PERIOD
        if (movingAverageTs in cumulativeByTs) {
          movingAverageAll = (cumulative - cumulativeByTs[movingAverageTs]) / MOVING_AVERAGE_DAYS
        }

        const ret = {
          timestamp: Number(timestamp),
          all,
          cumulative,
          movingAverageAll,
        }
        PROPS.forEach((prop) => {
          ret[prop] = sumBy(values, prop)
        })
        cumulativeByTs[timestamp] = cumulative
        return ret
      })
      .value()
      .filter((item) => item.timestamp >= from)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feesData])

  return [feesChartData, loading, error]
}

export function useAumPerformanceData({ from = FIRST_DATE_TS, to = NOW_TS, groupPeriod, chainId = DEFAULT_CHAIN }) {
  const [feesData, feesLoading] = useFeesData({
    from,
    to,
    chainId,
  })
  const [glpData, glpLoading] = useGlpData({ from, to, chainId })
  const [volumeData, volumeLoading] = useVolumeData({
    from,
    to,
    chainId,
  })

  const dailyCoef = 86400 / groupPeriod

  const data = useMemo(() => {
    if (!feesData || !glpData || !volumeData) {
      return null
    }

    const ret = feesData.map((feeItem, i) => {
      const glpItem = find(glpData, { timestamp: feeItem.timestamp })
      const volumeItem = volumeData[i]

      let apr = feeItem?.all && glpItem?.aum ? (feeItem.all / glpItem.aum) * 100 * 365 * dailyCoef : 0
      if (apr > 10000) {
        apr = 0
      }
      let usage = volumeItem?.all && glpItem?.aum ? (volumeItem.all / glpItem.aum) * 100 * dailyCoef : 0
      if (usage > 10000) {
        usage = 0
      }

      return {
        timestamp: feeItem.timestamp,
        apr,
        usage,
      }
    })
    const averageApr = ret.reduce((memo, item) => item.apr + memo, 0) / ret.length
    ret.forEach((item) => (item.averageApr = averageApr))
    const averageUsage = ret.reduce((memo, item) => item.usage + memo, 0) / ret.length
    ret.forEach((item) => (item.averageUsage = averageUsage))
    return ret
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feesData, glpData, volumeData])

  return [data, feesLoading || glpLoading || volumeLoading]
}

export function useGlpData({ from = FIRST_DATE_TS, to = NOW_TS, chainId = DEFAULT_CHAIN } = {}) {
  const timestampProp = 'timestamp'
  const query = `{
    glpStats(
      first: 1000
      orderBy: ${timestampProp}
      orderDirection: desc
      where: {period: daily, ${timestampProp}_gte: ${from}, ${timestampProp}_lte: ${to}}
    ) {
      ${timestampProp}
      aumInUsdg
      glpSupply
      distributedUsd
      distributedEth
    }
  }`
  let [data, loading, error] = useGraph(query, { chainId })

  let cumulativeDistributedUsdPerGlp = 0
  let cumulativeDistributedEthPerGlp = 0
  const glpChartData = useMemo(() => {
    if (!data) {
      return null
    }

    // const getTimestamp = (item) => item.timestamp || parseInt(item[timestampProp]);

    let prevGlpSupply
    let prevAum

    let ret = sortBy(data.glpStats, (item) => item[timestampProp])
      .filter((item) => item[timestampProp] % 86400 === 0)
      .reduce((memo, item) => {
        const last = memo[memo.length - 1]

        const aum = Number(item.aumInUsdg) / 1e18
        const glpSupply = Number(item.glpSupply) / 1e18

        const distributedUsd = Number(item.distributedUsd) / 1e30
        const distributedUsdPerGlp = distributedUsd / glpSupply || 0
        // eslint-disable-next-line react-hooks/exhaustive-deps
        cumulativeDistributedUsdPerGlp += distributedUsdPerGlp

        const distributedEth = Number(item.distributedEth) / 1e18
        const distributedEthPerGlp = distributedEth / glpSupply || 0
        // eslint-disable-next-line react-hooks/exhaustive-deps
        cumulativeDistributedEthPerGlp += distributedEthPerGlp

        const xlpPrice = aum / glpSupply
        const timestamp = parseInt(item[timestampProp])

        const newItem = {
          timestamp,
          aum,
          glpSupply,
          xlpPrice,
          cumulativeDistributedEthPerGlp,
          cumulativeDistributedUsdPerGlp,
          distributedUsdPerGlp,
          distributedEthPerGlp,
        }

        if (last && last.timestamp === timestamp) {
          memo[memo.length - 1] = newItem
        } else {
          memo.push(newItem)
        }

        return memo
      }, [])
      .map((item) => {
        let { glpSupply, aum } = item
        if (!glpSupply) {
          glpSupply = prevGlpSupply
        }
        if (!aum) {
          aum = prevAum
        }
        item.glpSupplyChange = prevGlpSupply ? ((glpSupply - prevGlpSupply) / prevGlpSupply) * 100 : 0
        if (item.glpSupplyChange > 1000) item.glpSupplyChange = 0
        item.aumChange = prevAum ? ((aum - prevAum) / prevAum) * 100 : 0
        if (item.aumChange > 1000) item.aumChange = 0
        prevGlpSupply = glpSupply
        prevAum = aum
        return item
      })

    ret = fillNa(ret)
    return ret
  }, [data])

  return [glpChartData, loading, error]
}

export const useGlpPerformanceData = (
  glpData,
  feesData,
  { from = FIRST_DATE_TS, chainId = DEFAULT_CHAIN } = {},
): [any] => {
  const [btcPrices] = useCoingeckoPrices('BTC', { from })
  const [ethPrices] = useCoingeckoPrices('ETH', { from })
  // const [avaxPrices] = useCoingeckoPrices("AVAX", { from });
  const [bnbPrices] = useCoingeckoPrices('BNB', { from })

  const glpPerformanceChartData = useMemo(() => {
    if (!btcPrices || !ethPrices || !bnbPrices /* || !avaxPrices */ || !glpData || !feesData) {
      return null
    }

    const glpDataById = glpData.reduce((memo, item) => {
      memo[item.timestamp] = item
      return memo
    }, {})

    const feesDataById = feesData.reduce((memo, item) => {
      memo[item.timestamp] = item
      return memo
    }, {})

    let BTC_WEIGHT = 0.25
    let ETH_WEIGHT = 0.25
    // let AVAX_WEIGHT = 0;
    let BNB_WEIGHT = 0

    const STABLE_WEIGHT = 1 - BTC_WEIGHT - ETH_WEIGHT /* - AVAX_WEIGHT*/
    const GLP_START_PRICE = glpDataById[btcPrices[0].timestamp]?.xlpPrice || 1.19

    const btcFirstPrice = btcPrices[0]?.value
    const ethFirstPrice = ethPrices[0]?.value
    // const avaxFirstPrice = avaxPrices[0]?.value;
    const bnbFirstPrice = bnbPrices[0]?.value

    let indexBtcCount = (GLP_START_PRICE * BTC_WEIGHT) / btcFirstPrice
    let indexEthCount = (GLP_START_PRICE * ETH_WEIGHT) / ethFirstPrice
    // let indexAvaxCount = (GLP_START_PRICE * AVAX_WEIGHT) / avaxFirstPrice;
    let indexBnbCount = (GLP_START_PRICE * BNB_WEIGHT) / bnbFirstPrice
    let indexStableCount = GLP_START_PRICE * STABLE_WEIGHT

    const lpBtcCount = (GLP_START_PRICE * 0.5) / btcFirstPrice
    const lpEthCount = (GLP_START_PRICE * 0.5) / ethFirstPrice
    // const lpAvaxCount = (GLP_START_PRICE * 0.5) / avaxFirstPrice;
    const lpBnbCount = (GLP_START_PRICE * 0.5) / indexBnbCount

    const ret: any[] = []
    let cumulativeFeesPerGlp = 0
    // let cumulativeEsgmxRewardsPerGlp = 0;
    let lastGlpPrice = 0

    let prevEthPrice = 3400
    // let prevAvaxPrice = 1000;
    let prevBnbPrice = 1000 // TODO: update price
    for (let i = 0; i < btcPrices.length; i++) {
      const btcPrice = btcPrices[i].value
      const ethPrice = ethPrices[i]?.value || prevEthPrice
      // const avaxPrice = avaxPrices[i]?.value || prevAvaxPrice;
      const bnbPrice = bnbPrices[i]?.value || prevBnbPrice
      // prevAvaxPrice = avaxPrice;
      prevEthPrice = ethPrice

      const timestampGroup = Math.floor(btcPrices[i].timestamp / 86400) * 86400
      const glpItem = glpDataById[timestampGroup]
      const xlpPrice = glpItem?.xlpPrice ?? lastGlpPrice
      lastGlpPrice = xlpPrice
      const glpSupply = glpDataById[timestampGroup]?.glpSupply
      const dailyFees = feesDataById[timestampGroup]?.all

      const syntheticPrice =
        indexBtcCount * btcPrice + indexEthCount * ethPrice + /*indexAvaxCount * avaxPrice +*/ indexStableCount

      // rebalance each day. can rebalance each X days
      if (i % 1 === 0) {
        indexBtcCount = (syntheticPrice * BTC_WEIGHT) / btcPrice
        indexEthCount = (syntheticPrice * ETH_WEIGHT) / ethPrice
        // indexAvaxCount = (syntheticPrice * AVAX_WEIGHT) / avaxPrice;
        indexStableCount = syntheticPrice * STABLE_WEIGHT
      }

      const lpBtcPrice =
        (lpBtcCount * btcPrice + GLP_START_PRICE / 2) * (1 + getImpermanentLoss(btcPrice / btcFirstPrice))
      const lpEthPrice =
        (lpEthCount * ethPrice + GLP_START_PRICE / 2) * (1 + getImpermanentLoss(ethPrice / ethFirstPrice))
      // const lpAvaxPrice =
      // (lpAvaxCount * avaxPrice + GLP_START_PRICE / 2) * (1 + getImpermanentLoss(avaxPrice / avaxFirstPrice));
      const lpBnbPrice =
        (lpBnbCount * bnbPrice + GLP_START_PRICE / 2) * (1 + getImpermanentLoss(bnbPrice / bnbFirstPrice))

      if (dailyFees && glpSupply) {
        const INCREASED_GLP_REWARDS_TIMESTAMP = 1635714000
        const GLP_REWARDS_SHARE = timestampGroup >= INCREASED_GLP_REWARDS_TIMESTAMP ? 0.7 : 0.5
        const collectedFeesPerGlp = (dailyFees / glpSupply) * GLP_REWARDS_SHARE
        cumulativeFeesPerGlp += collectedFeesPerGlp

        // cumulativeEsgmxRewardsPerGlp += (xlpPrice * 0.8) / 365;
      }

      let glpPlusFees = xlpPrice
      if (xlpPrice && glpSupply && cumulativeFeesPerGlp) {
        glpPlusFees = xlpPrice + cumulativeFeesPerGlp
      }

      let glpApr
      let glpPlusDistributedUsd
      let glpPlusDistributedEth
      if (glpItem) {
        if (glpItem.cumulativeDistributedUsdPerGlp) {
          glpPlusDistributedUsd = xlpPrice + glpItem.cumulativeDistributedUsdPerGlp
          // glpApr = glpItem.distributedUsdPerGlp / xlpPrice * 365 * 100 // incorrect?
        }
        if (glpItem.cumulativeDistributedEthPerGlp) {
          glpPlusDistributedEth = xlpPrice + glpItem.cumulativeDistributedEthPerGlp * ethPrice
        }
      }

      ret.push({
        timestamp: btcPrices[i].timestamp,
        syntheticPrice,
        lpBtcPrice,
        lpEthPrice,
        // lpAvaxPrice,
        lpBnbPrice,
        xlpPrice,
        btcPrice,
        ethPrice,
        glpPlusFees,
        glpPlusDistributedUsd,
        glpPlusDistributedEth,

        indexBtcCount,
        indexEthCount,
        // indexAvaxCount,
        indexBnbCount,
        indexStableCount,

        BTC_WEIGHT,
        ETH_WEIGHT,
        // AVAX_WEIGHT,
        BNB_WEIGHT,
        STABLE_WEIGHT,

        performanceLpEth: ((xlpPrice / lpEthPrice) * 100).toFixed(2),
        performanceLpEthCollectedFees: ((glpPlusFees / lpEthPrice) * 100).toFixed(2),
        performanceLpEthDistributedUsd: ((glpPlusDistributedUsd / lpEthPrice) * 100).toFixed(2),
        performanceLpEthDistributedEth: ((glpPlusDistributedEth / lpEthPrice) * 100).toFixed(2),

        performanceLpBtcCollectedFees: ((glpPlusFees / lpBtcPrice) * 100).toFixed(2),

        // performanceLpAvaxCollectedFees: ((glpPlusFees / lpAvaxPrice) * 100).toFixed(2),

        performanceLpBnbCollectedFees: ((glpPlusFees / lpBnbPrice) * 100).toFixed(2),

        performanceSynthetic: ((xlpPrice / syntheticPrice) * 100).toFixed(2),
        performanceSyntheticCollectedFees: ((glpPlusFees / syntheticPrice) * 100).toFixed(2),
        performanceSyntheticDistributedUsd: ((glpPlusDistributedUsd / syntheticPrice) * 100).toFixed(2),
        performanceSyntheticDistributedEth: ((glpPlusDistributedEth / syntheticPrice) * 100).toFixed(2),

        glpApr,
      })
    }

    return ret
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [btcPrices, ethPrices, glpData, feesData])

  return [glpPerformanceChartData]
}

export function useTokenStats({ from = FIRST_DATE_TS, to = NOW_TS, period = 'daily', chainId = DEFAULT_CHAIN } = {}) {
  const getTokenStatsFragment = ({ skip = 0 } = {}) => `
    tokenStats(
      first: 1000,
      skip: ${skip},
      orderBy: timestamp,
      orderDirection: desc,
      where: { period: ${period}, timestamp_gte: ${from}, timestamp_lte: ${to} }
    ) {
      poolAmountUsd
      timestamp
      token
    }
  `

  // Request more than 1000 records to retrieve maximum stats for period
  const query = `{
    a: ${getTokenStatsFragment()}
    b: ${getTokenStatsFragment({ skip: 1000 })},
    c: ${getTokenStatsFragment({ skip: 2000 })},
    d: ${getTokenStatsFragment({ skip: 3000 })},
    e: ${getTokenStatsFragment({ skip: 4000 })},
    f: ${getTokenStatsFragment({ skip: 5000 })},
  }`

  const [graphData, loading, error] = useGraph(query, { chainId })

  const data = useMemo(() => {
    if (loading || !graphData) {
      return null
    }

    const fullData: any[] = Object.values(graphData).reduce<any>((memo: any, records: any) => {
      memo.push(...records)
      return memo
    }, [])

    const retrievedTokens = new Set()

    const timestampGroups = fullData.reduce((memo, item) => {
      const { timestamp, token, ...stats } = item

      const symbol = tokenSymbols[chainId][token] || token

      retrievedTokens.add(symbol)

      memo[timestamp] = memo[timestamp || 0] || {}

      memo[timestamp][symbol] = {
        poolAmountUsd: parseInt(stats.poolAmountUsd) / 1e30,
      }

      return memo
    }, {})

    const poolAmountUsdRecords: any[] = []

    Object.entries(timestampGroups).forEach(([timestamp, dataItem]: [any, any]) => {
      const poolAmountUsdRecord: any[] = Object.entries(dataItem).reduce(
        (memo: any, [token, stats]: [any, any]) => {
          memo.all += stats.poolAmountUsd
          memo[token] = stats.poolAmountUsd
          memo.timestamp = timestamp

          return memo
        },
        { all: 0 },
      )

      poolAmountUsdRecords.push(poolAmountUsdRecord)
    })

    return {
      poolAmountUsd: poolAmountUsdRecords,
      tokenSymbols: Array.from(retrievedTokens),
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphData, loading])

  return [data, loading, error]
}

export function useReferralsData({ from = FIRST_DATE_TS, to = NOW_TS } = {}) {
  const query = `{
    globalStats(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: { period: "daily", timestamp_gte: ${from}, timestamp_lte: ${to} }
    ) {
      volume
      volumeCumulative
      totalRebateUsd
      totalRebateUsdCumulative
      discountUsd
      discountUsdCumulative
      referrersCount
      referrersCountCumulative
      referralCodesCount
      referralCodesCountCumulative
      referralsCount
      referralsCountCumulative
      timestamp
    }
  }`
  const [graphData, loading, error] = useGraph(query)

  const data = graphData
    ? sortBy(graphData.globalStats, 'timestamp').map((item) => {
        const totalRebateUsd = item.totalRebateUsd / 1e30
        const discountUsd = item.discountUsd / 1e30
        return {
          ...item,
          volume: item.volume / 1e30,
          volumeCumulative: item.volumeCumulative / 1e30,
          totalRebateUsd,
          totalRebateUsdCumulative: item.totalRebateUsdCumulative / 1e30,
          discountUsd,
          referrerRebateUsd: totalRebateUsd - discountUsd,
          discountUsdCumulative: item.discountUsdCumulative / 1e30,
          referralCodesCount: parseInt(item.referralCodesCount),
          referralCodesCountCumulative: parseInt(item.referralCodesCountCumulative),
          referrersCount: parseInt(item.referrersCount),
          referrersCountCumulative: parseInt(item.referrersCountCumulative),
          referralsCount: parseInt(item.referralsCount),
          referralsCountCumulative: parseInt(item.referralsCountCumulative),
        }
      })
    : null

  return [data, loading, error]
}
