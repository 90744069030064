import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { getExplorerUrl, helperToast, useChainId } from 'utils'
import { useWeb3 } from './useWeb3'

const PendingTxnsContext = createContext<any>(null)

type PendingTxn = { hash: string; message: string }
const PendingTxnsProvider = ({ children }) => {
  const { provider } = useWeb3()
  const { chainId } = useChainId()

  const [pendingTxns, setPendingTxns] = useState<Array<PendingTxn>>([])

  useEffect(() => {
    const checkPendingTxns = async () => {
      const updatedPendingTxns: Array<PendingTxn> = []
      for (let i = 0; i < pendingTxns.length; i++) {
        try {
          const pendingTxn = pendingTxns[i] as PendingTxn

          const receipt = await provider.getTransactionReceipt(pendingTxn.hash)

          if (receipt) {
            if (!receipt.status) {
              const txUrl = getExplorerUrl(chainId) + 'tx/' + pendingTxn.hash
              helperToast.error(
                <div>
                  Txn failed.{' '}
                  <a href={txUrl} target="_blank" rel="noopener noreferrer">
                    View
                  </a>
                </div>,
              )
            }
            if (receipt.status && pendingTxn.message) {
              const txUrl = getExplorerUrl(chainId) + 'tx/' + pendingTxn.hash
              helperToast.success(
                <div>
                  {pendingTxn.message}{' '}
                  <a href={txUrl} target="_blank" rel="noopener noreferrer">
                    View
                  </a>
                </div>,
              )
            }
            continue
          }
          updatedPendingTxns.push(pendingTxn)
        } catch (error) {
          console.error(error)
        }
      }

      if (updatedPendingTxns.length !== pendingTxns.length) {
        setPendingTxns(updatedPendingTxns)
      }
    }

    const interval = setInterval(() => {
      checkPendingTxns()
    }, 3000)

    return () => clearInterval(interval)
  }, [provider, pendingTxns, chainId])

  const value = useMemo(() => {
    return {
      pendingTxns,
      setPendingTxns,
    }
  }, [pendingTxns, setPendingTxns])

  return <PendingTxnsContext.Provider value={value}>{children}</PendingTxnsContext.Provider>
}

const usePendingTxns = (): any => {
  const context = useContext(PendingTxnsContext)
  if (context === undefined) {
    throw new Error('usePendingTxns must be used within a PendingTxnsProvider')
  }
  return context
}

export { PendingTxnsProvider, usePendingTxns }
