import { getContract } from '@tfx/addresses'
import { Event, ethers } from 'ethers'
import { useWeb3 } from 'hooks/useWeb3'
import { uniqBy } from 'lodash'
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast/headless'
import { useChainId } from 'utils'

import FulfillController from 'abis/FulfillController.json'
import { useLocalStorage } from 'react-use'
import { useAccount } from 'wagmi'
import Toaster from './Toaster'

export interface Step {
  tid: string
  sendingHash: string
  requestHash?: string
  fulfillHash?: string
  taskId?: number
  state: PendingStepState
  errorMsg?: string
  timestamp?: number
  startTimestamp?: number
  completeTimestamp?: number
  blockNumber?: number
  message: string
  chainId: number
  account: string
}
interface PendingStepWorkflow {
  hash: string
  message: string
  chainId: number
  state?: PendingStepState
  taskId?: number
  blockNumber?: number
  timestamp?: number
}

interface PendingStepsContextType {
  pendingStep: Array<Step>
  setPendingStepWorkflow: (workflow: PendingStepWorkflow) => void
  cancelPendingStep: (hash: string) => void
}

export enum PendingStepState {
  SENDING,
  REQUESTING,
  FULFILLING,
  COMPLETE,
  ARCHIVE,
  ERROR,
}

const PendingStepsContext = createContext<PendingStepsContextType | null>(null)

const PendingStepsContextProvider = ({ children }: { children: ReactNode }) => {
  const DEADLINE = 15
  const { provider, account } = useWeb3()
  const { chainId } = useChainId()
  const { chain } = useAccount()
  const [isReady, setIsReady] = useState<boolean>(false)
  const [pendingStep, setPendingStep] = useState<Step[]>([])
  const [pendingStepStorge, setPendingStepStorage] = useLocalStorage<Step[]>(`pending-steps.${chainId}.${account}`, [])
  const fullFillControllerAddress = getContract(chainId, 'FullFillController')

  useEffect(() => {
    if (!isReady && pendingStepStorge && pendingStepStorge?.length !== 0 && account) {
      const uniqueHash = uniqBy(pendingStepStorge, 'sendingHash')

      const now = parseInt(String(Date.now() / 1000))

      for (let i = 0; i < uniqueHash.length; i++) {
        const { sendingHash, timestamp, message, chainId, state, ...rest } = uniqueHash[i] as Step
        // < 5m
        if (timestamp && timestamp + 300 > now && state < PendingStepState.COMPLETE) {
          setPendingStepWorkflow({
            hash: sendingHash,
            message,
            chainId,
            state,
            ...rest,
          })
        }

        // > 5m & < 3d
        if (timestamp && timestamp + 300 < now && timestamp + 259200 > now && state < PendingStepState.COMPLETE) {
          setPendingStepWorkflow(
            {
              hash: sendingHash,
              message,
              chainId,
              state,
              ...rest,
            },
            false,
          )
        }
      }
    }

    setIsReady(true)
    // eslint-disable-next-line
  }, [isReady])

  useEffect(() => {
    if (isReady) {
      toast.dismiss()
      setIsReady(false)
      setPendingStep([])
    }
    // eslint-disable-next-line
  }, [chainId, account])

  useEffect(() => {
    if (
      isReady &&
      Array.isArray(pendingStep) &&
      Array.isArray(pendingStepStorge) &&
      pendingStep.length !== pendingStepStorge.length
    ) {
      setPendingStepStorage(pendingStep)
    }
    // eslint-disable-next-line
  }, [isReady, pendingStep])

  useEffect(() => {
    let isCancelled = false
    const checkPendingTxns = async () => {
      const now = parseInt(String(Date.now() / 1000))
      const fullFillController = new ethers.Contract(fullFillControllerAddress, FulfillController, provider)

      const filterPendingSteps = pendingStep.filter((step) => {
        return step.chainId === chainId && step.account === account && step.state !== PendingStepState.ARCHIVE
      })

      const updater: Step[] = []

      for (let i = 0; i < filterPendingSteps.length; i++) {
        const pendingTxn = filterPendingSteps[i] as Step
        if (pendingTxn.state === PendingStepState.SENDING) {
          const receipt = await provider.getTransactionReceipt(pendingTxn.sendingHash)
          if (receipt) {
            if (!receipt.status) {
              pendingTxn.errorMsg = 'Txn failed.'
              pendingTxn.state = PendingStepState.ERROR
              pendingTxn.completeTimestamp = now
            } else {
              pendingTxn.state = PendingStepState.REQUESTING
              pendingTxn.blockNumber = receipt.blockNumber
            }
          }
          updater[i] = pendingTxn
          continue
        }

        if (pendingTxn.state === PendingStepState.REQUESTING && pendingTxn.account === account) {
          const eventFilter = fullFillController.filters.RequestTask(null, account)
          const events = await fullFillController.queryFilter(eventFilter, pendingTxn.blockNumber)

          const timestamp = pendingTxn?.blockNumber ? (await provider.getBlock(pendingTxn.blockNumber)).timestamp : now

          if (events && events.length) {
            const foundEvent = events.find((event) => event.transactionHash === pendingTxn.sendingHash)

            if (foundEvent && foundEvent.args) {
              pendingTxn.taskId = +foundEvent.args[0]
              pendingTxn.requestHash = foundEvent.transactionHash
              pendingTxn.state = PendingStepState.FULFILLING
              pendingTxn.startTimestamp = timestamp
            }
          } else {
            pendingTxn.errorMsg = 'Txn failed.'
            pendingTxn.state = PendingStepState.ERROR
            pendingTxn.completeTimestamp = now
          }
          updater[i] = pendingTxn
          continue
        }

        if (pendingTxn.state === PendingStepState.FULFILLING && pendingTxn.account === account && pendingTxn.taskId) {
          const eventFilter = fullFillController.filters.FulfillTask(pendingTxn.taskId, [account])
          const events = await fullFillController.queryFilter(eventFilter, pendingTxn.blockNumber)

          if (events && events.length) {
            const foundEvent = events.find((event: Event) => +event?.args?.taskId === pendingTxn.taskId)

            if (foundEvent && foundEvent.args) {
              pendingTxn.fulfillHash = foundEvent.transactionHash
              pendingTxn.completeTimestamp = now

              if (!foundEvent.args[2] && foundEvent.args[3]) {
                pendingTxn.errorMsg = foundEvent.args[3]
                pendingTxn.state = PendingStepState.ERROR
              } else {
                pendingTxn.state = PendingStepState.COMPLETE
              }
            }
          }
          updater[i] = pendingTxn
          continue
        }

        // Dismiss toast by id and change state to archived
        if (
          pendingTxn.tid &&
          pendingTxn.completeTimestamp &&
          ((pendingTxn.state === PendingStepState.COMPLETE && pendingTxn.completeTimestamp && pendingTxn.fulfillHash) ||
            (pendingTxn.state === PendingStepState.ERROR && pendingTxn.completeTimestamp))
        ) {
          if (pendingTxn.completeTimestamp + DEADLINE < now) {
            cancelPendingStep(pendingTxn.tid)
            pendingTxn.state = PendingStepState.ARCHIVE
          }
          updater[i] = pendingTxn
          continue
        } else if (
          !pendingTxn.tid &&
          (pendingTxn.state === PendingStepState.COMPLETE || pendingTxn.state === PendingStepState.ERROR)
        ) {
          const tid = toast(pendingTxn as any, {
            duration: 3000,
          })
          pendingTxn.tid = tid
          pendingTxn.state = PendingStepState.ARCHIVE
          updater[i] = pendingTxn
          continue
        }

        if (isCancelled) break

        updater[i] = pendingTxn
      }

      if (!isCancelled) {
        setPendingStepStorage(updater)
      }
    }

    // Use a named function for the interval
    const intervalFunction = () => {
      if (isReady && chain && chainId && account) {
        checkPendingTxns()
      }
    }

    // Call the interval function immediately after setting the interval
    intervalFunction()

    // Set an interval to call the interval function
    const interval = setInterval(intervalFunction, 3000)

    // Clear the interval on component unmount
    return () => {
      clearInterval(interval)
      isCancelled = true
    }
    // eslint-disable-next-line
  }, [pendingStep, isReady])

  const setPendingStepWorkflow = useCallback(
    ({ hash, timestamp, message, chainId: cId, taskId, ...rest }: PendingStepWorkflow, showNow: boolean = true) => {
      if (!account || !chainId || chainId !== cId) return
      const now = parseInt(String(Date.now() / 1000))
      const step = {
        sendingHash: hash,
        state: PendingStepState.SENDING,
        message,
        chainId,
        account,
        ...rest,
        // reset tid
        tid: '',
        taskId: 0,
        timestamp: now,
      }

      if (showNow) {
        const tid = toast(step as any)
        step.tid = tid
      }

      if (taskId) step.taskId = taskId

      setPendingStep((steps) => [...steps, step])
    },
    // eslint-disable-next-line
    [account, pendingStep, chainId],
  )

  const cancelPendingStep = useCallback(
    (tid: string) => {
      toast.dismiss(tid)

      if (!Array.isArray(pendingStep) || pendingStep?.length === 0) return

      const updatedPendingTxns = pendingStep.map((step) => {
        if (tid === step.tid) {
          return {
            ...step,
            state: PendingStepState.ARCHIVE,
          }
        }
        return step
      })
      setPendingStep(updatedPendingTxns)
    },
    [pendingStep, setPendingStep],
  )

  const value = useMemo(() => {
    return {
      pendingStep,
      setPendingStepWorkflow,
      cancelPendingStep,
    }
  }, [pendingStep, setPendingStepWorkflow, cancelPendingStep])

  return (
    <PendingStepsContext.Provider value={value}>
      {children}
      <Toaster />
    </PendingStepsContext.Provider>
  )
}

const usePendingSteps = (): any => {
  const context = useContext(PendingStepsContext)
  if (context === undefined) {
    throw new Error('usePendingSteps must be used within a PendingStepsContextProvider')
  }
  return context
}

export { usePendingSteps }
export default PendingStepsContextProvider
