import { gql } from '@apollo/client'
import { useQuery } from '@tanstack/react-query'
import { getContract } from '@tfx/addresses'
import ReaderV2 from 'abis/Reader.json'
import { BigNumber } from 'ethers'
import useSWR from 'swr'
import {
  PLACEHOLDER_ACCOUNT,
  USD_DECIMALS,
  XLP_DECIMALS,
  bigNumberify,
  expandDecimals,
  fetcher,
  formatAmount,
} from 'utils'
import { useAum } from 'utils/api/stake'
import { getGraphClient } from 'utils/client'
import { useWeb3 } from './useWeb3'

const DAILY_PREIOD = 86400
export function timestampToPeriod(timestamp: number, period: 'halfly' | 'daily' | 'weekly' | 'hourly'): number {
  let delimeter = 0
  if (period === 'daily') delimeter = DAILY_PREIOD
  else if (period === 'weekly') delimeter = DAILY_PREIOD * 7
  else if (period === 'hourly') delimeter = 3600
  else if (period === 'halfly') delimeter = 3600 / 2
  return parseInt(String(timestamp / delimeter)) * delimeter
}

export interface XLPSupplyAumResponse {
  day: Record<string, XLPStat>
  week: Record<string, XLPStat>
  month: Record<string, XLPStat>
}

export interface XLPStat {
  timestamp: number
  aum: number
  xlpSupply: number
  xlpPrice: number
}

const DEFAULT_GROUP_PERIOD = 86400
const NOW_TS = parseInt(String(Date.now() / 1000))
const FROM_TS = NOW_TS - DEFAULT_GROUP_PERIOD * 30

export const useGetXLPSupplyAum = ({
  chainId,
  enabled,
}): {
  data: XLPSupplyAumResponse
  isLoading: boolean
} => {
  const query = gql(`
    {
      xlpPrices(
        first: 1000
        orderBy: timestamp
        orderDirection: desc
        where: {timestamp_gte: ${FROM_TS}, timestamp_lte: ${NOW_TS}}
      ) {
        aum
        timestamp
        xlpSupply
      }
    }
  `)

  const { active, library, account } = useWeb3()

  const xlpAddress = getContract(chainId, 'XLP')
  const readerAddress = getContract(chainId, 'Reader')

  const [aum] = useAum(library, chainId, active)
  const { data: balancesAndSupplies } = useSWR(
    [
      `XlpSwap:getTokenBalancesWithSupplies:${active}`,
      chainId,
      readerAddress,
      'getTokenBalancesWithSupplies',
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: fetcher(library, ReaderV2, [xlpAddress]),
    },
  )

  const glpSupply = balancesAndSupplies ? balancesAndSupplies[1] : bigNumberify(0)
  const xlpPrice =
    aum && aum.gt(0) && glpSupply.gt(0)
      ? aum.mul(expandDecimals(1, XLP_DECIMALS)).div(glpSupply)
      : expandDecimals(1, USD_DECIMALS)

  const { data, isLoading } = useQuery<XLPSupplyAumResponse>({
    queryKey: [`XLPSupplyAUM/${FROM_TS}-${NOW_TS}`],
    queryFn: async () => {
      const graphClient = getGraphClient(chainId)
      return graphClient.query({ query }).then(({ data }) => {
        const glpStats = data?.xlpPrices

        if (!glpStats || glpStats?.length === 0) {
          return {
            day: {},
            week: {},
            month: {},
          }
        }

        try {
          const glpStatsData = glpStats.map(({ timestamp, aum: aumInUsdg, xlpSupply: glpSupply }) => {
            const aum = BigNumber.from(aumInUsdg)
            const xlpSupply = BigNumber.from(glpSupply)
            const xlpPrice = aum.mul(expandDecimals(1, XLP_DECIMALS)).div(xlpSupply)

            return {
              timestamp,
              aum,
              xlpSupply,
              xlpPrice: +formatAmount(xlpPrice, USD_DECIMALS, 3, true),
            }
          })

          const startTime = timestampToPeriod(NOW_TS, 'halfly')

          let defaultData = {
            timestamp: startTime,
            aum,
            xlpSupply: glpSupply,
            xlpPrice: +formatAmount(xlpPrice, USD_DECIMALS, 3),
          }

          const objData: XLPSupplyAumResponse = {
            day: {},
            week: {},
            month: {},
          }

          for (let i = startTime; i > FROM_TS; i -= 1800) {
            const foundData = glpStatsData.find((item) => item.timestamp === i) || defaultData

            if (i > startTime - DAILY_PREIOD) {
              objData.day[timestampToPeriod(i, 'halfly')] = foundData
            }

            if (i > startTime - DAILY_PREIOD * 7) {
              objData.week[timestampToPeriod(i, 'hourly')] = foundData
            }

            objData.month[timestampToPeriod(i, 'daily')] = foundData

            defaultData = {
              ...foundData,
              timestamp: i,
            }
          }

          return objData
        } catch (error) {
          console.warn('XLPSupplyAUM: ', error?.message)
          return {
            day: {},
            week: {},
            month: {},
          }
        }
      })
    },
    initialData: () => ({
      day: {},
      week: {},
      month: {},
    }),
    refetchInterval: 50000,
    refetchOnWindowFocus: true,
    enabled: enabled && Boolean(aum) && Boolean(glpSupply) && Boolean(xlpPrice),
  })

  return {
    data,
    isLoading,
  }
}
