import Particles from 'react-particles'
import { loadFull } from 'tsparticles'
import { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

const ParticlesBox = () => {
  const location = useLocation()

  const particlesInit = useCallback(
    async (engine: any) => {
      await loadFull(engine)
    },
    // eslint-disable-next-line
    [location.pathname],
  )

  const particlesLoaded = useCallback(async (container: any | undefined) => {
    // await console.log(container);
  }, [])

  return useMemo(() => {
    return (
      <Particles
        id="tsparticles"
        loaded={particlesLoaded}
        init={particlesInit}
        style={{
          opacity: 0.7,
        }}
        options={{
          background: {
            color: {
              value: 'transparent',
            },
          },
          fpsLimit: 120,
          interactivity: {
            events: {
              onHover: {
                enable: true,
                mode: 'repulse',
              },
            },
            modes: {
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: '#fff',
            },
            links: {
              color: '#fff',
              distance: 150,
              enable: true,
              opacity: 0.2,
              width: 1,
            },
            // collisions: {
            //   enable: true,
            // },
            move: {
              enable: true,
              outModes: {
                default: 'bounce',
              },
              random: false,
              speed: 3,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 80,
            },
            opacity: {
              value: 0.1,
            },
            shape: {
              type: 'circle',
            },
            size: {
              value: { min: 1, max: 5 },
            },
          },
          detectRetina: true,
        }}
      />
    )
  }, [particlesInit, particlesLoaded])
}

export default ParticlesBox
