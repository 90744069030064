import hexToRgba from 'hex-to-rgba'
import {
  XLP_DECIMALS,
  GLPPOOLCOLORS,
  USD_DECIMALS,
  formatAmount,
  PLACEHOLDER_ACCOUNT,
  useChainId,
  fetcher,
  BASIS_POINTS_DIVISOR,
  getTokenInfo,
  expandDecimals,
  SECONDS_PER_YEAR,
} from 'utils'

// import glpImage from "img/glp_icon.svg";
import glpImage from 'img/tfx_64x64.svg'
import { BigNumber, ethers } from 'ethers'
import { Cell, Pie, PieChart, Tooltip } from 'recharts'
import Button from 'components/Button'
import { useHistory } from 'react-router-dom'
import { useAnalytic } from 'components/Analytic'
import { GA_EVENT_KEY } from 'components/Analytic/constants'
import { useWeb3 } from 'hooks/useWeb3'
import useSWR from 'swr'
import { getContract } from '@tfx/addresses'
import RewardReader from 'abis/RewardReader.json'
import { useMemo } from 'react'

type Pool = {
  fullname: string
  name: string
  value: number
}
type LpStatsProps = {
  chainId: number
  xlpPrice: BigNumber
  xlpSupply: BigNumber
  xlpMarketCap: BigNumber
  stablePercentage: string
  xlpPool: Array<Pool>
  onXLPPoolChartLeave: any
  onXLPPoolChartEnter: any
  glpActiveIndex: any
  infoTokens: any
}

function getStakingData(stakingInfo): any {
  if (!stakingInfo || stakingInfo.length === 0) {
    return
  }

  const keys = ['FeeXlpTracker']
  const data = {}
  const propsLength = 5

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    data[key] = {
      claimable: stakingInfo[i * propsLength],
      tokensPerInterval: stakingInfo[i * propsLength + 1],
      averageStakedAmounts: stakingInfo[i * propsLength + 2],
      cumulativeRewards: stakingInfo[i * propsLength + 3],
      totalSupply: stakingInfo[i * propsLength + 4],
    }
  }
  return data
}

const LpStats = ({
  xlpPrice,
  xlpSupply,
  xlpMarketCap,
  stablePercentage,
  xlpPool,
  onXLPPoolChartLeave,
  onXLPPoolChartEnter,
  glpActiveIndex,
  infoTokens,
}: LpStatsProps) => {
  const { push } = useHistory()
  const { active, library, account } = useWeb3()
  const { chainId } = useChainId()

  const { AddressZero } = ethers.constants

  const rewardReaderAddress = getContract(chainId, 'RewardReader')
  const feeXlpTrackerAddress = getContract(chainId, 'FeeXlpTracker')
  const rewardTrackersForStakingInfo = [feeXlpTrackerAddress]

  const { data: stakingInfo } = useSWR(
    [`XlpSwap:stakingInfo:${active}`, chainId, rewardReaderAddress, 'getStakingInfo', account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
    },
  )

  const stakingData = getStakingData(stakingInfo)
  const nativeToken = getTokenInfo(infoTokens, AddressZero)

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="stats-label">
          <div className="stats-label-color" style={{ backgroundColor: payload[0].color }}></div>
          {payload[0].value}% {payload[0].name}
        </div>
      )
    }
    return null
  }

  const feeGlpTrackerApr = useMemo(() => {
    let feeGlpTrackerApr
    let feeGlpTrackerAnnualRewardsUsd
    if (
      stakingData &&
      stakingData.FeeXlpTracker &&
      stakingData.FeeXlpTracker.tokensPerInterval &&
      nativeToken &&
      nativeToken.minPrice &&
      xlpSupply &&
      xlpSupply?.gt(0)
    ) {
      const xlpSupplyUsd = xlpSupply.mul(xlpPrice).div(expandDecimals(1, XLP_DECIMALS))
      feeGlpTrackerAnnualRewardsUsd = stakingData.FeeXlpTracker.tokensPerInterval
        .mul(SECONDS_PER_YEAR)
        .mul(nativeToken.minPrice)
        .div(expandDecimals(1, 18))
      feeGlpTrackerApr = feeGlpTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(xlpSupplyUsd)
    }
    return feeGlpTrackerApr
  }, [xlpPrice, xlpSupply, nativeToken, stakingData])

  const { sendEvent } = useAnalytic()

  return (
    <div className="block lg:grid grid-cols-3 my-10 gap-10">
      <div className="col-span-1 p-5 py-2">
        <div className="flex items-center gap-5 mb-1">
          <img src={glpImage} alt="xlp logo" className="w-[40px]" />
          <div className="text-[3.5rem] font-bold">xLP</div>
        </div>
        <p className="text-3xl">
          xLP is the liquidity provider token. Accrues 70% of the platform's generated fees.
          <a
            href="https://tfx-market.gitbook.io/tfx/trading#execution-fee"
            target="_blank"
            rel="noopener noreferrer"
            className="underline font-sm inline-block mx-3"
          >
            Read More
          </a>
        </p>
        <div className="my-5 mx-2">
          <Button
            className="text-[1.5rem] p-10 py-6 min-w-[140px] font-bold justify-center shadow-xl rounded-2xl bg-bg-color-6 text-white border-0 cursor-pointe"
            onClick={() => {
              sendEvent({
                category: GA_EVENT_KEY.DASHBOARD.CATEGORY,
                action: GA_EVENT_KEY.DASHBOARD.ACTION.CLICK_BUY_XLP_BUTTON,
              })
              push('/pool')
            }}
          >
            Provide Liquidity
          </Button>
        </div>
      </div>

      <div className="col-span-2 bg-bg-color-5 border border-bg-color-2 dark:bg-bg-dark-color-2 p-6 rounded-3xl sm:flex block">
        <div className="grid grid-cols-2 grow items-center gap-5">
          <div className="px-6 flex-1 text-[2rem] font-bold">
            <div className="tracking-tighter">{formatAmount(feeGlpTrackerApr, 2, 2, false)}%</div>
            <div className="tracking-tighter text-[1.5rem] text-gray-400 dark:text-gray-3000">APR</div>
          </div>

          <div className="px-6 flex-1 text-[2rem] font-bold">
            <div className="tracking-tighter">
              ${formatAmount(xlpPrice, USD_DECIMALS, 3, true)}
              <div className="tracking-tighter text-[1.5rem] text-gray-400 dark:text-gray-300">Price</div>
            </div>
          </div>

          <div className="px-6 flex-1 text-[2rem] font-bold">
            <div className="tracking-tighter">
              {formatAmount(xlpSupply, XLP_DECIMALS, 0, true)} xLP
              <div className="tracking-tighter text-[1.5rem] text-gray-400 dark:text-gray-300">Supply</div>
            </div>
          </div>

          <div className="px-6 flex-1 text-[2rem] font-bold">
            <div className="tracking-tighter">
              ${formatAmount(xlpMarketCap, USD_DECIMALS, 0, true)}
              <div className="tracking-tighter text-[1.5rem] text-gray-400 dark:text-gray-300">Total Staked</div>
            </div>
          </div>

          <div className="px-6 flex-1 text-[2rem] font-bold">
            <div className="tracking-tighter">
              ${formatAmount(xlpMarketCap, USD_DECIMALS, 0, true)}
              <div className="tracking-tighter text-[1.5rem] text-gray-400 dark:text-gray-300">Market Cap</div>
            </div>
          </div>

          <div className="px-6 flex-1 text-[2rem] font-bold">
            <div className="tracking-tighter">
              {stablePercentage}%
              <div className="tracking-tighter text-[1.5rem] text-gray-400 dark:text-gray-300">
                Stablecoin Percentage
              </div>
            </div>
          </div>
        </div>

        <div className="flex-none">
          <div className="stats-piechart" onMouseOut={onXLPPoolChartLeave}>
            {xlpPool.length > 0 && (
              <PieChart width={210} height={210}>
                <Pie
                  data={xlpPool}
                  cx={100}
                  cy={100}
                  innerRadius={73}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  startAngle={90}
                  endAngle={-270}
                  onMouseEnter={onXLPPoolChartEnter}
                  onMouseOut={onXLPPoolChartLeave}
                  onMouseLeave={onXLPPoolChartLeave}
                  paddingAngle={2}
                >
                  {xlpPool.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={GLPPOOLCOLORS[entry.name]}
                      style={{
                        filter:
                          glpActiveIndex === index
                            ? `drop-shadow(0px 0px 6px ${hexToRgba(GLPPOOLCOLORS[entry.name], 0.7)})`
                            : 'none',
                        cursor: 'pointer',
                      }}
                      stroke={GLPPOOLCOLORS[entry.name]}
                      strokeWidth={glpActiveIndex === index ? 1 : 1}
                    />
                  ))}
                </Pie>
                <text
                  x={'50%'}
                  y={'50%'}
                  className="fill-gray-700 dark:fill-white"
                  textAnchor="middle"
                  dominantBaseline="middle"
                >
                  xLP Pool
                </text>
                <Tooltip content={<CustomTooltip />} />
              </PieChart>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LpStats
