import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'

export interface IExplorerPagination {
  current: number
  onChange: (current: number, pageSize: number) => void
  pageSize: number
  showTotal: (total: number) => string
  total: number
}

const ExplorerPagination = ({ current, onChange, pageSize, total }: IExplorerPagination) => {
  const totalPage = Math.ceil(total / pageSize)
  return (
    <nav
      className="flex items-center justify-between bg-white px-4 py-3 sm:px-6 dark:bg-gray-800 dark:border-gray-700 rounded-b-lg border"
      aria-label="Pagination"
    >
      <div className="flex flex-1 justify-between sm:justify-end">
        <div className="flex">
          <button
            disabled={current === 1}
            onClick={() => onChange && onChange(1, pageSize)}
            className="text-lg relative inline-flex items-center rounded-md bg-white px-3 py-2 text-gray-900 hover:bg-gray-50 focus-visible:outline-offset-0 cursor-pointer border border-gray-300 disabled:cursor-no-drop  disabled:opacity-50"
          >
            First
          </button>
          <button
            disabled={current === 1}
            onClick={() => onChange && onChange(current - 1, pageSize)}
            className="ml-3 relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus-visible:outline-offset-0 cursor-pointer border border-gray-300 disabled:cursor-no-drop  disabled:opacity-50"
          >
            <ChevronLeftIcon className="w-8 h-8" />
          </button>
        </div>
        <div className="flex items-center ml-3">
          <p className="text-xl text-gray-700 dark:text-white">
            Showing <span className="font-medium">{current}</span> to <span className="font-medium">{totalPage}</span>
          </p>
        </div>
        <div className="flex">
          <button
            disabled={current === totalPage}
            onClick={() => onChange && onChange(current + 1, pageSize)}
            className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus-visible:outline-offset-0 cursor-pointer border border-gray-300 disabled:cursor-no-drop  disabled:opacity-50"
          >
            <ChevronRightIcon className="w-8 h-8" />
          </button>
          <button
            disabled={current === totalPage}
            onClick={() => onChange && onChange(totalPage, pageSize)}
            className="ml-3 text-lg relative inline-flex items-center rounded-md bg-white px-3 py-2 text-gray-900 hover:bg-gray-50 focus-visible:outline-offset-0 cursor-pointer border border-gray-300 disabled:cursor-no-drop  disabled:opacity-50"
          >
            Last
          </button>
        </div>
      </div>
    </nav>
  )
}

export default ExplorerPagination
