import cx from 'classnames'

import './Tab.css'

export default function Tab(props) {
  const { options, option, setOption, onChange, type = 'block', className, optionLabels, icons, isBg = true } = props

  const onClick = (opt) => {
    if (setOption) {
      setOption(opt)
    }
    if (onChange) {
      onChange(opt)
    }
  }

  return (
    <div
      className={`rounded-xl p-2 ${cx('Tab', type, className, {
        'bg-bg-color-5 dark:bg-bg-dark-color-2': isBg,
      })}`}
    >
      {options.map((opt) => {
        const label = optionLabels && optionLabels[opt] ? optionLabels[opt] : opt
        const Icon = icons && icons[opt] ? icons[opt] : null
        return (
          <div
            className={cx('Tab-option text-xl py-2 px-4 rounded-md font-bold', 'muted', {
              active: opt === option,
              'underline dark:bg-none': opt === option && !isBg,
            })}
            onClick={() => onClick(opt)}
            key={opt}
          >
            {Icon && <Icon className="Tab-option-icon" />}
            {label}
          </div>
        )
      })}
    </div>
  )
}
