import { ConnectButton as RainbowConnectButton } from '@rainbow-me/rainbowkit'
import cx from 'classnames'
import { IoWifiOutline } from 'react-icons/io5'
import { MdAccountBalanceWallet } from 'react-icons/md'
import './Button.css'

function Button({ href, imgSrc, children, onClick, className, size = 'lg', align = 'center', ...rest }) {
  let classNames = cx('btn btn-primary', align === 'left' ? 'btn-left' : 'btn-center', `btn-${size}`, className)
  if (typeof children === 'object') {
    return (
      <a className={classNames} href={href} target="_blank" rel="noopener noreferrer" {...rest}>
        {children}
      </a>
    )
  }

  if (href) {
    return (
      <a className={classNames} href={href} target="_blank" rel="noopener noreferrer" {...rest}>
        {imgSrc && <img className="btn-image" src={imgSrc} alt={children} />}
        <span className="btn-label">{children}</span>
      </a>
    )
  }
  return (
    <button className={classNames} onClick={onClick} {...rest}>
      {imgSrc && <img className="btn-image" src={imgSrc} alt={children} />}
      <span className="btn-label">{children}</span>
    </button>
  )
}
export const ConnectWalletButton = ({ theme }) => {
  return (
    <RainbowConnectButton.Custom>
      {({ account, chain, openAccountModal, openChainModal, openConnectModal, mounted }) => {
        return (
          <div className="flex">
            {(() => {
              if (!mounted || !account || !chain) {
                return (
                  <div
                    className="cursor-pointer inline-flex items-center px-3.5 py-3.5 font-bold text-xl border border-transparent leading-4 
                    rounded-2xl shadow-sm text-white bg-blue-600 bg-opacity-70 hover:bg-blue-700 focus:outline-none focus:ring-2 
                    focus:ring-offset-2 focus:ring-blue-500"
                    onClick={() => {
                      openConnectModal()
                    }}
                  >
                    <MdAccountBalanceWallet className="mr-2" />
                    Connect
                  </div>
                )
              }
              return (
                <>
                  <div
                    className={
                      theme === 'light'
                        ? 'cursor-pointer min-w-[32px] inline-flex items-center rounded-2xl border border-transparent bg-opacity-70 p-1.5 text-xl text-gray-600 shadow-sm hover:bg-bg-dark-color-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                        : 'cursor-pointer min-w-[32px] inline-flex items-center rounded-2xl border border-transparent bg-opacity-70 p-1.5 text-xl text-white shadow-sm hover:bg-bg-dark-color-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                    }
                    onClick={openChainModal}
                  >
                    {chain?.unsupported && (
                      <>
                        <IoWifiOutline className="text-red-500" size="22px" />
                      </>
                    )}
                    {chain.hasIcon && (
                      <>
                        <div
                          style={{
                            background: chain.iconBackground,
                            width: 24,
                            height: 24,
                            borderRadius: 999,
                            overflow: 'hidden',
                          }}
                        >
                          {chain.iconUrl && (
                            <img alt={chain.name ?? 'Chain icon'} src={chain.iconUrl} className="w-[24px] h-[24px]" />
                          )}
                        </div>
                        <div className="px-[5px] hidden lg:block whitespace-nowrap">{chain.name}</div>
                      </>
                    )}
                  </div>
                  <div
                    className={
                      theme === 'light'
                        ? 'cursor-pointer ml-4 inline-flex items-center whitespace-nowrap px-3.5 py-2 font-bold text-xl border border-transparent leading-4 rounded-2xl shadow-sm text-gray-600 bg-opacity-70 hover:bg-bg-dark-color-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                        : 'cursor-pointer ml-4 inline-flex items-center whitespace-nowrap px-3.5 py-2 font-bold text-xl border border-transparent leading-4 rounded-2xl shadow-sm text-white bg-opacity-70 hover:bg-bg-dark-color-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                    }
                    onClick={chain.unsupported ? openChainModal : openAccountModal}
                  >
                    <MdAccountBalanceWallet className="mr-2" size="18px" />
                    {chain.unsupported ? 'Unsupported Network' : account.displayName}
                  </div>
                </>
              )
            })()}
          </div>
        )
      }}
    </RainbowConnectButton.Custom>
  )
}

export default Button
