import { useMemo, useRef, useState } from 'react'
import { FiPlus, FiTwitter } from 'react-icons/fi'
import { useCopyToClipboard } from 'react-use'
import { IoWarningOutline } from 'react-icons/io5'
import { BiCopy, BiErrorCircle } from 'react-icons/bi'
import Modal from '../Modal/Modal'
import { getNativeToken, getToken } from '@tfx/tokens'
import { bigNumberify, formatAmount, formatDate, getExplorerUrl, helperToast, shortenAddress, getHomeUrl } from 'utils'
import { REFERRAL_CODE_QUERY_PARAM } from 'config/localStorage'
import EmptyMessage from './EmptyMessage'
import InfoCard from './InfoCard'
import {
  getTierIdDisplay,
  getTwitterShareUrl,
  getUSDValue,
  isRecentReferralCodeNotExpired,
  tierRebateInfo,
} from './referralsHelper'
import { AffiliateCodeForm } from './AddAffiliateCode'
import TooltipWithPortal from '../Tooltip/TooltipWithPortal'
import { PButton } from 'components/Button'
import { getChainDisplayName } from '@tfx/chains'

function AffiliatesStats({
  referralsData,
  handleCreateReferralCode,
  chainId,
  setRecentlyAddedCodes,
  recentlyAddedCodes,
}) {
  const homeUrl = getHomeUrl()
  const [isAddReferralCodeModalOpen, setIsAddReferralCodeModalOpen] = useState(false)
  const addNewModalRef = useRef<HTMLInputElement>(null)

  const [, copyToClipboard] = useCopyToClipboard()
  const open = () => setIsAddReferralCodeModalOpen(true)
  const close = () => setIsAddReferralCodeModalOpen(false)

  const { cumulativeStats, referrerTotalStats, referrerTierInfo, rebateDistributions } = referralsData
  const allReferralCodes = referrerTotalStats.map((c) => c.referralCode.trim())
  const finalAffiliatesTotalStats = useMemo(
    () =>
      recentlyAddedCodes.filter(isRecentReferralCodeNotExpired).reduce((acc, cv) => {
        if (!allReferralCodes.includes(cv.referralCode)) {
          acc = acc.concat(cv)
        }
        return acc
      }, referrerTotalStats),
    [allReferralCodes, referrerTotalStats, recentlyAddedCodes],
  )

  const tierId = referrerTierInfo?.tierId
  let referrerRebates = bigNumberify(0)
  if (cumulativeStats && cumulativeStats.totalRebateUsd && cumulativeStats.discountUsd) {
    referrerRebates = cumulativeStats.totalRebateUsd.sub(cumulativeStats.discountUsd)
  }

  return (
    <div>
      <Modal
        className="Connect-wallet-modal"
        isVisible={isAddReferralCodeModalOpen}
        setIsVisible={close}
        label="Create Referral Code"
        onAfterOpen={() => addNewModalRef.current?.focus()}
      >
        <div className="edit-referral-modal w-full text-center">
          <AffiliateCodeForm
            handleCreateReferralCode={handleCreateReferralCode}
            recentlyAddedCodes={recentlyAddedCodes}
            setRecentlyAddedCodes={setRecentlyAddedCodes}
            callAfterSuccess={close}
          />
        </div>
      </Modal>

      <div className="w-full bg-bg-color-5 dark:bg-bg-dark-color-3 border border-bg-color-2 p-8 rounded-3xl text-3xl">
        <div>
          <dl className="sm:grid sm:grid-cols-3 py-8 relative">
            <InfoCard
              label="Total Traders Referred"
              tooltipText="Amount of traders you referred."
              data={cumulativeStats?.registeredReferralsCount || '0'}
            />
            <InfoCard
              label="Total Trading Volume"
              tooltipText="Volume traded by your referred traders."
              data={getUSDValue(cumulativeStats?.volume)}
            />
            <InfoCard
              label="Total Rebates"
              tooltipText="Rebates earned by this account as an affiliate."
              data={getUSDValue(referrerRebates, 4)}
            />
          </dl>
        </div>

        <div className="mb-8 py-6 flex justify-between items-center border-b border-t border-bg-color-2">
          <p className="font-bold text-2xl">
            Referral Codes{' '}
            <span className="sub-title">
              {referrerTierInfo && `Tier ${getTierIdDisplay(tierId)} (${tierRebateInfo[tierId]}% rebate)`}
            </span>
          </p>
          <PButton onClick={open} size="sm" className="!w-[90px] h-[30px] px-2">
            <FiPlus /> <span className="ml-2">Create</span>
          </PButton>
        </div>

        <div className="grid-cols-4 gap-4 w-full px-4 mb-4 hidden md:grid dark:text-gray-300 text-2xl">
          <div className="col-auto text-left p-3">Referral Code</div>
          <div className="col-auto text-left p-3">Total Volume</div>
          <div className="col-auto text-left p-3">Traders Referred</div>
          <div className="col-auto text-left p-3">Total Rebates</div>
        </div>

        {finalAffiliatesTotalStats.map((stat, index) => {
          const ownerOnOtherChain = stat?.ownerOnOtherChain
          let referrerRebate = bigNumberify(0)
          if (stat && stat.totalRebateUsd && stat.discountUsd) {
            referrerRebate = stat.totalRebateUsd.sub(stat.discountUsd)
          }
          return (
            <div
              key={'ref-affiliate-stats' + index}
              className="grid border border-bg-color-2 items-center md:divide-y-0 md:grid-cols-4
              md:gap-4 w-full bg-[#dbe4fc] p-4 mt-3 rounded-2xl relative grid-cols-2 divide-y
              divide-gray-700 dark:bg-[#0f172a]"
            >
              <div className="col-auto p-3 text-2xl">
                <div className="text-[10px] mb-1 font-bold text-txt-color-1 block md:hidden dark:text-white">
                  Referral Code:{' '}
                </div>
                <div className="flex items-center gap-3">
                  {ownerOnOtherChain && ownerOnOtherChain?.isTaken && !ownerOnOtherChain?.isTakenByCurrentUser && (
                    <TooltipWithPortal
                      position="right"
                      handle={<BiErrorCircle color="#e82e56" size={16} />}
                      renderContent={() => (
                        <div>
                          This code has been taken by someone else on {getChainDisplayName(chainId)}, you will not
                          receive rebates from traders using this code on {getChainDisplayName(chainId) ?? '-'}.
                        </div>
                      )}
                    />
                  )}

                  <div
                    onClick={() => {
                      copyToClipboard(`${homeUrl}/#/?${REFERRAL_CODE_QUERY_PARAM}=${stat.referralCode}`)
                      helperToast.success('Referral link copied to your clipboard')
                    }}
                    className="cursor-pointer"
                  >
                    <BiCopy />
                  </div>

                  <a href={getTwitterShareUrl(stat.referralCode)} target="_blank" rel="noopener noreferrer">
                    <FiTwitter />
                  </a>

                  <span className="border-l pl-3 ml-3 border-gray-300">{stat.referralCode}</span>

                  {ownerOnOtherChain && !ownerOnOtherChain?.isTaken && (
                    <div className="info">
                      <TooltipWithPortal
                        position="right"
                        handle={<IoWarningOutline color="#ffba0e" size={16} />}
                        renderContent={() => (
                          <div>
                            This code is not yet registered on {getChainDisplayName(chainId)}, you will not receive
                            rebates there.
                            <br />
                            <br />
                            Switch your network to create this code on {getChainDisplayName(chainId)}.
                          </div>
                        )}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-auto p-3 text-2xl" style={{ border: 0 }}>
                <div className="text-[10px] mb-1 font-bold text-txt-color-1 block md:hidden dark:text-white">
                  Total Volume:{' '}
                </div>
                {getUSDValue(stat.volume)}
              </div>
              <div className="col-auto p-3 text-2xl">
                <div className="text-[10px] mb-1 font-bold text-txt-color-1 block md:hidden dark:text-white">
                  Traders Referred:{' '}
                </div>
                {stat.registeredReferralsCount}
              </div>
              <div className="col-auto p-3 text-2xl">
                <div className="text-[10px] mb-1 font-bold text-txt-color-1 block md:hidden dark:text-white">
                  Total Rebates:{' '}
                </div>
                {getUSDValue(referrerRebate, 4)}
              </div>
            </div>
          )
        })}
      </div>

      <div className="w-full bg-bg-color-5 dark:bg-bg-dark-color-3 border border-bg-color-2 p-8 rounded-3xl text-3xl mt-16">
        <div className="mb-8 py-6 pt-0 border-b border-bg-color-2">
          <div className="font-bold text-3xl">Transactions</div>
        </div>

        <div className="grid-cols-[150px,1fr,1fr] gap-4 w-full px-4 mb-4 hidden md:grid dark:text-gray-300 text-2xl">
          <div className="col-auto text-left p-3">Date</div>
          <div className="col-auto text-left p-3">Amount</div>
          <div className="col-auto text-left p-3">Transaction</div>
        </div>

        {rebateDistributions?.length <= 0 ? (
          <EmptyMessage tooltipText="Rebates are airdropped weekly." message="No rebates distribution history yet." />
        ) : (
          rebateDistributions?.map((rebate, index) => {
            let tokenInfo
            try {
              tokenInfo = getToken(chainId, rebate.token)
            } catch {
              tokenInfo = getNativeToken(chainId)
            }
            const explorerURL = getExplorerUrl(chainId)
            return (
              <div
                key={index}
                className="grid border border-bg-color-2 items-center md:divide-y-0 md:grid-cols-[150px,1fr,1fr]
                      md:gap-4 w-full bg-[#dbe4fc] p-4 mt-3 rounded-2xl relative grid-cols-2 divide-y
                      divide-gray-700 dark:bg-[#0f172a]"
              >
                <div className="col-auto p-3 text-2xl">
                  <div className="text-[10px] mb-1 font-bold text-txt-color-1 block md:hidden dark:text-white">
                    Date:{' '}
                  </div>
                  {formatDate(rebate.timestamp)}
                </div>
                <div className="col-auto p-3 text-2xl" style={{ border: 0 }}>
                  <div className="text-[10px] mb-1 font-bold text-txt-color-1 block md:hidden dark:text-white">
                    Amount:{' '}
                  </div>
                  {formatAmount(rebate.amount, tokenInfo.decimals, 6, true)} {tokenInfo.symbol}
                </div>
                <div className="col-span-2 md:col-auto p-3 text-2xl">
                  <div className="text-[10px] mb-1 font-bold text-txt-color-1 block md:hidden dark:text-white">
                    Transaction:{' '}
                  </div>
                  <a target="_blank" rel="noopener noreferrer" href={explorerURL + `tx/${rebate.transactionHash}`}>
                    {shortenAddress(rebate.transactionHash, 13)}
                  </a>
                </div>
              </div>
            )
          })
        )}
      </div>
    </div>
  )
}

export default AffiliatesStats
