import { getContract } from '@tfx/addresses';
import { ChainId } from '@tfx/chains';
import { ethers } from 'ethers';

// src/tokens.ts
var tokens = {
  [ChainId.DEV_TESTNET]: [
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 18,
      isShortable: true,
      address: "0x9E4317b6a9e990D38A7D6850e00A794B49F77248",
      priceFeedIndex: "0",
      coingeckoId: "bitcoin"
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      isShortable: true,
      address: "0x0000000000000000000000000000000000000000",
      isNative: true,
      priceFeedIndex: "1",
      coingeckoId: "ethereum"
    },
    {
      name: "Binance Coin",
      symbol: "BNB",
      decimals: 18,
      address: "0x800f889DA4bba83E966536113F4b99461C1d35D2",
      isShortable: true,
      priceFeedIndex: "2",
      coingeckoId: "binancecoin"
    },
    {
      name: "Wrapped Ethereum",
      symbol: "WETH",
      decimals: 18,
      address: getContract(1112, "NATIVE_TOKEN"),
      isWrapped: true,
      isShortable: true,
      baseSymbol: "ETH",
      priceFeedIndex: "1",
      coingeckoId: "ethereum"
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 18,
      address: "0xa07C8259fBFa95FB7DaDc536030de6b1e9fA3e49",
      isStable: true,
      isShortable: false,
      priceFeedIndex: "3",
      coingeckoId: "tether"
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 18,
      address: "0x1eBfbAf2646Eb623334Ea2B5f45D18cDAc3dbfFE",
      isStable: true,
      isShortable: false,
      priceFeedIndex: "5",
      coingeckoId: "usd-coin"
    },
    {
      name: "Polygon",
      symbol: "MATIC",
      decimals: 18,
      address: "0xB5F3587C5a9D04233254dDf7f221AFeA444d7fA6",
      isShortable: true,
      priceFeedIndex: "21",
      coingeckoId: "matic-network"
    },
    {
      name: "Optimism",
      symbol: "OP",
      decimals: 18,
      address: "0x76806Bb3b09F5fE634a75B6990EfEde67133B7Cf",
      isShortable: true,
      priceFeedIndex: "28",
      coingeckoId: "optimism"
    },
    {
      name: "Arbitrum",
      symbol: "ARB",
      decimals: 18,
      address: "0xEA041B9a61d59E9A4D521Ee72F70Be29F0D43e9b",
      isShortable: true,
      priceFeedIndex: "29",
      coingeckoId: "arbitrum"
    }
  ],
  [ChainId.ARBITRUM_SEPOLIA]: [
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 18,
      isShortable: true,
      address: "0x3b0C46252B44bd40c54f1557970171b0D70d2e53",
      priceFeedIndex: "0",
      coingeckoId: "bitcoin"
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      isShortable: true,
      address: "0x0000000000000000000000000000000000000000",
      isNative: true,
      priceFeedIndex: "1",
      coingeckoId: "ethereum"
    },
    {
      name: "Wrapped Ethereum",
      symbol: "WETH",
      decimals: 18,
      address: getContract(421614, "NATIVE_TOKEN"),
      isWrapped: true,
      isShortable: true,
      baseSymbol: "ETH",
      priceFeedIndex: "1",
      coingeckoId: "ethereum"
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 18,
      address: "0xbceC86a186274C4215030b1a5903FAC944b540dd",
      isStable: true,
      isShortable: false,
      priceFeedIndex: "3",
      coingeckoId: "tether"
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 18,
      address: "0x539912979f90fb0eb170c61fCE865d1714659Cf8",
      isStable: true,
      isShortable: false,
      priceFeedIndex: "5",
      coingeckoId: "usd-coin"
    },
    {
      name: "Solana",
      symbol: "SOL",
      decimals: 18,
      address: "0xB80808027a0Ef560528bF8f1C7788a942bBc0CE7",
      isShortable: true,
      priceFeedIndex: "22",
      coingeckoId: "solana"
    },
    {
      name: "Optimism",
      symbol: "OP",
      decimals: 18,
      address: "0x1e0BBfD45acda3DafD0d9CC6AdC2B1F8Ba5C1b6A",
      isShortable: true,
      priceFeedIndex: "28",
      coingeckoId: "optimism"
    },
    {
      name: "Arbitrum",
      symbol: "ARB",
      decimals: 18,
      address: "0x45950C3dB9f713b10cfd1208A2f0007175697FED",
      isShortable: true,
      priceFeedIndex: "29",
      coingeckoId: "arbitrum"
    }
  ]
};

// src/constant.ts
var assetsUrl = "https://assets.tfx.market";

// src/helper.ts
var { AddressZero } = ethers.constants;
var ADDITIONAL_TOKENS = {};
var AVAILABLE_CHART_TOKENS = {
  [ChainId.DEV_TESTNET]: ["BTC", "ETH", "BNB", "USDT", "USDC", "MATIC", "OP", "ARB"],
  [ChainId.ARBITRUM_SEPOLIA]: ["BTC", "ETH", "USDT", "USDC", "OP", "ARB", "SOL"]
};
var CHAIN_IDS = [ChainId.DEV_TESTNET, ChainId.ARBITRUM_SEPOLIA];
var TOKENS_MAP = {};
var TOKENS_BY_SYMBOL_MAP = {};
for (const chainId of CHAIN_IDS) {
  TOKENS_MAP[chainId] = {};
  TOKENS_BY_SYMBOL_MAP[chainId] = {};
  let tokenList = tokens[chainId] ?? [];
  if (ADDITIONAL_TOKENS[chainId]) {
    tokenList = tokenList.concat(ADDITIONAL_TOKENS[chainId] ?? []);
  }
  for (const token of tokenList) {
    TOKENS_MAP[chainId][token.address] = token;
    TOKENS_BY_SYMBOL_MAP[chainId][token.symbol] = token;
  }
}
var WRAPPED_TOKENS_MAP = {};
var NATIVE_TOKENS_MAP = {};
for (const chainId of CHAIN_IDS) {
  for (const token of tokens[chainId] ?? []) {
    if (token.isWrapped) {
      WRAPPED_TOKENS_MAP[chainId] = token;
    } else if (token.isNative) {
      NATIVE_TOKENS_MAP[chainId] = token;
    }
  }
}
function getWrappedToken(chainId) {
  return WRAPPED_TOKENS_MAP[chainId];
}
function getNativeToken(chainId) {
  return NATIVE_TOKENS_MAP[chainId];
}
function getTokens(chainId) {
  return tokens[chainId] ?? [];
}
function getTokenSymbols(chainId, isWrapped = false) {
  var _a;
  return ((_a = tokens[chainId]) == null ? void 0 : _a.reduce(
    (acc, token) => {
      if (!token.isWrapped) {
        acc[token.address.toLowerCase()] = token.symbol;
      }
      if (isWrapped && token.isWrapped) {
        acc[token.address.toLowerCase()] = token.symbol;
      }
      return acc;
    },
    {}
  )) ?? [];
}
function isValidToken(chainId, address) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  return address in TOKENS_MAP[chainId];
}
function getToken(chainId, address) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  if (!TOKENS_MAP[chainId][address]) {
    throw new Error(`Incorrect address "${address}" for chainId ${chainId}`);
  }
  return TOKENS_MAP[chainId][address];
}
function getTokenBySymbol(chainId, symbol) {
  const token = TOKENS_BY_SYMBOL_MAP[chainId][symbol];
  if (!token) {
    throw new Error(`Incorrect symbol "${symbol}" for chainId ${chainId}`);
  }
  return token;
}
function getWhitelistedTokens(chainId) {
  var _a;
  return ((_a = tokens[chainId]) == null ? void 0 : _a.filter((token) => token.symbol !== "USDX")) ?? [];
}
function getVisibleTokens(chainId) {
  return getWhitelistedTokens(chainId).filter((token) => !token.isWrapped && !token.isTempHidden);
}
function getNormalizedTokenSymbol(tokenSymbol) {
  if (["WBTC", "WETH", "WBNB"].includes(tokenSymbol)) {
    return tokenSymbol.substr(1);
  }
  return tokenSymbol;
}
function isChartAvailableForToken(chainId, tokenSymbol) {
  var _a;
  const token = getTokenBySymbol(chainId, tokenSymbol);
  return (token.isStable || ((_a = AVAILABLE_CHART_TOKENS[chainId]) == null ? void 0 : _a.includes(getNormalizedTokenSymbol(tokenSymbol)))) ?? false;
}
var getTokenImgBySymbol = (symbol) => {
  return `${assetsUrl}/coins/${symbol.toLowerCase()}.svg`;
};
var getTokenAddress = (token, nativeTokenAddress) => {
  if (token.address === AddressZero) {
    return nativeTokenAddress;
  }
  return token.address;
};
var getTokenInfo = (infoTokens, tokenAddress, replaceNative, nativeTokenAddress) => {
  if (replaceNative && tokenAddress === nativeTokenAddress) {
    return infoTokens[AddressZero];
  }
  if (!infoTokens)
    return {};
  return infoTokens[tokenAddress.toString()];
};

export { AVAILABLE_CHART_TOKENS, getNativeToken, getNormalizedTokenSymbol, getToken, getTokenAddress, getTokenBySymbol, getTokenImgBySymbol, getTokenInfo, getTokenSymbols, getTokens, getVisibleTokens, getWhitelistedTokens, getWrappedToken, isChartAvailableForToken, isValidToken, tokens };
