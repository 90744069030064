import {
  BASIS_POINTS_DIVISOR,
  DEFAULT_MAX_USDG_AMOUNT,
  USD_DECIMALS,
  bigNumberify,
  formatAmount,
  formatKeyAmount,
} from 'utils'

import TooltipComponent from 'components/Tooltip/Popper'
import { Fragment } from 'react'
import { getCoinSGV } from 'utils/image'
import AssetDropdown from './AssetDropdown'
import { TooltipCardRow } from './TooltipCard'

type TokenListProps = {
  visibleTokens: any
  infoTokens: any
  getWeightText: any
}
const TokenList = ({ visibleTokens, infoTokens, getWeightText }: TokenListProps) => {
  return (
    <div className="py-10 mt-20">
      <h1 className="flex items-center gap-5 text-5xl font-bold mt-[2rem] mb-[3rem]">xLP Index Composition</h1>

      {/* <div className='col-span-2 bg-bg-color-5 border border-bg-color-2 dark:bg-bg-dark-color-2 p-6 rounded-3xl sm:flex block'> */}

      <div className="w-full text-2xl bg-bg-color-5 bg-opacity-50 dark:bg-bg-dark-color-2 rounded-3xl p-2 md:p-8 md:px-14">
        <div className="grid-cols-[1fr,1fr,1fr,1fr,100px] gap-4 w-full px-4 mb-6 hidden md:grid">
          <div className="col-auto text-left p-3">Token</div>
          <div className="col-auto text-left p-3">Price</div>
          <div className="col-auto text-left p-3">Pool</div>
          <div className="col-auto text-left p-3">Weight</div>
          <div className="col-auto text-left p-3">Utilization</div>
        </div>

        {visibleTokens.map((token) => {
          const tokenInfo = infoTokens[token.address]
          let utilization = bigNumberify(0)
          if (tokenInfo && tokenInfo.reservedAmount && tokenInfo.poolAmount && tokenInfo.poolAmount.gt(0)) {
            utilization = tokenInfo.reservedAmount.mul(BASIS_POINTS_DIVISOR).div(tokenInfo.poolAmount)
          }
          let maxUsdgAmount = DEFAULT_MAX_USDG_AMOUNT
          if (tokenInfo.maxUsdgAmount && tokenInfo.maxUsdgAmount.gt(0)) {
            maxUsdgAmount = tokenInfo.maxUsdgAmount
          }
          const tokenImage = getCoinSGV(token.symbol)

          return (
            <Fragment key={token.symbol}>
              <div className="grid border border-bg-color-2 items-center md:divide-y-0 md:grid-cols-[1fr,1fr,1fr,1fr,100px] md:gap-4 w-full bg-bg-color-5 p-4 py-2 mb-4 rounded-3xl relative grid-cols-2 divide-y divide-gray-700 dark:bg-bg-dark-color-3">
                <div className="md:col-auto col-span-2 p-3 text-2xl">
                  <div className="token-symbol-wrapper">
                    <div className="App-card-title-info">
                      <div className="App-card-title-info-icon">
                        <img src={tokenImage} alt={token.symbol} width="40px" />
                      </div>
                      <div className="App-card-title-info-text">
                        <div className="App-card-info-title">{token.name}</div>
                        <div className="App-card-info-subtitle">{token.symbol}</div>
                      </div>
                      <div>
                        <AssetDropdown assetSymbol={token.symbol} assetInfo={token} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-auto p-3 text-2xl">
                  <div className="text-[10px] font-bold text-txt-color-1 block md:hidden dark:text-white">Price: </div>$
                  {formatKeyAmount(tokenInfo, 'minPrice', USD_DECIMALS, 2, true)}
                </div>

                <div className="col-auto p-3 text-2xl">
                  <div className="text-[10px] font-bold text-txt-color-1 block md:hidden dark:text-white">Pool: </div>
                  <TooltipComponent
                    handle={`$${formatKeyAmount(tokenInfo, 'managedUsd', USD_DECIMALS, 0, true)}`}
                    renderContent={() => {
                      return (
                        <>
                          <TooltipCardRow
                            label="Pool Amount"
                            amount={`${formatKeyAmount(tokenInfo, 'managedAmount', token.decimals, 2, true)} ${
                              token.symbol
                            }`}
                            showDollar={false}
                          />
                          <TooltipCardRow
                            label="Target Min Amount"
                            amount={`${formatKeyAmount(tokenInfo, 'bufferAmount', token.decimals, 2, true)} ${
                              token.symbol
                            }`}
                            showDollar={false}
                          />
                          <TooltipCardRow
                            label={`Max ${tokenInfo.symbol} Capacity`}
                            amount={formatAmount(maxUsdgAmount, 18, 0, true)}
                            showDollar={true}
                          />
                        </>
                      )
                    }}
                  />
                </div>

                <div className="col-auto p-3 text-2xl">
                  <div className="text-[10px] font-bold text-txt-color-1 block md:hidden dark:text-white">Weight: </div>
                  {getWeightText(tokenInfo)}
                </div>
                <div className="col-auto p-3 text-2xl">
                  <div className="text-[10px] font-bold text-txt-color-1 block md:hidden dark:text-white">
                    Utilization:{' '}
                  </div>
                  {formatAmount(utilization, 2, 2, false)}%
                </div>
              </div>
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default TokenList
