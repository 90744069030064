import { useMemo } from 'react'
import Tooltip from 'components/Tooltip/Popper'
import { formatAmount, USD_DECIMALS } from 'utils'
import { BigNumber } from 'ethers'
import Panel from 'components/Panel'

import { Token } from 'utils/types'

type MarketBoxProps = {
  isLong: boolean
  isShort: boolean
  toToken: Token
  entryMarkPrice: BigNumber
  savedSlippageAmount: number
  exitMarkPrice: BigNumber
  borrowFeeText: string
  hasZeroBorrowFee: boolean
  toTokenInfo: any
}
const MarketBox = ({
  isLong,
  isShort,
  toToken,
  entryMarkPrice,
  savedSlippageAmount,
  exitMarkPrice,
  borrowFeeText,
  hasZeroBorrowFee,
  toTokenInfo,
}: MarketBoxProps) => {
  const renderAvailableLongLiquidity = useMemo(() => {
    if (!isLong) {
      return null
    }

    return (
      <div className="Exchange-info-row">
        <div className="Exchange-info-label">Available Liquidity</div>
        <div className="align-right">
          <Tooltip
            handle={`${formatAmount(toTokenInfo.maxAvailableLong, USD_DECIMALS, 2, true)}`}
            renderContent={() => {
              return (
                <>
                  Max {toTokenInfo.symbol} long capacity: $
                  {formatAmount(toTokenInfo.maxLongCapacity, USD_DECIMALS, 2, true)}
                  <br />
                  <br />
                  Current {toTokenInfo.symbol} long: ${formatAmount(toTokenInfo.guaranteedUsd, USD_DECIMALS, 2, true)}
                  <br />
                </>
              )
            }}
          />
        </div>
      </div>
    )
  }, [isLong, toTokenInfo])

  return (
    (isLong || isShort) && (
      <Panel title={`${isLong ? 'Long' : 'Short'} ${toToken.symbol}`}>
        <div className="Exchange-info-row">
          <div className="Exchange-info-label">Entry Price</div>
          <div className="align-right">
            <Tooltip
              handle={`${formatAmount(entryMarkPrice, USD_DECIMALS, 2, true)} USD`}
              renderContent={() => {
                return (
                  <>
                    The position will be opened at {formatAmount(entryMarkPrice, USD_DECIMALS, 2, true)} USD with a max
                    slippage of {parseFloat(`${savedSlippageAmount / 100.0}`).toFixed(2)}%.
                    <br />
                    <br />
                    The slippage amount can be configured under Settings, found by clicking on your address at the top
                    right of the page after connecting your wallet.
                    <br />
                    <br />
                    <a
                      href="https://tfx-market.gitbook.io/tfx/trading#opening-a-position"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline"
                    >
                      More Info
                    </a>
                  </>
                )
              }}
            />
          </div>
        </div>
        <div className="Exchange-info-row">
          <div className="Exchange-info-label">Exit Price</div>
          <div className="align-right">
            <Tooltip
              handle={`${formatAmount(exitMarkPrice, USD_DECIMALS, 2, true)} USD`}
              renderContent={() => {
                return (
                  <>
                    If you have an existing position, the position will be closed at{' '}
                    {formatAmount(entryMarkPrice, USD_DECIMALS, 2, true)} USD.
                    <br />
                    <br />
                    This exit price will change with the price of the asset.
                    <br />
                    <br />
                    <a
                      href="https://tfx-market.gitbook.io/tfx/trading#opening-a-position"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline"
                    >
                      More Info
                    </a>
                  </>
                )
              }}
            />
          </div>
        </div>
        <div className="Exchange-info-row">
          <div className="Exchange-info-label">Borrow Fee</div>
          <div className="align-right">
            <Tooltip
              handle={borrowFeeText}
              renderContent={() => {
                return (
                  <>
                    {hasZeroBorrowFee && (
                      <div>
                        {isLong && 'There are more shorts than longs, borrow fees for longing is currently zero'}
                        {isShort && 'There are more longs than shorts, borrow fees for shorting is currently zero'}
                      </div>
                    )}
                    {!hasZeroBorrowFee && (
                      <div>
                        The borrow fee is calculated as (assets borrowed) / (total assets in pool) * 0.01% per hour.
                        <br />
                        <br />
                        {isShort && `You can change the "Profits In" token above to find lower fees`}
                      </div>
                    )}
                    <a
                      href="https://tfx-market.gitbook.io/tfx/trading#opening-a-position"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline"
                    >
                      More Info
                    </a>
                  </>
                )
              }}
            />
          </div>
        </div>

        {renderAvailableLongLiquidity}

        {isShort && toTokenInfo.hasMaxAvailableShort && (
          <div className="Exchange-info-row">
            <div className="Exchange-info-label">Available Liquidity</div>
            <div className="align-right">
              <Tooltip
                handle={`${formatAmount(toTokenInfo.maxAvailableShort, USD_DECIMALS, 2, true)}`}
                renderContent={() => {
                  return (
                    <>
                      Max {toTokenInfo.symbol} short capacity: $
                      {formatAmount(toTokenInfo.maxGlobalShortSize, USD_DECIMALS, 2, true)}
                      <br />
                      <br />
                      Current {toTokenInfo.symbol} shorts: $
                      {formatAmount(toTokenInfo.globalShortSize, USD_DECIMALS, 2, true)}
                      <br />
                    </>
                  )
                }}
              />
            </div>
          </div>
        )}
      </Panel>
    )
  )
}

export default MarketBox
