import dayjs from 'dayjs'

export function isTimestampMoreThan24HoursAgo(timestamp: number): boolean {
  const timestampDate = dayjs.unix(timestamp)
  const currentTime = dayjs()
  // Calculate the difference in hours
  const hoursDifference = currentTime.diff(timestampDate, 'hour')
  // Check if the difference is greater than 24 hours
  return hoursDifference > 24
}
