import { useMemo } from 'react'
import Card from 'components/Card'
import { getContract } from '@tfx/addresses'
import { getConstant } from 'config/chains'
import { getToken, getTokens } from '@tfx/tokens'
import { useWeb3 } from 'hooks/useWeb3'
import useSWR from 'swr'
import {
  BASIS_POINTS_DIVISOR,
  XLP_DECIMALS,
  PLACEHOLDER_ACCOUNT,
  SECONDS_PER_YEAR,
  USD_DECIMALS,
  bigNumberify,
  expandDecimals,
  fetcher,
  formatAmount,
  formatKeyAmount,
  getStakingData,
  getTokenInfo,
  useChainId,
} from 'utils'
import { useGmxPrice, useInfoTokens } from 'utils/api'
import { usePrice } from 'utils/api/price'
import { useAum } from 'utils/api/stake'

import ReaderV2 from 'abis/Reader.json'
import RewardTracker from 'abis/RewardTracker.json'
import RewardReader from 'abis/RewardReader.json'
import { BigNumber, ethers } from 'ethers'

import TotalRewards from './TotalRewards'

const { AddressZero } = ethers.constants
const defaultValue = bigNumberify(0)

export default function SwapContent() {
  const { active, library, account } = useWeb3()
  const { chainId } = useChainId()

  const tokens = getTokens(chainId)
  const readerAddress = getContract(chainId, 'Reader')
  const usdxAddress = getContract(chainId, 'USDX')
  const xlpAddress = getContract(chainId, 'XLP')
  const feeXlpTrackerAddress = getContract(chainId, 'FeeXlpTracker')
  const rewardReaderAddress = getContract(chainId, 'RewardReader')
  const nativeTokenAddress = getContract(chainId, 'NATIVE_TOKEN')
  const nativeTokenSymbol = getConstant(chainId, 'nativeTokenSymbol')
  const wrappedTokenSymbol = getConstant(chainId, 'wrappedTokenSymbol')
  const rewardRouterAddress = getContract(chainId, 'RewardRouter')

  const tokensForBalanceAndSupplyQuery = [xlpAddress, usdxAddress]
  const tokenAddresses = tokens.map((token) => token.address)

  const { data: balancesAndSupplies } = useSWR(
    [
      `XlpSwap:getTokenBalancesWithSupplies:${active}`,
      chainId,
      readerAddress,
      'getTokenBalancesWithSupplies',
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: fetcher(library, ReaderV2, [tokensForBalanceAndSupplyQuery]),
    },
  )

  const { data: glpBalance } = useSWR(
    [`XlpSwap:glpBalance:${active}`, chainId, feeXlpTrackerAddress, 'stakedAmounts', account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(library, RewardTracker),
    },
  )

  const rewardTrackersForStakingInfo = [feeXlpTrackerAddress]
  const { data: stakingInfo } = useSWR(
    [`XlpSwap:stakingInfo:${active}`, chainId, rewardReaderAddress, 'getStakingInfo', account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
    },
  )

  const { data: tokenBalances } = useSWR(
    [`XlpSwap:getTokenBalances:${active}`, chainId, readerAddress, 'getTokenBalances', account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(library, ReaderV2, [tokenAddresses]),
    },
  )

  const nativeTokenPriceIndex = getToken(chainId, nativeTokenAddress)?.priceFeedIndex
  const [nativeTokenPrice] = usePrice(nativeTokenPriceIndex)
  const { gmxPrice } = useGmxPrice(chainId)
  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, undefined)
  const stakingData = getStakingData(stakingInfo)
  const nativeToken = getTokenInfo(infoTokens, AddressZero)
  const [aum] = useAum(library, chainId, active)

  const glpSupply = balancesAndSupplies ? balancesAndSupplies[1] : bigNumberify(0)

  const xlpPrice =
    aum && aum.gt(0) && glpSupply.gt(0)
      ? aum.mul(expandDecimals(1, XLP_DECIMALS)).div(glpSupply)
      : expandDecimals(1, USD_DECIMALS)

  const glpSupplyUsd = glpSupply.mul(xlpPrice).div(expandDecimals(1, XLP_DECIMALS))
  let glpBalanceUsd
  if (glpBalance) {
    glpBalanceUsd = glpBalance.mul(xlpPrice).div(expandDecimals(1, XLP_DECIMALS))
  }

  const feeGlpTrackerApr = useMemo((): BigNumber | undefined => {
    if (
      stakingData &&
      stakingData.FeeXlpTracker &&
      stakingData.FeeXlpTracker.tokensPerInterval &&
      nativeToken &&
      nativeToken.minPrice &&
      glpSupplyUsd &&
      glpSupplyUsd.gt(0)
    ) {
      const feeGlpTrackerAnnualRewardsUsd = stakingData.FeeXlpTracker.tokensPerInterval
        .mul(SECONDS_PER_YEAR)
        .mul(nativeToken.minPrice)
        .div(expandDecimals(1, 18))

      return feeGlpTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(glpSupplyUsd)
    }

    return defaultValue
  }, [glpSupplyUsd, nativeToken, stakingData])

  const processedData = useMemo(() => {
    if (!stakingData || !gmxPrice || !nativeTokenPrice)
      return {
        feeGlpTrackerRewards: defaultValue,
        feeGlpTrackerRewardsUsd: defaultValue,
      }
    const feeGlpTrackerRewardsUsd = stakingData.FeeXlpTracker.claimable
      ? stakingData.FeeXlpTracker.claimable.mul(nativeTokenPrice).div(expandDecimals(1, 18))
      : defaultValue

    return {
      feeGlpTrackerRewards: stakingData.FeeXlpTracker.claimable ?? defaultValue,
      feeGlpTrackerRewardsUsd,
    }
  }, [stakingData, gmxPrice, nativeTokenPrice])

  const calculateApy = (apr, compoundingFrequency = 12) => {
    try {
      const aprDecimal = +formatAmount(apr, 2, 2, false) / 100
      const apy = Math.pow(1 + aprDecimal / compoundingFrequency, compoundingFrequency) - 1
      const apyPercentage = (apy * 100).toFixed(2)

      return apyPercentage
    } catch (error) {
      return '0.00'
    }
  }

  const apy = useMemo(() => calculateApy(feeGlpTrackerApr), [feeGlpTrackerApr])

  return (
    <div className="mb-7">
      <Card className="px-10 lg:pl-16 py-7">
        <div className="flex gap-4 text-2xl flex-wrap">
          <div className="flex-1 min-w-[170px] py-2">
            <div>Total Supply</div>
            <div className="font-bold mt-2 text-3xl">{formatAmount(glpSupply, XLP_DECIMALS, 4, true)} xLP</div>
            <div className="opacity-70 text-lg">${formatAmount(glpSupplyUsd, USD_DECIMALS, 2, true)}</div>
          </div>
          <div className="flex-1 min-w-[170px] py-2">
            <div>Staked</div>
            <div className="font-bold mt-2 text-3xl">{formatAmount(glpBalance, XLP_DECIMALS, 4, true)} xLP</div>
            <div className="opacity-70 text-lg">${formatAmount(glpBalanceUsd, USD_DECIMALS, 2, true)}</div>
          </div>
          <div className="flex-1 min-w-[170px] py-2">
            <div>APY</div>
            <div className="font-bold mt-2 text-3xl">{apy}%</div>
            <div className="opacity-70 text-lg">APR {formatAmount(feeGlpTrackerApr, 2, 2, false)}%</div>
          </div>
          <div className="flex-1 min-w-[170px] py-2">
            <div>Reward</div>
            <div className="font-bold mt-2 text-3xl">
              ${formatKeyAmount(processedData, 'feeGlpTrackerRewardsUsd', USD_DECIMALS, 2, true)}
            </div>
            <div className="opacity-70 text-lg">
              {nativeTokenSymbol} {formatKeyAmount(processedData, 'feeGlpTrackerRewards', 18, 4)}
            </div>
          </div>
          <div className="w-[150px] self-center py-2">
            <TotalRewards
              nativeTokenSymbol={nativeTokenSymbol}
              wrappedTokenSymbol={wrappedTokenSymbol}
              processedData={processedData}
              chainId={chainId}
              rewardRouterAddress={rewardRouterAddress}
              disabled={processedData.feeGlpTrackerRewardsUsd.isZero() || !active}
            />
          </div>
        </div>
      </Card>
    </div>
  )
}
