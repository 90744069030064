import { useAnalytic } from 'components/Analytic'
import { GA_EVENT_KEY } from 'components/Analytic/constants'
import { BigNumber } from 'ethers'
import { USD_DECIMALS, numberWithCommas } from 'utils'
import { nFormatter } from 'utils/number'
import costIcon from 'img/ic_bank.png'
import liquidityIcon from 'img/ic_diamond.png'
import simpleSwapIcon from 'img/ic_bitcoin.png'
import { getExternalImg } from 'utils/image'

type HeroSectionProps = {
  totalVolume: BigNumber
  openInterest: BigNumber
  totalUsers: BigNumber
}

const HeroSection = ({ totalVolume, openInterest, totalUsers }: HeroSectionProps) => {
  const { sendEvent } = useAnalytic()

  return (
    <>
      <div
        className="absolute w-full h-[80vh] overflow-hidden top-0 l-0 z-10 bg-no-repeat bg-top bg-cover md:bg-contain"
        style={{
          backgroundImage: `url(${getExternalImg('hero_bg')})`,
        }}
      />
      <div className="m-auto max-w-[1536px] w-full px-[2.2rem] z-20 relative">
        <div className="h-[55vh] md:h-[60vh] border-red-100 z-20">
          <div className="flex flex-col items-start justify-center lg:items-center lg:justify-center h-[65vh] md:h-[70vh]">
            <h1 className="mt-2 text-[4rem] leading-[4rem] sm:text-[4.8rem] font-bold mb-2 sm:leading-[5rem]">
              Decentralized <br className="block lg:hidden" /> Perpetual <br className="block lg:hidden" /> Exchange
            </h1>
            <h3 className="text-2xl pt-2">
              Trade BTC, ETH, BNB and other top <br className="block sm:hidden" /> cryptocurrencies with up to 50x
              leverage <br className="block lg:hidden" /> directly from your wallet
            </h3>

            <div className="flex mt-10 items-center gap-10">
              <a
                className="text-[1.8rem] p-6 px-[3rem] rounded-2xl font-bold bg-bg-color-6 text-white border-0 cursor-pointer"
                onClick={() => {
                  sendEvent({
                    category: GA_EVENT_KEY.DASHBOARD.CATEGORY,
                    action: GA_EVENT_KEY.DASHBOARD.ACTION.CLICK_TRADE_BUTTON,
                  })
                }}
                href="#/trade"
              >
                Trade Now
              </a>
            </div>
          </div>
        </div>

        <div className="mt-[5rem] mb-[10rem]">
          <div className="flex my-16 flex-wrap items-center gap-3 justify-center">
            <div className="px-6 flex-1 text-[2.3rem] font-bold min-w-[160px] max-w-[300px] text-center">
              <div className="text-[2.8rem] sm:text-[4.5rem] font-bold">
                ${nFormatter(Number(totalVolume) / 10 ** USD_DECIMALS, 1)}+
              </div>
              <div className="tracking-tighter text-2xl">Total Trading Volume</div>
            </div>
            <div className="px-6 flex-1 text-[2.3rem] font-bold min-w-[160px] max-w-[300px] text-center">
              <div className="text-[2.8rem] sm:text-[4.5rem] font-bold">
                ${nFormatter(Number(openInterest) / 10 ** USD_DECIMALS, 1)}+
              </div>
              <div className="tracking-tighter text-2xl">Open Interest</div>
            </div>
            <div className="px-6 flex-1 text-[2.3rem] font-bold min-w-[160px] max-w-[300px] text-center">
              <div className="text-[2.8rem] sm:text-[4.5rem] font-bold">
                {numberWithCommas(totalUsers?.toNumber())}+
              </div>
              <div className="tracking-tighter text-2xl">Total Users</div>
            </div>
          </div>
        </div>

        <div className="default-container">
          <div className="bg-bg-color-5 dark:bg-bg-dark-color-3 border border-bg-color-2 p-8 rounded-[2.4rem] text-3xl">
            <div className="flex gap-10 flex-col md:flex-row">
              <div className="flex-1">
                <h4 className="text-[2rem] font-bold py-4 flex items-center gap-5">
                  <img src={liquidityIcon} className="h-[40px] w-[40px]" alt="liquidity" />
                  Real Yield
                </h4>
                <p className="text-gray-400">
                  Protocol revenue share paid out to Community users, The yields are real protocol fees, they are not
                  from governance token farming, and are intended for sustainable growth.
                </p>
              </div>

              <div className="flex-1">
                <h4 className="text-[2rem] font-bold py-4 flex items-center gap-5">
                  <img src={costIcon} alt="cost" className="Home-benefit-icon-symbol h-[40px] w-[40px]" />
                  Protocol-owned liquidity (POL)
                </h4>
                <p className="text-gray-400">
                  All of the liquidity (xLP) in the vault is provided by Templar DAO, keeping the protocol always
                  liquid, low cost, and sustainable.
                </p>
              </div>

              <div className="flex-1">
                <h4 className="text-[2rem] font-bold py-4 flex items-center gap-5">
                  <img src={simpleSwapIcon} alt="simpleswap" className="Home-benefit-icon-symbol h-[40px] w-[40px]" />
                  Vault Strategy
                </h4>
                <p className="text-gray-400">
                  The assets liquidity (xLP) in the vault is used for yield farming on reliable DEXs. That maximizes
                  protocol liquidity efficiency and provides the highest possible return to xLP providers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeroSection
