export const GA_EVENT_KEY = {
  TRADE: {
    CATEGORY: 'trade',
    ACTION: {
      CHANGE_TRADE_TAB: 'change_trade_tab',
      CHANGE_ORDER_OPTION: 'change_order_option',
      CHANGE_FROM_TOKEN: 'change_from_token',
      CHANGE_TO_TOKEN: 'change_to_token',
      CLICK_FROM_MAX_VALUE: 'click_from_max_value',
      CHECK_LEVERAGE_SLIDER: 'check_leverage_slider',
      MOVE_LEVERAGE_SLIDER_END: 'move_leverage_slider_end',
    },
  },
  EARN: {
    CATEGORY: 'earn',
    ACTION: {
      CLICK_BUY_XLP_BUTTON: 'click_buy_XLP_BUTTON',
      CLICK_SELL_XLP_BUTTON: 'click_sell_XLP_BUTTON',
    },
  },
  BUY_XLP: {
    CATEGORY: 'buy_xlp',
    ACTION: {
      CLICK_TAB_BUY: 'click_tab_buy',
      CLICK_TAB_SELL: 'click_tab_sell',
      SELECT_PAY_TOKEN: 'select_pay_token',
      SELECT_SELL_TOKEN: 'select_sell_token',
      SELECT_PAY_TOKEN_ON_FEE_TABLE: 'select_pay_token_on_fee_table',
      SELECT_SELL_TOKEN_ON_FEE_TABLE: 'select_sell_token_on_fee_table',
      SUBMIT_BUY: 'submit_buy_xlp',
      SUBMIT_SELL: 'submit_sell_xlp',
    },
  },
  DASHBOARD: {
    CATEGORY: 'dashboard',
    ACTION: {
      CLICK_TRADE_BUTTON: 'click_trade_button',
      CLICK_BUY_XLP_BUTTON: 'click_buy_xlp_button',
    },
  },
  NAVBAR: {
    CATEGORY: 'navbar',
    ACTION: {
      SUCCESS_REDEEM_BUSD: 'success_redeem_busd_faucet',
      FAIL_REDEEM_BUSD: 'fail_redeem_busd_faucet',
    },
  },
  SETTING: {
    CATEGORY: 'setting',
    ACTION: {
      CLICK_SAVE: 'click_save',
    },
  },
  REFERRAL: {
    CATEGORY: 'referral',
    ACTION: {
      SUBMIT_CREATE_CODE: 'submit_create_code',
      SUBMIT_ENTER_CODE: 'submit_enter_code',
    },
  },
}
