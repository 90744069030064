import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import './AssetDropdown.css'

import arbitrumSepoliaIcon from 'img/ic_arb_testnet_16.svg'
import metamaskIcon from 'img/ic_metamask_16.svg'

import { useWeb3 } from 'hooks/useWeb3'
import { addTokenToMetamask, ARBITRUM_SEPOLIA, DEV_TESTNET, ICONLINKS, platformTokens, useChainId } from 'utils'

function AssetDropdown({ assetSymbol, assetInfo }) {
  const { active } = useWeb3()
  const { chainId } = useChainId()
  let { devTestnet } = ICONLINKS[chainId][assetSymbol]

  const unavailableTokenSymbols = {
    [DEV_TESTNET]: ['ETH'],
    [ARBITRUM_SEPOLIA]: ['ETH'],
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div className="self-center">
        <Menu.Button as="div" className="dropdown-arrow center-both">
          <FiChevronDown size={15} className="text-black dark:text-white" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items as="div" className="asset-menu-items w-[150px] right-0">
          <Menu.Item>
            <>
              {devTestnet && (
                <a href={devTestnet} className="asset-item" target="_blank" rel="noopener noreferrer">
                  <img src={arbitrumSepoliaIcon} alt="Open in Linea Testnet explorer" className="w-[16px]" />
                  <p>Open in Explorer</p>
                </a>
              )}
            </>
          </Menu.Item>

          <Menu.Item>
            <>
              {active && unavailableTokenSymbols[chainId].indexOf(assetSymbol) < 0 && (
                <div
                  onClick={() => {
                    let token = assetInfo
                      ? { ...assetInfo, image: assetInfo.imageUrl }
                      : platformTokens[chainId][assetSymbol]

                    addTokenToMetamask(token)
                  }}
                  className="asset-item"
                >
                  <img src={metamaskIcon} alt="Add to Metamask" />
                  <p>Add to Metamask</p>
                </div>
              )}
            </>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default AssetDropdown
