import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

const ThemeContext = createContext<any>(null)

type Theme = 'dark' | 'light'

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState<Theme>('dark')
  const [mounted, setMounted] = useState(false)

  const setMode = (mode: Theme) => {
    window.localStorage.setItem('theme', mode)
    setTheme(mode)
  }

  useEffect(() => {
    const localTheme = window.localStorage.getItem('theme') as Theme
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches && !localTheme) {
      setMode('dark')
      document.documentElement.classList.add('dark')
    } else if (localTheme) {
      setTheme(localTheme)
      document.documentElement.classList.add(localTheme)
    } else {
      setMode('dark')
      document.documentElement.classList.add('dark')
    }
    setMounted(true)
  }, [])

  const toggleTheme = useCallback(
    (e: KeyboardEvent) => {
      e.stopPropagation()
      if (theme === 'light') {
        document.documentElement.classList.add('dark')
        setMode('dark')
      } else {
        document.documentElement.classList.remove('dark')
        setMode('light')
      }
    },
    [theme],
  )

  const value = useMemo(() => {
    return {
      theme,
      mounted,
      toggleTheme,
    }
  }, [theme, mounted, toggleTheme])

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}

const useTheme = (): any => {
  const context = useContext(ThemeContext)
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }
  return context
}

export { ThemeProvider, useTheme }
