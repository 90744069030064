import { useMemo } from 'react'
import { gql } from '@apollo/client'
import useSWR from 'swr'
import { ethers } from 'ethers'

import { USD_DECIMALS, CHART_PERIODS, formatAmount, sleep } from 'utils'
import { chainlinkClient, priceCandleApiUrl } from 'utils/api/common'
import {
  Token,
  getNativeToken,
  getNormalizedTokenSymbol,
  getToken,
  getTokenBySymbol,
  getWrappedToken,
  isChartAvailableForToken,
} from '@tfx/tokens'
import { priceFetching } from './api/price'

const BigNumber = ethers.BigNumber

// Ethereum network, Chainlink Aggregator contracts
const FEED_ID_MAP = {
  BTC_USD: '0xae74faa92cb67a95ebcab07358bc222e33a34da7',
  ETH_USD: '0x37bc7498f4ff12c19678ee8fe19d713b87f6a9e6',
  BNB_USD: '0xc45ebd0f901ba6b2b8c7e70b717778f055ef5e6d',
  DOGE_USD: '0x33cca8e7420114db103d61bd39a72ff65e46352d',
}
export const timezoneOffset = -new Date().getTimezoneOffset() * 60

function formatBarInfo(bar) {
  const { t, o: open, c: close, h: high, l: low } = bar
  return {
    time: t + timezoneOffset,
    open,
    close,
    high,
    low,
  }
}

export function fillGaps(prices, periodSeconds) {
  if (prices.length < 2) {
    return prices
  }

  const newPrices = [prices[0]]
  let prevTime = prices[0].time
  for (let i = 1; i < prices.length; i++) {
    const { time, open } = prices[i]
    if (prevTime) {
      let j = (time - prevTime) / periodSeconds - 1
      while (j > 0) {
        newPrices.push({
          time: time - j * periodSeconds,
          open,
          close: open,
          high: open * 1.0003,
          low: open * 0.9996,
        })
        j--
      }
    }

    prevTime = time
    newPrices.push(prices[i])
  }

  return newPrices
}

export async function getLimitChartPricesFromStats(chainId, symbol, period, limit = 1) {
  symbol = getNormalizedTokenSymbol(symbol)

  if (!isChartAvailableForToken(chainId, symbol)) {
    symbol = getNativeToken(chainId)?.symbol
  }

  const url = `${priceCandleApiUrl}/candles/${symbol}?period=${period}&limit=${limit}`

  try {
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    const prices = await response.json().then(({ prices }) => prices)
    return prices.map(formatBarInfo)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`Error fetching data: ${error}`)
  }
}

export async function getChartPricesFromStats(chainId, symbol, period, from?: number, to?: number) {
  symbol = getNormalizedTokenSymbol(symbol)

  let limit = ''
  if (!from && !to) {
    const timeDiff = CHART_PERIODS[period] * 10000
    from = Math.floor(Date.now() / 1000 - timeDiff)
    to = Math.floor(Date.now() / 1000)
    limit = '&limit=1000'
  }
  const url = `${priceCandleApiUrl}/candles/${symbol}?period=${period}&from=${from}&to=${to}&preferableSource=fast${limit}`

  const TIMEOUT = 20000
  const res: Response = await new Promise(async (resolve, reject) => {
    let done = false
    setTimeout(() => {
      done = true
      reject(new Error(`request timeout ${url}`))
    }, TIMEOUT)

    let lastEx
    for (let i = 0; i < 3; i++) {
      if (done) return
      try {
        const res = await fetch(url)
        resolve(res)
        return
      } catch (ex) {
        await sleep(300)
        lastEx = ex
      }
    }
    reject(lastEx)
  })
  if (!res.ok) {
    throw new Error(`request failed ${res.status} ${res.statusText}`)
  }
  const json = await res.json()
  let prices = json?.prices
  if (!prices || prices.length < 1) {
    throw new Error(`not enough prices data: ${prices?.length}`)
  }

  const OBSOLETE_THRESHOLD = Date.now() / 1000 - 60 * 30 // 30 min ago
  const updatedAt = json?.updatedAt || 0
  if (updatedAt < OBSOLETE_THRESHOLD) {
    throw new Error(
      'chart data is obsolete, last price record at ' +
        new Date(updatedAt * 1000).toISOString() +
        ' now: ' +
        new Date().toISOString(),
    )
  }
  prices = prices.reverse().map(formatBarInfo)
  return prices
}

function getCandlesFromPrices(prices, period) {
  const periodTime = CHART_PERIODS[period]

  if (prices.length < 2) {
    return []
  }

  const candles: any[] = []
  const first = prices[0]
  let prevTsGroup = Math.floor(first[0] / periodTime) * periodTime
  let prevPrice = first[1]
  let o = prevPrice
  let h = prevPrice
  let l = prevPrice
  let c = prevPrice
  for (let i = 1; i < prices.length; i++) {
    const [ts, price] = prices[i]
    const tsGroup = Math.floor(ts / periodTime) * periodTime
    if (prevTsGroup !== tsGroup) {
      candles.push({ t: prevTsGroup + timezoneOffset, o, h, l, c })
      o = c
      h = Math.max(o, c)
      l = Math.min(o, c)
    }
    c = price
    h = Math.max(h, price)
    l = Math.min(l, price)
    prevTsGroup = tsGroup
  }

  return candles.map(({ t: time, o: open, c: close, h: high, l: low }) => ({
    time,
    open,
    close,
    high,
    low,
  }))
}

export function getChainlinkChartPricesFromGraph(tokenSymbol, period) {
  tokenSymbol = getNormalizedTokenSymbol(tokenSymbol)
  const marketName = tokenSymbol + '_USD'
  const feedId = FEED_ID_MAP[marketName]
  if (!feedId) {
    throw new Error(`undefined marketName ${marketName}`)
  }

  const PER_CHUNK = 1000
  const CHUNKS_TOTAL = 6
  const requests: any[] = []
  for (let i = 0; i < CHUNKS_TOTAL; i++) {
    const query = gql(`{
      rounds(
        first: ${PER_CHUNK},
        skip: ${i * PER_CHUNK},
        orderBy: unixTimestamp,
        orderDirection: desc,
        where: {feed: "${feedId}"}
      ) {
        unixTimestamp,
        value
      }
    }`)
    requests.push(chainlinkClient.query({ query }))
  }

  return Promise.all(requests)
    .then((chunks) => {
      let prices: any[] = []
      const uniqTs = new Set()
      chunks.forEach((chunk) => {
        chunk.data.rounds.forEach((item) => {
          if (uniqTs.has(item.unixTimestamp)) {
            return
          }

          uniqTs.add(item.unixTimestamp)
          prices.push([item.unixTimestamp, Number(item.value) / 1e8])
        })
      })

      prices.sort(([timeA], [timeB]) => timeA - timeB)
      prices = getCandlesFromPrices(prices, period)
      return prices
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err)
    })
}

export function useChartPrices(chainId, symbol, isStable, period, currentAveragePrice) {
  const swrKey = !isStable && symbol ? ['getChartCandles', chainId, symbol, period] : null
  let { data: prices, mutate: updatePrices } = useSWR(swrKey, {
    fetcher: async (...args) => {
      try {
        return await getChartPricesFromStats(chainId, symbol, period)
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.warn(ex)
        // eslint-disable-next-line no-console
        console.warn('Switching to graph chainlink data')
        try {
          return await getChainlinkChartPricesFromGraph(symbol, period)
        } catch (ex2) {
          // eslint-disable-next-line no-console
          console.warn('getChainlinkChartPricesFromGraph failed')
          // eslint-disable-next-line no-console
          console.warn(ex2)
          return []
        }
      }
    },
    dedupingInterval: 60000,
    focusThrottleInterval: 60000 * 10,
  })

  const currentAveragePriceString = currentAveragePrice && currentAveragePrice.toString()
  const retPrices = useMemo(() => {
    if (isStable) {
      return getStablePriceData(period)
    }

    if (!prices) {
      return []
    }

    let _prices = [...prices]
    if (currentAveragePriceString && prices.length) {
      _prices = appendCurrentAveragePrice(_prices, BigNumber.from(currentAveragePriceString), period)
    }

    return fillGaps(_prices, CHART_PERIODS[period])
  }, [prices, isStable, currentAveragePriceString, period])

  return [retPrices, updatePrices]
}

function appendCurrentAveragePrice(prices, currentAveragePrice, period) {
  const periodSeconds = CHART_PERIODS[period]
  const currentCandleTime = Math.floor(Date.now() / 1000 / periodSeconds) * periodSeconds + timezoneOffset
  const last = prices[prices.length - 1]
  const averagePriceValue = parseFloat(formatAmount(currentAveragePrice, USD_DECIMALS, 2))
  if (currentCandleTime === last.time) {
    last.close = averagePriceValue
    last.high = Math.max(last.open, last.high, averagePriceValue)
    last.low = Math.min(last.open, last.low, averagePriceValue)
    return prices
  } else {
    const newCandle = {
      time: currentCandleTime,
      open: last.close,
      close: averagePriceValue,
      high: averagePriceValue,
      low: averagePriceValue,
    }
    return [...prices, newCandle]
  }
}

export function getStablePriceData(period, countBack = 100) {
  const periodSeconds = CHART_PERIODS[period]
  const now = Math.floor(Date.now() / 1000 / periodSeconds) * periodSeconds
  let priceData: any = []
  for (let i = countBack; i > 0; i--) {
    priceData.push({
      time: now - i * periodSeconds,
      open: 1,
      close: 1,
      high: 1,
      low: 1,
    })
  }
  return priceData
}

function getCurrentBarTimestamp(periodSeconds) {
  return Math.floor(Date.now() / (periodSeconds * 1000)) * (periodSeconds * 1000)
}

export const getTokenChartPrice = async (chainId: number, symbol: string, period: string, from: number, to: number) => {
  let prices
  try {
    prices = await getChartPricesFromStats(chainId, symbol, period, from, to)
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.warn(ex, 'Switching to graph chainlink data')
    try {
      prices = await getChainlinkChartPricesFromGraph(symbol, period)
    } catch (ex2) {
      // eslint-disable-next-line no-console
      console.warn('getChainlinkChartPricesFromGraph failed', ex2)
      prices = []
    }
  }
  return prices
}

export async function getCurrentPriceOfToken(chainId: number, symbol: string) {
  try {
    let symbolInfo: Token | undefined = getTokenBySymbol(chainId, symbol)
    const indexPrices = await priceFetching()
    if (symbolInfo.isNative) {
      symbolInfo = getWrappedToken(chainId)
    }
    const tokenPriceIndex = getToken(chainId, symbolInfo?.address ?? '')?.priceFeedIndex
    return indexPrices[tokenPriceIndex]
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)
  }
}

export function fillBarGaps(prices, periodSeconds) {
  if (prices.length < 2) return prices

  const currentBarTimestamp = getCurrentBarTimestamp(periodSeconds) / 1000 + timezoneOffset
  let lastBar = prices[prices.length - 1]

  if (lastBar.time !== currentBarTimestamp) {
    prices.push({
      ...lastBar,
      time: currentBarTimestamp,
    })
  }

  const newPrices = [prices[0]]
  let prevTime = prices[0].time

  for (let i = 1; i < prices.length; i++) {
    const { time, open } = prices[i]
    if (prevTime) {
      const numBarsToFill = Math.floor((time - prevTime) / periodSeconds) - 1
      for (let j = numBarsToFill; j > 0; j--) {
        const newBar = {
          time: time - j * periodSeconds,
          open,
          close: open,
          high: open * 1.0003,
          low: open * 0.9996,
        }
        newPrices.push(newBar)
      }
    }
    prevTime = time
    newPrices.push(prices[i])
  }
  return newPrices
}

export function getStableCoinPrice(period: string, from: number, to: number) {
  const periodSeconds = CHART_PERIODS[period]
  const fromCandle = Math.floor(from / periodSeconds) * periodSeconds
  const toCandle = Math.floor(to / periodSeconds) * periodSeconds
  let priceData: any = []
  for (let candleTime = fromCandle; candleTime <= toCandle; candleTime += periodSeconds) {
    priceData.push({
      time: candleTime,
      open: 1,
      close: 1,
      high: 1,
      low: 1,
    })
  }
  return priceData.filter((candle) => candle.time >= from && candle.time <= to)
}
