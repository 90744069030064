import cx from 'classnames'

import { NavLink } from 'react-router-dom'

import { getContract } from '@tfx/addresses'
import { useAllOrders, useAllOrdersStats, usePositionsForOrders } from 'utils/api'
import {
  USD_DECIMALS,
  DECREASE,
  INCREASE,
  SWAP,
  useChainId,
  formatAmount,
  shortenAddress,
  getTokenInfo,
  getExchangeRateDisplay,
  getExchangeRate,
  shouldInvertTriggerRatio,
  formatDateTime,
  getOrderKey,
} from 'utils'

import * as Api from 'utils/api'

import './OrdersOverview.css'
import { BiErrorCircle } from 'react-icons/bi'
import { useWeb3 } from 'hooks/useWeb3'
import { Footer } from '@tfx/ui'
import Tooltip from 'components/Tooltip/Popper'
import { MdAutoGraph, MdSwapHoriz, MdTrendingDown, MdTrendingUp } from 'react-icons/md'

export default function OrdersOverview() {
  const { chainId } = useChainId()
  const { library, account, active } = useWeb3()

  const nativeTokenAddress = getContract(chainId, 'NATIVE_TOKEN')

  const { infoTokens } = Api.useInfoTokens(library, chainId, active, undefined, undefined)

  const orders = useAllOrders(chainId, library)
  const stats = useAllOrdersStats(chainId)

  const positionsForOrders = usePositionsForOrders(
    chainId,
    library,
    orders.filter((order) => order.type === DECREASE),
  )

  let openTotal
  let executedTotal
  let cancelledTotal

  if (stats) {
    openTotal = Math.max(0, stats.openDecrease + stats.openIncrease + stats.openSwap)
    executedTotal = Math.max(0, stats.executedDecrease + stats.executedIncrease + stats.executedSwap)
    cancelledTotal = Math.max(0, stats.cancelledDecrease + stats.cancelledIncrease + stats.cancelledSwap)
  }

  const NEAR_TRESHOLD = 98

  const executeOrder = (evt, order) => {
    evt.preventDefault()

    const params = [chainId, library, order.account, order.index, account]
    let method
    if (order.type === 'Swap') {
      method = 'executeSwapOrder'
    } else if (order.type === 'Increase') {
      method = 'executeIncreaseOrder'
    } else {
      method = 'executeDecreaseOrder'
    }
    return Api[method](...params)
  }

  const statsList = [
    {
      icon: <MdAutoGraph size={18} />,
      title: 'Total',
      active: openTotal,
      excuted: executedTotal,
      cancelled: cancelledTotal,
    },
    {
      icon: <MdTrendingUp size={18} />,
      title: 'Increase',
      active: Math.max(0, stats?.openIncrease),
      excuted: stats?.executedIncrease ?? 0,
      cancelled: stats?.cancelledIncrease ?? 0,
    },
    {
      icon: <MdTrendingDown size={18} />,
      title: 'Decrease',
      active: Math.max(0, stats?.openDecrease),
      excuted: stats?.executedDecrease ?? 0,
      cancelled: stats?.cancelledDecrease ?? 0,
    },
    {
      icon: <MdSwapHoriz size={18} />,
      title: 'Swap',
      active: Math.max(0, stats?.openSwap),
      excuted: stats?.executedSwap ?? 0,
      cancelled: stats?.cancelledSwap ?? 0,
    },
  ]

  return (
    <div className="default-container page-layout">
      <h1 className="text-[3rem] font-bold mb-5">Order Overview</h1>
      <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
        {stats &&
          statsList.map((stats, index) => (
            <div
              key={`stats-total-${index}`}
              className="overflow-hidden rounded-[1.75rem] bg-white dark:bg-bg-dark-color-2 dark:border-bg-dark-color-2 shadow"
            >
              <div className="bg-white border dark:bg-bg-dark-color-2 dark:border-bg-dark-color-2 p-6">
                <div className="flex gap-2 items-center mt-0 pt-1 text-left">
                  {stats.icon}
                  <p className="font-bold dark:text-whitetext-lg">{stats.title}</p>
                </div>
              </div>
              <div className="grid divide-y divide-bg-dark-color-2 border-t border-gray-200 dark:border-bg-dark-color-2 bg-gray-50 dark:bg-bg-dark-color-2 grid-cols-3 sm:divide-y-0 sm:divide-x">
                <div className="px-6 py-5 text-center text-lg font-medium flex flex-col">
                  <span className="text-gray-900 dark:text-white">{stats.active}</span>
                  <span className="text-gray-600 dark:text-gray-300">Active</span>
                </div>
                <div className="px-6 py-5 text-center text-lg font-medium flex flex-col">
                  <span className="text-gray-900 dark:text-white">{stats.excuted}</span>
                  <span className="text-gray-600 dark:text-gray-300">Executed</span>
                </div>
                <div className="px-6 py-5 text-center text-lg font-medium flex flex-col">
                  <span className="text-gray-900 dark:text-white">{stats.cancelled}</span>
                  <span className="text-gray-600 dark:text-gray-300">Cancelled</span>
                </div>
              </div>
            </div>
          ))}
      </div>

      <div className="flex gap-3 flex-wrap text-lg mt-8">
        <div className="flex items-center gap-1">
          <span className="block h-4 w-4 rounded-full bg-green-500" />
          <span className="text-gray-900 dark:text-white">Price conditions are met</span>
        </div>
        <div className="flex items-center gap-1">
          <span className="block h-4 w-4 rounded-full bg-orange-500" />
          <span className="text-gray-900 dark:text-white">Close to execution price</span>
        </div>
        <div className="flex items-center gap-1">
          <span className="block h-4 w-4 rounded-full bg-red-500" />
          <span className="text-gray-900 dark:text-white">Can't execute because of an error</span>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full text-xl border-separate border-spacing-y-4 min-w-[1100px] mt-2">
          <thead>
            <tr>
              <th scope="col" className="px-3 py-3.5 text-left font-semibold">
                Type
              </th>
              <th scope="col" colSpan={2} className="px-3 py-3.5 text-left font-semibold">
                Order
              </th>
              <th scope="col" className="px-3 py-3.5 text-left font-semibold">
                Price
              </th>
              <th scope="col" className="px-3 py-3.5 text-left font-semibold">
                Mark Price
              </th>
              <th scope="col" className="px-3 py-3.5 text-left font-semibold">
                Diff
              </th>
              <th scope="col" className="px-3 py-3.5 text-left font-semibold">
                Account
              </th>
              <th scope="col" className="px-3 py-3.5 text-left font-semibold">
                Created At
              </th>
              <th scope="col" className="px-3 py-3.5 text-left font-semibold">
                Index
              </th>
              <th scope="col" className="px-3 py-3.5 text-left font-semibold" colSpan={2} />
            </tr>
          </thead>

          {!orders || !orders?.length ? (
            <tbody>
              <tr>
                <td colSpan={9} className="text-left md:text-center py-[8rem] border-t dark:border-bg-dark-color-2">
                  No orders
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {orders.map((order) => {
                const { type } = order
                const key = getOrderKey(order)
                if (type === SWAP) {
                  const fromToken = getTokenInfo(infoTokens, order.path[0], true, nativeTokenAddress)
                  const toTokenAddress = order.path[order.path.length - 1]
                  const toToken = getTokenInfo(infoTokens, toTokenAddress, order.shoudUnwrap, nativeTokenAddress)

                  let markExchangeRate
                  let prefix
                  let shouldExecute
                  let nearExecute
                  let diffPercent
                  let invalidToken = false
                  let error
                  if (fromToken && toToken) {
                    const invert = shouldInvertTriggerRatio(fromToken, toToken)
                    markExchangeRate = getExchangeRate(fromToken, toToken)
                    prefix =
                      (order.triggerAboveThreshold && !invert) || (!order.triggerAboveThreshold && invert) ? '> ' : '< '
                    shouldExecute = markExchangeRate && markExchangeRate.lt(order.triggerRatio)
                    nearExecute =
                      markExchangeRate && markExchangeRate.lt(order.triggerRatio.mul(100).div(NEAR_TRESHOLD))

                    if (markExchangeRate) {
                      const diff = order.triggerRatio.gt(markExchangeRate)
                        ? order.triggerRatio.sub(markExchangeRate)
                        : markExchangeRate.sub(order.triggerRatio)
                      diffPercent = diff.mul(10000).div(markExchangeRate)
                    }
                  } else {
                    invalidToken = true
                    error = `Invalid token fromToken: "${order.path0}" toToken: "${toTokenAddress}"`
                  }

                  return (
                    <tr
                      className="rounded-2xl bg-white rounded-2 border dark:bg-bg-dark-color-2 dark:border-bg-dark-color-2 leading-10"
                      key={key}
                    >
                      <td className="px-3 py-5 rounded-l-xl">Swap</td>
                      <td className="px-3 py-5" colSpan="2">
                        {!invalidToken && (
                          <>
                            {formatAmount(order.amountIn, fromToken.decimals, 4, true)} {fromToken.symbol}
                            &nbsp;for&nbsp;
                            {formatAmount(order.minOut, toToken.decimals, 4, true)} {toToken.symbol}
                          </>
                        )}
                      </td>
                      <td
                        className={cx('px-3 py-5', {
                          positive: shouldExecute,
                          near: !shouldExecute && nearExecute,
                        })}
                      >
                        {!invalidToken && prefix}
                        {getExchangeRateDisplay(order.triggerRatio, fromToken, toToken)}
                      </td>
                      <td
                        className={cx('px-3 py-5', {
                          positive: shouldExecute,
                          near: !shouldExecute && nearExecute,
                        })}
                      >
                        {getExchangeRateDisplay(markExchangeRate, fromToken, toToken)}
                      </td>
                      <td
                        className={cx('px-3 py-5', {
                          positive: shouldExecute,
                          near: !shouldExecute && nearExecute,
                        })}
                      >
                        {formatAmount(diffPercent, 2, 2)}%
                      </td>
                      <td className="px-3 py-5">
                        <NavLink to={`/actions/${order.account}`}>{shortenAddress(order.account)}</NavLink>
                      </td>
                      <td className="px-3 py-5">{formatDateTime(order.createdTimestamp)}</td>
                      <td className="px-3 py-5">{order.index}</td>
                      <td className="px-3 py-5">
                        {error ? (
                          <Tooltip
                            handle={<BiErrorCircle className="negative" size={15} />}
                            renderContent={() => {
                              return <div className="text-lg">{error}</div>
                            }}
                          />
                        ) : (
                          <span />
                        )}
                      </td>
                      <td className="px-3 py-5 rounded-r-xl">
                        <button className="underline" onClick={(evt) => executeOrder(evt, order)}>
                          Execute
                        </button>
                      </td>
                    </tr>
                  )
                } else {
                  const indexToken = getTokenInfo(infoTokens, order.indexToken, true, nativeTokenAddress)
                  const collateralToken = getTokenInfo(infoTokens, order.collateralToken, true, nativeTokenAddress)
                  const purchaseToken = getTokenInfo(infoTokens, order.purchaseToken)

                  let markPrice
                  let error
                  if (indexToken && collateralToken && (order.type === DECREASE || purchaseToken)) {
                    markPrice = order.triggerAboveThreshold ? indexToken.minPrice : indexToken.maxPrice
                  } else {
                    error = `Invalid token indexToken: "${order.indexToken}" collateralToken: "${order.collateralToken}"`
                    if (order.type === 'increase') {
                      error += ` purchaseToken: ${order.purchaseToken}`
                    }
                  }

                  let shouldExecute
                  let nearExecute
                  let diffPercent
                  if (markPrice) {
                    shouldExecute = order.triggerAboveThreshold
                      ? markPrice.gt(order.triggerPrice)
                      : markPrice.lt(order.triggerPrice)

                    nearExecute = order.triggerAboveThreshold
                      ? markPrice.gt(order.triggerPrice.mul(NEAR_TRESHOLD).div(100))
                      : markPrice.lt(order.triggerPrice.mul(100).div(NEAR_TRESHOLD))

                    const diff = markPrice.gt(order.triggerPrice)
                      ? markPrice.sub(order.triggerPrice)
                      : order.triggerPrice.sub(markPrice)
                    diffPercent = diff.mul(10000).div(markPrice)
                  }

                  if (!error && type === DECREASE) {
                    if (positionsForOrders && key in positionsForOrders) {
                      const position = positionsForOrders[key]
                      if (!position) {
                        error = 'No position'
                      } else if (order.sizeDelta.gt(position[0])) {
                        error = `Order size exceeds position`
                      } else if (order.sizeDelta.eq(0)) {
                        error = 'Order size is 0'
                      }
                    }
                  }

                  return (
                    <tr
                      className="rounded-2xl bg-white rounded-2 border dark:bg-bg-dark-color-2 dark:border-bg-dark-color-2 leading-10"
                      key={key}
                    >
                      <td className="px-3 py-5 rounded-l-xl">{order.type}</td>
                      <td className="px-3 py-5">
                        {order.isLong ? 'Long' : 'Short'} {indexToken && indexToken.symbol}
                      </td>
                      <td className="px-3 py-5">
                        {type === INCREASE ? '+' : '-'}${formatAmount(order.sizeDelta, USD_DECIMALS, 2, true)}
                      </td>
                      <td
                        className={cx('px-3 py-5', {
                          positive: shouldExecute,
                          near: !shouldExecute && nearExecute,
                        })}
                      >
                        {order.triggerAboveThreshold ? '> ' : '< '}
                        {formatAmount(order.triggerPrice, USD_DECIMALS, 2, true)}
                      </td>
                      <td
                        className={cx('px-3 py-5', {
                          positive: shouldExecute,
                          near: !shouldExecute && nearExecute,
                        })}
                      >
                        ${formatAmount(markPrice, USD_DECIMALS, 2, true)}
                      </td>
                      <td
                        className={cx('px-3 py-5', {
                          positive: shouldExecute,
                          near: !shouldExecute && nearExecute,
                        })}
                      >
                        {formatAmount(diffPercent, 2, 2)}%
                      </td>
                      <td className="px-3 py-5">
                        <NavLink to={`/actions/${order.account}`}>{shortenAddress(order.account, 12)}</NavLink>
                      </td>
                      <td className="px-3 py-5">{formatDateTime(order.createdTimestamp)}</td>
                      <td className="px-3 py-5">{order.index}</td>
                      <td className="px-3 py-5">
                        {error ? (
                          <Tooltip
                            handle={<BiErrorCircle className="negative" size={15} />}
                            renderContent={() => {
                              return <div className="text-lg">{error}</div>
                            }}
                          />
                        ) : (
                          <span />
                        )}
                      </td>
                      <td className="px-3 py-5 rounded-r-xl">
                        <button className="underline" onClick={(evt) => executeOrder(evt, order)}>
                          Execute
                        </button>
                      </td>
                    </tr>
                  )
                }
              })}
            </tbody>
          )}
        </table>
      </div>

      <div className="lg:hidden text-lg">
        {'<<'} horizontal scroll {'>>'}
      </div>

      <Footer isPaddingX={false} />
    </div>
  )
}
