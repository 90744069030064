import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart, Line, ResponsiveContainer } from 'recharts'

import ChartWrapper from './ChartWrapper'

import { COLORS, convertToPercents, tooltipFormatter, tooltipLabelFormatter, yaxisFormatter } from '../helpers'
import { useChartViewState } from 'hooks/useChartViewState'
import { useTheme } from 'hooks/useTheme'

const convertToPercentsHandler = (data) =>
  convertToPercents(data, {
    ignoreKeys: ['cumulative', 'movingAverageAll'],
    totalKey: 'all',
  })

export default function FeesChart(props) {
  const { data, loading, chartHeight, yaxisWidth } = props

  const csvFields = [
    { key: 'swap', name: 'Swap' },
    { key: 'margin', name: 'Margin trading' },
    { key: 'mint', name: 'Mint xLP' },
    { key: 'burn', name: 'Burn xLP' },
    { key: 'liquidation', name: 'Liquidation' },
    { key: 'cumulative', name: 'Cumulative' },
  ]

  const controls = {
    convertToPercents: convertToPercentsHandler,
  }

  const { viewState, togglePercentView, formattedData, ...viewSettings } = useChartViewState({ controls, data })
  const { theme } = useTheme()

  return (
    <ChartWrapper
      title="Fees"
      loading={loading}
      csvFields={csvFields}
      data={formattedData}
      controls={controls}
      viewState={viewState}
      togglePercentView={togglePercentView}
    >
      <ResponsiveContainer width="100%" height={chartHeight}>
        <ComposedChart data={formattedData} syncId="syncA">
          <CartesianGrid strokeDasharray="2 2" stroke={theme === 'dark' ? '#585555' : '#e3dcdc'} />
          <XAxis
            dataKey="timestamp"
            tickFormatter={tooltipLabelFormatter}
            minTickGap={30}
            stroke={theme === 'dark' ? '#e7e5e5' : '#716e6e'}
            fontSize={13}
            padding={{
              bottom: 15,
            }}
            tick={{ fill: theme === 'dark' ? '#c5c5c5' : '#888888' }}
          />

          <YAxis
            dataKey="all"
            interval="preserveStartEnd"
            tickCount={viewState.isPercentsView ? undefined : 7}
            tickFormatter={viewSettings.yaxisTickFormatter}
            width={yaxisWidth}
            stroke={theme === 'dark' ? '#fff' : '#646464'}
            fontSize={13}
            tick={{ fill: theme === 'dark' ? '#c5c5c5' : '#888888' }}
          />

          <YAxis
            dataKey="cumulative"
            orientation="right"
            yAxisId="right"
            tickFormatter={yaxisFormatter}
            width={yaxisWidth}
            stroke={theme === 'dark' ? '#fff' : '#646464'}
            fontSize={13}
            tick={{ fill: theme === 'dark' ? '#c5c5c5' : '#888888' }}
          />

          <Tooltip
            formatter={tooltipFormatter}
            labelFormatter={tooltipLabelFormatter}
            contentStyle={{
              background: theme === 'dark' ? '#e6e5e5' : '#ffffff',
              border: 0,
              opacity: 0.8,
            }}
            labelStyle={{ color: '#000', fontSize: '13px' }}
            itemStyle={{ fontSize: '13px' }}
          />

          <Legend />

          <Bar
            unit={viewSettings.itemsUnit}
            isAnimationActive={false}
            type="monotone"
            dataKey="swap"
            stackId="a"
            name="Swap"
            fill={COLORS[0]}
          />
          <Bar
            unit={viewSettings.itemsUnit}
            isAnimationActive={false}
            type="monotone"
            dataKey="mint"
            stackId="a"
            name="Mint xLP"
            fill={COLORS[1]}
          />
          <Bar
            unit={viewSettings.itemsUnit}
            isAnimationActive={false}
            type="monotone"
            dataKey="burn"
            stackId="a"
            name="Burn xLP"
            fill={COLORS[2]}
          />
          <Bar
            unit={viewSettings.itemsUnit}
            isAnimationActive={false}
            type="monotone"
            dataKey="liquidation"
            stackId="a"
            name="Liquidation"
            fill={COLORS[3]}
          />
          <Bar
            unit={viewSettings.itemsUnit}
            isAnimationActive={false}
            type="monotone"
            dataKey="margin"
            stackId="a"
            name="Margin trading"
            fill={COLORS[4]}
          />
          <Line
            isAnimationActive={false}
            type="monotone"
            strokeWidth={3}
            dot={false}
            stroke={COLORS[12]}
            dataKey="cumulative"
            yAxisId="right"
            name="Cumulative"
          />
        </ComposedChart>
      </ResponsiveContainer>
      <div className="text-xl pl-10">
        Collected fees. USD value is calculated with token price at the moment of swap, trade, minting or redeeming xLP
      </div>
    </ChartWrapper>
  )
}
