import { cx } from '@emotion/css'

interface Props extends React.ComponentProps<'div'> {
  className?: string
}

const Card = ({ children, className }: Props) => (
  <div
    className={cx(
      'bg-white rounded-[1.75rem] border dark:bg-bg-dark-color-2 dark:border-bg-dark-color-2 relative px-6 py-8',
      className,
    )}
  >
    {children}
  </div>
)

export default Card
