import useSWR from 'swr'
import { ACTIVE_CHAIN_IDS, arrayTextURLFetcher, getServerUrl } from '../utils'

export default function useTotalVolume() {
  const { data: totalVolume } = useSWR(
    ACTIVE_CHAIN_IDS.map((chain) => getServerUrl(chain, `/total_volume?chain=${chain}`)),
    {
      fetcher: arrayTextURLFetcher,
    },
  )
  return totalVolume
}
