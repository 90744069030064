import {
  ARBITRUM_SEPOLIA,
  bigNumberify,
  DEV_TESTNET,
  formatAmount,
  isAddressZero,
  MAX_REFERRAL_CODE_LENGTH,
  USD_DECIMALS,
} from 'utils'
import { encodeReferralCode, getReferralCodeOwner } from 'utils/api/referrals'

export const REFERRAL_CODE_REGEX = /^\w+$/ // only number, string and underscore is allowed
export const REGEX_VERIFY_BYTES32 = /^0x[0-9a-f]{64}$/

export function isRecentReferralCodeNotExpired(referralCodeInfo) {
  const REFERRAL_DATA_MAX_TIME = 60000 * 5 // 5 minutes
  if (referralCodeInfo.time) {
    return referralCodeInfo.time + REFERRAL_DATA_MAX_TIME > Date.now()
  }
}

export async function getReferralCodeTakenStatus(account, referralCode, chainId) {
  const referralCodeBytes32 = encodeReferralCode(referralCode)

  const [ownerArbitrumSepolia] = await Promise.all([getReferralCodeOwner(ARBITRUM_SEPOLIA, referralCodeBytes32)])
  const [ownerDevTestnet] = await Promise.all([getReferralCodeOwner(DEV_TESTNET, referralCodeBytes32)])

  const takenLineTestnet =
    !isAddressZero(ownerArbitrumSepolia) &&
    (ownerArbitrumSepolia !== account || (ownerArbitrumSepolia === account && chainId === ARBITRUM_SEPOLIA))

  const takenDevTestnet =
    !isAddressZero(ownerDevTestnet) &&
    (ownerDevTestnet !== account || (ownerDevTestnet === account && chainId === ARBITRUM_SEPOLIA))

  const referralCodeTakenInfo = {
    [ARBITRUM_SEPOLIA]: takenLineTestnet,
    [DEV_TESTNET]: takenDevTestnet,
    both: takenDevTestnet && takenLineTestnet,
    ownerArbitrumSepolia,
    ownerDevTestnet,
  }

  if (referralCodeTakenInfo.both) {
    return { status: 'all', info: referralCodeTakenInfo }
  }
  if (referralCodeTakenInfo[chainId]) {
    return { status: 'current', info: referralCodeTakenInfo }
  }

  if (chainId === DEV_TESTNET ? referralCodeTakenInfo[DEV_TESTNET] : referralCodeTakenInfo[ARBITRUM_SEPOLIA]) {
    return { status: 'other', info: referralCodeTakenInfo }
  }

  return { status: 'none', info: referralCodeTakenInfo }
}

export function getTierIdDisplay(tierId) {
  return Number(tierId) + 1
}

export const tierRebateInfo = {
  0: 5,
  1: 10,
  2: 15,
}

export const tierDiscountInfo = {
  0: 5,
  1: 10,
  2: 10,
}

function areObjectsWithSameKeys(obj1, obj2) {
  return Object.keys(obj1).every((key) => key in obj2)
}

export function deserializeSampleStats(input) {
  const parsedData = JSON.parse(input)
  if (!Array.isArray(parsedData)) return []
  return parsedData
    .map((data) => {
      if (!areObjectsWithSameKeys(getSampleReferrarStat(), data)) return null
      return Object.keys(data).reduce((acc, cv) => {
        const currentValue = data[cv]
        if (currentValue?.type === 'BigNumber') {
          acc[cv] = bigNumberify(currentValue.hex || 0)
        } else {
          acc[cv] = currentValue
        }
        return acc
      }, {})
    })
    .filter(Boolean)
}

export const getSampleReferrarStat = (code = '', ownerOnOtherNetwork = '', account = '') => {
  return {
    discountUsd: bigNumberify(0),
    referralCode: code,
    totalRebateUsd: bigNumberify(0),
    tradedReferralsCount: 0,
    registeredReferralsCount: 0,
    trades: 0,
    volume: bigNumberify(0),
    time: Date.now(),
    ownerOnOtherChain: {
      code: encodeReferralCode(code),
      codeString: code,
      owner: undefined,
      isTaken: !isAddressZero(ownerOnOtherNetwork),
      isTakenByCurrentUser:
        !isAddressZero(ownerOnOtherNetwork) && ownerOnOtherNetwork.toLowerCase() === account.toLowerCase(),
    },
  }
}

export function getUSDValue(value, decimals = 2) {
  return `$${formatAmount(value, USD_DECIMALS, decimals, true, '0.00')}`
}

export function getCodeError(value) {
  const trimmedValue = value.trim()
  if (!trimmedValue) return ''

  if (trimmedValue.length > MAX_REFERRAL_CODE_LENGTH) {
    return `The referral code can't be more than ${MAX_REFERRAL_CODE_LENGTH} characters.`
  }

  if (!REFERRAL_CODE_REGEX.test(trimmedValue)) {
    return 'Only letters, numbers and underscores are allowed.'
  }
  return ''
}

export function getTwitterShareUrl(referralCode) {
  const message = 'Trying out trading on @TFX_MARKET, up to 30x leverage on $BTC, $ETH 📈%0a%0aFor fee discounts use:'

  return `http://twitter.com/intent/tweet?text=${message}&url=https://tfx.market?ref=${referralCode}`
}
