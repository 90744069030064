import { useState, useCallback, ReactNode, useRef } from 'react'
import { usePopper } from 'react-popper'

import './Tooltip.css'
import { Placement } from '@popperjs/core'
import { cx } from '@emotion/css'

const isTouch = 'ontouchstart' in window
const OPEN_DELAY = 10
const CLOSE_DELAY = 10

interface PopperProps {
  handle: string | ReactNode
  renderContent: () => ReactNode
  trigger?: string
  position?: Placement
  popperClassName?: string
  className?: string
  hasArrow?: boolean
  hidden?: boolean
}

const Popper = (props: PopperProps) => {
  const [referenceElement, setReferenceElement] = useState<null | HTMLButtonElement>(null)
  const [popperElement, setPopperElement] = useState<null | HTMLDivElement>(null)

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: props.position ?? 'auto',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  })

  const [visible, setVisible] = useState(false)
  const intervalCloseRef = useRef<ReturnType<typeof setInterval> | null>(null)
  const intervalOpenRef = useRef<ReturnType<typeof setInterval> | null>(null)

  const trigger = props.trigger ?? 'hover'

  const onMouseEnter = useCallback(() => {
    if (trigger !== 'hover' || isTouch) return

    if (intervalCloseRef.current) {
      clearInterval(intervalCloseRef.current)
      intervalCloseRef.current = null
    }
    if (!intervalOpenRef.current) {
      intervalOpenRef.current = setTimeout(() => {
        setVisible(true)
        intervalOpenRef.current = null
      }, OPEN_DELAY)
    }
  }, [setVisible, intervalCloseRef, intervalOpenRef, trigger])

  const onMouseClick = useCallback(() => {
    if (trigger !== 'click' && !isTouch) return
    if (intervalCloseRef.current) {
      clearInterval(intervalCloseRef.current)
      intervalCloseRef.current = null
    }
    if (intervalOpenRef.current) {
      clearInterval(intervalOpenRef.current)
      intervalOpenRef.current = null
    }
    setVisible(true)
  }, [setVisible, intervalCloseRef, trigger])

  const onMouseLeave = useCallback(() => {
    intervalCloseRef.current = setTimeout(() => {
      setVisible(false)
      intervalCloseRef.current = null
    }, CLOSE_DELAY)
    if (intervalOpenRef.current) {
      clearInterval(intervalOpenRef.current)
      intervalOpenRef.current = null
    }
  }, [setVisible, intervalCloseRef])

  return (
    <div className="inline-block" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onMouseClick}>
      <button
        type="button"
        className={cx(['Tooltip-handle underline decoration-dotted', props.className])}
        ref={setReferenceElement}
      >
        {props.handle}
      </button>
      {visible && !props.hidden && (
        <div
          ref={setPopperElement}
          className={cx(['Tooltip-popup shadow-sm max-w-md', props.popperClassName])}
          style={styles.popper}
          {...attributes.popper}
        >
          {props.renderContent()}
          {props.hasArrow && <div className="Tooltip-arrow" data-popper-placement />}
        </div>
      )}
    </div>
  )
}

export default Popper
