import { useEffect, useMemo, useRef } from 'react'
import { useAccount } from 'wagmi'
import { useEthersProvider, useEthersSigner } from './wagmiEthers'

function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef()
  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current
}

const useWeb3 = () => {
  const { isConnected: active, address: account, connector, chain } = useAccount()
  const signer = useEthersSigner()
  const provider = useEthersProvider()

  const prevNewworkId = usePrevious(chain?.id)

  useEffect(() => {
    if (prevNewworkId && chain?.id && prevNewworkId !== chain?.id) {
      window.location.reload()
      return
    }
  }, [prevNewworkId, chain])

  const library = useMemo(() => {
    if (active && account && signer) {
      return signer
    }

    return provider
  }, [signer, provider, active, account, chain])

  const value = useMemo(() => {
    return {
      active,
      library,
      account,
      connector,
      chain,
      provider,
    }
  }, [active, library, account, connector, chain, provider])

  return value
}

export { useWeb3 }
