import * as React from 'react'

const ChartLoading = ({ className }) => {
  return (
    <div className={`p-4 w-full rounded animate-pulse md:p-6 ${className}`}>
      <div className="flex flex-row items-center mt-4 space-x-6">
        <div className="flex flex-col justify-center items-center w-full">
          <div className="w-1 h-4 mb-0 bg-gray-200 rounded-t-md dark:bg-gray-700" />
          <div className="w-full h-14 bg-gray-200 rounded-lg dark:bg-gray-700" />
          <div className="w-1 h-6 mt-0 bg-gray-200 rounded-b-md dark:bg-gray-700" />
          <div className="w-1 h-36" />
        </div>
        <div className="flex flex-col justify-center items-center w-full">
          <div className="w-1 h-4 mb-0 bg-gray-200 rounded-t-md dark:bg-gray-700" />
          <div className="w-full h-6 bg-gray-200 rounded-lg dark:bg-gray-700" />
          <div className="w-1 h-14 mt-0 bg-gray-200 rounded-b-md dark:bg-gray-700" />
          <div className="w-1 h-14" />
        </div>
        <div className="flex flex-col justify-center items-center w-full">
          <div className="w-1 h-4 mb-0 bg-gray-200 rounded-t-md dark:bg-gray-700" />
          <div className="w-full h-14 bg-gray-200 rounded-lg dark:bg-gray-700" />
          <div className="w-1 h-4 mt-0 bg-gray-200 rounded-b-md dark:bg-gray-700" />
          <div className="w-1 h-12" />
        </div>
        <div className="flex flex-col justify-center items-center w-full">
          <div className="w-1 h-16 mb-0 bg-gray-200 rounded-t-md dark:bg-gray-700" />
          <div className="w-full h-6 bg-gray-200 rounded-lg dark:bg-gray-700" />
          <div className="w-1 h-4 mt-0 bg-gray-200 rounded-b-md dark:bg-gray-700" />
          <div className="w-1 h-6" />
        </div>
        <div className="flex flex-col justify-center items-center w-full">
          <div className="w-1 h-4 mb-0 bg-gray-200 rounded-t-md dark:bg-gray-700" />
          <div className="w-full h-28 bg-gray-200 rounded-lg dark:bg-gray-700" />
          <div className="w-1 h-4 mt-0 bg-gray-200 rounded-b-md dark:bg-gray-700" />
          <div className="w-1 h-24" />
        </div>
        <div className="flex flex-col justify-center items-center w-full">
          <div className="w-1 h-4 mb-0 bg-gray-200 rounded-t-md dark:bg-gray-700" />
          <div className="w-full h-10 bg-gray-200 rounded-lg dark:bg-gray-700" />
          <div className="w-1 h-14 mt-0 bg-gray-200 rounded-b-md dark:bg-gray-700" />
          <div className="w-1 h-32" />
        </div>
        <div className="flex flex-col justify-center items-center w-full">
          <div className="w-1 h-4 mb-0 bg-gray-200 rounded-t-md dark:bg-gray-700" />
          <div className="w-full h-16 bg-gray-200 rounded-lg dark:bg-gray-700" />
          <div className="w-1 h-6 mt-0 bg-gray-200 rounded-b-md dark:bg-gray-700" />
          <div className="w-1 h-44" />
        </div>
        <div className="flex flex-col justify-center items-center w-full">
          <div className="w-1 h-4 mb-0 bg-gray-200 rounded-t-md dark:bg-gray-700" />
          <div className="w-full h-6 bg-gray-200 rounded-lg dark:bg-gray-700" />
          <div className="w-1 h-12 mt-0 bg-gray-200 rounded-b-md dark:bg-gray-700" />
          <div className="w-1 h-28" />
        </div>
        <div className="flex flex-col justify-center items-center w-full">
          <div className="w-1 h-4 mb-0 bg-gray-200 rounded-t-md dark:bg-gray-700" />
          <div className="w-full h-20 bg-gray-200 rounded-lg dark:bg-gray-700" />
          <div className="w-1 h-4 mt-0 bg-gray-200 rounded-b-md dark:bg-gray-700" />
          <div className="w-1 h-20" />
        </div>
        <div className="flex flex-col justify-center items-center w-full">
          <div className="w-1 h-4 mb-0 bg-gray-200 rounded-t-md dark:bg-gray-700" />
          <div className="w-full h-16 bg-gray-200 rounded-lg dark:bg-gray-700" />
          <div className="w-1 h-4 mt-0 bg-gray-200 rounded-b-md dark:bg-gray-700" />
          <div className="w-1 h-0" />
        </div>
        <div className="flex flex-col justify-center items-center w-full">
          <div className="w-1 h-14 mb-0 bg-gray-200 rounded-t-md dark:bg-gray-700" />
          <div className="w-full h-6 bg-gray-200 rounded-lg dark:bg-gray-700" />
          <div className="w-1 h-4 mt-0 bg-gray-200 rounded-b-md dark:bg-gray-700" />
          <div className="w-1 h-0" />
        </div>
        <div className="flex flex-col justify-center items-center w-full">
          <div className="w-1 h-4 mb-0 bg-gray-200 rounded-t-md dark:bg-gray-700" />
          <div className="w-full h-14 bg-gray-200 rounded-lg dark:bg-gray-700" />
          <div className="w-1 h-4 mt-0 bg-gray-200 rounded-b-md dark:bg-gray-700" />
          <div className="w-1 h-6" />
        </div>
        <div className="flex flex-col justify-center items-center w-full">
          <div className="w-1 h-4 mb-0 bg-gray-200 rounded-t-md dark:bg-gray-700" />
          <div className="w-full h-28 bg-gray-200 rounded-lg dark:bg-gray-700" />
          <div className="w-1 h-4 mt-0 bg-gray-200 rounded-b-md dark:bg-gray-700" />
          <div className="w-1 h-44" />
        </div>
        <div className="flex flex-col justify-center items-center w-full">
          <div className="w-1 h-4 mb-0 bg-gray-200 rounded-t-md dark:bg-gray-700" />
          <div className="w-full h-6 bg-gray-200 rounded-lg dark:bg-gray-700" />
          <div className="w-1 h-12 mt-0 bg-gray-200 rounded-b-md dark:bg-gray-700" />
          <div className="w-1 h-48" />
        </div>
        <div className="flex flex-col justify-center items-center w-full">
          <div className="w-1 h-4 mb-0 bg-gray-200 rounded-t-md dark:bg-gray-700" />
          <div className="w-full h-16 bg-gray-200 rounded-lg dark:bg-gray-700" />
          <div className="w-1 h-4 mt-0 bg-gray-200 rounded-b-md dark:bg-gray-700" />
          <div className="w-1 h-36" />
        </div>
        <div className="flex flex-col justify-center items-center w-full">
          <div className="w-1 h-4 mb-0 bg-gray-200 rounded-t-md dark:bg-gray-700" />
          <div className="w-full h-10 bg-gray-200 rounded-lg dark:bg-gray-700" />
          <div className="w-1 h-14 mt-0 bg-gray-200 rounded-b-md dark:bg-gray-700" />
          <div className="w-1 h-16" />
        </div>
        <div className="flex flex-col justify-center items-center w-full">
          <div className="w-1 h-4 mb-0 bg-gray-200 rounded-t-md dark:bg-gray-700" />
          <div className="w-full h-16 bg-gray-200 rounded-lg dark:bg-gray-700" />
          <div className="w-1 h-4 mt-0 bg-gray-200 rounded-b-md dark:bg-gray-700" />
          <div className="w-1 h-12" />
        </div>
        <div className="flex flex-col justify-center items-center w-full">
          <div className="w-1 h-12 mb-0 bg-gray-200 rounded-t-md dark:bg-gray-700" />
          <div className="w-full h-6 bg-gray-200 rounded-lg dark:bg-gray-700" />
          <div className="w-1 h-4 mt-0 bg-gray-200 rounded-b-md dark:bg-gray-700" />
          <div className="w-1 h-1" />
        </div>
        <div className="flex flex-col justify-center items-center w-full">
          <div className="w-1 h-4 mb-0 bg-gray-200 rounded-t-md dark:bg-gray-700" />
          <div className="w-full h-16 bg-gray-200 rounded-lg dark:bg-gray-700" />
          <div className="w-1 h-4 mt-0 bg-gray-200 rounded-b-md dark:bg-gray-700" />
          <div className="w-1 h-16" />
        </div>
        <div className="flex flex-col justify-center items-center w-full">
          <div className="w-1 h-4 mb-0 bg-gray-200 rounded-t-md dark:bg-gray-700" />
          <div className="w-full h-10 bg-gray-200 rounded-lg dark:bg-gray-700" />
          <div className="w-1 h-4 mt-0 bg-gray-200 rounded-b-md dark:bg-gray-700" />
          <div className="w-1 h-20" />
        </div>
        <div className="flex flex-col justify-center items-center w-full">
          <div className="w-1 h-4 mb-0 bg-gray-200 rounded-t-md dark:bg-gray-700" />
          <div className="w-full h-24 bg-gray-200 rounded-lg dark:bg-gray-700" />
          <div className="w-1 h-4 mt-0 bg-gray-200 rounded-b-md dark:bg-gray-700" />
          <div className="w-1 h-40" />
        </div>
        <div className="flex flex-col justify-center items-center w-full">
          <div className="w-1 h-4 mb-0 bg-gray-200 rounded-t-md dark:bg-gray-700" />
          <div className="w-full h-10 bg-gray-200 rounded-lg dark:bg-gray-700" />
          <div className="w-1 h-12 mt-0 bg-gray-200 rounded-b-md dark:bg-gray-700" />
          <div className="w-1 h-48" />
        </div>
      </div>
    </div>
  )
}

export default ChartLoading
