import useSWR from 'swr'
import { fetcher } from 'utils'
import { getWhitelistedTokens } from '@tfx/tokens'
import { getContract } from '@tfx/addresses'
import XlpManagerReader from 'abis/GlpManagerReader.json'
import { useCallback, useMemo } from 'react'
import { usePrices } from './price'
import { isEmpty } from 'lodash'

export const useAum = (library, chainId, active) => {
  const xlpManagerAddress = getContract(chainId, 'XlpManager')
  const glpManagerReaderAddress = getContract(chainId, 'XlpManagerReader')
  const vaultAddress = getContract(chainId, 'Vault')

  const [prices] = usePrices()

  const whitelistedTokens = getWhitelistedTokens(chainId)
  const whitelistedTokenAddressesAndKey = useMemo(() => {
    return !isEmpty(prices)
      ? whitelistedTokens
          .filter((t) => !t.isNative)
          .sort((a, b) => (a?.last_nom ?? 0) - (b?.last_nom ?? 0))
          .map((token) => [token.address, prices[token.priceFeedIndex]])
      : []
  }, [whitelistedTokens, prices])

  const {
    data,
    mutate: updateAum,
    error: pricesError,
  } = useSWR(
    whitelistedTokenAddressesAndKey.length ? [`useAum:${active}`, chainId, glpManagerReaderAddress, 'getAum'] : null,
    {
      fetcher: fetcher(library, XlpManagerReader, [xlpManagerAddress, vaultAddress, whitelistedTokenAddressesAndKey]),
      refreshInterval: 5000,
    },
  )

  const mutate = useCallback(() => {
    updateAum()
  }, [updateAum])

  return [data, mutate, pricesError]
}
