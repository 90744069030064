import { useCallback, useState } from 'react'
import { ethers } from 'ethers'
import { callContract } from 'utils/api'

import RewardRouter from 'abis/RewardRouterV3.json'
import { PButton } from 'components/Button'
import { usePendingSteps } from 'components/ToastPendingStep'

const Compound = (props) => {
  const { setIsVisible, rewardRouterAddress, library, chainId } = props

  const [isCompounding, setIsCompounding] = useState(false)

  const { setPendingStepWorkflow } = usePendingSteps()

  const isPrimaryEnabled = useCallback(() => {
    return !isCompounding
  }, [isCompounding])

  const getPrimaryText = useCallback(() => {
    if (isCompounding) {
      return 'Compounding...'
    }
    return 'Compound'
  }, [isCompounding])

  const onClickPrimary = () => {
    setIsCompounding(true)

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter, library)
    callContract(chainId, contract, 'compound', [], {
      sentMsg: 'Compound submitted!',
      failMsg: 'Compound failed.',
      successMsg: 'Requested to compound your reward!',
      setPendingStepWorkflow,
    })
      .then(async (res) => {
        setIsVisible(false)
      })
      .finally(() => {
        setIsCompounding(false)
      })
  }

  return (
    <div className="Exchange-swap-button-container mt-5">
      <PButton onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
        {getPrimaryText()}
      </PButton>
    </div>
  )
}

export default Compound
