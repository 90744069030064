import { Transition } from '@headlessui/react'
import { PendingStepState, usePendingSteps } from 'components/ToastPendingStep'
import { Toast } from 'react-hot-toast'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { BiLinkExternal } from 'react-icons/bi'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { ImSpinner2 } from 'react-icons/im'
import { MdAccessTime, MdError } from 'react-icons/md'
import { getExplorerUrl, useChainId } from 'utils'

const StatusIcons = ({ isPending, hash, error }: { isPending: boolean; hash?: string; error: boolean }) => {
  if (error) return <MdError size={18} color="orange" />
  if (isPending) return <ImSpinner2 className="spin" size={18} />
  if (!isPending && hash) return <BsFillCheckCircleFill size={18} className="dark:text-[#0ecc83] text-[#007C41]" />
  return <MdAccessTime size={18} color="#878787" />
}

const LiComponent = ({
  title,
  loadingTitle,
  completeTitle,
  currentState,
  stateNumber,
  hash,
  isError,
}: {
  title: string
  loadingTitle: string
  completeTitle: string
  stateNumber: PendingStepState
  hash: string
  currentState: PendingStepState
  isError: boolean
}) => {
  const { chainId } = useChainId()
  const txUrl = getExplorerUrl(chainId) + 'tx/' + hash

  const statusIconProps = {
    isPending: currentState === stateNumber,
    hash,
    error: isError,
  }

  const statusIconClasses = `
    ${PendingStepState.FULFILLING === stateNumber ? 'after:h-0' : 'after:h-1'} 
    ${
      currentState > stateNumber && hash && !isError
        ? 'after:bg-[#007C41] dark:after:bg-[#0ecc83]'
        : isError
        ? 'after:bg-[#FBA502]'
        : 'after:bg-[#878787]'
    }
    mb-2 after:rounded-lg after:w-[85px] after:inline-block after:absolute after:top-3 after:right-[-42px]
  `

  const listItemClasses = 'flex flex-col items-center relative w-full'

  const linkClasses = `
    whitespace-nowrap flex items-end
    ${currentState === stateNumber ? 'text-black dark:text-white' : ''}
    ${
      currentState > stateNumber && hash && !isError
        ? 'text-[#007C41] dark:text-[#0ecc83] font-bold'
        : isError
        ? 'text-[#FBA502] font-bold'
        : 'text-[#878787]'
    }
  `

  const statusTitle = currentState === stateNumber ? loadingTitle : !isError ? completeTitle : title
  const linkElement = (
    <a href={txUrl} className={linkClasses} target="_blank" rel="noopener noreferrer">
      {statusTitle}
      {currentState !== stateNumber && hash && <BiLinkExternal size={12} className="mb-[3px] ml-1" />}
    </a>
  )

  return (
    <li className={listItemClasses}>
      <div className={statusIconClasses}>
        <StatusIcons {...statusIconProps} />
      </div>
      {hash ? linkElement : <span>{statusTitle}</span>}
    </li>
  )
}

const PendingStepToaster = ({
  t,
  updateHeight,
}: {
  t: Toast
  updateHeight: (toastId: string, height: number) => void
}) => {
  const { cancelPendingStep } = usePendingSteps()

  const ref = (el) => {
    if (el && typeof t.height !== 'number') {
      const height = el.getBoundingClientRect().height
      updateHeight(t.id, height)
    }
  }

  const step = t.message as any

  return (
    <Transition
      appear
      show={t.visible}
      className="transform bg-[#ecf0f9] dark:bg-[#4b5563] dark:border-2 border-1 dark:border-[#374151] dark:text-white px-5 py-6 shadow-xl rounded-[15px] mb-2 text-black text-[14px] w-screen max-w-xl relative"
      enter="transition-all duration-150"
      enterFrom="opacity-0 scale-50"
      enterTo="opacity-100 scale-100"
      leave="transition-all duration-150"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-75"
      key={t.id}
    >
      <div ref={ref} {...t.ariaProps}>
        <button type="button" onClick={() => cancelPendingStep(t.id)} className="absolute top-2 right-2">
          <AiOutlineCloseCircle size="20" />
        </button>

        <div className="flex mb-6">{step.message}</div>

        <ol className="flex justify-between items-center relative">
          {[
            // Send > Sending > Sent tx
            {
              title: 'Send',
              loadingTitle: 'Sending',
              completeTitle: 'Sent tx',
              stateNumber: PendingStepState.SENDING,
              hash: step.sendingHash,
              isError:
                step.sendingHash &&
                step.errorMsg &&
                step.state === PendingStepState.ERROR &&
                !step.requestHash &&
                !step.fulfillHash,
            },
            // Request > Requesting > Requested tx
            {
              title: 'Request',
              loadingTitle: 'Requesting',
              completeTitle: 'Requested tx',
              stateNumber: PendingStepState.REQUESTING,
              hash: step.requestHash,
              isError:
                step.sendingHash &&
                step.requestHash &&
                step.errorMsg &&
                step.state === PendingStepState.ERROR &&
                !step.fulfillHash,
            },
            // FulFill > FulFilling > Fulfilled tx
            {
              title: 'FulFill',
              loadingTitle: 'FulFilling',
              completeTitle: 'Fulfilled tx',
              stateNumber: PendingStepState.FULFILLING,
              hash: step.fulfillHash,
              isError:
                step.sendingHash &&
                step.requestHash &&
                step.fulfillHash &&
                step.errorMsg &&
                step.state === PendingStepState.ERROR,
            },
          ].map((stepProps, index) => (
            <LiComponent key={index} currentState={step.state} {...stepProps} />
          ))}
        </ol>
        {!!step.errorMsg && (
          <div className="bg-[#FFEECF] mt-4 p-3 rounded-xl text-[14px] text-center dark:text-black">
            {step.errorMsg}
          </div>
        )}
      </div>
    </Transition>
  )
}

export default PendingStepToaster
