import { useState } from 'react'
import './style.css'

const SearchForm = ({ setSearch, methodOptions }) => {
  const [txtSearchInp, setTxtSearchInp] = useState('')
  const [filterStatusSlt, setFilterStatusSlt] = useState('')
  const [filterMethodSlt, setFilterMethodSlt] = useState('')

  const handleChange = (event) => {
    setTxtSearchInp(event.target.value)
  }

  const handleStatusSelect = (event) => {
    setFilterStatusSlt(event.target.value)
  }

  const handleMethodSelect = (event) => {
    setFilterMethodSlt(event.target.value)
  }

  const onSubmit = () => {
    setSearch({
      address: txtSearchInp.toLowerCase(),
      status: filterStatusSlt,
      method: filterMethodSlt,
    })
  }

  return (
    <div>
      <div className="bg-white rounded-xl p-2 flex items-center border-2 dark:bg-[#e1e1e1]">
        <div className="flex-1 ml-1 dark:border-gray-400">
          <input
            type="text"
            name="txtSearchInp"
            id="txtSearchInp"
            className="block focus:ring-0 w-full border-0 text-xl bg-transparent text-black"
            placeholder="Search by address or transaction hash"
            autoComplete="off"
            onChange={handleChange}
            onKeyDown={(event) => event.key === 'Enter' && onSubmit()}
            value={txtSearchInp}
          />
        </div>
      </div>

      <div className="flex flex-column md:flex-row gap-3 mt-3 md:items-center items-end">
        <div className="bg-white rounded-xl p-2 border-2 flex-1 w-full dark:bg-[#e1e1e1]">
          <select
            id="filterSlt"
            name="filterSlt"
            className="border-0 focus:ring-0 text-xl w-full dark:text-gray-900 bg-transparent"
            onChange={handleMethodSelect}
            value={filterMethodSlt}
          >
            <option value="">All Method</option>
            {Object.keys(methodOptions).map((key) => (
              <option key={key} value={key}>
                {methodOptions[key]}
              </option>
            ))}
          </select>
        </div>

        <div className="flex flex-1 gap-3 items-center w-full">
          <div className="bg-white rounded-xl p-2 border-2 flex-1 dark:bg-[#e1e1e1]">
            <select
              id="filterSlt"
              name="filterSlt"
              className="border-0 focus:ring-0 text-xl w-full dark:text-gray-900 bg-transparent"
              onChange={handleStatusSelect}
              value={filterStatusSlt}
            >
              <option value="">All Status</option>
              <option value="1">Success</option>
              <option value="2">Pending</option>
              <option value="3">Fail</option>
            </select>
          </div>

          <div>
            <button
              type="button"
              onClick={onSubmit}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-xl px-5 h-[38px] dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchForm
