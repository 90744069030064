import cx from 'classnames'

import { useAllPositions } from 'utils/api'
import { USD_DECIMALS, useChainId, formatAmount, getTimeRemaining } from 'utils'

import { FcPositiveDynamic } from 'react-icons/fc'
import { FaSkull } from 'react-icons/fa'

import './PositionsOverview.css'
import { useWeb3 } from 'hooks/useWeb3'
import { Footer } from '@tfx/ui'

export default function PositionsOverview() {
  const { chainId } = useChainId()
  const { library } = useWeb3()

  const positions = useAllPositions(chainId, library)

  return (
    <div className="default-container page-layout">
      <h1 className="text-[3rem] font-bold mb-5">Positions Overview</h1>

      <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
        <div className="overflow-hidden rounded-2xl bg-white border dark:bg-bg-dark-color-2 dark:border-bg-dark-color-2">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <FcPositiveDynamic size={40} />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="truncate text-xl font-medium">Open positions</dt>
                  <dd>
                    <div className="text-2xl font-medium">{positions.length}</div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="overflow-hidden rounded-2xl bg-white border dark:bg-bg-dark-color-2 dark:border-bg-dark-color-2">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <FaSkull size={40} className="text-red-700" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="truncate text-xl font-medium">Under risk</dt>
                  <dd>
                    <div className="text-2xl font-medium">{positions.filter((p) => p.danger).length}</div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full text-xl border-separate border-spacing-y-4 min-w-[966px] mt-6">
          <thead>
            <tr>
              <th scope="col" className="px-3 py-3.5 text-left font-semibold">
                account
              </th>
              <th scope="col" className="px-3 py-3.5 text-left font-semibold">
                size
              </th>
              <th scope="col" className="px-3 py-3.5 text-left font-semibold">
                collateral
              </th>
              <th scope="col" className="px-3 py-3.5 text-left font-semibold">
                fee
              </th>
              <th scope="col" className="px-3 py-3.5 text-left font-semibold">
                time to liq
              </th>
            </tr>
          </thead>
          {positions && positions.filter((p) => p.danger)?.length ? (
            <tbody>
              {positions &&
                positions
                  .filter((p) => p.danger)
                  .map((position, i) => {
                    const { size, account, collateral, fee, danger } = position

                    const diffToLiq = position.collateral.sub(position.fee)
                    const fundingFee = 80
                    const feesPerHour = position.size.mul(fundingFee).div(1000000)
                    const hoursToLiq = diffToLiq.div(feesPerHour)
                    const liqTime = hoursToLiq.toNumber() * 60 * 60 + Date.now() / 1000
                    return (
                      <tr
                        className="rounded-2xl bg-white rounded-2 border dark:bg-bg-dark-color-2 dark:border-bg-dark-color-2 leading-10"
                        key={`${account}-${i}`}
                      >
                        <td className="px-3 py-5 rounded-l-xl">{account}</td>
                        <td className="px-3 py-5">${formatAmount(size, USD_DECIMALS, 2, true)}</td>
                        <td className="px-3 py-5">${formatAmount(collateral, USD_DECIMALS, 2, true)}</td>
                        <td className={cx({ negative: danger })}>${formatAmount(fee, USD_DECIMALS, 2, true)}</td>
                        <td className="px-3 py-5 rounded-r-xl">
                          {getTimeRemaining(liqTime)} ($
                          {formatAmount(feesPerHour, USD_DECIMALS, 2, true)}/h)
                        </td>
                      </tr>
                    )
                  })}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={5} className="text-left md:text-center py-[8rem] border-t dark:border-bg-dark-color-2">
                  No positions
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      <div className="md:hidden text-lg">
        {'<<'} horizontal scroll {'>>'}
      </div>

      <Footer isPaddingX={false} />
    </div>
  )
}
