import { usePendingSteps } from 'components/ToastPendingStep'
import { Transition } from '@headlessui/react'
import { Toast } from 'react-hot-toast'
import cx from 'classnames'

import { AiOutlineCloseCircle } from 'react-icons/ai'

const GeneralToaster = ({ t, updateHeight }: { t: Toast; updateHeight: (toastId: string, height: number) => void }) => {
  const { cancelPendingStep } = usePendingSteps()

  const ref = (el) => {
    if (el && typeof t.height !== 'number') {
      const height = el.getBoundingClientRect().height
      updateHeight(t.id, height)
    }
  }

  const msg = t.message as any

  const transitionClassname = cx(
    'text-white text-[14px] transform border-1 px-5 py-6 shadow-xl rounded-[15px] mb-2 text-black w-screen max-w-xl relative',
    {
      'bg-red-600': t.type === 'error',
      'bg-green-600': t.type !== 'error' && t.type === 'success',
      'bg-slate-400': t.type !== 'error' && t.type !== 'success',
    },
  )

  return (
    <Transition
      appear
      show={t.visible}
      className={transitionClassname}
      enter="transition-all duration-150"
      enterFrom="opacity-0 scale-50"
      enterTo="opacity-100 scale-100"
      leave="transition-all duration-150"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-75"
      key={t.id}
    >
      <div {...ref} {...t.ariaProps}>
        <button type="button" onClick={() => cancelPendingStep(t.id)} className="absolute top-2 right-2">
          <AiOutlineCloseCircle size="20" />
        </button>
        <div className="flex items-center gap-3 pr-7 [&>svg]:min-w-[25px] [&>svg]:text-[14px] [&>div>a]:underline">
          {msg}
        </div>
      </div>
    </Transition>
  )
}

export default GeneralToaster
