import { HistoryCallback, PeriodParams, ResolutionString, SubscribeBarsCallback } from 'charting_library'
import { getNativeToken, getTokens, isChartAvailableForToken } from '@tfx/tokens'
import { SUPPORTED_RESOLUTIONS } from 'config/tradingview'
import { SymbolInfo } from 'config/types'
import { useEffect, useMemo, useRef } from 'react'
import { TVDataProvider } from 'utils/tradingview'
import { formatTimeInBarToMs, useLocalStorageSerializeKey } from 'utils'
import { useChainId } from 'utils'
import { DEFAULT_TIMEZONE, getTVDecimalPointBySymbol } from 'components/TVChartContainer/constants'

const configurationData = {
  supported_resolutions: Object.keys(SUPPORTED_RESOLUTIONS),
  supports_marks: false,
  supports_timescale_marks: false,
  supports_time: true,
  reset_cache_timeout: 100,
}

type Props = {
  dataProvider?: TVDataProvider
}

export default function useTVDatafeed({ dataProvider }: Props) {
  const { chainId } = useChainId()
  const intervalRef = useRef<ReturnType<typeof setInterval> | undefined>()
  const resetCacheRef = useRef<() => void | undefined>()
  const activeTicker = useRef<string | undefined>()
  const tvDataProvider = useRef<TVDataProvider>()
  const shouldRefetchBars = useRef<boolean>(false)
  const [tz] = useLocalStorageSerializeKey([chainId, 'Chart-timezone'], DEFAULT_TIMEZONE)

  useEffect(() => {
    if (dataProvider && tvDataProvider.current !== dataProvider) {
      tvDataProvider.current = dataProvider
    }
  }, [dataProvider])

  return useMemo(() => {
    return {
      resetCache: function () {
        shouldRefetchBars.current = true
        resetCacheRef.current?.()
        shouldRefetchBars.current = false
      },
      datafeed: {
        onReady: (callback) => {
          setTimeout(() => callback(configurationData))
        },
        resolveSymbol(symbolName, onSymbolResolvedCallback) {
          if (!isChartAvailableForToken(chainId, symbolName)) {
            symbolName = getNativeToken(chainId)?.symbol
          }
          const stableTokens = getTokens(chainId)
            .filter((t) => t.isStable)
            .map((t) => t.symbol)
          const symbolInfo = {
            name: symbolName,
            type: 'crypto',
            description: symbolName + ' / USD',
            ticker: symbolName,
            session: '24x7',
            exchange: '',
            minmov: 1,
            pricescale: 100000000,
            timezone: tz,
            has_intraday: true,
            has_daily: true,
            currency_code: 'USD',
            visible_plots_set: 'ohlc',
            data_status: 'streaming',
            isStable: stableTokens.includes(symbolName),
          }

          const tokenDisplayDecimals = getTVDecimalPointBySymbol(symbolName)
          symbolInfo.pricescale = Math.pow(10, tokenDisplayDecimals)

          setTimeout(() => onSymbolResolvedCallback(symbolInfo))
        },

        async getBars(
          symbolInfo: SymbolInfo,
          resolution: ResolutionString,
          periodParams: PeriodParams,
          onHistoryCallback: HistoryCallback,
          onErrorCallback: (error: string) => void,
        ) {
          if (!SUPPORTED_RESOLUTIONS[resolution]) {
            return onErrorCallback('[getBars] Invalid resolution')
          }
          const { ticker, isStable } = symbolInfo
          if (activeTicker.current !== ticker) {
            activeTicker.current = ticker
          }

          try {
            if (!ticker) {
              onErrorCallback('Invalid ticker!')
              return
            }
            const bars = await tvDataProvider.current?.getBars(
              chainId,
              ticker,
              resolution,
              isStable,
              periodParams,
              shouldRefetchBars.current,
            )
            // to fetch more data from server
            onHistoryCallback(bars, {
              noData: !bars.length,
            })
          } catch {
            onErrorCallback('Unable to load historical data!')
          }
        },
        async subscribeBars(
          symbolInfo: SymbolInfo,
          resolution: ResolutionString,
          onRealtimeCallback: SubscribeBarsCallback,
          _subscribeUID,
          onResetCacheNeededCallback: () => void,
        ) {
          const { ticker, isStable } = symbolInfo
          if (!ticker) {
            return
          }
          intervalRef.current && clearInterval(intervalRef.current)
          resetCacheRef.current = onResetCacheNeededCallback
          if (!isStable) {
            intervalRef.current = setInterval(function () {
              tvDataProvider.current?.getLiveBar(chainId, ticker, resolution).then((bar) => {
                if (bar && ticker === activeTicker.current) {
                  onRealtimeCallback(formatTimeInBarToMs(bar))
                }
              })
            }, 2000)
          }
        },
        unsubscribeBars: () => {
          intervalRef.current && clearInterval(intervalRef.current)
        },
      },
    }
  }, [chainId, tz])
}
