import { getTokens } from '@tfx/tokens'
import { Footer } from '@tfx/ui'
import Popper from 'components/Tooltip/Popper'
import Tooltip from 'components/Tooltip/Tooltip'
import { getConstant } from 'config/chains'
import dayjs from 'dayjs'
import { getTaskFilterCount, useTaskData } from 'hooks/useTask'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { BiCopy } from 'react-icons/bi'
import { MdAccessTime, MdCheckCircleOutline, MdError, MdVerifiedUser } from 'react-icons/md'
import { useCopyToClipboard } from 'react-use'
import {
  formatAmount,
  getEnv,
  getExplorerUrl,
  getTimeAgo,
  helperToast,
  shortenAddress,
  trimRightAddress,
  useChainId,
} from 'utils'
import { getCoinSGV } from 'utils/image'
import { isTimestampMoreThan24HoursAgo } from 'utils/time'
import { useAccount } from 'wagmi'
import ExplorerPagination, { IExplorerPagination } from './Pagination'
import SearchForm from './SearchForm'
import './style.css'

let methodName = {}
let tokensTemplate = {}
const filterDefault = {
  first: 20,
  skip: 0,
}

const Explorer = () => {
  const { chainId } = useChainId()
  const nativeTokenSymbol = getConstant(chainId, 'nativeTokenSymbol')

  methodName = {
    '0x348621c8': 'Swap',
    '0x635e2a2e': `Swap to ${nativeTokenSymbol}`,
    '0x9a208100': 'Create Increase Position', // "Execute Increase Position"
    '0xf3883d8b': 'Create Decrease Position', // "Execute Decrease Position"
    '0x8248f567': 'Create Increase Order',
    '0x12360ca2': 'Execute Order',
    '0x694a1db3': 'Liquidate Position',
    '0xb0aeb400': 'Buy xLP', // "Add Liquidity"
    '0x3b971a9e': 'Sell xLP', // "Remove Liquidity"
    '0x32f89f7b': 'Buy and Stake xLP', // "Mint and Stake XLP"
    '0x72b57547': 'Unstake and Sell xLP', // "Unstake and Redeem XLP"
    '0xc1b5dab8': `Unstake and Sell xLP to ${nativeTokenSymbol}`, // "Unstake and Redeem XLP to ETH"
    '0xadf13488': 'Compound', // "Compound"
  }

  getTokens(chainId).forEach((token) => {
    const coinSvg = getCoinSGV(token.symbol)
    tokensTemplate[token.address.toLocaleLowerCase()] = {
      tokenIndex: token.priceFeedIndex,
      symbol: token.symbol,
      img: coinSvg,
    }
  })

  const [search, setSearch] = useState({
    txtSearchType: 'account',
    address: '',
    method: '',
    status: '',
    isFilterAddress: false,
  })

  const [filter, setFilter] = useState(filterDefault)
  const [dataCount, setDataCount] = useState(0)

  const { address: userAddress } = useAccount()

  const { data, isLoading } = useTaskData(chainId, {
    ...filter,
    ...search,
    userAddress,
  })

  useEffect(() => {
    const totalData = getTaskFilterCount(chainId, {
      ...filter,
      ...search,
      userAddress,
    })
    totalData.then((data) => {
      setDataCount(data.totalData)
    })
  }, [chainId, filter, search, userAddress])
  const [, copyToClipboard] = useCopyToClipboard()

  const setPage = useCallback((current, pageSize) => {
    const selected = current - 1
    const newOffset = selected * pageSize
    setFilter((filter) => ({
      ...filter,
      skip: newOffset,
    }))
  }, [])

  const handleSetFilter = (search) => {
    setFilter(filterDefault)
    setSearch(search)
  }

  const Dasboard = useMemo(() => {
    let total = 0
    let pending = 0
    if (data) {
      const { taskStat }: any = data
      total = taskStat?.taskCount ?? 0
      pending = taskStat?.pendingCount ?? 0
    }
    return (
      <div className="Page-description">
        Find and track all transactions fulfilled by xOracle.
        <br />
        ⛓️ Fulfilled {total} transactions, {pending} pending.
      </div>
    )
  }, [data])

  const paginationProps = useMemo(() => {
    if (!data) {
      return {
        total: 0,
        pageSize: 0,
        onChange: setPage,
        showTotal: (total) => `Total ${total} items`,
        current: 0,
      }
    }

    const current = Math.ceil(filter.skip / filter.first) + 1
    return {
      total: dataCount ?? 0,
      pageSize: filter.first,
      onChange: setPage,
      showTotal: (total) => `Total ${total} items`,
      current,
    } as IExplorerPagination
  }, [data, filter.first, filter.skip, setPage, dataCount])

  const fulfillPrice = (priceList) => {
    let data = {}
    priceList.forEach((price: any) => {
      const token = tokensTemplate[price.token.toLocaleLowerCase()]
      if (token) {
        data[token.tokenIndex] = {
          ...token,
          price: formatAmount(price.value, 8, 3, true),
        }
      }
    })

    return Object.values(data).map((token: any, i) => {
      return (
        <div key={i} className="lg:flex gap-3 mb-2">
          <div className="w-[20%]">
            <img src={token.img} alt={token.symbol} className="w-8" />
          </div>
          <div className="w-[80%] font-medium text-gray-700 text-left dark:text-white">{token.price}</div>
        </div>
      )
    })
  }

  const List = useMemo(() => {
    if (isLoading)
      return (
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
          <td
            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center "
            colSpan={8}
          >
            <div className="py-[300px]">Loading...</div>
          </td>
        </tr>
      )

    if (!data && !isLoading) {
      return (
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
          <td
            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center "
            colSpan={8}
          >
            <div className="py-[300px]">
              Data loading issue,
              <a
                className="px-3 underline"
                href="https://tfx-market.atlassian.net/servicedesk/customer/portal/1"
                target="_blank"
                rel="noreferrer"
              >
                report issue
              </a>
              .
            </div>
          </td>
        </tr>
      )
    }

    const { tasks }: any = data
    if (!tasks?.length)
      return (
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
          <td
            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center "
            colSpan={8}
          >
            <div className="py-[300px]">No data.</div>
          </td>
        </tr>
      )

    const popperClassName = '!min-w-max !p-[0.5rem] rounded-lg  bg-[#12161c] text-white'

    return tasks.map((task: any, i) => {
      const timeAgo = dayjs.unix(task.timestamp).format('D MMM YYYY, h:mm A')
      return (
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={task.id}>
          <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{task.taskId}</td>
          <td className="px-6 py-4">
            <Popper
              position={'top'}
              className="!no-underline"
              hasArrow
              popperClassName={popperClassName}
              handle={
                <span className="truncate inline-block border rounded-lg bg-[#f8f9fa] text-gray-900 dark:bg-bg-dark-color-2 dark:border-bg-color-2 dark:text-white p-2 text-base min-w-[95px] text-center max-w-[95px]">
                  {methodName[task.signature] ?? task.signature}
                </span>
              }
              renderContent={() => {
                return <>{methodName[task.signature] ?? task.signature}</>
              }}
            />
          </td>
          <td className="px-6 py-4">
            <Popper
              position={'top'}
              className={
                isTimestampMoreThan24HoursAgo(Number(task.timestamp)) ? 'cursor-default !no-underline' : '!no-underline'
              }
              hasArrow
              popperClassName={popperClassName}
              hidden={isTimestampMoreThan24HoursAgo(Number(task.timestamp))}
              handle={
                <div>
                  {isTimestampMoreThan24HoursAgo(Number(task.timestamp)) ? timeAgo : getTimeAgo(task.timestamp)}
                </div>
              }
              renderContent={() => {
                return <>{timeAgo}</>
              }}
            />
          </td>
          <td className="px-6 py-4 w-[250px]">
            <div className="flex items-center gap-2">
              <a
                className="text-[#2188c5]"
                href={`${getExplorerUrl(chainId)}address/${task.account}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {shortenAddress(task.account, 21)}
              </a>
              <span
                onClick={() => {
                  copyToClipboard(task.account)
                  helperToast.success('Address copied to your clipboard')
                }}
                className="cursor-pointer text-[#adb5bd]"
              >
                <BiCopy />
              </span>
            </div>
          </td>
          <td className="px-6 py-4 w-[250px]">
            <div className="flex items-center gap-2">
              <a
                className="text-[#2188c5]"
                href={`${getEnv('REACT_APP_XORACLE_WEB_URL')}/pricefeed/${task.txHashRequest}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {trimRightAddress(task.txHashRequest, 19)}
              </a>
              <span
                onClick={() => {
                  copyToClipboard(task.txHashRequest)
                  helperToast.success('TxHash copied to your clipboard')
                }}
                className="cursor-pointer text-[#adb5bd]"
              >
                <BiCopy />
              </span>
            </div>
          </td>
          <td className="px-6 py-4">
            <div className="flex justify-center">
              {task.status === 'pending' ? (
                <MdVerifiedUser size={16} color="#CCC" />
              ) : task.success && task.fullFillTask.priceList.length === 0 ? (
                <MdVerifiedUser size={16} color="orange" />
              ) : task.fullFillTask.priceList?.length ? (
                <Tooltip
                  className="tooltip-price"
                  handle={<MdVerifiedUser size={16} color="#6439b0" />}
                  position={i > 0 && i > tasks?.length - 6 && tasks?.length > 6 ? 'center-top' : 'center-bottom'}
                  renderContent={() => {
                    return <>{fulfillPrice(task.fullFillTask.priceList)}</>
                  }}
                />
              ) : null}
            </div>
          </td>
          <td className="px-6 py-4">
            <div className="flex justify-center">
              {task.status === 'pending' ? (
                <MdAccessTime size={16} color="#CCC" />
              ) : task.success ? (
                <MdCheckCircleOutline size={16} color="green" />
              ) : (
                <Tooltip
                  handle={<MdError size={16} color="orange" />}
                  position={i > 0 && i === tasks.length - 1 ? 'right-top' : 'right-bottom'}
                  renderContent={() => {
                    return <>{task.message}</>
                  }}
                />
              )}
            </div>
          </td>
        </tr>
      )
    })
  }, [chainId, copyToClipboard, data, isLoading])

  return (
    <div className="default-container page-layout pb-8">
      <div className="block lg:flex gap-10 mb-8 flex-wrap">
        <div className="flex-1">
          <div className="Page-title">Explorer</div>
          {Dasboard}
        </div>
        <div className="min-w-auto sm:min-w-[500px]">
          <SearchForm setSearch={handleSetFilter} methodOptions={methodName} />
        </div>
      </div>
      <div className="text-2xl">
        <div className="relative overflow-x-auto rounded-t-2xl border border-b-0 min-h-[680px] bg-white dark:bg-gray-800">
          <table className="w-full text-left text-gray-500 dark:text-gray-400 text-1-5xl min-w-[1195px]">
            <thead className="text-gray-700 text-1-5xl bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="p-6">
                  #
                </th>
                <th scope="col" className="p-6">
                  Method
                </th>
                <th scope="col" className="p-6">
                  Date Time
                </th>
                <th scope="col" className="p-6">
                  From
                </th>
                <th scope="col" className="p-6">
                  Tx Hash
                </th>
                <th scope="col" className="p-6">
                  Price
                </th>
                <th scope="col" className="p-6">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>{List}</tbody>
          </table>
        </div>
      </div>

      {!!data && <ExplorerPagination {...paginationProps} />}

      <Footer isPaddingX={false} />
    </div>
  )
}

export default Explorer
