import { ARBITRUM_SEPOLIA, DEV_TESTNET, formatAmount, isSupportedChain, USD_DECIMALS } from 'utils'

export default function TooltipCard({
  title,
  total,
  arbitrumSepolia,
  devTestnet,
  showDollar = true,
  decimalsForConversion = USD_DECIMALS,
}: any) {
  return (
    <>
      {/* <p className="Tooltip-row">
        <span className="label">{title} on opBNB Testnet:</span>
        <span className="amount">
          {showDollar && "$"}
          {formatAmount(opBNBTestnet, decimalsForConversion, 0, true)}
        </span>
      </p> */}

      {isSupportedChain(ARBITRUM_SEPOLIA) && (
        <p className="Tooltip-row">
          <span className="label">{title} on Arbitrum Sepolia:</span>
          <span className="amount">
            {showDollar && '$'}
            {formatAmount(arbitrumSepolia, decimalsForConversion, 0, true)}
          </span>
        </p>
      )}

      {isSupportedChain(DEV_TESTNET) && (
        <p className="Tooltip-row">
          <span className="label">{title} on Dev Testnet:</span>
          <span className="amount">
            {showDollar && '$'}
            {formatAmount(devTestnet, decimalsForConversion, 0, true)}
          </span>
        </p>
      )}

      <div className="Tooltip-divider" />
      <p className="Tooltip-row">
        <span className="label">Total:</span>
        <span className="amount">
          {showDollar && '$'}
          {formatAmount(total, decimalsForConversion, 0, true)}
        </span>
      </p>
    </>
  )
}

export function TooltipCardRow({ label, amount, showDollar = true }) {
  return (
    <p className="Tooltip-row">
      <span className="label">{label}:</span>
      <span className="amount">
        {showDollar && '$'}
        {amount}
      </span>
    </p>
  )
}
