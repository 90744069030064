import { useEffect } from 'react'
import dayjs from 'dayjs'

import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

export const useDayJs = () => {
  const { timeZone: currentTimeZone } = Intl.DateTimeFormat().resolvedOptions()

  useEffect(() => {
    dayjs.extend(relativeTime)
    dayjs.extend(updateLocale)
    dayjs.extend(timezone)
    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.updateLocale('en', {
      relativeTime: {
        future: '0s',
        past: '%s ago',
        s: '%ds',
        m: 'a minute',
        mm: '%d minutes',
        h: 'an hour',
        hh: '%d hours',
        d: 'a day',
        dd: '%d days',
        M: 'a month',
        MM: '%d months',
        y: 'a year',
        yy: '%d years',
      },
    })

    dayjs.tz.setDefault(currentTimeZone)
  }, [currentTimeZone])
}
