import { useEffect, useState } from 'react'
import { helperToast, useChainId, useDebounce } from 'utils'
import { getCodeError, getReferralCodeTakenStatus, getSampleReferrarStat } from './referralsHelper'
import { useWeb3 } from 'hooks/useWeb3'
import Card from 'components/Card'
import { PButton } from 'components/Button'
import FieldInput from 'components/FieldInput'
import { useAnalytic } from 'components/Analytic'
import { GA_EVENT_KEY } from 'components/Analytic/constants'

function AddAffiliateCode({
  handleCreateReferralCode,
  active,
  connectWallet,
  setRecentlyAddedCodes,
  recentlyAddedCodes,
}) {
  return (
    <Card className="referral-card txt-color-1 dark:txt-color-4 section-center mt-medium w-full">
      <h2 className="title">Generate Referral Code</h2>
      <p className="sub-title">
        Looks like you don't have a referral code to share. <br /> Create one now and start earning rebates!
      </p>
      <div className="mt-10 mb-5">
        {active ? (
          <AffiliateCodeForm
            handleCreateReferralCode={handleCreateReferralCode}
            recentlyAddedCodes={recentlyAddedCodes}
            setRecentlyAddedCodes={setRecentlyAddedCodes}
          />
        ) : (
          <PButton size="xl" type="submit" onClick={connectWallet}>
            Connect Wallet
          </PButton>
        )}
      </div>
    </Card>
  )
}

interface AffiliateCodeFormProps {
  handleCreateReferralCode: (value: any) => Promise<any>
  recentlyAddedCodes: any[]
  setRecentlyAddedCodes: (value: any[]) => void
  callAfterSuccess?: () => any
}

export function AffiliateCodeForm({
  handleCreateReferralCode,
  recentlyAddedCodes,
  setRecentlyAddedCodes,
  callAfterSuccess,
}: AffiliateCodeFormProps) {
  const [referralCode, setReferralCode] = useState('')
  const [isProcessing, setIsProcessing] = useState(false)
  const [error, setError] = useState('')
  const [referralCodeCheckStatus, setReferralCodeCheckStatus] = useState('ok')
  const debouncedReferralCode = useDebounce(referralCode, 300)
  const { account } = useWeb3()
  const { chainId } = useChainId()
  const { sendEvent } = useAnalytic()

  useEffect(() => {
    let cancelled = false
    const checkCodeTakenStatus = async () => {
      if (error) {
        setReferralCodeCheckStatus('ok')
        return
      }
      const { status: takenStatus } = await getReferralCodeTakenStatus(account, debouncedReferralCode, chainId)
      // ignore the result if the referral code to check has changed
      if (cancelled) {
        return
      }
      if (takenStatus === 'none') {
        setReferralCodeCheckStatus('ok')
      } else {
        setReferralCodeCheckStatus('taken')
      }
    }
    setReferralCodeCheckStatus('checking')
    checkCodeTakenStatus()
    return () => {
      cancelled = true
    }
  }, [account, debouncedReferralCode, error, chainId])

  function getButtonError() {
    if (!debouncedReferralCode) {
      return 'Enter a code'
    }
    if (referralCodeCheckStatus === 'taken') {
      return 'Code already taken'
    }
    if (referralCodeCheckStatus === 'checking') {
      return 'Checking code...'
    }

    return false
  }

  const buttonError = getButtonError()

  function getPrimaryText() {
    if (buttonError) {
      return buttonError
    }

    if (isProcessing) {
      return 'Creating...'
    }

    return 'Create'
  }
  function isPrimaryEnabled() {
    if (buttonError) {
      return false
    }
    if (error || isProcessing) {
      return false
    }
    return true
  }

  async function handleSubmit(event) {
    event.preventDefault()
    setIsProcessing(true)
    const { status: takenStatus, info: takenInfo } = await getReferralCodeTakenStatus(account, referralCode, chainId)
    if (['all', 'current', 'other'].includes(takenStatus)) {
      setIsProcessing(false)
    }

    if (takenStatus === 'none' || takenStatus === 'other') {
      const ownerOnOtherNetwork = takenInfo['ownerDevTestnet']
      try {
        const tx = await handleCreateReferralCode(referralCode)
        if (callAfterSuccess) {
          callAfterSuccess()
        }
        const receipt = await tx.wait()
        if (receipt.status === 1) {
          recentlyAddedCodes.push(getSampleReferrarStat(referralCode, ownerOnOtherNetwork, account))

          sendEvent({
            category: GA_EVENT_KEY.REFERRAL.CATEGORY,
            action: GA_EVENT_KEY.REFERRAL.ACTION.SUBMIT_CREATE_CODE,
            label: referralCode,
          })

          helperToast.success('Referral code created!')
          setRecentlyAddedCodes(recentlyAddedCodes)
          setReferralCode('')
        }
      } catch (err) {
        console.error(err)
      } finally {
        setIsProcessing(false)
      }
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <FieldInput
        type="text"
        value={referralCode}
        disabled={isProcessing}
        placeholder="Enter a code"
        label="Referral Code"
        onChange={({ target }) => {
          const { value } = target
          setReferralCode(value)
          setError(getCodeError(value))
        }}
      />
      {error && <p className="error">{error}</p>}
      <PButton type="submit" size="xl" disabled={!isPrimaryEnabled()}>
        {getPrimaryText()}
      </PButton>
    </form>
  )
}

export default AddAffiliateCode
