import cx from 'classnames'
import { ethers } from 'ethers'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import useSWR from 'swr'
import { useAccount } from 'wagmi'

import { getContract } from '@tfx/addresses'
import { getPositionKey, getPositionQuery, getPositions } from '@tfx/tfx-sdk'
import { getToken, getTokenBySymbol, getTokens, getWhitelistedTokens } from '@tfx/tokens'
import { Footer } from '@tfx/ui'

import Reader from 'abis/Reader.json'
import Router from 'abis/Router.json'
import Token from 'abis/Token.json'
import VaultV2 from 'abis/Vault.json'

import Checkbox from 'components/Checkbox/Checkbox'
import { getChartToken } from 'components/Exchange/ExchangeTVChart'
import ExchangeWalletTokens from 'components/Exchange/ExchangeWalletTokens'
import OrdersList from 'components/Exchange/OrdersList'
import PositionsList from 'components/Exchange/PositionsList'
import SwapBox from 'components/Exchange/SwapBox'
import TradeHistory from 'components/Exchange/TradeHistory'
import Tab from 'components/Tab/Tab'
import { getConstant } from 'config/chains'
import { useConstants } from 'hooks/useConstant'
import { useTheme } from 'hooks/useTheme'
import { useWeb3 } from 'hooks/useWeb3'
import {
  FUNDING_RATE_PRECISION,
  LONG,
  SHORT,
  SWAP,
  USD_DECIMALS,
  bigNumberify,
  fetcher,
  formatAmount,
  getExplorerUrl,
  getPageTitle,
  getTokenInfo,
  helperToast,
  useAccountOrders,
  useChainId,
  useLocalStorageByChainId,
} from 'utils'
import { approvePlugin, cancelMultipleOrders, useInfoTokens, useMinExecutionFee } from 'utils/api'
import ExchangeTVChart from './ExchangeTVChart'

import './Exchange.css'

const { AddressZero } = ethers.constants

const PENDING_POSITION_VALID_DURATION = 600 * 1000
const UPDATED_POSITION_VALID_DURATION = 60 * 1000

const notifications = {}

function pushSuccessNotification(chainId, message, e) {
  const { transactionHash } = e
  const id = ethers.utils.id(message + transactionHash)
  if (notifications[id]) {
    return
  }

  notifications[id] = true

  const txUrl = getExplorerUrl(chainId) + 'tx/' + transactionHash
  helperToast.success(
    <div>
      {message}{' '}
      <a href={txUrl} target="_blank" rel="noopener noreferrer">
        View
      </a>
    </div>,
  )
}

function pushErrorNotification(chainId, message, e) {
  const { transactionHash } = e
  const id = ethers.utils.id(message + transactionHash)
  if (notifications[id]) {
    return
  }

  notifications[id] = true

  const txUrl = getExplorerUrl(chainId) + 'tx/' + transactionHash
  helperToast.error(
    <div>
      {message}{' '}
      <a href={txUrl} target="_blank" rel="noopener noreferrer">
        View
      </a>
    </div>,
  )
}

function getFundingFee(data) {
  let { entryFundingRate, cumulativeFundingRate, size } = data
  if (entryFundingRate && cumulativeFundingRate) {
    return size.mul(cumulativeFundingRate.sub(entryFundingRate)).div(FUNDING_RATE_PRECISION)
  }
  return
}

const getTokenAddress = (token, nativeTokenAddress) => {
  if (token.address === AddressZero) {
    return nativeTokenAddress
  }
  return token.address
}

export const Exchange = forwardRef((props, ref) => {
  const {
    savedIsPnlInLeverage,
    setSavedIsPnlInLeverage,
    savedShowPnlAfterFees,
    savedSlippageAmount,
    pendingTxns,
    setPendingTxns,
    connectWallet,
    savedShouldDisableOrderValidation,
    savedShouldShowPositionLines,
    setSavedShouldShowPositionLines,
  } = props

  const { mounted } = useTheme()

  const [pendingPositions, setPendingPositions] = useState({})
  const [updatedPositions, setUpdatedPositions] = useState({})

  const [orderPaginate, setOrderPaginate] = useState(10)

  const { active, account, library } = useWeb3()
  const { chainId } = useChainId()
  const { chain } = useAccount()

  const currentAccount = account

  const nativeTokenAddress = getContract(chainId, 'NATIVE_TOKEN')

  const vaultAddress = getContract(chainId, 'Vault')
  const vaultPositionController = getContract(chainId, 'VaultPositionController')
  const positionRouterAddress = getContract(chainId, 'PositionRouter')
  const readerAddress = getContract(chainId, 'Reader')
  const usdxAddress = getContract(chainId, 'USDX')

  const whitelistedTokens = getWhitelistedTokens(chainId)
  const whitelistedTokenAddresses = whitelistedTokens.map((token) => token.address)

  const positionQuery = getPositionQuery(whitelistedTokens, nativeTokenAddress)

  const { data: constants } = useConstants(chainId, library)

  const marginFeeBasisPoints = constants.MARGIN_FEE_BASIS_POINTS
  const liquidationFee = constants.LIQUIDATION_FEE
  const maxLeverage = constants.MAX_LEVERAGE

  const defaultCollateralSymbol = getConstant(chainId, 'defaultCollateralSymbol')
  const defaultTokenSelection = useMemo(
    () => ({
      [SWAP]: {
        from: AddressZero,
        to: getTokenBySymbol(chainId, defaultCollateralSymbol).address,
      },
      [LONG]: {
        from: AddressZero,
        to: AddressZero,
      },
      [SHORT]: {
        from: getTokenBySymbol(chainId, defaultCollateralSymbol).address,
        to: AddressZero,
      },
    }),
    [chainId, defaultCollateralSymbol],
  )

  const [tokenSelection, setTokenSelection] = useLocalStorageByChainId(
    chainId,
    'Exchange-token-selection-v2',
    defaultTokenSelection,
  )
  const [swapOption, setSwapOption] = useLocalStorageByChainId(chainId, 'Swap-option-v2', LONG)

  const fromTokenAddress = tokenSelection[swapOption].from
  const toTokenAddress = tokenSelection[swapOption].to

  const setFromTokenAddress = useCallback(
    (selectedSwapOption, address) => {
      const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection))
      newTokenSelection[selectedSwapOption].from = address
      setTokenSelection(newTokenSelection)
    },
    [tokenSelection, setTokenSelection],
  )

  const setToTokenAddress = useCallback(
    (selectedSwapOption, address) => {
      const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection))
      newTokenSelection[selectedSwapOption].to = address
      if (selectedSwapOption === LONG || selectedSwapOption === SHORT) {
        newTokenSelection[LONG].to = address
        newTokenSelection[SHORT].to = address
      }
      setTokenSelection(newTokenSelection)
    },
    [tokenSelection, setTokenSelection],
  )

  const setMarket = (selectedSwapOption, toTokenAddress) => {
    setSwapOption(selectedSwapOption)
    const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection))
    newTokenSelection[selectedSwapOption].to = toTokenAddress
    if (selectedSwapOption === LONG || selectedSwapOption === SHORT) {
      newTokenSelection[LONG].to = toTokenAddress
      newTokenSelection[SHORT].to = toTokenAddress
    }
    setTokenSelection(newTokenSelection)
  }

  const [isConfirming, setIsConfirming] = useState(false)
  const [isPendingConfirmation, setIsPendingConfirmation] = useState(false)

  const tokens = getTokens(chainId)

  const tokenAddresses = tokens.map((token) => token.address)

  const { data: tokenBalances } = useSWR(active && [active, chainId, readerAddress, 'getTokenBalances', account], {
    fetcher: fetcher(library, Reader, [tokenAddresses]),
  })

  const { data: positionData, error: positionDataError } = useSWR(
    active && [active, chainId, readerAddress, 'getPositions', vaultPositionController, account],
    {
      fetcher: fetcher(library, Reader, [
        positionQuery.collateralTokens,
        positionQuery.indexTokens,
        positionQuery.isLong,
      ]),
    },
  )

  const positionsDataIsLoading = active && !positionData && !positionDataError

  const { data: fundingRateInfo } = useSWR([active, chainId, readerAddress, 'getFundingRates'], {
    fetcher: fetcher(library, Reader, [vaultAddress, nativeTokenAddress, whitelistedTokenAddresses]),
  })

  const { data: totalTokenWeights } = useSWR(
    [`Exchange:totalTokenWeights:${active}`, chainId, vaultAddress, 'totalTokenWeights'],
    {
      fetcher: fetcher(library, VaultV2),
    },
  )

  const { data: usdxSupply } = useSWR([`Exchange:usdxSupply:${active}`, chainId, usdxAddress, 'totalSupply'], {
    fetcher: fetcher(library, Token),
  })

  const orderBookAddress = getContract(chainId, 'OrderBook')
  const routerAddress = getContract(chainId, 'Router')
  const { data: orderBookApproved } = useSWR(
    active && [active, chainId, routerAddress, 'approvedPlugins', account, orderBookAddress],
    {
      fetcher: fetcher(library, Router),
    },
  )

  const { data: positionRouterApproved } = useSWR(
    active && [active, chainId, routerAddress, 'approvedPlugins', account, positionRouterAddress],
    {
      fetcher: fetcher(library, Router),
    },
  )

  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, fundingRateInfo)
  const { minExecutionFee, minExecutionFeeUSD, minExecutionFeeErrorMessage } = useMinExecutionFee(
    library,
    active,
    chainId,
    infoTokens,
  )

  useEffect(() => {
    const fromToken = getTokenInfo(infoTokens, fromTokenAddress)
    const toToken = getTokenInfo(infoTokens, toTokenAddress)
    let selectedToken = getChartToken(swapOption, fromToken, toToken, chainId)
    let currentTokenPriceStr = formatAmount(selectedToken.maxPrice, USD_DECIMALS, 2, true)
    let title = getPageTitle(currentTokenPriceStr + ` | ${selectedToken.symbol}${selectedToken.isStable ? '' : 'USD'}`)
    document.title = title
  }, [tokenSelection, swapOption, infoTokens, chainId, fromTokenAddress, toTokenAddress])

  const { positions, positionsMap } = getPositions(
    chainId,
    positionQuery,
    positionData,
    infoTokens,
    savedIsPnlInLeverage,
    savedShowPnlAfterFees,
    account,
    pendingPositions,
    updatedPositions,
    marginFeeBasisPoints,
    liquidationFee,
    maxLeverage,
  )

  useImperativeHandle(ref, () => ({
    onUpdatePosition(key, size, collateral, averagePrice, entryFundingRate, reserveAmount, realisedPnl) {
      for (let i = 0; i < positions.length; i++) {
        const position = positions[i]
        if (position.contractKey === key) {
          updatedPositions[position.key] = {
            size,
            collateral,
            averagePrice,
            entryFundingRate,
            reserveAmount,
            realisedPnl,
            updatedAt: Date.now(),
          }
          setUpdatedPositions({ ...updatedPositions })
          break
        }
      }
    },
    onClosePosition(key, size, collateral, averagePrice, entryFundingRate, reserveAmount, realisedPnl, e) {
      for (let i = 0; i < positions.length; i++) {
        const position = positions[i]
        if (position.contractKey === key) {
          updatedPositions[position.key] = {
            size: bigNumberify(0),
            collateral: bigNumberify(0),
            averagePrice,
            entryFundingRate,
            reserveAmount,
            realisedPnl,
            updatedAt: Date.now(),
          }
          setUpdatedPositions({ ...updatedPositions })
          break
        }
      }
    },
    onIncreasePosition(key, account, collateralToken, indexToken, collateralDelta, sizeDelta, isLong, price, fee, e) {
      if (account !== currentAccount) {
        return
      }

      const indexTokenItem = getToken(chainId, indexToken)
      const tokenSymbol = indexTokenItem.isWrapped ? getConstant(chainId, 'nativeTokenSymbol') : indexTokenItem.symbol

      let message
      if (sizeDelta.eq(0)) {
        message = `Deposited ${formatAmount(collateralDelta, USD_DECIMALS, 2, true)} USD into ${tokenSymbol} ${
          isLong ? 'Long' : 'Short.'
        }`
      } else {
        message = `Increased ${tokenSymbol} ${isLong ? 'Long' : 'Short'}, +${formatAmount(
          sizeDelta,
          USD_DECIMALS,
          2,
          true,
        )} USD.`
      }

      pushSuccessNotification(chainId, message, e)
    },

    onDecreasePosition(key, account, collateralToken, indexToken, collateralDelta, sizeDelta, isLong, price, fee, e) {
      if (account !== currentAccount) {
        return
      }

      const indexTokenItem = getToken(chainId, indexToken)
      const tokenSymbol = indexTokenItem.isWrapped ? getConstant(chainId, 'nativeTokenSymbol') : indexTokenItem.symbol

      let message
      if (sizeDelta.eq(0)) {
        message = `Withdrew ${formatAmount(collateralDelta, USD_DECIMALS, 2, true)} USD from ${tokenSymbol} ${
          isLong ? 'Long' : 'Short'
        }.`
      } else {
        message = `Decreased ${tokenSymbol} ${isLong ? 'Long' : 'Short'}, -${formatAmount(
          sizeDelta,
          USD_DECIMALS,
          2,
          true,
        )} USD.`
      }

      pushSuccessNotification(chainId, message, e)
    },

    onCancelIncreasePosition(
      account,
      path,
      indexToken,
      amountIn,
      minOut,
      sizeDelta,
      isLong,
      acceptablePrice,
      executionFee,
      blockGap,
      timeGap,
      e,
    ) {
      if (account !== currentAccount) {
        return
      }
      const indexTokenItem = getToken(chainId, indexToken)
      const tokenSymbol = indexTokenItem.isWrapped ? getConstant(chainId, 'nativeTokenSymbol') : indexTokenItem.symbol

      const message = `Could not increase ${tokenSymbol} ${
        isLong ? 'Long' : 'Short'
      } within the allowed slippage, you can adjust the allowed slippage in the settings on the top right of the page.`

      pushErrorNotification(chainId, message, e)

      const key = getPositionKey(account, path[path.length - 1], indexToken, isLong)
      pendingPositions[key] = {}
      setPendingPositions({ ...pendingPositions })
    },

    onCancelDecreasePosition(
      account,
      path,
      indexToken,
      collateralDelta,
      sizeDelta,
      isLong,
      receiver,
      acceptablePrice,
      minOut,
      executionFee,
      blockGap,
      timeGap,
      e,
    ) {
      if (account !== currentAccount) {
        return
      }
      const indexTokenItem = getToken(chainId, indexToken)
      const tokenSymbol = indexTokenItem.isWrapped ? getConstant(chainId, 'nativeTokenSymbol') : indexTokenItem.symbol

      const message = `Could not decrease ${tokenSymbol} ${
        isLong ? 'Long' : 'Short'
      } within the allowed slippage, you can adjust the allowed slippage in the settings on the top right of the page.`

      pushErrorNotification(chainId, message, e)

      const key = getPositionKey(account, path[path.length - 1], indexToken, isLong)
      pendingPositions[key] = {}
      setPendingPositions({ ...pendingPositions })
    },

    onSwap(taskId, account, success, message, data, rest) {
      // owner, path, amountIn, amountOut, receiver, rest

      if (account !== currentAccount) {
        return
      }

      let txData = data
      if (data.substring(0, 2) !== '0x') {
        txData = `0x${data}`
      }

      // FulfillSwap
      if (txData.indexOf('0x348621c8') !== -1 || txData.indexOf('0x635e2a2e') !== -1) {
        const params = ethers.utils.defaultAbiCoder.decode(
          ['address', 'address[]', 'uint256', 'uint256', 'address'],
          ethers.utils.hexDataSlice(txData, 4),
        )

        const path = params[1]

        if (path.length < 2) {
          pushSuccessNotification(chainId, 'Swap is done.', rest)
          return
        }

        const fromTokenSymbol = getToken(chainId, path[0])
        const toTokenSymbol = getToken(chainId, path[1])
        const amountIn = formatAmount(params[2], 18, 4, true)
        const amountOut = formatAmount(params[3], 18, 4, true)

        if (success) {
          const successMsg = `Swaped ${amountIn} ${fromTokenSymbol?.symbol} for ${amountOut} ${toTokenSymbol?.symbol}!`
          pushSuccessNotification(chainId, successMsg, rest)
          return
        }
        pushErrorNotification(chainId, `FulfillSwap: ${message}`, rest)
        return
      }
    },
  }))

  const flagOrdersEnabled = true
  const [orders, , , totalOrder] = useAccountOrders(flagOrdersEnabled, null, orderPaginate)

  const [isWaitingForPluginApproval, setIsWaitingForPluginApproval] = useState(false)
  const [isWaitingForPositionRouterApproval, setIsWaitingForPositionRouterApproval] = useState(false)
  const [isPluginApproving, setIsPluginApproving] = useState(false)
  const [isPositionRouterApproving, setIsPositionRouterApproving] = useState(false)
  const [isCancelMultipleOrderProcessing, setIsCancelMultipleOrderProcessing] = useState(false)
  const [cancelOrderIdList, setCancelOrderIdList] = useState([])

  const onMultipleCancelClick = useCallback(
    async function () {
      setIsCancelMultipleOrderProcessing(true)
      try {
        const tx = await cancelMultipleOrders(chainId, library, cancelOrderIdList, {
          successMsg: 'Orders cancelled.',
          failMsg: 'Cancel failed.',
          sentMsg: 'Cancel submitted.',
          pendingTxns,
          setPendingTxns,
        })
        const receipt = await tx.wait()
        if (receipt.status === 1) {
          setCancelOrderIdList([])
        }
      } catch (error) {
        console.error(error)
      } finally {
        setIsCancelMultipleOrderProcessing(false)
      }
    },
    [
      chainId,
      library,
      pendingTxns,
      setPendingTxns,
      setCancelOrderIdList,
      cancelOrderIdList,
      setIsCancelMultipleOrderProcessing,
    ],
  )

  const renderChart = useMemo(() => {
    if (!mounted) return null
    return (
      <div className="TVBox">
        <ExchangeTVChart
          fromTokenAddress={fromTokenAddress}
          toTokenAddress={toTokenAddress}
          infoTokens={infoTokens}
          swapOption={swapOption}
          chainId={chainId}
          positions={positions}
          savedShouldShowPositionLines={savedShouldShowPositionLines}
          orders={orders}
          setToTokenAddress={setToTokenAddress}
        />
      </div>
    )
  }, [
    chainId,
    fromTokenAddress,
    infoTokens,
    mounted,
    orders,
    positions,
    savedShouldShowPositionLines,
    setToTokenAddress,
    swapOption,
    toTokenAddress,
  ])

  const approveOrderBook = () => {
    setIsPluginApproving(true)
    return approvePlugin(chainId, orderBookAddress, {
      library,
      pendingTxns,
      setPendingTxns,
      sentMsg: 'Enable orders sent.',
      failMsg: 'Enable orders failed.',
    })
      .then(() => {
        setIsWaitingForPluginApproval(true)
      })
      .finally(() => {
        setIsPluginApproving(false)
      })
  }

  const approvePositionRouter = ({ sentMsg, failMsg }) => {
    setIsPositionRouterApproving(true)
    return approvePlugin(chainId, positionRouterAddress, {
      library,
      pendingTxns,
      setPendingTxns,
      sentMsg,
      failMsg,
    })
      .then(() => {
        setIsWaitingForPositionRouterApproval(true)
      })
      .finally(() => {
        setIsPositionRouterApproving(false)
      })
  }

  const LIST_SECTIONS = ['Positions', flagOrdersEnabled ? 'Orders' : undefined, 'History'].filter(Boolean)
  let [listSection, setListSection] = useLocalStorageByChainId(chainId, 'List-section-v2', LIST_SECTIONS[0])
  const LIST_SECTIONS_LABELS = {
    Orders: chain && orders.length ? `Orders (${orders.length})` : undefined,
    Positions: chain && positions.length ? `Positions (${positions.length})` : undefined,
  }
  if (!LIST_SECTIONS.includes(listSection)) {
    listSection = LIST_SECTIONS[0]
  }

  if (!getToken(chainId, toTokenAddress)) {
    return null
  }

  const renderCancelOrderButton = () => {
    const orderText = cancelOrderIdList.length > 1 ? 'orders' : 'order'
    if (cancelOrderIdList.length === 0) return
    return (
      <button
        className="text-xl underline mr-6 inline-block text-txt-color-2"
        disabled={isCancelMultipleOrderProcessing}
        type="button"
        onClick={onMultipleCancelClick}
      >
        Cancel {cancelOrderIdList.length} {orderText}
      </button>
    )
  }

  const onClickMoreOrder = () => {
    setOrderPaginate(orderPaginate + 10)
  }

  const getListSection = () => {
    return (
      <div>
        <div className="Exchange-list-tab-container">
          <div>
            <Tab
              options={LIST_SECTIONS}
              optionLabels={LIST_SECTIONS_LABELS}
              option={listSection}
              onChange={(section) => setListSection(section)}
              type="inline-flex"
              className="Exchange-list-tabs border"
            />
          </div>
          <div className="align-right Exchange-should-show-position-lines">
            {renderCancelOrderButton()}
            <Checkbox
              isChecked={savedShouldShowPositionLines}
              setIsChecked={setSavedShouldShowPositionLines}
              className={cx('muted chart-positions', {
                active: savedShouldShowPositionLines,
              })}
            >
              <span>Chart positions</span>
            </Checkbox>
          </div>
        </div>

        {listSection === 'Positions' && (
          <PositionsList
            positionsDataIsLoading={positionsDataIsLoading}
            pendingPositions={pendingPositions}
            setPendingPositions={setPendingPositions}
            setListSection={setListSection}
            setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
            setIsWaitingForPositionRouterApproval={setIsWaitingForPositionRouterApproval}
            approveOrderBook={approveOrderBook}
            approvePositionRouter={approvePositionRouter}
            isPluginApproving={isPluginApproving}
            isPositionRouterApproving={isPositionRouterApproving}
            isWaitingForPluginApproval={isWaitingForPluginApproval}
            isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
            orderBookApproved={orderBookApproved}
            positionRouterApproved={positionRouterApproved}
            positions={positions}
            positionsMap={positionsMap}
            infoTokens={infoTokens}
            active={active}
            account={account}
            library={library}
            pendingTxns={pendingTxns}
            setPendingTxns={setPendingTxns}
            flagOrdersEnabled={flagOrdersEnabled}
            savedIsPnlInLeverage={savedIsPnlInLeverage}
            chainId={chainId}
            nativeTokenAddress={nativeTokenAddress}
            setMarket={setMarket}
            orders={orders}
            showPnlAfterFees={savedShowPnlAfterFees}
            minExecutionFee={minExecutionFee}
            minExecutionFeeUSD={minExecutionFeeUSD}
            minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
          />
        )}
        {listSection === 'Orders' && (
          <OrdersList
            account={account}
            active={active}
            library={library}
            pendingTxns={pendingTxns}
            setPendingTxns={setPendingTxns}
            infoTokens={infoTokens}
            positionsMap={positionsMap}
            chainId={chainId}
            orders={orders}
            totalTokenWeights={totalTokenWeights}
            usdxSupply={usdxSupply}
            savedShouldDisableOrderValidation={savedShouldDisableOrderValidation}
            cancelOrderIdList={cancelOrderIdList}
            setCancelOrderIdList={setCancelOrderIdList}
            onClickMoreOrder={onClickMoreOrder}
            isHasPaginate={totalOrder > orderPaginate}
          />
        )}
        {listSection === 'History' && (
          <TradeHistory
            account={account}
            forSingleAccount={true}
            infoTokens={infoTokens}
            getTokenInfo={getTokenInfo}
            chainId={chainId}
            nativeTokenAddress={nativeTokenAddress}
          />
        )}
      </div>
    )
  }

  const onSelectWalletToken = (token) => {
    setFromTokenAddress(swapOption, token.address)
  }

  return (
    <div className="Exchange page-layout">
      <div className="Exchange-content">
        <div className="Exchange-left lg:order-none order-1">
          {renderChart}
          <div className="Exchange-lists large">{getListSection()}</div>
        </div>
        <div className="Exchange-right">
          <SwapBox
            pendingPositions={pendingPositions}
            setPendingPositions={setPendingPositions}
            setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
            setIsWaitingForPositionRouterApproval={setIsWaitingForPositionRouterApproval}
            approveOrderBook={approveOrderBook}
            approvePositionRouter={approvePositionRouter}
            isPluginApproving={isPluginApproving}
            isPositionRouterApproving={isPositionRouterApproving}
            isWaitingForPluginApproval={isWaitingForPluginApproval}
            isWaitingForPositionRouterApproval={isWaitingForPositionRouterApproval}
            orderBookApproved={orderBookApproved}
            positionRouterApproved={positionRouterApproved}
            orders={orders}
            flagOrdersEnabled={flagOrdersEnabled}
            chainId={chainId}
            infoTokens={infoTokens}
            active={active}
            connectWallet={connectWallet}
            library={library}
            account={account}
            positionsMap={positionsMap}
            fromTokenAddress={fromTokenAddress}
            setFromTokenAddress={setFromTokenAddress}
            toTokenAddress={toTokenAddress}
            setToTokenAddress={setToTokenAddress}
            swapOption={swapOption}
            setSwapOption={setSwapOption}
            pendingTxns={pendingTxns}
            setPendingTxns={setPendingTxns}
            tokenSelection={tokenSelection}
            setTokenSelection={setTokenSelection}
            isConfirming={isConfirming}
            setIsConfirming={setIsConfirming}
            isPendingConfirmation={isPendingConfirmation}
            setIsPendingConfirmation={setIsPendingConfirmation}
            savedIsPnlInLeverage={savedIsPnlInLeverage}
            setSavedIsPnlInLeverage={setSavedIsPnlInLeverage}
            nativeTokenAddress={nativeTokenAddress}
            savedSlippageAmount={savedSlippageAmount}
            totalTokenWeights={totalTokenWeights}
            usdxSupply={usdxSupply}
            savedShouldDisableOrderValidation={savedShouldDisableOrderValidation}
            minExecutionFee={minExecutionFee}
            minExecutionFeeUSD={minExecutionFeeUSD}
            minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
          />
          <div className="Exchange-wallet-tokens">
            <div className="Exchange-wallet-tokens-content">
              <ExchangeWalletTokens tokens={tokens} infoTokens={infoTokens} onSelectToken={onSelectWalletToken} />
            </div>
          </div>
        </div>
        <div className="Exchange-lists small lg:order-none order-1">{getListSection()}</div>
      </div>
      <Footer />
    </div>
  )
})
