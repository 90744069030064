export function nFormatter(num: number, digits: number) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value
    })

  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : digits > 0
    ? num.toFixed(digits)
    : '0'
}

export function trim(number = 0, precision = 0) {
  // why would number ever be undefined??? what are we trimming?
  const array = number.toString().split('.')
  if (array.length === 1) return number.toString()
  if (precision === 0) return array[0].toString()

  const poppedNumber = array.pop() || '0'
  array.push(poppedNumber.substring(0, precision))
  const trimmedNumber = array.join('.')
  return trimmedNumber
}
