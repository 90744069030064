// src/chainId.ts
var ChainId = /* @__PURE__ */ ((ChainId2) => {
  ChainId2[ChainId2["ETHEREUM"] = 1] = "ETHEREUM";
  ChainId2[ChainId2["GOERLI"] = 5] = "GOERLI";
  ChainId2[ChainId2["BSC"] = 56] = "BSC";
  ChainId2[ChainId2["BSC_TESTNET"] = 97] = "BSC_TESTNET";
  ChainId2[ChainId2["ZKSYNC_TESTNET"] = 280] = "ZKSYNC_TESTNET";
  ChainId2[ChainId2["ZKSYNC"] = 324] = "ZKSYNC";
  ChainId2[ChainId2["OPBNB_TESTNET"] = 5611] = "OPBNB_TESTNET";
  ChainId2[ChainId2["OPBNB"] = 204] = "OPBNB";
  ChainId2[ChainId2["POLYGON_ZKEVM"] = 1101] = "POLYGON_ZKEVM";
  ChainId2[ChainId2["POLYGON_ZKEVM_TESTNET"] = 1442] = "POLYGON_ZKEVM_TESTNET";
  ChainId2[ChainId2["ARBITRUM_ONE"] = 42161] = "ARBITRUM_ONE";
  ChainId2[ChainId2["ARBITRUM_GOERLI"] = 421613] = "ARBITRUM_GOERLI";
  ChainId2[ChainId2["SCROLL_SEPOLIA"] = 534351] = "SCROLL_SEPOLIA";
  ChainId2[ChainId2["ARBITRUM_SEPOLIA"] = 421614] = "ARBITRUM_SEPOLIA";
  ChainId2[ChainId2["LINEA"] = 59144] = "LINEA";
  ChainId2[ChainId2["LINEA_TESTNET"] = 59140] = "LINEA_TESTNET";
  ChainId2[ChainId2["BASE"] = 8453] = "BASE";
  ChainId2[ChainId2["BASE_TESTNET"] = 84531] = "BASE_TESTNET";
  ChainId2[ChainId2["DEV_TESTNET"] = 1112] = "DEV_TESTNET";
  return ChainId2;
})(ChainId || {});

// src/chainNames.ts
var chainNames = {
  [1 /* ETHEREUM */]: "eth",
  [5 /* GOERLI */]: "goerli",
  [56 /* BSC */]: "bsc",
  [97 /* BSC_TESTNET */]: "bscTestnet",
  [42161 /* ARBITRUM_ONE */]: "arb",
  [421613 /* ARBITRUM_GOERLI */]: "arbGoerli",
  [1101 /* POLYGON_ZKEVM */]: "polygonZkEVM",
  [1442 /* POLYGON_ZKEVM_TESTNET */]: "polygonZkEVMTestnet",
  [324 /* ZKSYNC */]: "zkSync",
  [280 /* ZKSYNC_TESTNET */]: "zkSyncTestnet",
  [59144 /* LINEA */]: "linea",
  [59140 /* LINEA_TESTNET */]: "lineaTestnet",
  [204 /* OPBNB */]: "opBNB",
  [5611 /* OPBNB_TESTNET */]: "opBnbTestnet",
  [8453 /* BASE */]: "base",
  [84531 /* BASE_TESTNET */]: "baseTestnet",
  [534351 /* SCROLL_SEPOLIA */]: "scrollSepolia",
  [1112 /* DEV_TESTNET */]: "devTestnet",
  [421614 /* ARBITRUM_SEPOLIA */]: "arbSepolia"
};
var chainDisplayNames = {
  [1 /* ETHEREUM */]: "Ethereum",
  [5 /* GOERLI */]: "Goerli",
  [56 /* BSC */]: "BSC",
  [97 /* BSC_TESTNET */]: "BSC Testnet",
  [42161 /* ARBITRUM_ONE */]: "Arbitrum",
  [421613 /* ARBITRUM_GOERLI */]: "Arbitrum Goerli",
  [1101 /* POLYGON_ZKEVM */]: "Polygon ZkEVM",
  [1442 /* POLYGON_ZKEVM_TESTNET */]: "Polygon ZkEVM Testnet",
  [324 /* ZKSYNC */]: "zkSync",
  [280 /* ZKSYNC_TESTNET */]: "zkSync Testnet",
  [59144 /* LINEA */]: "Linea",
  [59140 /* LINEA_TESTNET */]: "Linea Testnet",
  [204 /* OPBNB */]: "opBNB",
  [5611 /* OPBNB_TESTNET */]: "opBnbTestnet",
  [8453 /* BASE */]: "Base",
  [84531 /* BASE_TESTNET */]: "Base Testnet",
  [534351 /* SCROLL_SEPOLIA */]: "Scroll Sepolia",
  [1112 /* DEV_TESTNET */]: "Dev Testnet",
  [421614 /* ARBITRUM_SEPOLIA */]: "Arbitrum Sepolia"
};
var chainNameToChainId = Object.entries(chainNames).reduce(
  (acc, [chainId, chainName]) => {
    return {
      [chainName]: chainId,
      ...acc
    };
  },
  {}
);
var chainNameToChainDisplayNames = Object.entries(chainNames).reduce(
  (acc, [chainId, chainName]) => {
    return {
      [chainName]: chainDisplayNames[chainId],
      ...acc
    };
  },
  {}
);

// src/subgraphs.ts
var CORE_SUBGRAPHS = {
  [1 /* ETHEREUM */]: null,
  [5 /* GOERLI */]: null,
  [56 /* BSC */]: null,
  [97 /* BSC_TESTNET */]: null,
  [42161 /* ARBITRUM_ONE */]: null,
  [421613 /* ARBITRUM_GOERLI */]: null,
  [1101 /* POLYGON_ZKEVM */]: null,
  [1442 /* POLYGON_ZKEVM_TESTNET */]: null,
  [324 /* ZKSYNC */]: null,
  [280 /* ZKSYNC_TESTNET */]: null,
  [59144 /* LINEA */]: null,
  [59140 /* LINEA_TESTNET */]: "https://linea-testnet-subgraph.0xnode.cloud/subgraphs/name/tfx/tfx-core-v2",
  [8453 /* BASE */]: null,
  [84531 /* BASE_TESTNET */]: null,
  [204 /* OPBNB */]: null,
  [5611 /* OPBNB_TESTNET */]: null,
  [534351 /* SCROLL_SEPOLIA */]: null,
  [1112 /* DEV_TESTNET */]: "https://develop-subgraph.0xnode.cloud/subgraphs/name/tfx/tfx-core",
  [421614 /* ARBITRUM_SEPOLIA */]: "https://arb-testnet-subgraph.0xnode.cloud/subgraphs/name/tfx/tfx-core-mock"
};
var REFERRAL_SUBGRAPHS = {
  [1 /* ETHEREUM */]: null,
  [5 /* GOERLI */]: null,
  [56 /* BSC */]: null,
  [97 /* BSC_TESTNET */]: null,
  [42161 /* ARBITRUM_ONE */]: null,
  [421613 /* ARBITRUM_GOERLI */]: null,
  [1101 /* POLYGON_ZKEVM */]: null,
  [1442 /* POLYGON_ZKEVM_TESTNET */]: null,
  [324 /* ZKSYNC */]: null,
  [280 /* ZKSYNC_TESTNET */]: null,
  [59144 /* LINEA */]: null,
  [59140 /* LINEA_TESTNET */]: "https://linea-testnet-subgraph.0xnode.cloud/subgraphs/name/tfx/referrals-v2",
  [8453 /* BASE */]: null,
  [84531 /* BASE_TESTNET */]: null,
  [204 /* OPBNB */]: null,
  [5611 /* OPBNB_TESTNET */]: null,
  [534351 /* SCROLL_SEPOLIA */]: null,
  [1112 /* DEV_TESTNET */]: "https://develop-subgraph.0xnode.cloud/subgraphs/name/tfx/referrals",
  [421614 /* ARBITRUM_SEPOLIA */]: "https://arb-testnet-subgraph.0xnode.cloud/subgraphs/name/tfx/referrals-mock"
};

// src/helper.ts
function getChainName(chainId) {
  return chainNames[chainId];
}
function getChainIdByChainName(chainName) {
  if (!chainName)
    return void 0;
  return chainNameToChainId[chainName] ?? void 0;
}
function getChainDisplayName(chainId) {
  return chainDisplayNames[chainId];
}
function getChainDisplayNameByChainName(chainName) {
  if (!chainName)
    return void 0;
  return chainNameToChainDisplayNames[chainName] ?? void 0;
}
function getCoreSubgraphUrl(chainId) {
  return CORE_SUBGRAPHS[chainId] ?? "";
}
function getRefSubgraphUrl(chainId) {
  return REFERRAL_SUBGRAPHS[chainId] ?? "";
}

export { CORE_SUBGRAPHS, ChainId, REFERRAL_SUBGRAPHS, chainDisplayNames, chainNameToChainDisplayNames, chainNameToChainId, chainNames, getChainDisplayName, getChainDisplayNameByChainName, getChainIdByChainName, getChainName, getCoreSubgraphUrl, getRefSubgraphUrl };
