import Card from 'components/Card'
import Tooltip from '../Tooltip/Popper'

function EmptyMessage({ message = '', tooltipText }) {
  return (
    <div>
      <Card className="p-8 text-center my-10 text-2xl">
        {tooltipText ? <Tooltip handle={message} renderContent={() => tooltipText} /> : <p>{message}</p>}
      </Card>
    </div>
  )
}

export default EmptyMessage
