import { ApolloClient, InMemoryCache } from '@apollo/client'
import { ChainId, getCoreSubgraphUrl, getRefSubgraphUrl } from '@tfx/chains'

export const subgraphArbitrumSepoliaCore = getCoreSubgraphUrl(ChainId.ARBITRUM_SEPOLIA)
export const subgraphDevTestnetCore = getCoreSubgraphUrl(ChainId.DEV_TESTNET)

export const subgraphArbitrumSepoliaRefferal = getRefSubgraphUrl(ChainId.ARBITRUM_SEPOLIA)
export const subgraphDevTestnetRefferal = getRefSubgraphUrl(ChainId.DEV_TESTNET)

// Linea Testnet
export const arbitrumSepoliaReferralsGraphClient = createClient(subgraphArbitrumSepoliaRefferal)
export const arbitrumSepoliaGraphClient = createClient(subgraphArbitrumSepoliaCore)

// Dev Testnet
export const devTestnetReferralsGraphClient = createClient(subgraphDevTestnetRefferal)
export const devTestnetGraphClient = createClient(subgraphDevTestnetCore)

// Others
export const pricefeedApiUrl = 'https://api.xoracle.io'
export const chainlinkClient = createClient('https://api.thegraph.com/subgraphs/name/deividask/chainlink')
export const priceCandleApiUrl = 'https://price-chart-api.tfx.market/api'
export const assetsUrl = 'https://assets.tfx.market'

function createClient(uri: string): ApolloClient<any> {
  return new ApolloClient({
    uri,
    cache: new InMemoryCache(),
  })
}
