import { RefObject } from 'react'
import { cx } from '@emotion/css'

interface Props extends React.ComponentPropsWithoutRef<'input'> {
  label?: string
  ref?: RefObject<HTMLInputElement>
  disabled?: boolean
  subfix?: any
}

const FieldInput = ({ label, id, name, className, ref, subfix, ...props }: Props) => {
  return (
    <div className="relative mb-6">
      {label && (
        <label htmlFor={id} className="absolute top-4 left-5 text-xl text-[#bebebe]">
          {label}:
        </label>
      )}
      <div>
        <input
          {...props}
          ref={ref}
          name={name}
          id={id}
          className={cx(
            'block w-full rounded-2xl bg-bg-color-1 border border-bg-color-2 dark:bg-bg-dark-color-2 dark:border-bg-color-2 p-[1.2rem] text-3xl focus:ring-0',
            {
              'pt-[45px] pb-7': !!label,
              'pr-16': !!subfix,
            },
            className,
          )}
        />
        {subfix && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center text-2xl pr-6 mt-10">
            {subfix}
          </div>
        )}
      </div>
    </div>
  )
}

export default FieldInput
