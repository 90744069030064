import { ChainId } from '@tfx/chains';

// src/addresses.ts
var contracts = {
  [ChainId.DEV_TESTNET]: {
    Vault: "0x4A753dE182cE4287432791c6d56D126B4BD221c9",
    VaultPositionController: "0x2346B80cAE8713677B34DAD5aF4BD6a3b38aBEAA",
    Router: "0xc1EB971614f0c9B7629eCDa38E673042b4F09C2B",
    Reader: "0x1f88Ee02f1e316C8C912151245CCf3eE8B858E50",
    RewardRouter: "0xd2Ee541759c27bA078E774c7ec73f6C4E8BA769C",
    RewardReader: "0x446764C69f12D2ee2565Cdc8f7A4DFcf97Af71b0",
    GMX: "0x00",
    XLP: "0x2D8d03e4E96BCa78c02F4d8190b1dE1253053430",
    OrderBook: "0x0Bbf0F7a0669C73F4801205ae06E30524592F405",
    OrderBookReader: "0x8b03979805A7315E4D13ecd14DB6005a6677E12c",
    USDX: "0xe2e92dF7F4A5B85d440b253bd0eE1c20De6756Fd",
    NATIVE_TOKEN: "0x078c04b8cfC949101905fdd5912D31Aad0a244Cb",
    StakedXlpDistributor: "0x773ECA5D8Ebf839a7A22Fc8636d02e6b24681a15",
    PositionRouter: "0xD33227F77F04e967f324A42e8eE3B03DF0bb7409",
    PositionManager: "0x00D60CCb6a66C4c962942753D16d1227B423De9e",
    PancakeGmxBnbPool: "0x00",
    ReferralStorage: "0x3111dBaccB884A21d125ADF2f2EFcbfacA4F3aB5",
    ReferralReader: "0x0089cA4792cecd09E6c27360d4F57B0EE406D223",
    FeeXlpTracker: "0xc5d9C951aF4c90bca07a65d3e10566bceF22d066",
    XlpManager: "0xc499BB911B4B009924d27B6e6136642357a44743",
    VaultReader: "0x7c831712da375C7ae98eDd621740911533996A41",
    XOracle: "0xCaa766A36Ea93c581299719934404D099E65478f",
    XlpManagerReader: "0x0c719AD6CDEa9B2E7D7F759D120a261ba2906c43",
    FullFillController: "0xB39cE44F8Ad7F1d47991e3AD227202Fb98D71C9F",
    FaucetBUSD: "0x00"
  },
  [ChainId.ARBITRUM_SEPOLIA]: {
    Vault: "0x50410C7FC9Edc9abe7f33f2b14e40630Bda7Fd93",
    VaultPositionController: "0xa828F3b5e0Fe2B370612c437897f5281769059CC",
    Router: "0x2E576bb26c220FF639B7A694E71f4b33f108a124",
    Reader: "0x4407C2D00aBe3f0a033e0921A9De37b894A04A26",
    RewardRouter: "0xdF2CA045c1F3Aa7e5974b6410262A12F549497B6",
    RewardReader: "0x53a79E66AeEB8b2b76D8F676D1A90E71A3d499d4",
    GMX: "0x00",
    XLP: "0x9eCB70FaB37b4415D64bb4bFf400d6B9B17Ad4d9",
    OrderBook: "0xfdb93d9420cc10c83BF5B5Bab7545bce0B4F746d",
    OrderBookReader: "0x25E585b09028945a0E1c61a69137d24c34629722",
    USDX: "0x89A6C053f5013e9c8C035db65C8409a6Af1a963c",
    NATIVE_TOKEN: "0x980B62Da83eFf3D4576C647993b0c1D7faf17c73",
    StakedXlpDistributor: "0x99f00629678C1347294eb2B490294833b42Fdf60",
    PositionRouter: "0x0B37a7Ba0ecd6B2246BCe4892B0BDe7Fd3813E8C",
    PositionManager: "0xd4A8a852aC6Ce633b560AA255F85CEC95a01306f",
    PancakeGmxBnbPool: "0x00",
    ReferralStorage: "0x586CF575d920FC4206F05869BfD5EF9dA051ef6C",
    ReferralReader: "0xE4B65e8Bd1A467c0DCe4468E14DB90Ae7790BfdF",
    FeeXlpTracker: "0x10ADd7c54b53e777368a000bCDb5AeB6FaADfE96",
    XlpManager: "0xC6B0Dea190677a2174403474cb1d7CD4d35ae90C",
    VaultReader: "0x81E77F6d9F67F6613AA86DD06953F2C996606b33",
    XOracle: "0xa3B16ad55513d91c8650Ef7D218A5299d59265d7",
    XlpManagerReader: "0xea5bB66c84D13C937E280f2Ee47edA1286C6e531",
    FullFillController: "0x9248b38F7A55C6d80f5F55954d294690Be93D267",
    FaucetBUSD: "0x00"
  }
};
var addresses_default = contracts;

// src/helper.ts
function getContract(chainId, name) {
  const chainContracts = addresses_default[chainId];
  if (!chainContracts) {
    throw new Error(`Unknown chainId ${chainId}`);
  }
  const chainContractName = chainContracts[name];
  if (!chainContractName) {
    throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
  }
  return chainContractName;
}

export { getContract };
