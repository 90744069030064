import { getContract } from '@tfx/addresses'
import { getToken, getTokens, getWhitelistedTokens } from '@tfx/tokens'
import Tab from 'components/Tab/Tab'
import { ethers } from 'ethers'
import { useEffect, useState } from 'react'
import { IoMdSwap } from 'react-icons/io'
import { MdTrendingDown, MdTrendingUp } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import useSWR from 'swr'
import {
  adjustForDecimals,
  approveTokens,
  BASIS_POINTS_DIVISOR,
  bigNumberify,
  expandDecimals,
  fetcher,
  formatAmount,
  formatAmountFree,
  getBuyGlpFromAmount,
  getBuyGlpToAmount,
  getSellGlpFromAmount,
  getSellGlpToAmount,
  getTokenInfo,
  getUsd,
  GLP_COOLDOWN_DURATION,
  parseValue,
  PLACEHOLDER_ACCOUNT,
  USD_DECIMALS,
  USDX_DECIMALS,
  useChainId,
  useLocalStorageByChainId,
  XLP_DECIMALS,
} from 'utils'
import { callContract, useInfoTokens } from 'utils/api'
import { useAum } from 'utils/api/stake'

import BuyInputSection from 'components/BuyInputSection/BuyInputSection'
import TokenSelector from 'components/Exchange/TokenSelector'
import Tooltip from 'components/Tooltip/Popper'

import XlpManager from 'abis/GlpManager.json'
import ReaderV2 from 'abis/Reader.json'
import RewardRouter from 'abis/RewardRouterV3.json'
import RewardTracker from 'abis/RewardTracker.json'
import Token from 'abis/Token.json'
import VaultV2 from 'abis/Vault.json'

import { useAnalytic } from 'components/Analytic'
import { GA_EVENT_KEY } from 'components/Analytic/constants'
import { PButton } from 'components/Button'
import Card from 'components/Card'
import { useWeb3 } from 'hooks/useWeb3'
import glp24Icon from 'img/ic_glp_24.svg'

import { usePendingSteps } from 'components/ToastPendingStep'
import TokenWeightText from 'components/TokenWeightText'
import PriceChart from './PriceChart'
import TokenList from './TokenList'

import { useConstants } from 'hooks/useConstant'
import './Swap.css'

const { AddressZero } = ethers.constants

export default function Swap(props) {
  const { savedSlippageAmount, isBuying, connectWallet, setIsBuying } = props

  const { setPendingStepWorkflow } = usePendingSteps()

  const history = useHistory()
  const swapLabel = isBuying ? 'AddLiquidityXLP' : 'RemoveLiquidityXLP'
  const tabLabel = isBuying ? 'Add Liquidity' : 'Remove Liquidity'
  const { active, library, account } = useWeb3()
  const { chainId } = useChainId()
  const tokens = getTokens(chainId)
  const whitelistedTokens = getWhitelistedTokens(chainId)
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped)
  const visibleTokens = tokenList.filter((t) => !t.isTempHidden)
  const [swapValue, setSwapValue] = useState('')
  const [glpValue, setGlpValue] = useState('')
  const [swapTokenAddress, setSwapTokenAddress] = useLocalStorageByChainId(
    chainId,
    `${swapLabel}-swap-token-address`,
    AddressZero,
  )
  const [isApproving, setIsApproving] = useState(false)
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [anchorOnSwapAmount, setAnchorOnSwapAmount] = useState(true)
  const [feeBasisPoints, setFeeBasisPoints] = useState<number | undefined>(undefined)
  const { sendEvent } = useAnalytic()

  const readerAddress = getContract(chainId, 'Reader')
  const vaultAddress = getContract(chainId, 'Vault')
  const nativeTokenAddress = getContract(chainId, 'NATIVE_TOKEN')
  const feeXlpTrackerAddress = getContract(chainId, 'FeeXlpTracker')
  const usdxAddress = getContract(chainId, 'USDX')
  const xlpManagerAddress = getContract(chainId, 'XlpManager')
  const rewardRouterAddress = getContract(chainId, 'RewardRouter')
  const xlpAddress = getContract(chainId, 'XLP')

  const tokensForBalanceAndSupplyQuery = [xlpAddress, usdxAddress]

  const { data: constants } = useConstants(chainId, library)
  const mintBurnFeeBasisPoints = constants ? constants.MINT_BURN_FEE_BASIS_POINTS : 0
  const taxBasisPoints = constants ? constants.TAX_BASIS_POINTS : 0

  const tokenAddresses = tokens.map((token) => token.address)
  const { data: tokenBalances } = useSWR(
    [`Swap:getTokenBalances:${active}`, chainId, readerAddress, 'getTokenBalances', account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(library, ReaderV2, [tokenAddresses]),
    },
  )

  const { data: balancesAndSupplies } = useSWR(
    [
      `Swap:getTokenBalancesWithSupplies:${active}`,
      chainId,
      readerAddress,
      'getTokenBalancesWithSupplies',
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: fetcher(library, ReaderV2, [tokensForBalanceAndSupplyQuery]),
    },
  )

  const [aum] = useAum(library, chainId, active)

  const { data: totalTokenWeights } = useSWR(
    [`Swap:totalTokenWeights:${active}`, chainId, vaultAddress, 'totalTokenWeights'],
    {
      fetcher: fetcher(library, VaultV2),
    },
  )

  const tokenAllowanceAddress = swapTokenAddress === AddressZero ? nativeTokenAddress : swapTokenAddress
  const { data: tokenAllowance } = useSWR(
    [active, chainId, tokenAllowanceAddress, 'allowance', account || PLACEHOLDER_ACCOUNT, rewardRouterAddress],
    {
      fetcher: fetcher(library, Token),
    },
  )

  const { data: lastPurchaseTime } = useSWR(
    [`Swap:lastPurchaseTime:${active}`, chainId, xlpManagerAddress, 'lastAddedAt', account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(library, XlpManager),
    },
  )

  const { data: glpBalance } = useSWR(
    [`Swap:glpBalance:${active}`, chainId, feeXlpTrackerAddress, 'stakedAmounts', account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(library, RewardTracker),
    },
  )

  const redemptionTime = lastPurchaseTime ? lastPurchaseTime.add(GLP_COOLDOWN_DURATION) : undefined
  const inCooldownWindow = redemptionTime && Date.now() / 1000 < redemptionTime

  const glpSupply = balancesAndSupplies ? balancesAndSupplies[1] : bigNumberify(0)
  const usdxSupply = balancesAndSupplies ? balancesAndSupplies[3] : bigNumberify(0)

  const xlpPrice =
    aum && aum.gt(0) && glpSupply.gt(0)
      ? aum.mul(expandDecimals(1, XLP_DECIMALS)).div(glpSupply)
      : expandDecimals(1, USD_DECIMALS)

  let maxSellAmount = glpBalance

  const { infoTokens } = useInfoTokens(library, chainId, active, tokenBalances, undefined)
  const swapToken = getToken(chainId, swapTokenAddress)
  const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress)

  const swapTokenBalance = swapTokenInfo && swapTokenInfo.balance ? swapTokenInfo.balance : bigNumberify(0)

  const swapAmount = parseValue(swapValue, swapToken && swapToken.decimals)
  const xlpAmount = parseValue(glpValue, XLP_DECIMALS)

  const needApproval =
    isBuying && swapTokenAddress !== AddressZero && tokenAllowance && swapAmount && swapAmount.gt(tokenAllowance)

  const swapUsdMin = getUsd(swapAmount, swapTokenAddress, false, infoTokens)
  const xlpUsdMax = xlpAmount && xlpPrice ? xlpAmount?.mul(xlpPrice).div(expandDecimals(1, XLP_DECIMALS)) : undefined

  let isSwapTokenCapReached
  if (swapTokenInfo.managedUsd && swapTokenInfo.maxUsdgAmount) {
    isSwapTokenCapReached = swapTokenInfo.managedUsd.gt(
      adjustForDecimals(swapTokenInfo.maxUsdgAmount, USDX_DECIMALS, USD_DECIMALS),
    )
  }

  const onSwapValueChange = (e) => {
    setAnchorOnSwapAmount(true)
    setSwapValue(e.target.value)
  }

  const onGlpValueChange = (e) => {
    setAnchorOnSwapAmount(false)
    setGlpValue(e.target.value)
  }

  const onSelectSwapToken = (token) => {
    sendEvent({
      category: GA_EVENT_KEY.BUY_XLP.CATEGORY,
      action: isBuying ? GA_EVENT_KEY.BUY_XLP.ACTION.SELECT_PAY_TOKEN : GA_EVENT_KEY.BUY_XLP.ACTION.SELECT_SELL_TOKEN,
      label: token.symbol,
    })
    setSwapTokenAddress(token.address)
    setIsWaitingForApproval(false)
  }

  useEffect(() => {
    const updateSwapAmounts = () => {
      if (anchorOnSwapAmount) {
        if (!swapAmount) {
          setGlpValue('')
          setFeeBasisPoints(undefined)
          return
        }

        if (isBuying) {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getBuyGlpToAmount(
            swapAmount,
            swapTokenAddress,
            infoTokens,
            xlpPrice,
            usdxSupply,
            totalTokenWeights,
            mintBurnFeeBasisPoints,
            taxBasisPoints,
          )
          const nextValue = formatAmountFree(nextAmount, XLP_DECIMALS, XLP_DECIMALS)
          setGlpValue(nextValue)
          setFeeBasisPoints(feeBps)
        } else {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getSellGlpFromAmount(
            swapAmount,
            swapTokenAddress,
            infoTokens,
            xlpPrice,
            usdxSupply,
            totalTokenWeights,
            mintBurnFeeBasisPoints,
            taxBasisPoints,
          )
          const nextValue = formatAmountFree(nextAmount, XLP_DECIMALS, XLP_DECIMALS)
          setGlpValue(nextValue)
          setFeeBasisPoints(feeBps)
        }

        return
      }

      if (!xlpAmount) {
        setSwapValue('')
        setFeeBasisPoints(undefined)
        return
      }

      if (swapToken) {
        if (isBuying) {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getBuyGlpFromAmount(
            xlpAmount,
            swapTokenAddress,
            infoTokens,
            xlpPrice,
            usdxSupply,
            totalTokenWeights,
            mintBurnFeeBasisPoints,
            taxBasisPoints,
          )
          const nextValue = formatAmountFree(nextAmount, swapToken.decimals, swapToken.decimals)
          setSwapValue(nextValue)
          setFeeBasisPoints(feeBps)
        } else {
          const { amount: nextAmount, feeBasisPoints: feeBps } = getSellGlpToAmount(
            xlpAmount,
            swapTokenAddress,
            infoTokens,
            xlpPrice,
            usdxSupply,
            totalTokenWeights,
            mintBurnFeeBasisPoints,
            taxBasisPoints,
          )

          const nextValue = formatAmountFree(nextAmount, swapToken.decimals, swapToken.decimals)
          setSwapValue(nextValue)
          setFeeBasisPoints(feeBps)
        }
      }
    }
    updateSwapAmounts()

    // let delay = setTimeout(() => updateSwapAmounts(), 500);
    // return () => clearTimeout(delay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isBuying,
    anchorOnSwapAmount,
    swapAmount,
    xlpAmount,
    swapToken,
    swapTokenAddress,
    infoTokens,
    xlpPrice,
    usdxSupply,
    totalTokenWeights,
  ])

  const switchSwapOption = (hash = '') => {
    history.push(`${history.location.pathname}#${hash}`)
    props.setIsBuying(hash === 'redeem' ? false : true)
  }

  const fillMaxAmount = () => {
    if (isBuying) {
      setAnchorOnSwapAmount(true)
      setSwapValue(formatAmountFree(swapTokenBalance, swapToken.decimals, swapToken.decimals))
      return
    }

    setAnchorOnSwapAmount(false)
    setGlpValue(formatAmountFree(maxSellAmount, XLP_DECIMALS, XLP_DECIMALS))
  }

  const getError = () => {
    if (!isBuying && inCooldownWindow) {
      return [`Redemption time not yet reached`]
    }

    if (!swapAmount || swapAmount.eq(0)) {
      return ['Enter an amount']
    }
    if (!xlpAmount || xlpAmount.eq(0)) {
      return ['Enter an amount']
    }

    if (isBuying) {
      const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress)
      if (swapTokenInfo && swapTokenInfo.balance && swapAmount && swapAmount.gt(swapTokenInfo.balance)) {
        return [`Insufficient ${swapTokenInfo.symbol} balance`]
      }

      if (swapTokenInfo.maxUsdgAmount && swapTokenInfo.usdgAmount && swapUsdMin) {
        const usdgFromAmount = adjustForDecimals(swapUsdMin, USD_DECIMALS, USDX_DECIMALS)
        const nextUsdgAmount = swapTokenInfo.usdgAmount.add(usdgFromAmount)
        if (swapTokenInfo.maxUsdgAmount.gt(0) && nextUsdgAmount.gt(swapTokenInfo.maxUsdgAmount)) {
          return [`${swapTokenInfo.symbol} pool exceeded, try different token`, true]
        }
      }
    }

    if (!isBuying) {
      if (maxSellAmount && xlpAmount && xlpAmount.gt(maxSellAmount)) {
        return [`Insufficient xLP balance`]
      }

      const swapTokenInfo = getTokenInfo(infoTokens, swapTokenAddress)
      if (
        swapTokenInfo &&
        swapTokenInfo.availableAmount &&
        swapAmount &&
        swapAmount.gt(swapTokenInfo.availableAmount)
      ) {
        return [`Insufficient liquidity`]
      }
    }

    return [false]
  }

  const isPrimaryEnabled = () => {
    if (!active) {
      return true
    }
    const [error, modal] = getError()
    if (error && !modal) {
      return false
    }
    if ((needApproval && isWaitingForApproval) || isApproving) {
      return false
    }
    if (isApproving) {
      return false
    }
    if (isSubmitting) {
      return false
    }
    if (isBuying && isSwapTokenCapReached) {
      return false
    }

    return true
  }

  const getPrimaryText = () => {
    if (!active) {
      return 'Connect Wallet'
    }
    const [error, modal] = getError()
    if (error && !modal) {
      return error
    }
    if (isBuying && isSwapTokenCapReached) {
      return `Max Capacity for ${swapToken.symbol} Reached`
    }

    if (needApproval && isWaitingForApproval) {
      return 'Waiting for Approval...'
    }
    if (isApproving) {
      return `Approving ${swapToken.symbol}...`
    }
    if (needApproval) {
      return `Approve ${swapToken.symbol}`
    }

    if (isSubmitting) {
      return isBuying ? `Staking...` : `Unstaking...`
    }

    return isBuying ? 'Add Liquidity and Stake' : 'Unstake and Remove Liquidity'
  }

  const approveFromToken = () => {
    // TODO: fix type
    approveTokens({
      setIsApproving,
      library,
      tokenAddress: swapToken.address,
      spender: rewardRouterAddress,
      chainId: chainId,
      onApproveSubmitted: () => {
        setIsWaitingForApproval(true)
      },
      infoTokens,
      getTokenInfo,
    } as any)
  }

  const addLiquidity = () => {
    setIsSubmitting(true)

    const minGlp = xlpAmount?.mul(BASIS_POINTS_DIVISOR - savedSlippageAmount).div(BASIS_POINTS_DIVISOR)

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter, library)
    const method = swapTokenAddress === AddressZero ? 'mintAndStakeXlpETH' : 'mintAndStakeXlp'
    const params = swapTokenAddress === AddressZero ? [0, minGlp] : [swapTokenAddress, swapAmount, 0, minGlp]
    const value = swapTokenAddress === AddressZero ? swapAmount : 0

    sendEvent({
      category: GA_EVENT_KEY.BUY_XLP.CATEGORY,
      action: GA_EVENT_KEY.BUY_XLP.ACTION.SUBMIT_BUY,
      value: Number(formatAmount(xlpAmount, 18)),
    })

    callContract(chainId, contract, method, params, {
      value,
      sentMsg: 'Add Liquidity submitted.',
      failMsg: 'Add Liquidity failed.',
      successMsg: `Requested to add liquidity ${formatAmount(xlpAmount, 18, 4, true)} xLP with ${formatAmount(
        swapAmount,
        swapTokenInfo.decimals,
        4,
        true,
      )} ${swapTokenInfo.symbol}!`,
      setPendingStepWorkflow,
    }).finally(() => {
      setIsSubmitting(false)
    })
  }

  const removedLiquidity = () => {
    setIsSubmitting(true)

    const minOut = swapAmount?.mul(BASIS_POINTS_DIVISOR - savedSlippageAmount).div(BASIS_POINTS_DIVISOR)

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter, library)
    const method = swapTokenAddress === AddressZero ? 'unstakeAndRedeemXlpETH' : 'unstakeAndRedeemXlp'
    const params =
      swapTokenAddress === AddressZero ? [xlpAmount, minOut, account] : [swapTokenAddress, xlpAmount, minOut, account]

    sendEvent({
      category: GA_EVENT_KEY.BUY_XLP.CATEGORY,
      action: GA_EVENT_KEY.BUY_XLP.ACTION.SUBMIT_SELL,
      value: Number(formatAmount(xlpAmount, 18)),
    })

    callContract(chainId, contract, method, params, {
      sentMsg: 'Remove Liquidity submitted!',
      failMsg: 'Remove Liquidity failed.',
      successMsg: `Requested to remove liquidity ${formatAmount(xlpAmount, 18, 4, true)} xLP for ${formatAmount(
        swapAmount,
        swapTokenInfo.decimals,
        4,
        true,
      )} ${swapTokenInfo.symbol}!`,
      setPendingStepWorkflow,
    }).finally(() => {
      setIsSubmitting(false)
    })
  }

  const onClickPrimary = () => {
    if (!active) {
      connectWallet()
      return
    }

    if (needApproval) {
      approveFromToken()
      return
    }

    const [, modal] = getError()

    if (modal) {
      return
    }

    if (isBuying) {
      addLiquidity()
    } else {
      removedLiquidity()
    }
  }

  let payLabel = 'Pay'
  let receiveLabel = 'Receive'
  let payBalance = '$0.00'
  let receiveBalance = '$0.00'
  if (isBuying) {
    if (swapUsdMin) {
      payBalance = `$${formatAmount(swapUsdMin, USD_DECIMALS, 2, true)}`
    }
    if (xlpUsdMax) {
      receiveBalance = `$${formatAmount(xlpUsdMax, USD_DECIMALS, 2, true)}`
    }
  } else {
    if (xlpUsdMax) {
      payBalance = `$${formatAmount(xlpUsdMax, USD_DECIMALS, 2, true)}`
    }
    if (swapUsdMin) {
      receiveBalance = `$${formatAmount(swapUsdMin, USD_DECIMALS, 2, true)}`
    }
  }

  let feePercentageText = formatAmount(feeBasisPoints, 2, 2, true, '-')
  if (feeBasisPoints !== undefined && feeBasisPoints.toString().length > 0) {
    feePercentageText += '%'
  }

  const onSwapOptionChange = (opt) => {
    sendEvent({
      category: GA_EVENT_KEY.BUY_XLP.CATEGORY,
      action:
        opt === 'Add Liquidity'
          ? GA_EVENT_KEY.BUY_XLP.ACTION.CLICK_TAB_SELL
          : GA_EVENT_KEY.BUY_XLP.ACTION.CLICK_TAB_BUY,
    })
    if (opt === 'Remove Liquidity') {
      switchSwapOption('redeem')
    } else {
      switchSwapOption()
    }
  }

  const selectedTokenInfo = getTokenInfo(infoTokens, swapToken.address)

  return (
    <div>
      <div className="flex flex-col-reverse md:flex-row gap-10">
        <div className="flex-1 lg:basis-full">
          <PriceChart price={xlpPrice} />
        </div>

        <div className="flex-1 lg:flex-none lg:w-[450px]">
          <Card>
            <Tab
              icons={{
                'Add Liquidity': MdTrendingUp,
                'Remove Liquidity': MdTrendingDown,
              }}
              options={['Add Liquidity', 'Remove Liquidity']}
              option={tabLabel}
              onChange={onSwapOptionChange}
              className="Exchange-swap-option-tabs mb-5"
            />

            {isBuying && (
              <BuyInputSection
                topLeftLabel={payLabel}
                topRightLabel={`Balance: `}
                tokenBalance={`${formatAmount(swapTokenBalance, swapToken.decimals, 4, true)}`}
                inputValue={swapValue}
                onInputValueChange={onSwapValueChange}
                showMaxButton={swapValue !== formatAmountFree(swapTokenBalance, swapToken.decimals, swapToken.decimals)}
                onClickTopRightLabel={fillMaxAmount}
                onClickMax={fillMaxAmount}
                selectedToken={swapToken}
                balance={payBalance}
              >
                <TokenSelector
                  label="Pay"
                  chainId={chainId}
                  tokenAddress={swapTokenAddress}
                  onSelectToken={onSelectSwapToken}
                  tokens={whitelistedTokens}
                  infoTokens={infoTokens}
                  className="Swap-from-token"
                  showSymbolImage={true}
                  showTokenImgInDropdown={true}
                />
              </BuyInputSection>
            )}

            {!isBuying && (
              <BuyInputSection
                topLeftLabel={payLabel}
                topRightLabel={`Available: `}
                tokenBalance={`${formatAmount(maxSellAmount, XLP_DECIMALS, 4, true)}`}
                inputValue={glpValue}
                onInputValueChange={onGlpValueChange}
                showMaxButton={glpValue !== formatAmountFree(maxSellAmount, XLP_DECIMALS, XLP_DECIMALS)}
                onClickTopRightLabel={fillMaxAmount}
                onClickMax={fillMaxAmount}
                balance={payBalance}
                defaultTokenName={'xLP'}
              >
                <div className="selected-token">
                  xLP <img src={glp24Icon} alt="glp24Icon" />
                </div>
              </BuyInputSection>
            )}

            <div className="Exchange-swap-ball-container">
              <div
                className="Exchange-swap-ball"
                onClick={() => {
                  setIsBuying(!isBuying)
                  switchSwapOption(isBuying ? 'redeem' : '')
                }}
              >
                <IoMdSwap className="Exchange-swap-ball-icon" />
              </div>
            </div>

            {isBuying && (
              <BuyInputSection
                topLeftLabel={receiveLabel}
                topRightLabel={`Balance: `}
                tokenBalance={`${formatAmount(glpBalance, XLP_DECIMALS, 4, true)}`}
                inputValue={glpValue}
                onInputValueChange={onGlpValueChange}
                balance={receiveBalance}
                defaultTokenName={'XLP'}
              >
                <div className="selected-token">
                  xLP <img src={glp24Icon} alt="glp24Icon" />
                </div>
              </BuyInputSection>
            )}

            {!isBuying && (
              <BuyInputSection
                topLeftLabel={receiveLabel}
                topRightLabel={`Balance: `}
                tokenBalance={`${formatAmount(swapTokenBalance, swapToken.decimals, 4, true)}`}
                inputValue={swapValue}
                onInputValueChange={onSwapValueChange}
                balance={receiveBalance}
                selectedToken={swapToken}
              >
                <TokenSelector
                  label="Receive"
                  chainId={chainId}
                  tokenAddress={swapTokenAddress}
                  onSelectToken={onSelectSwapToken}
                  tokens={whitelistedTokens}
                  infoTokens={infoTokens}
                  className="Swap-from-token"
                  showSymbolImage={true}
                  showTokenImgInDropdown={true}
                />
              </BuyInputSection>
            )}
            <div>
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">Weight</div>
                <div className="align-right fee-block">
                  <TokenWeightText tokenInfo={selectedTokenInfo} />
                </div>
              </div>
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">
                  {feeBasisPoints && feeBasisPoints > 50 ? 'WARNING: High Fees' : 'Fees'}
                </div>
                <div className="align-right fee-block">
                  {isBuying && (
                    <Tooltip
                      handle={isBuying && isSwapTokenCapReached ? 'NA' : feePercentageText}
                      renderContent={() => {
                        return (
                          <>
                            {feeBasisPoints && feeBasisPoints > 50 && (
                              <div>To reduce fees, select a different asset to pay with.</div>
                            )}
                            Check the "Save on Fees" section below to get the lowest fee percentages.
                          </>
                        )
                      }}
                    />
                  )}
                  {!isBuying && (
                    <Tooltip
                      handle={feePercentageText}
                      renderContent={() => {
                        return (
                          <>
                            {feeBasisPoints && feeBasisPoints > 50 && (
                              <div>To reduce fees, select a different asset to receive.</div>
                            )}
                            Check the "Save on Fees" section below to get the lowest fee percentages.
                          </>
                        )
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">Minimum Receive</div>
                <div className="align-right fee-block">{receiveBalance}</div>
              </div>
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">Cool Down</div>
                <div className="align-right fee-block">15 Min</div>
              </div>
            </div>
            <div className="Swap-cta Exchange-swap-button-container">
              <PButton size="xl" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
                {getPrimaryText()}
              </PButton>
            </div>
          </Card>
        </div>
      </div>

      <div className="mt-7">
        <TokenList
          isBuying={isBuying}
          visibleTokens={visibleTokens}
          xlpAmount={xlpAmount}
          infoTokens={infoTokens}
          xlpPrice={xlpPrice}
          usdxSupply={usdxSupply}
          totalTokenWeights={totalTokenWeights}
          mintBurnFeeBasisPoints={mintBurnFeeBasisPoints}
          taxBasisPoints={taxBasisPoints}
        />
      </div>
    </div>
  )
}
