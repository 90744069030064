import dayjs from 'dayjs'
import { ethers } from 'ethers'
import { useWeb3 } from 'hooks/useWeb3'
import _ from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast/headless'
import { BiErrorCircle } from 'react-icons/bi'
import { MdDoneOutline, MdInfoOutline } from 'react-icons/md'
import { useLocalStorage } from 'react-use'
import useSWR from 'swr'
import { useAccount } from 'wagmi'

import { getContract } from '@tfx/addresses'
import { ChainId } from '@tfx/chains'
import { getPositionKey } from '@tfx/tfx-sdk'
import { getWhitelistedTokens, isValidToken } from '@tfx/tokens'

import { WALLET_CONNECT_LOCALSTORAGE_KEY, WALLET_LINK_LOCALSTORAGE_PREFIX } from 'config/localStorage'
import OrderBook from '../abis/OrderBook.json'
import OrderBookReader from '../abis/OrderBookReader.json'
import Token from '../abis/Token.json'

const { AddressZero } = ethers.constants
export const timezoneOffset = -new Date().getTimezoneOffset() * 60
export const TRADES_PAGE_SIZE = '50'

// use a random placeholder account instead of the zero address as the zero address might have tokens
export const PLACEHOLDER_ACCOUNT = ethers.Wallet.createRandom().address

export const DEV_TESTNET = ChainId.DEV_TESTNET
export const ARBITRUM_SEPOLIA = ChainId.ARBITRUM_SEPOLIA

// TODO take it from web3
export const DEFAULT_CHAIN_ID = ARBITRUM_SEPOLIA
export const CHAIN_ID = DEFAULT_CHAIN_ID

export const MIN_PROFIT_TIME = 0

const SELECTED_NETWORK_LOCAL_STORAGE_KEY = 'SELECTED_NETWORK'

export const ACTIVE_CHAIN_IDS = isProduction() ? [ARBITRUM_SEPOLIA] : [ARBITRUM_SEPOLIA]

const GAS_PRICE_ADJUSTMENT_MAP = {
  [DEV_TESTNET]: '5000000000', // 5 gwei
  [ARBITRUM_SEPOLIA]: '5000000000', // 5 gwei
}

const MAX_GAS_PRICE_MAP = {
  [DEV_TESTNET]: '50000000000', // 50 gwei
  [ARBITRUM_SEPOLIA]: '50000000000', // 50 gwei
}

export const USDG_ADDRESS = getContract(CHAIN_ID, 'USDX')

export const MAX_PRICE_DEVIATION_BASIS_POINTS = 250
export const DEFAULT_GAS_LIMIT = 1 * 1000 * 1000
export const SECONDS_PER_YEAR = 31536000
export const USDX_DECIMALS = 18
export const USD_DECIMALS = 30
export const BASIS_POINTS_DIVISOR = 10000
export const DUST_BNB = '2000000000000000'
export const DUST_USD = expandDecimals(1, USD_DECIMALS)
export const PRECISION = expandDecimals(1, 30)
export const XLP_DECIMALS = 18
export const GMX_DECIMALS = 18
export const DEFAULT_MAX_USDG_AMOUNT = expandDecimals(200 * 1000 * 1000, 18)

export const GLP_COOLDOWN_DURATION = 15 * 60
export const THRESHOLD_REDEMPTION_VALUE = expandDecimals(993, 27) // 0.993
export const FUNDING_RATE_PRECISION = 1000000

export const SWAP = 'Swap'
export const INCREASE = 'Increase'
export const DECREASE = 'Decrease'
export const LONG = 'Long'
export const SHORT = 'Short'

export const MARKET = 'Market'
export const LIMIT = 'Limit'
export const STOP = 'Stop'
export const LEVERAGE_ORDER_OPTIONS = [MARKET, LIMIT, STOP]
export const SWAP_ORDER_OPTIONS = [MARKET, LIMIT]
export const SWAP_OPTIONS = [LONG, SHORT, SWAP]
export const DEFAULT_SLIPPAGE_AMOUNT = 30
export const DEFAULT_HIGHER_SLIPPAGE_AMOUNT = 100

export const MAX_REFERRAL_CODE_LENGTH = 20

export const TRIGGER_PREFIX_ABOVE = '>'
export const TRIGGER_PREFIX_BELOW = '<'

export const MIN_PROFIT_BIPS = 0

export const GLPPOOLCOLORS = {
  BTC: '#F7931A',
  ETH: '#6062a6',
  BNB: '#E84142',
  USDT: '#DFFF00',
  BUSD: '#E9167C',
  USDC: '#40E0D0',
  DAI: '#FFBF00',
  XRP: '#FF7F50',
  DOGE: '#CD5C5C',
  TRX: '#DE3163',
  ADA: '#9FE2BF',
  MATIC: '#40E0D0',
  SOL: '#6495ED',
  DOT: '#CCCCFF',
  AVAX: '#800080',
  FTM: '#FF00FF',
  NEAR: '#000080',
  ATOM: '#0000FF',
  OP: '#008080',
  ARB: '#00FFFF	',
}

export const HIGH_EXECUTION_FEES_MAP = {
  [ARBITRUM_SEPOLIA]: 3, // 3 USD
  [DEV_TESTNET]: 3, // 3 USD
}

export const ICONLINKS = {
  1112: {
    GMX: {
      devTestnet: getTokenUrl(1112, '0xF883809140a0054FC29ba1D8805895ad9A1a928C'),
    },
    XLP: {
      devTestnet: getTokenUrl(1112, '0xeb38A06D5bCf7665486e8156AcCF99dfd095EaC2'),
    },
    ETH: {
      coingecko: 'https://www.coingecko.com/en/coins/ethereum',
      devTestnet: getTokenUrl(1112, '0x7151ae22CD175abD757b1b48420963Ea09259c53'),
    },
    BTC: {
      coingecko: 'https://www.coingecko.com/en/coins/wrapped-bitcoin',
      devTestnet: getTokenUrl(1112, '0x9022D25efB809fF34a0c3eEFb923C7c508b20D4f'),
    },
    BNB: {
      coingecko: 'https://www.coingecko.com/en/coins/bnb',
      devTestnet: getTokenUrl(1112, '0xf93474c3338a35cF41c2b449675D912bB3615044'),
    },
    USDT: {
      coingecko: 'https://www.coingecko.com/en/coins/binance-usd',
      devTestnet: getTokenUrl(1112, '0xf72783c80E7B306BeC5E4A7B62a804E66142C177'),
    },
    USDC: {
      coingecko: 'https://www.coingecko.com/en/coins/usd-coin',
      devTestnet: getTokenUrl(1112, '0x9A34046778846BB03aC07120332Be5B39fe75c60'),
    },
    MATIC: {
      coingecko: 'https://www.coingecko.com/en/coins/polygon',
      devTestnet: getTokenUrl(1112, '0x8CaC666243CD86A7A71fBc1AC755258C226d476D'),
    },
    OP: {
      coingecko: 'https://www.coingecko.com/en/coins/optimism',
      devTestnet: getTokenUrl(1112, '0x7271FB7c519bf38cE0f54041aC6aeFdB170420fB'),
    },
    ARB: {
      coingecko: 'https://www.coingecko.com/en/coin/arbitrum',
      devTestnet: getTokenUrl(1112, '0x2B1456b57DA000C6A945607055F4d24D3EbA675F'),
    },
  },
  421614: {
    GMX: {
      devTestnet: getTokenUrl(421614, '0xF883809140a0054FC29ba1D8805895ad9A1a928C'),
    },
    XLP: {
      devTestnet: getTokenUrl(421614, '0xeb38A06D5bCf7665486e8156AcCF99dfd095EaC2'),
    },
    ETH: {
      coingecko: 'https://www.coingecko.com/en/coins/ethereum',
      devTestnet: getTokenUrl(421614, '0x2C1b868d6596a18e32E61B901E4060C872647b6C'),
    },
    BTC: {
      coingecko: 'https://www.coingecko.com/en/coins/wrapped-bitcoin',
      devTestnet: getTokenUrl(421614, '0x3b0C46252B44bd40c54f1557970171b0D70d2e53'),
    },
    USDT: {
      coingecko: 'https://www.coingecko.com/en/coins/binance-usd',
      devTestnet: getTokenUrl(421614, '0xbceC86a186274C4215030b1a5903FAC944b540dd'),
    },
    USDC: {
      coingecko: 'https://www.coingecko.com/en/coins/usd-coin',
      devTestnet: getTokenUrl(421614, '0x539912979f90fb0eb170c61fCE865d1714659Cf8'),
    },
    OP: {
      coingecko: 'https://www.coingecko.com/en/coins/optimism',
      devTestnet: getTokenUrl(421614, '0x1e0BBfD45acda3DafD0d9CC6AdC2B1F8Ba5C1b6A'),
    },
    ARB: {
      coingecko: 'https://www.coingecko.com/en/coin/arbitrum',
      devTestnet: getTokenUrl(421614, '0x45950C3dB9f713b10cfd1208A2f0007175697FED'),
    },
    SOL: {
      coingecko: 'https://www.coingecko.com/en/coin/solana',
      devTestnet: getTokenUrl(421614, '0xB80808027a0Ef560528bF8f1C7788a942bBc0CE7'),
    },
  },
}

const host = window.location.origin
export const platformTokens = {
  [DEV_TESTNET]: {
    // xoracle testnet
    GMX: {
      name: 'TFX',
      symbol: 'TFX',
      decimals: 18,
      address: getContract(DEV_TESTNET, 'GMX'),
      imageUrl: `${host}/img/tfx.svg`,
    },
    XLP: {
      name: 'TFX LP',
      symbol: 'xLP',
      decimals: 18,
      // TODO: update address
      address: getContract(DEV_TESTNET, 'FeeXlpTracker'),
      imageUrl: `${host}/img/tfx.svg`,
    },
  },
  [ARBITRUM_SEPOLIA]: {
    // xoracle testnet
    GMX: {
      name: 'TFX',
      symbol: 'TFX',
      decimals: 18,
      address: getContract(ARBITRUM_SEPOLIA, 'GMX'),
      imageUrl: `${host}/img/tfx.svg`,
    },
    XLP: {
      name: 'TFX LP',
      symbol: 'xLP',
      decimals: 18,
      // TODO: update address
      address: getContract(ARBITRUM_SEPOLIA, 'FeeXlpTracker'),
      imageUrl: `${host}/img/tfx.svg`,
    },
  },
}

const supportedChainIds = ACTIVE_CHAIN_IDS

export function isSupportedChain(chainId) {
  return supportedChainIds.includes(chainId)
}

export function deserialize(data) {
  for (const [key, value] of Object.entries(data)) {
    if (value._type === 'BigNumber') {
      data[key] = bigNumberify(value.value)
    }
  }
  return data
}

const duration = 5000
export const helperToast = {
  success: (content) => {
    toast.success(
      <>
        <MdDoneOutline /> {content}
      </>,
      {
        duration,
      },
    )
  },
  error: (content) => {
    toast.error(
      <>
        <BiErrorCircle /> {content}
      </>,
      {
        duration,
      },
    )
  },
  default: (content) => {
    toast(
      <>
        <MdInfoOutline /> {content}
      </>,
      {
        duration,
      },
    )
  },
}

export function useLocalStorageByChainId(chainId, key, defaultValue) {
  const [internalValue, setInternalValue] = useLocalStorage(key, {})

  const setValue = useCallback(
    (value) => {
      setInternalValue((internalValue) => {
        if (typeof value === 'function') {
          value = value(internalValue[chainId] || defaultValue)
        }
        const newInternalValue = {
          ...internalValue,
          [chainId]: value,
        }
        return newInternalValue
      })
    },
    [chainId, setInternalValue, defaultValue],
  )

  let value
  if (chainId in internalValue) {
    value = internalValue[chainId]
  } else {
    value = defaultValue
  }

  return [value, setValue]
}

export function useLocalStorageSerializeKey(key, value, opts) {
  key = JSON.stringify(key)
  return useLocalStorage(key, value, opts)
}

function getTriggerPrice(tokenAddress, max, info, orderOption, triggerPriceUsd) {
  // Limit/stop orders are executed with price specified by user
  if (orderOption && orderOption !== MARKET && triggerPriceUsd) {
    return triggerPriceUsd
  }

  // Market orders are executed with current market price
  if (!info) {
    return
  }
  if (max && !info.maxPrice) {
    return
  }
  if (!max && !info.minPrice) {
    return
  }
  return max ? info.maxPrice : info.minPrice
}

export const replaceNativeTokenAddress = (path, nativeTokenAddress) => {
  if (!path) {
    return
  }

  let updatedPath = []
  for (let i = 0; i < path.length; i++) {
    let address = path[i]
    if (address === AddressZero) {
      address = nativeTokenAddress
    }
    updatedPath.push(address)
  }

  return updatedPath
}

export function getServerBaseUrl(chainId) {
  if (!chainId) {
    throw new Error('chainId is not provided')
  }
  return process.env.REACT_APP_API_URL
}

export function getEnv(key) {
  const value = process.env[key]
  if (value === undefined) {
    throw new Error(`Missing env variable ${key}`)
  }
  return value
}

export function getServerUrl(chainId, path) {
  return `${getServerBaseUrl(chainId)}${path}`
}

export function isTriggerRatioInverted(fromTokenInfo, toTokenInfo) {
  if (!toTokenInfo || !fromTokenInfo) return false
  if (toTokenInfo.isStable || toTokenInfo.isUsdg) return true
  if (toTokenInfo.maxPrice) return toTokenInfo.maxPrice.lt(fromTokenInfo.maxPrice)
  return false
}

export function getExchangeRate(tokenAInfo, tokenBInfo, inverted) {
  if (!tokenAInfo || !tokenAInfo.minPrice || !tokenBInfo || !tokenBInfo.maxPrice) {
    return
  }
  if (inverted) {
    return tokenAInfo.minPrice.mul(PRECISION).div(tokenBInfo.maxPrice)
  }
  return tokenBInfo.maxPrice.mul(PRECISION).div(tokenAInfo.minPrice)
}

export function getMostAbundantStableToken(chainId, infoTokens) {
  const whitelistedTokens = getWhitelistedTokens(chainId)
  let availableAmount
  let stableToken = whitelistedTokens.find((t) => t.isStable)
  for (let i = 0; i < whitelistedTokens.length; i++) {
    const info = getTokenInfo(infoTokens, whitelistedTokens[i].address)
    if (!info.isStable || !info.availableAmount) {
      continue
    }

    const adjustedAvailableAmount = adjustForDecimals(info.availableAmount, info.decimals, USD_DECIMALS)
    if (!availableAmount || adjustedAvailableAmount.gt(availableAmount)) {
      availableAmount = adjustedAvailableAmount
      stableToken = info
    }
  }
  return stableToken
}

export function shouldInvertTriggerRatio(tokenA, tokenB) {
  if (tokenB.isStable || tokenB.isUsdg) return true
  if (tokenB.maxPrice && tokenA.maxPrice && tokenB.maxPrice.lt(tokenA.maxPrice)) return true
  return false
}

export function getExchangeRateDisplay(rate, tokenA, tokenB, opts = {}) {
  if (!rate || !tokenA || !tokenB) return '...'
  if (shouldInvertTriggerRatio(tokenA, tokenB)) {
    ;[tokenA, tokenB] = [tokenB, tokenA]
    rate = PRECISION.mul(PRECISION).div(rate)
  }
  const rateValue = formatAmount(rate, USD_DECIMALS, tokenA.isStable || tokenA.isUsdg ? 2 : 4, true)
  if (opts.omitSymbols) {
    return rateValue
  }
  return `${rateValue} ${tokenA.symbol} / ${tokenB.symbol}`
}

const adjustForDecimalsFactory = (n) => (number) => {
  if (n === 0) {
    return number
  }
  if (n > 0) {
    return number.mul(expandDecimals(1, n))
  }
  return number.div(expandDecimals(1, -n))
}

export function adjustForDecimals(amount, divDecimals, mulDecimals) {
  return amount.mul(expandDecimals(1, mulDecimals)).div(expandDecimals(1, divDecimals))
}

export function getTargetUsdgAmount(token, usdxSupply, totalTokenWeights) {
  if (!token || !token.weight || !usdxSupply) {
    return
  }

  if (usdxSupply.eq(0)) {
    return bigNumberify(0)
  }

  return token.weight.mul(usdxSupply).div(totalTokenWeights)
}

export function getFeeBasisPoints(
  token,
  usdgDelta,
  feeBasisPoints,
  taxBasisPoints,
  increment,
  usdxSupply,
  totalTokenWeights,
) {
  if (!token || !token.usdgAmount || !usdxSupply || !totalTokenWeights) {
    return 0
  }

  feeBasisPoints = bigNumberify(feeBasisPoints)
  taxBasisPoints = bigNumberify(taxBasisPoints)

  const initialAmount = token.usdgAmount
  let nextAmount = initialAmount.add(usdgDelta)
  if (!increment) {
    nextAmount = usdgDelta.gt(initialAmount) ? bigNumberify(0) : initialAmount.sub(usdgDelta)
  }

  const targetAmount = getTargetUsdgAmount(token, usdxSupply, totalTokenWeights)
  if (!targetAmount || targetAmount.eq(0)) {
    return feeBasisPoints.toNumber()
  }

  const initialDiff = initialAmount.gt(targetAmount) ? initialAmount.sub(targetAmount) : targetAmount.sub(initialAmount)
  const nextDiff = nextAmount.gt(targetAmount) ? nextAmount.sub(targetAmount) : targetAmount.sub(nextAmount)

  if (nextDiff.lt(initialDiff)) {
    const rebateBps = taxBasisPoints.mul(initialDiff).div(targetAmount)
    return rebateBps.gt(feeBasisPoints) ? 0 : feeBasisPoints.sub(rebateBps).toNumber()
  }

  let averageDiff = initialDiff.add(nextDiff).div(2)
  if (averageDiff.gt(targetAmount)) {
    averageDiff = targetAmount
  }
  const taxBps = taxBasisPoints.mul(averageDiff).div(targetAmount)
  return feeBasisPoints.add(taxBps).toNumber()
}

export function getBuyGlpToAmount(
  fromAmount,
  swapTokenAddress,
  infoTokens,
  xlpPrice,
  usdxSupply,
  totalTokenWeights,
  mintBurnFeeBasisPoints,
  taxBasisPoints,
) {
  const defaultValue = { amount: bigNumberify(0), feeBasisPoints: 0 }
  if (!fromAmount || !swapTokenAddress || !infoTokens || !xlpPrice || !usdxSupply || !totalTokenWeights) {
    return defaultValue
  }

  const swapToken = getTokenInfo(infoTokens, swapTokenAddress)
  if (!swapToken || !swapToken.minPrice) {
    return defaultValue
  }

  let xlpAmount = fromAmount.mul(swapToken.minPrice).div(xlpPrice)
  xlpAmount = adjustForDecimals(xlpAmount, swapToken.decimals, USDX_DECIMALS)

  let usdgAmount = fromAmount.mul(swapToken.minPrice).div(PRECISION)
  usdgAmount = adjustForDecimals(usdgAmount, swapToken.decimals, USDX_DECIMALS)
  const feeBasisPoints = getFeeBasisPoints(
    swapToken,
    usdgAmount,
    mintBurnFeeBasisPoints,
    taxBasisPoints,
    true,
    usdxSupply,
    totalTokenWeights,
  )

  xlpAmount = xlpAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)

  return { amount: xlpAmount, feeBasisPoints }
}

export function getSellGlpFromAmount(
  toAmount,
  swapTokenAddress,
  infoTokens,
  xlpPrice,
  usdxSupply,
  totalTokenWeights,
  mintBurnFeeBasisPoints,
  taxBasisPoints,
) {
  const defaultValue = { amount: bigNumberify(0), feeBasisPoints: 0 }
  if (!toAmount || !swapTokenAddress || !infoTokens || !xlpPrice || !usdxSupply || !totalTokenWeights) {
    return defaultValue
  }

  const swapToken = getTokenInfo(infoTokens, swapTokenAddress)
  if (!swapToken || !swapToken.maxPrice) {
    return defaultValue
  }

  let xlpAmount = toAmount.mul(swapToken.maxPrice).div(xlpPrice)
  xlpAmount = adjustForDecimals(xlpAmount, swapToken.decimals, USDX_DECIMALS)

  let usdgAmount = toAmount.mul(swapToken.maxPrice).div(PRECISION)
  usdgAmount = adjustForDecimals(usdgAmount, swapToken.decimals, USDX_DECIMALS)
  const feeBasisPoints = getFeeBasisPoints(
    swapToken,
    usdgAmount,
    mintBurnFeeBasisPoints,
    taxBasisPoints,
    false,
    usdxSupply,
    totalTokenWeights,
  )

  xlpAmount = xlpAmount.mul(BASIS_POINTS_DIVISOR).div(BASIS_POINTS_DIVISOR - feeBasisPoints)

  return { amount: xlpAmount, feeBasisPoints }
}

export function getBuyGlpFromAmount(
  toAmount,
  fromTokenAddress,
  infoTokens,
  xlpPrice,
  usdxSupply,
  totalTokenWeights,
  mintBurnFeeBasisPoints,
  taxBasisPoints,
) {
  const defaultValue = { amount: bigNumberify(0) }
  if (!toAmount || !fromTokenAddress || !infoTokens || !xlpPrice || !usdxSupply || !totalTokenWeights) {
    return defaultValue
  }

  const fromToken = getTokenInfo(infoTokens, fromTokenAddress)
  if (!fromToken || !fromToken.minPrice) {
    return defaultValue
  }

  let fromAmount = toAmount.mul(xlpPrice).div(fromToken.minPrice)
  fromAmount = adjustForDecimals(fromAmount, XLP_DECIMALS, fromToken.decimals)

  const usdgAmount = toAmount.mul(xlpPrice).div(PRECISION)
  const feeBasisPoints = getFeeBasisPoints(
    fromToken,
    usdgAmount,
    mintBurnFeeBasisPoints,
    taxBasisPoints,
    true,
    usdxSupply,
    totalTokenWeights,
  )

  fromAmount = fromAmount.mul(BASIS_POINTS_DIVISOR).div(BASIS_POINTS_DIVISOR - feeBasisPoints)

  return { amount: fromAmount, feeBasisPoints }
}

export function getSellGlpToAmount(
  toAmount,
  fromTokenAddress,
  infoTokens,
  xlpPrice,
  usdxSupply,
  totalTokenWeights,
  mintBurnFeeBasisPoints,
  taxBasisPoints,
) {
  const defaultValue = { amount: bigNumberify(0) }
  if (!toAmount || !fromTokenAddress || !infoTokens || !xlpPrice || !usdxSupply || !totalTokenWeights) {
    return defaultValue
  }

  const fromToken = getTokenInfo(infoTokens, fromTokenAddress)
  if (!fromToken || !fromToken.maxPrice) {
    return defaultValue
  }

  let fromAmount = toAmount.mul(xlpPrice).div(fromToken.maxPrice)
  fromAmount = adjustForDecimals(fromAmount, XLP_DECIMALS, fromToken.decimals)

  const usdgAmount = toAmount.mul(xlpPrice).div(PRECISION)
  const feeBasisPoints = getFeeBasisPoints(
    fromToken,
    usdgAmount,
    mintBurnFeeBasisPoints,
    taxBasisPoints,
    false,
    usdxSupply,
    totalTokenWeights,
  )

  fromAmount = fromAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)

  return { amount: fromAmount, feeBasisPoints }
}

export function getNextFromAmount(
  chainId,
  toAmount,
  fromTokenAddress,
  toTokenAddress,
  infoTokens,
  toTokenPriceUsd,
  ratio,
  usdxSupply,
  totalTokenWeights,
  forSwap,
  stableSwapFeeBasisPoints,
  swapFeeBasisPoints,
  stableTaxBasisPoints,
  taxBasisPoints,
) {
  const defaultValue = { amount: bigNumberify(0) }

  if (!toAmount || !fromTokenAddress || !toTokenAddress || !infoTokens) {
    return defaultValue
  }

  if (fromTokenAddress === toTokenAddress) {
    return { amount: toAmount }
  }

  const fromToken = getTokenInfo(infoTokens, fromTokenAddress)
  const toToken = getTokenInfo(infoTokens, toTokenAddress)

  if (fromToken.isNative && toToken.isWrapped) {
    return { amount: toAmount }
  }

  if (fromToken.isWrapped && toToken.isNative) {
    return { amount: toAmount }
  }

  // the realtime price should be used if it is for a transaction to open / close a position
  // or if the transaction involves doing a swap and opening / closing a position
  // otherwise use the contract price instead of realtime price for swaps

  let fromTokenMinPrice
  if (fromToken) {
    fromTokenMinPrice = forSwap ? fromToken.contractMinPrice : fromToken.minPrice
  }

  let toTokenMaxPrice
  if (toToken) {
    toTokenMaxPrice = forSwap ? toToken.contractMaxPrice : toToken.maxPrice
  }

  if (!fromToken || !fromTokenMinPrice || !toToken || !toTokenMaxPrice) {
    return defaultValue
  }

  const adjustDecimals = adjustForDecimalsFactory(fromToken.decimals - toToken.decimals)

  let fromAmountBasedOnRatio
  if (ratio && !ratio.isZero()) {
    fromAmountBasedOnRatio = toAmount.mul(ratio).div(PRECISION)
  }

  const fromAmount =
    ratio && !ratio.isZero() ? fromAmountBasedOnRatio : toAmount.mul(toTokenMaxPrice).div(fromTokenMinPrice)

  let usdgAmount = fromAmount.mul(fromTokenMinPrice).div(PRECISION)
  usdgAmount = adjustForDecimals(usdgAmount, toToken.decimals, USDX_DECIMALS)
  const swapFeeBSPs = fromToken.isStable && toToken.isStable ? stableSwapFeeBasisPoints : swapFeeBasisPoints
  const taxBSPs = fromToken.isStable && toToken.isStable ? stableTaxBasisPoints : taxBasisPoints
  const feeBasisPoints0 = getFeeBasisPoints(
    fromToken,
    usdgAmount,
    swapFeeBSPs,
    taxBSPs,
    true,
    usdxSupply,
    totalTokenWeights,
  )
  const feeBasisPoints1 = getFeeBasisPoints(
    toToken,
    usdgAmount,
    swapFeeBSPs,
    taxBSPs,
    false,
    usdxSupply,
    totalTokenWeights,
  )
  const feeBasisPoints = feeBasisPoints0 > feeBasisPoints1 ? feeBasisPoints0 : feeBasisPoints1

  return {
    amount: adjustDecimals(fromAmount.mul(BASIS_POINTS_DIVISOR).div(BASIS_POINTS_DIVISOR - feeBasisPoints)),
    feeBasisPoints,
  }
}

export function getNextToAmount(
  chainId,
  fromAmount,
  fromTokenAddress,
  toTokenAddress,
  infoTokens,
  toTokenPriceUsd,
  ratio,
  usdxSupply,
  totalTokenWeights,
  forSwap,
  stableSwapFeeBasisPoints,
  swapFeeBasisPoints,
  stableTaxBasisPoints,
  taxBasisPoints,
) {
  const defaultValue = { amount: bigNumberify(0) }
  if (!fromAmount || !fromTokenAddress || !toTokenAddress || !infoTokens) {
    return defaultValue
  }

  if (fromTokenAddress === toTokenAddress) {
    return { amount: fromAmount }
  }

  const fromToken = getTokenInfo(infoTokens, fromTokenAddress)
  const toToken = getTokenInfo(infoTokens, toTokenAddress)

  if (fromToken.isNative && toToken.isWrapped) {
    return { amount: fromAmount }
  }

  if (fromToken.isWrapped && toToken.isNative) {
    return { amount: fromAmount }
  }

  // the realtime price should be used if it is for a transaction to open / close a position
  // or if the transaction involves doing a swap and opening / closing a position
  // otherwise use the contract price instead of realtime price for swaps

  let fromTokenMinPrice
  if (fromToken) {
    fromTokenMinPrice = forSwap ? fromToken.contractMinPrice : fromToken.minPrice
  }

  let toTokenMaxPrice
  if (toToken) {
    toTokenMaxPrice = forSwap ? toToken.contractMaxPrice : toToken.maxPrice
  }

  if (!fromTokenMinPrice || !toTokenMaxPrice) {
    return defaultValue
  }

  const adjustDecimals = adjustForDecimalsFactory(toToken.decimals - fromToken.decimals)

  let toAmountBasedOnRatio = bigNumberify(0)
  if (ratio && !ratio.isZero()) {
    toAmountBasedOnRatio = fromAmount.mul(PRECISION).div(ratio)
  }

  if (toTokenAddress === USDG_ADDRESS) {
    const feeBasisPoints = getSwapFeeBasisPoints(fromToken.isStable, stableSwapFeeBasisPoints, swapFeeBasisPoints)

    if (ratio && !ratio.isZero()) {
      const toAmount = toAmountBasedOnRatio
      return {
        amount: adjustDecimals(toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)),
        feeBasisPoints,
      }
    }

    const toAmount = fromAmount.mul(fromTokenMinPrice).div(PRECISION)
    return {
      amount: adjustDecimals(toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)),
      feeBasisPoints,
    }
  }

  if (fromTokenAddress === USDG_ADDRESS) {
    const redemptionValue = toToken.redemptionAmount
      .mul(toTokenPriceUsd || toTokenMaxPrice)
      .div(expandDecimals(1, toToken.decimals))

    if (redemptionValue.gt(THRESHOLD_REDEMPTION_VALUE)) {
      const feeBasisPoints = getSwapFeeBasisPoints(toToken.isStable, stableSwapFeeBasisPoints, swapFeeBasisPoints)

      const toAmount =
        ratio && !ratio.isZero()
          ? toAmountBasedOnRatio
          : fromAmount.mul(toToken.redemptionAmount).div(expandDecimals(1, toToken.decimals))

      return {
        amount: adjustDecimals(toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)),
        feeBasisPoints,
      }
    }

    const expectedAmount = fromAmount

    const stableToken = getMostAbundantStableToken(chainId, infoTokens)
    if (!stableToken || stableToken.availableAmount.lt(expectedAmount)) {
      const toAmount =
        ratio && !ratio.isZero()
          ? toAmountBasedOnRatio
          : fromAmount.mul(toToken.redemptionAmount).div(expandDecimals(1, toToken.decimals))
      const feeBasisPoints = getSwapFeeBasisPoints(toToken.isStable, stableSwapFeeBasisPoints, swapFeeBasisPoints)
      return {
        amount: adjustDecimals(toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)),
        feeBasisPoints,
      }
    }

    const feeBasisPoints0 = getSwapFeeBasisPoints(true, stableSwapFeeBasisPoints, swapFeeBasisPoints)
    const feeBasisPoints1 = getSwapFeeBasisPoints(false, stableSwapFeeBasisPoints, swapFeeBasisPoints)

    if (ratio && !ratio.isZero()) {
      const toAmount = toAmountBasedOnRatio
        .mul(BASIS_POINTS_DIVISOR - feeBasisPoints0 - feeBasisPoints1)
        .div(BASIS_POINTS_DIVISOR)
      return {
        amount: adjustDecimals(toAmount),
        path: [USDG_ADDRESS, stableToken.address, toToken.address],
        feeBasisPoints: feeBasisPoints0 + feeBasisPoints1,
      }
    }

    // get toAmount for USDX => stableToken
    let toAmount = fromAmount.mul(PRECISION).div(stableToken.maxPrice)
    // apply USDX => stableToken fees
    toAmount = toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints0).div(BASIS_POINTS_DIVISOR)

    // get toAmount for stableToken => toToken
    toAmount = toAmount.mul(stableToken.minPrice).div(toTokenPriceUsd || toTokenMaxPrice)
    // apply stableToken => toToken fees
    toAmount = toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints1).div(BASIS_POINTS_DIVISOR)

    return {
      amount: adjustDecimals(toAmount),
      path: [USDG_ADDRESS, stableToken.address, toToken.address],
      feeBasisPoints: feeBasisPoints0 + feeBasisPoints1,
    }
  }

  const toAmount =
    ratio && !ratio.isZero()
      ? toAmountBasedOnRatio
      : fromAmount.mul(fromTokenMinPrice).div(toTokenPriceUsd || toTokenMaxPrice)

  let usdgAmount = fromAmount.mul(fromTokenMinPrice).div(PRECISION)
  usdgAmount = adjustForDecimals(usdgAmount, fromToken.decimals, USDX_DECIMALS)
  const swapFeeBSPs = fromToken.isStable && toToken.isStable ? stableSwapFeeBasisPoints : swapFeeBasisPoints
  const taxBSPs = fromToken.isStable && toToken.isStable ? stableTaxBasisPoints : taxBasisPoints
  const feeBasisPoints0 = getFeeBasisPoints(
    fromToken,
    usdgAmount,
    swapFeeBSPs,
    taxBSPs,
    true,
    usdxSupply,
    totalTokenWeights,
  )
  const feeBasisPoints1 = getFeeBasisPoints(
    toToken,
    usdgAmount,
    swapFeeBSPs,
    taxBSPs,
    false,
    usdxSupply,
    totalTokenWeights,
  )
  const feeBasisPoints = feeBasisPoints0 > feeBasisPoints1 ? feeBasisPoints0 : feeBasisPoints1

  return {
    amount: adjustDecimals(toAmount.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)),
    feeBasisPoints,
  }
}

export function getProfitPrice(closePrice, position) {
  let profitPrice
  if (position && position.averagePrice && closePrice) {
    profitPrice = position.isLong
      ? position.averagePrice.mul(BASIS_POINTS_DIVISOR + MIN_PROFIT_BIPS).div(BASIS_POINTS_DIVISOR)
      : position.averagePrice.mul(BASIS_POINTS_DIVISOR - MIN_PROFIT_BIPS).div(BASIS_POINTS_DIVISOR)
  }
  return profitPrice
}

export function calculatePositionDelta(
  price,
  { size, collateral, isLong, averagePrice, lastIncreasedTime },
  sizeDelta,
) {
  if (size.eq(0) && sizeDelta.eq(0) && collateral.eq(0)) {
    return {
      delta: bigNumberify(0),
      pendingDelta: bigNumberify(0),
      pendingDeltaPercentage: bigNumberify(0),
      hasProfit: false,
      deltaPercentage: bigNumberify(0),
    }
  }

  if (!sizeDelta) {
    sizeDelta = size
  }

  const priceDelta = averagePrice.gt(price) ? averagePrice.sub(price) : price.sub(averagePrice)
  let delta = sizeDelta.mul(priceDelta).div(averagePrice)
  const pendingDelta = delta

  const minProfitExpired = lastIncreasedTime + MIN_PROFIT_TIME < Date.now() / 1000
  const hasProfit = isLong ? price.gt(averagePrice) : price.lt(averagePrice)
  if (!minProfitExpired && hasProfit && delta.mul(BASIS_POINTS_DIVISOR).lte(size.mul(MIN_PROFIT_BIPS))) {
    delta = bigNumberify(0)
  }

  const deltaPercentage = delta.mul(BASIS_POINTS_DIVISOR).div(collateral)
  const pendingDeltaPercentage = pendingDelta.mul(BASIS_POINTS_DIVISOR).div(collateral)

  return {
    delta,
    pendingDelta,
    pendingDeltaPercentage,
    hasProfit,
    deltaPercentage,
  }
}

export function getUsd(amount, tokenAddress, max, infoTokens, orderOption, triggerPriceUsd) {
  if (!amount) {
    return
  }
  if (tokenAddress === USDG_ADDRESS) {
    return amount.mul(PRECISION).div(expandDecimals(1, 18))
  }
  const info = getTokenInfo(infoTokens, tokenAddress)
  const price = getTriggerPrice(tokenAddress, max, info, orderOption, triggerPriceUsd)
  if (!price) {
    return
  }

  return amount.mul(price).div(expandDecimals(1, info.decimals))
}

export function getSwapFeeBasisPoints(isStable, stableSwapFeeBasisPoints, swapFeeBasisPoints) {
  return isStable ? stableSwapFeeBasisPoints : swapFeeBasisPoints
}

export const DEV_TESTNET_RPC_PROVIDERS = ['https://develop-chain.0xnode.cloud']
export const ARBITRUM_SEPOLIA_RPC_PROVIDERS = [
  'https://endpoints.omniatech.io/v1/arbitrum/sepolia/public',
  'https://arbitrum-sepolia.blockpi.network/v1/rpc/public',
  'https://public.stackup.sh/api/v1/node/arbitrum-sepolia',
]

const RPC_PROVIDERS = {
  [ARBITRUM_SEPOLIA]: ARBITRUM_SEPOLIA_RPC_PROVIDERS,
  [DEV_TESTNET]: DEV_TESTNET_RPC_PROVIDERS,
}

const FALLBACK_PROVIDERS = {
  [ARBITRUM_SEPOLIA]: ARBITRUM_SEPOLIA_RPC_PROVIDERS,
  [DEV_TESTNET]: DEV_TESTNET_RPC_PROVIDERS,
}

const alchemyWhitelistedDomains = ['gmx.io', 'app.gmx.io']

export function getAlchemyHttpUrl() {
  if (alchemyWhitelistedDomains.includes(window.location.host)) {
    return 'https://arb-mainnet.g.alchemy.com/v2/ha7CFsr1bx5ZItuR6VZBbhKozcKDY4LZ'
  }
  return 'https://arb-mainnet.g.alchemy.com/v2/EmVYwUw0N2tXOuG0SZfe5Z04rzBsCbr2'
}

export function getAlchemyWsUrl() {
  if (alchemyWhitelistedDomains.includes(window.location.host)) {
    return 'wss://arb-mainnet.g.alchemy.com/v2/ha7CFsr1bx5ZItuR6VZBbhKozcKDY4LZ'
  }
  return 'wss://arb-mainnet.g.alchemy.com/v2/EmVYwUw0N2tXOuG0SZfe5Z04rzBsCbr2'
}

export function shortenAddress(address, length) {
  if (!length) {
    return ''
  }
  if (!address) {
    return address
  }
  if (address.length < 10) {
    return address
  }
  let left = Math.floor((length - 3) / 2) + 1
  return address.substring(0, left) + '...' + address.substring(address.length - (length - (left + 3)), address.length)
}

export function trimRightAddress(address, length) {
  if (!length) {
    return ''
  }
  if (!address) {
    return address
  }
  if (address.length < 10) {
    return address
  }
  return address.substring(0, length - 3) + '...'
}

export function formatDateTime(time) {
  return dayjs(time * 1000).format('dd MMM YYYY, h:mm a')
}

export function getTimeRemaining(time) {
  const now = parseInt(Date.now() / 1000)

  if (time < now) {
    return '0h 0m'
  }
  const diff = time - now
  const hours = parseInt(diff / (60 * 60))
  const minutes = parseInt((diff - hours * 60 * 60) / 60)
  return `${hours}h ${minutes}m`
}

export function getTimeAgo(time) {
  const now = parseInt(Date.now() / 1000)

  if (now < time) {
    return ''
  }
  const diff = now - time
  const hours = parseInt(diff / (60 * 60))
  const minutes = parseInt((diff - hours * 60 * 60) / 60)

  if (diff < 60) {
    return `${diff} secs ago`
  } else if (hours < 1) {
    return `${minutes} mins ago`
  } else if (minutes < 1) {
    return `${hours} hrs ago`
  } else {
    return `${hours} hrs ${minutes} mins ago`
  }
}

export function formatDate(time) {
  return dayjs(time * 1000).format('dd MMM YYYY')
}

export function hasMetaMaskWalletExtension() {
  return window.ethereum
}

export function hasCoinBaseWalletExtension() {
  const { ethereum } = window

  if (!ethereum?.providers && !ethereum?.isCoinbaseWallet) {
    return false
  }
  return window.ethereum.isCoinbaseWallet || ethereum.providers.find(({ isCoinbaseWallet }) => isCoinbaseWallet)
}

export function activateInjectedProvider(providerName) {
  const { ethereum } = window

  if (!ethereum?.providers && !ethereum?.isCoinbaseWallet && !ethereum?.isMetaMask) {
    return undefined
  }

  let provider
  if (ethereum?.providers) {
    switch (providerName) {
      case 'CoinBase':
        provider = ethereum.providers.find(({ isCoinbaseWallet }) => isCoinbaseWallet)
        break
      case 'MetaMask':
      default:
        provider = ethereum.providers.find(({ isMetaMask }) => isMetaMask)
        break
    }
  }

  if (provider) {
    ethereum.setSelectedProvider(provider)
  }
}

export function useChainId() {
  let { chain } = useAccount()
  let chainId = chain?.id
  if (!chainId) {
    const chainIdFromLocalStorage = localStorage.getItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY)
    if (chainIdFromLocalStorage) {
      chainId = parseInt(chainIdFromLocalStorage)
      if (!chainId) {
        // localstorage value is invalid
        localStorage.removeItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY)
      }
    }
  }

  if (!chainId || !isSupportedChain(chainId)) {
    chainId = DEFAULT_CHAIN_ID
  }
  return { chainId, unsupported: !chain }
}

export function useENS(address) {
  const [ensName, setENSName] = useState()

  useEffect(() => {
    async function resolveENS() {
      if (address) {
        const provider = new ethers.providers.JsonRpcProvider('https://rpc.ankr.com/eth')
        const name = await provider.lookupAddress(address.toLowerCase())
        if (name) setENSName(name)
      }
    }
    resolveENS()
  }, [address])

  return { ensName }
}

export function clearWalletConnectData() {
  localStorage.removeItem(WALLET_CONNECT_LOCALSTORAGE_KEY)
}

export function clearWalletLinkData() {
  Object.entries(localStorage)
    .map((x) => x[0])
    .filter((x) => x.startsWith(WALLET_LINK_LOCALSTORAGE_PREFIX))
    .map((x) => localStorage.removeItem(x))
}

export function getProvider(library, chainId) {
  let provider
  if (library) {
    return library
  }
  provider = _.sample(RPC_PROVIDERS[chainId])
  return new ethers.providers.StaticJsonRpcProvider(provider, { chainId })
}

export function getFallbackProvider(chainId) {
  if (!FALLBACK_PROVIDERS[chainId]) {
    return
  }

  const provider = _.sample(FALLBACK_PROVIDERS[chainId])
  return new ethers.providers.StaticJsonRpcProvider(provider, { chainId })
}

export const getContractCall = ({ provider, contractInfo, arg0, arg1, method, params, additionalArgs, onError }) => {
  if (ethers.utils.isAddress(arg0)) {
    const address = arg0
    const contract = new ethers.Contract(address, contractInfo, provider)

    if (additionalArgs) {
      return contract[method](...params.concat(additionalArgs))
    }
    return contract[method](...params)
  }

  if (!provider) {
    return
  }

  return provider[method](arg1, ...params)
}

// prettier-ignore
export const fetcher = (library, contractInfo, additionalArgs) => (...args) => {
  // eslint-disable-next-line
  const [id, chainId, arg0, arg1, ...params] = args;
  const provider = getProvider(library, chainId);

  const method = ethers.utils.isAddress(arg0) ? arg1 : arg0;

  const contractCall = getContractCall({
    provider,
    contractInfo,
    arg0,
    arg1,
    method,
    params,
    additionalArgs,
  })

  let shouldCallFallback = true

  const handleFallback = async (resolve, reject, error) => {
    if (!shouldCallFallback) {
      return
    }
    // prevent fallback from being called twice
    shouldCallFallback = false

    const fallbackProvider = getFallbackProvider(chainId)
    if (!fallbackProvider) {
      reject(error)
      return
    }

    const fallbackContractCall = getContractCall({
      provider: fallbackProvider,
      contractInfo,
      arg0,
      arg1,
      method,
      params,
      additionalArgs,
    })

    fallbackContractCall.then((result) => resolve(result)).catch((e) => {
      console.error("fallback fetcher error", id, contractInfo.contractName, method, e);
      reject(e)
    })
  }

  return new Promise(async (resolve, reject) => {
    contractCall.then((result) => {
      shouldCallFallback = false
      resolve(result)
    }).catch((e) => {
      console.error("fetcher error", {
        id, contractName: contractInfo.contractName, method, e
      });
      handleFallback(resolve, reject, e)
    })

    setTimeout(() => {
      handleFallback(resolve, reject, "contractCall timeout")
    }, 4000)
  })
};

export function bigNumberify(n) {
  try {
    return ethers.BigNumber.from(n)
  } catch (e) {
    console.error('bigNumberify error', e)
    return undefined
  }
}

export function expandDecimals(n, decimals) {
  return bigNumberify(n).mul(bigNumberify(10).pow(decimals))
}

export const trimZeroDecimals = (amount) => {
  if (parseFloat(amount) === parseInt(amount)) {
    return parseInt(amount).toString()
  }
  return amount
}

export const limitDecimals = (amount, maxDecimals) => {
  let amountStr = amount.toString()
  if (maxDecimals === undefined) {
    return amountStr
  }
  if (maxDecimals === 0) {
    return amountStr.split('.')[0]
  }
  const dotIndex = amountStr.indexOf('.')
  if (dotIndex !== -1) {
    let decimals = amountStr.length - dotIndex - 1
    if (decimals > maxDecimals) {
      amountStr = amountStr.substr(0, amountStr.length - (decimals - maxDecimals))
    }
  }
  return amountStr
}

export const padDecimals = (amount, minDecimals) => {
  let amountStr = amount.toString()
  const dotIndex = amountStr.indexOf('.')
  if (dotIndex !== -1) {
    const decimals = amountStr.length - dotIndex - 1
    if (decimals < minDecimals) {
      amountStr = amountStr.padEnd(amountStr.length + (minDecimals - decimals), '0')
    }
  } else {
    amountStr = amountStr + '.0000'
  }
  return amountStr
}

export const formatKeyAmount = (map, key, tokenDecimals, displayDecimals, useCommas) => {
  if (!map || !map[key]) {
    return '...'
  }

  return formatAmount(map[key], tokenDecimals, displayDecimals, useCommas)
}

export const formatArrayAmount = (arr, index, tokenDecimals, displayDecimals, useCommas) => {
  if (!arr || !arr[index]) {
    return '...'
  }

  return formatAmount(arr[index], tokenDecimals, displayDecimals, useCommas)
}

function _parseOrdersData(ordersData, account, indexes, extractor, uintPropsLength, addressPropsLength) {
  if (!ordersData || ordersData.length === 0) {
    return []
  }
  const [uintProps, addressProps] = ordersData
  const count = uintProps.length / uintPropsLength

  const orders = []
  for (let i = 0; i < count; i++) {
    const sliced = addressProps
      .slice(addressPropsLength * i, addressPropsLength * (i + 1))
      .concat(uintProps.slice(uintPropsLength * i, uintPropsLength * (i + 1)))

    if (sliced[0] === AddressZero && sliced[1] === AddressZero) {
      continue
    }

    const order = extractor(sliced)
    order.index = indexes[i]
    order.account = account
    orders.push(order)
  }

  return orders
}

function parseDecreaseOrdersData(chainId, decreaseOrdersData, account, indexes) {
  const extractor = (sliced) => {
    const isLong = sliced[4].toString() === '1'
    return {
      collateralToken: sliced[0],
      indexToken: sliced[1],
      collateralDelta: sliced[2],
      sizeDelta: sliced[3],
      isLong,
      triggerPrice: sliced[5],
      triggerAboveThreshold: sliced[6].toString() === '1',
      type: DECREASE,
    }
  }
  return _parseOrdersData(decreaseOrdersData, account, indexes, extractor, 5, 2).filter((order) => {
    return isValidToken(chainId, order.collateralToken) && isValidToken(chainId, order.indexToken)
  })
}

function parseIncreaseOrdersData(chainId, increaseOrdersData, account, indexes) {
  const extractor = (sliced) => {
    const isLong = sliced[5].toString() === '1'
    return {
      purchaseToken: sliced[0],
      collateralToken: sliced[1],
      indexToken: sliced[2],
      purchaseTokenAmount: sliced[3],
      sizeDelta: sliced[4],
      isLong,
      triggerPrice: sliced[6],
      triggerAboveThreshold: sliced[7].toString() === '1',
      type: INCREASE,
    }
  }

  return _parseOrdersData(increaseOrdersData, account, indexes, extractor, 5, 3).filter((order) => {
    return (
      isValidToken(chainId, order.purchaseToken) &&
      isValidToken(chainId, order.collateralToken) &&
      isValidToken(chainId, order.indexToken)
    )
  })
}

function parseSwapOrdersData(chainId, swapOrdersData, account, indexes) {
  if (!swapOrdersData || !swapOrdersData.length) {
    return []
  }

  const extractor = (sliced) => {
    const triggerAboveThreshold = sliced[6].toString() === '1'
    const shouldUnwrap = sliced[7].toString() === '1'

    return {
      path: [sliced[0], sliced[1], sliced[2]].filter((address) => address !== AddressZero),
      amountIn: sliced[3],
      minOut: sliced[4],
      triggerRatio: sliced[5],
      triggerAboveThreshold,
      type: SWAP,
      shouldUnwrap,
    }
  }
  return _parseOrdersData(swapOrdersData, account, indexes, extractor, 5, 3).filter((order) => {
    return order.path.every((token) => isValidToken(chainId, token))
  })
}

export function getOrderKey(order) {
  return `${order.type}-${order.account}-${order.index}`
}

export function useAccountOrders(flagOrdersEnabled, overrideAccount, orderPaginate) {
  const { library, account: connectedAccount } = useWeb3()

  const active = true // this is used in Actions.js so set active to always be true
  const account = overrideAccount || connectedAccount

  const { chainId } = useChainId()
  const shouldRequest = active && account && flagOrdersEnabled

  const orderBookAddress = getContract(chainId, 'OrderBook')
  const orderBookReaderAddress = getContract(chainId, 'OrderBookReader')

  const key = shouldRequest ? [active, chainId, orderBookAddress, account, orderPaginate] : false

  const {
    data,
    mutate: updateOrders,
    error: ordersError,
  } = useSWR(key, {
    dedupingInterval: 5000,
    fetcher: async (active, chainId, orderBookAddress, account, orderPaginate) => {
      const provider = getProvider(library, chainId)
      const orderBookContract = new ethers.Contract(orderBookAddress, OrderBook, provider)
      const orderBookReaderContract = new ethers.Contract(orderBookReaderAddress, OrderBookReader, provider)

      const fetchIndexesFromServer = () => {
        const ordersIndexesUrl = `${getServerBaseUrl(
          chainId,
        )}/orders_indices?account=${account}&first=${orderPaginate}&chain=${chainId}`
        return fetch(ordersIndexesUrl)
          .then(async (res) => {
            const json = await res.json()
            const ret = {}
            const total = json.Total
            delete json.Total
            for (const key of Object.keys(json)) {
              ret[key.toLowerCase()] = json[key].map((val) => parseInt(val.value)).sort((a, b) => a - b)
            }

            return { ret, total }
          })
          .catch(() => ({ swap: [], increase: [], decrease: [], total: 0 }))
      }

      const fetchLastIndex = async (type) => {
        const method = type.toLowerCase() + 'OrdersIndex'
        return await orderBookContract[method](account).then((res) => bigNumberify(res._hex).toNumber())
      }

      const fetchLastIndexes = async () => {
        const [swap, increase, decrease] = await Promise.all([
          fetchLastIndex('swap'),
          fetchLastIndex('increase'),
          fetchLastIndex('decrease'),
        ])

        return { swap, increase, decrease }
      }

      const getRange = (to, from) => {
        const LIMIT = 10
        const _indexes = []
        from = from || Math.max(to - LIMIT, 0)
        for (let i = to - 1; i >= from; i--) {
          _indexes.push(i)
        }
        return _indexes
      }

      const getIndexes = (knownIndexes, lastIndex) => {
        if (knownIndexes.length === 0) {
          return getRange(lastIndex)
        }
        return [
          ...knownIndexes,
          ...getRange(lastIndex, knownIndexes[knownIndexes.length - 1] + 1).sort((a, b) => b - a),
        ]
      }

      const getOrders = async (method, knownIndexes, lastIndex, parseFunc) => {
        const indexes = getIndexes(knownIndexes, lastIndex)
        const ordersData = await orderBookReaderContract[method](orderBookAddress, account, indexes)
        const orders = parseFunc(chainId, ordersData, account, indexes)
        return orders
      }

      try {
        const [serverIndexes, lastIndexes] = await Promise.all([fetchIndexesFromServer(), fetchLastIndexes()])
        const [swapOrders = [], increaseOrders = [], decreaseOrders = []] = await Promise.all([
          getOrders('getSwapOrders', serverIndexes.ret.swap, lastIndexes.swap, parseSwapOrdersData),
          getOrders('getIncreaseOrders', serverIndexes.ret.increase, lastIndexes.increase, parseIncreaseOrdersData),
          getOrders('getDecreaseOrders', serverIndexes.ret.decrease, lastIndexes.decrease, parseDecreaseOrdersData),
        ])
        return {
          orders: [...swapOrders, ...increaseOrders, ...decreaseOrders],
          total: serverIndexes.total,
        }
      } catch (ex) {
        console.error('Opp!! OrderBook: ', ex)
      }
    },
  })

  return [data?.orders ?? [], updateOrders, ordersError, data?.total ?? 0]
}

export const formatAmount = (amount, tokenDecimals, displayDecimals, useCommas, defaultValue) => {
  if (!defaultValue) {
    defaultValue = '...'
  }
  if (amount === undefined || amount.toString().length === 0) {
    return defaultValue
  }
  if (displayDecimals === undefined) {
    displayDecimals = 4
  }
  let amountStr = ethers.utils.formatUnits(amount, tokenDecimals)

  // addjust decimals for low price
  if (displayDecimals > 0) {
    if (parseFloat(amountStr) < 0.01 && displayDecimals < 5) {
      displayDecimals = 5
    } else if (parseFloat(amountStr) < 0.1 && displayDecimals < 4) {
      displayDecimals = 4
    } else if (parseFloat(amountStr) < 2.0 && displayDecimals < 3) {
      displayDecimals = 3
    }
  }

  amountStr = limitDecimals(amountStr, displayDecimals)
  if (displayDecimals !== 0) {
    amountStr = padDecimals(amountStr, displayDecimals)
  }
  if (useCommas) {
    return numberWithCommas(amountStr)
  }
  return amountStr
}

export const formatAmountFree = (amount, tokenDecimals, displayDecimals) => {
  if (!amount) {
    return '...'
  }
  let amountStr = ethers.utils.formatUnits(amount, tokenDecimals)

  // addjust decimals for low price
  if (displayDecimals > 0) {
    if (parseFloat(amountStr) < 0.01 && displayDecimals < 5) {
      displayDecimals = 5
    } else if (parseFloat(amountStr) < 0.1 && displayDecimals < 4) {
      displayDecimals = 4
    } else if (parseFloat(amountStr) < 2.0 && displayDecimals < 3) {
      displayDecimals = 3
    }
  }

  amountStr = limitDecimals(amountStr, displayDecimals)
  return trimZeroDecimals(amountStr)
}

export const parseValue = (value, tokenDecimals) => {
  const pValue = parseFloat(value)
  if (isNaN(pValue)) {
    return undefined
  }
  value = limitDecimals(value, tokenDecimals)
  const amount = ethers.utils.parseUnits(value, tokenDecimals)
  return bigNumberify(amount)
}

export function numberWithCommas(x) {
  if (!x) {
    return '...'
  }
  var parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export function getExplorerUrl(chainId) {
  if (chainId === ARBITRUM_SEPOLIA) {
    return 'https://sepolia.arbiscan.io/'
  } else if (chainId === DEV_TESTNET) {
    return 'https://develop-chain-explorer.0xnode.cloud/'
  }

  return 'https://sepolia.arbiscan.io/'
}

export function getAccountUrl(chainId, account) {
  if (!account) {
    return getExplorerUrl(chainId)
  }
  return getExplorerUrl(chainId) + 'address/' + account
}

export function getTokenUrl(chainId, address) {
  if (!address) {
    return getExplorerUrl(chainId)
  }
  return getExplorerUrl(chainId) + 'token/' + address
}

export function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export async function setGasPrice(txnOpts, provider, chainId) {
  let maxGasPrice = MAX_GAS_PRICE_MAP[chainId]
  const premium = GAS_PRICE_ADJUSTMENT_MAP[chainId] || bigNumberify(0)

  const gasPrice = await provider.getGasPrice()

  if (maxGasPrice) {
    if (gasPrice.gt(maxGasPrice)) {
      maxGasPrice = gasPrice
    }

    const feeData = await provider.getFeeData()
    // the wallet provider might not return maxPriorityFeePerGas in feeData
    // in which case we should fallback to the usual getGasPrice flow handled below
    if (feeData && feeData.maxPriorityFeePerGas) {
      txnOpts.maxFeePerGas = maxGasPrice
      txnOpts.maxPriorityFeePerGas = feeData.maxPriorityFeePerGas.add(premium)
      return
    }
  }

  txnOpts.gasPrice = gasPrice.add(premium)
  return
}

export async function getGasLimit(contract, method, params = [], value = bigNumberify(0)) {
  const defaultValue = bigNumberify(0)

  if (!value) {
    value = defaultValue
  }

  let gasLimit = await contract.estimateGas[method](...params, { value })

  if (gasLimit.lt(22000)) {
    gasLimit = bigNumberify(22000)
  }

  return gasLimit.mul(11000).div(10000) // add a 10% buffer
}

export function approveTokens({
  setIsApproving,
  library,
  tokenAddress,
  spender,
  chainId,
  onApproveSubmitted,
  getTokenInfo,
  infoTokens,
  pendingTxns,
  setPendingTxns,
  includeMessage,
}) {
  setIsApproving(true)
  const contract = new ethers.Contract(tokenAddress, Token, library)
  contract
    .approve(spender, ethers.constants.MaxUint256)
    .then(async (res) => {
      const txUrl = getExplorerUrl(chainId) + 'tx/' + res.hash
      helperToast.success(
        <div>
          Approval submitted!{' '}
          <a href={txUrl} target="_blank" rel="noopener noreferrer">
            View status.
          </a>
          <br />
        </div>,
      )
      if (onApproveSubmitted) {
        onApproveSubmitted()
      }
      if (getTokenInfo && infoTokens && pendingTxns && setPendingTxns) {
        const token = getTokenInfo(infoTokens, tokenAddress)
        const pendingTxn = {
          hash: res.hash,
          message: includeMessage ? `${token.symbol} Approved!` : false,
        }
        setPendingTxns([...pendingTxns, pendingTxn])
      }
    })
    .catch((e) => {
      console.error(e)
      let failMsg
      if (
        ['not enough funds for gas', 'failed to execute call with revert code InsufficientGasFunds'].includes(
          e.data?.message,
        )
      ) {
        failMsg = (
          <div>
            There is not enough ETH in your account on Arbitrum to send this transaction.
            <br />
            <br />
            <a href={'https://arbitrum.io/bridge-tutorial/'} target="_blank" rel="noopener noreferrer">
              Bridge ETH to Arbitrum
            </a>
          </div>
        )
      } else if (e.message?.includes('User denied transaction signature')) {
        failMsg = 'Approval was cancelled'
      } else {
        failMsg = 'Approval failed'
      }
      helperToast.error(failMsg)
    })
    .finally(() => {
      setIsApproving(false)
    })
}

export const shouldRaiseGasError = (token, amount) => {
  if (!amount) {
    return false
  }
  if (token.address !== AddressZero) {
    return false
  }
  if (!token.balance) {
    return false
  }
  if (amount.gte(token.balance)) {
    return true
  }
  if (token.balance.sub(amount).lt(DUST_BNB)) {
    return true
  }
  return false
}

export const getTokenInfo = (infoTokens, tokenAddress, replaceNative, nativeTokenAddress) => {
  if (replaceNative && tokenAddress === nativeTokenAddress) {
    return infoTokens[AddressZero]
  }
  if (!infoTokens) return {}
  return infoTokens[tokenAddress]
}

export function isMobileDevice(navigator) {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export function setTokenUsingIndexPrices(token, indexPrices, nativeTokenAddress) {
  if (!indexPrices) {
    return
  }

  const tokenpriceFeedIndex = token.priceFeedIndex
  const indexPrice = indexPrices[tokenpriceFeedIndex]

  const indexPriceBn = indexPrice ? bigNumberify(indexPrice) : bigNumberify(expandDecimals(1, USD_DECIMALS))
  if (indexPriceBn.eq(0)) {
    return
  }

  const spread = token.maxPrice.sub(token.minPrice)
  const spreadBps = spread.mul(BASIS_POINTS_DIVISOR).div(token.maxPrice.add(token.minPrice).div(2))

  if (spreadBps.gt(MAX_PRICE_DEVIATION_BASIS_POINTS - 50)) {
    // only set one of the values as there will be a spread between the index price and the Chainlink price
    if (indexPriceBn.gt(token.minPrimaryPrice)) {
      token.maxPrice = indexPriceBn
    } else {
      token.minPrice = indexPriceBn
    }
    return
  }

  const halfSpreadBps = spreadBps.div(2).toNumber()
  token.maxPrice = indexPriceBn.mul(BASIS_POINTS_DIVISOR + halfSpreadBps).div(BASIS_POINTS_DIVISOR)
  token.minPrice = indexPriceBn.mul(BASIS_POINTS_DIVISOR - halfSpreadBps).div(BASIS_POINTS_DIVISOR)
}

export const CHART_PERIODS = {
  '5m': 60 * 5,
  '15m': 60 * 15,
  '1h': 60 * 60,
  '4h': 60 * 60 * 4,
  '1d': 60 * 60 * 24,
}

export function getTotalVolumeSum(volumes) {
  if (!volumes || volumes.length === 0) {
    return
  }

  let volume = bigNumberify(0)
  for (let i = 0; i < volumes.length; i++) {
    volume = volume.add(volumes[i].data.volume)
  }

  return volume
}

export function getBalanceAndSupplyData(balances) {
  if (!balances || balances.length === 0) {
    return {}
  }

  const keys = ['gmx', 'XLP']
  const balanceData = {}
  const supplyData = {}
  const propsLength = 2

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    balanceData[key] = balances[i * propsLength]
    supplyData[key] = balances[i * propsLength + 1]
  }

  return { balanceData, supplyData }
}

export function getDepositBalanceData(depositBalances) {
  if (!depositBalances || depositBalances.length === 0) {
    return
  }

  const keys = [
    'gmxInStakedGmx',
    'esGmxInStakedGmx',
    'stakedGmxInBonusGmx',
    'bonusGmxInFeeGmx',
    'bnGmxInFeeGmx',
    'glpInStakedGlp',
  ]
  const data = {}

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    data[key] = depositBalances[i]
  }

  return data
}

export function getVestingData(vestingInfo) {
  if (!vestingInfo || vestingInfo.length === 0) {
    return
  }

  const keys = ['gmxVester', 'glpVester']
  const data = {}
  const propsLength = 7

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    data[key] = {
      pairAmount: vestingInfo[i * propsLength],
      vestedAmount: vestingInfo[i * propsLength + 1],
      escrowedBalance: vestingInfo[i * propsLength + 2],
      claimedAmounts: vestingInfo[i * propsLength + 3],
      claimable: vestingInfo[i * propsLength + 4],
      maxVestableAmount: vestingInfo[i * propsLength + 5],
      averageStakedAmount: vestingInfo[i * propsLength + 6],
    }

    data[key + 'PairAmount'] = data[key].pairAmount
    data[key + 'VestedAmount'] = data[key].vestedAmount
    data[key + 'EscrowedBalance'] = data[key].escrowedBalance
    data[key + 'ClaimSum'] = data[key].claimedAmounts.add(data[key].claimable)
    data[key + 'Claimable'] = data[key].claimable
    data[key + 'MaxVestableAmount'] = data[key].maxVestableAmount
    data[key + 'AverageStakedAmount'] = data[key].averageStakedAmount
  }

  return data
}

export function getStakingData(stakingInfo) {
  if (!stakingInfo || stakingInfo.length === 0) {
    return
  }

  const keys = ['FeeXlpTracker']
  const data = {}
  const propsLength = 5

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    data[key] = {
      claimable: stakingInfo[i * propsLength],
      tokensPerInterval: stakingInfo[i * propsLength + 1],
      averageStakedAmounts: stakingInfo[i * propsLength + 2],
      cumulativeRewards: stakingInfo[i * propsLength + 3],
      totalSupply: stakingInfo[i * propsLength + 4],
    }
  }

  return data
}

export function getProcessedData(
  balanceData,
  supplyData,
  depositBalanceData,
  stakingData,
  vestingData,
  aum,
  nativeTokenPrice,
  stakedGmxSupply,
  gmxPrice,
  gmxSupply,
) {
  if (
    !balanceData ||
    !supplyData ||
    !depositBalanceData ||
    !stakingData ||
    !vestingData ||
    !aum ||
    !nativeTokenPrice ||
    !stakedGmxSupply ||
    !gmxPrice ||
    !gmxSupply
  ) {
    return {}
  }

  const data = {}

  data.gmxBalance = balanceData.gmx
  data.gmxBalanceUsd = balanceData.gmx.mul(gmxPrice).div(expandDecimals(1, 18))

  data.gmxSupply = bigNumberify(gmxSupply)

  data.gmxSupplyUsd = data.gmxSupply.mul(gmxPrice).div(expandDecimals(1, 18))
  data.stakedGmxSupply = stakedGmxSupply
  data.stakedGmxSupplyUsd = stakedGmxSupply.mul(gmxPrice).div(expandDecimals(1, 18))
  data.gmxInStakedGmx = depositBalanceData.gmxInStakedGmx
  data.gmxInStakedGmxUsd = depositBalanceData.gmxInStakedGmx.mul(gmxPrice).div(expandDecimals(1, 18))

  data.esGmxBalance = balanceData.esGmx
  data.esGmxBalanceUsd = balanceData.esGmx.mul(gmxPrice).div(expandDecimals(1, 18))

  data.stakedGmxTrackerSupply = supplyData.stakedGmxTracker
  data.stakedGmxTrackerSupplyUsd = supplyData.stakedGmxTracker.mul(gmxPrice).div(expandDecimals(1, 18))
  data.stakedEsGmxSupply = data.stakedGmxTrackerSupply.sub(data.stakedGmxSupply)
  data.stakedEsGmxSupplyUsd = data.stakedEsGmxSupply.mul(gmxPrice).div(expandDecimals(1, 18))

  data.esGmxInStakedGmx = depositBalanceData.esGmxInStakedGmx
  data.esGmxInStakedGmxUsd = depositBalanceData.esGmxInStakedGmx.mul(gmxPrice).div(expandDecimals(1, 18))

  data.bnGmxInFeeGmx = depositBalanceData.bnGmxInFeeGmx
  data.bonusGmxInFeeGmx = depositBalanceData.bonusGmxInFeeGmx
  data.feeGmxSupply = stakingData.feeGmxTracker.totalSupply
  data.feeGmxSupplyUsd = data.feeGmxSupply.mul(gmxPrice).div(expandDecimals(1, 18))

  data.stakedGmxTrackerRewards = stakingData.stakedGmxTracker.claimable
  data.stakedGmxTrackerRewardsUsd = stakingData.stakedGmxTracker.claimable.mul(gmxPrice).div(expandDecimals(1, 18))

  data.bonusGmxTrackerRewards = stakingData.bonusGmxTracker.claimable

  data.feeGmxTrackerRewards = stakingData.feeGmxTracker.claimable
  data.feeGmxTrackerRewardsUsd = stakingData.feeGmxTracker.claimable.mul(nativeTokenPrice).div(expandDecimals(1, 18))

  data.boostBasisPoints = bigNumberify(0)
  if (data && data.bnGmxInFeeGmx && data.bonusGmxInFeeGmx && data.bonusGmxInFeeGmx.gt(0)) {
    data.boostBasisPoints = data.bnGmxInFeeGmx.mul(BASIS_POINTS_DIVISOR).div(data.bonusGmxInFeeGmx)
  }

  data.stakedGmxTrackerAnnualRewardsUsd = stakingData.stakedGmxTracker.tokensPerInterval
    .mul(SECONDS_PER_YEAR)
    .mul(gmxPrice)
    .div(expandDecimals(1, 18))
  data.gmxAprForEsGmx =
    data.stakedGmxTrackerSupplyUsd && data.stakedGmxTrackerSupplyUsd.gt(0)
      ? data.stakedGmxTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(data.stakedGmxTrackerSupplyUsd)
      : bigNumberify(0)
  data.feeGmxTrackerAnnualRewardsUsd = stakingData.feeGmxTracker.tokensPerInterval
    .mul(SECONDS_PER_YEAR)
    .mul(nativeTokenPrice)
    .div(expandDecimals(1, 18))
  data.gmxAprForNativeToken =
    data.feeGmxSupplyUsd && data.feeGmxSupplyUsd.gt(0)
      ? data.feeGmxTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(data.feeGmxSupplyUsd)
      : bigNumberify(0)
  data.gmxBoostAprForNativeToken = data.gmxAprForNativeToken.mul(data.boostBasisPoints).div(BASIS_POINTS_DIVISOR)
  data.gmxAprTotal = data.gmxAprForNativeToken.add(data.gmxAprForEsGmx)
  data.gmxAprTotalWithBoost = data.gmxAprForNativeToken.add(data.gmxBoostAprForNativeToken).add(data.gmxAprForEsGmx)
  data.gmxAprForNativeTokenWithBoost = data.gmxAprForNativeToken.add(data.gmxBoostAprForNativeToken)

  data.totalGmxRewardsUsd = data.stakedGmxTrackerRewardsUsd.add(data.feeGmxTrackerRewardsUsd)

  data.glpSupply = supplyData.XLP
  data.fsGlpSupply = supplyData?.stakedGlpTracker

  data.xlpPrice =
    data.glpSupply && data.glpSupply.gt(0)
      ? aum.mul(expandDecimals(1, XLP_DECIMALS)).div(data.glpSupply)
      : bigNumberify(0)

  data.fsGlpSupplyUsd = supplyData?.stakedGlpTracker?.mul(data.xlpPrice).div(expandDecimals(1, 18))
  data.glpSupplyUsd = supplyData.XLP.mul(data.xlpPrice).div(expandDecimals(1, 18))

  data.glpBalance = depositBalanceData.glpInStakedGlp
  data.glpBalanceUsd = depositBalanceData.glpInStakedGlp.mul(data.xlpPrice).div(expandDecimals(1, XLP_DECIMALS))

  data.stakedGlpTrackerRewards = stakingData.stakedGlpTracker.claimable
  data.stakedGlpTrackerRewardsUsd = stakingData.stakedGlpTracker.claimable.mul(gmxPrice).div(expandDecimals(1, 18))

  data.feeGlpTrackerRewards = stakingData.FeeXlpTracker.claimable
  data.feeGlpTrackerRewardsUsd = stakingData.FeeXlpTracker.claimable.mul(nativeTokenPrice).div(expandDecimals(1, 18))

  data.stakedGlpTrackerAnnualRewardsUsd = stakingData.stakedGlpTracker.tokensPerInterval
    .mul(SECONDS_PER_YEAR)
    .mul(gmxPrice)
    .div(expandDecimals(1, 18))
  data.glpAprForEsGmx =
    data.glpSupplyUsd && data.glpSupplyUsd.gt(0)
      ? data.stakedGlpTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(data.glpSupplyUsd)
      : bigNumberify(0)
  data.feeGlpTrackerAnnualRewardsUsd = stakingData.FeeXlpTracker.tokensPerInterval
    .mul(SECONDS_PER_YEAR)
    .mul(nativeTokenPrice)
    .div(expandDecimals(1, 18))

  data.glpAprForNativeToken =
    data.glpSupplyUsd && data.glpSupplyUsd.gt(0)
      ? data.feeGlpTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(data.glpSupplyUsd)
      : bigNumberify(0)
  data.glpAprTotal = data.glpAprForNativeToken.add(data.glpAprForEsGmx)

  data.totalGlpRewardsUsd = data.stakedGlpTrackerRewardsUsd.add(data.feeGlpTrackerRewardsUsd)

  data.totalEsGmxRewards = data.stakedGmxTrackerRewards.add(data.stakedGlpTrackerRewards)
  data.totalEsGmxRewardsUsd = data.stakedGmxTrackerRewardsUsd.add(data.stakedGlpTrackerRewardsUsd)

  data.gmxVesterRewards = vestingData.gmxVester.claimable
  data.glpVesterRewards = vestingData.glpVester.claimable
  data.totalVesterRewards = data.gmxVesterRewards.add(data.glpVesterRewards)
  data.totalVesterRewardsUsd = data.totalVesterRewards.mul(gmxPrice).div(expandDecimals(1, 18))

  data.totalNativeTokenRewards = data.feeGmxTrackerRewards.add(data.feeGlpTrackerRewards)
  data.totalNativeTokenRewardsUsd = data.feeGmxTrackerRewardsUsd.add(data.feeGlpTrackerRewardsUsd)

  data.totalRewardsUsd = data.totalEsGmxRewardsUsd.add(data.totalNativeTokenRewardsUsd).add(data.totalVesterRewardsUsd)

  return data
}

export async function addTokenToMetamask(token) {
  try {
    const wasAdded = await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: token.address,
          symbol: token.symbol,
          decimals: token.decimals,
          image: token.imageUrl,
        },
      },
    })
    if (wasAdded) {
      // https://github.com/MetaMask/metamask-extension/issues/11377
      // We can show a toast message when the token is added to metamask but because of the bug we can't. Once the bug is fixed we can show a toast message.
    }
  } catch (error) {
    console.error(error)
  }
}

export function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), ms)
  })
}

export function getPageTitle(data) {
  return `${data} | Decentralized
  Perpetual Exchange | TFX`
}

export function isHashZero(value) {
  return value === ethers.constants.HashZero
}
export function isAddressZero(value) {
  return value === ethers.constants.AddressZero
}

export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay], // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

export function isDevelopment() {
  return !window.location.host?.includes('tfx.market') && !window.location.host?.includes('ipfs.io')
}

export function isProduction() {
  return process.env.REACT_APP_ENV === 'production'
}

export function isLocal() {
  return window.location.host?.includes('localhost')
}

export function getHomeUrl() {
  if (isLocal()) {
    return 'http://localhost:3011'
  }

  //return "https://gmx.io";
  return 'https://develop-web.tfx.market'
}

export function getAppBaseUrl() {
  if (isLocal()) {
    return 'http://localhost:3011/#'
  }

  //return "https://app.gmx.io/#";
  return 'https://develop-web.tfx.market'
}

export function getTradePageUrl() {
  if (isLocal()) {
    return 'http://localhost:3011/#/trade'
  }

  //return "https://app.gmx.io/#/trade";
  return 'https://tfx.market/#/trade'
}
export function isValidTimestamp(timestamp) {
  return new Date(timestamp).getTime() > 0
}

export function getPositionForOrder(account, order, positionsMap) {
  const key = getPositionKey(account, order.collateralToken, order.indexToken, order.isLong)
  const position = positionsMap[key]
  return position && position.size && position.size.gt(0) ? position : null
}

export function getOrderError(account, order, positionsMap, position) {
  if (order.type !== DECREASE) {
    return
  }

  const positionForOrder = position ? position : getPositionForOrder(account, order, positionsMap)

  if (!positionForOrder) {
    return 'No open position, order cannot be executed unless a position is opened'
  }
  if (positionForOrder.size.lt(order.sizeDelta)) {
    return 'Order size is bigger than position, will only be executable if position increases'
  }

  if (positionForOrder.size.gt(order.sizeDelta)) {
    if (positionForOrder.size.sub(order.sizeDelta).lt(positionForOrder.collateral.sub(order.collateralDelta))) {
      return "Order cannot be executed as it would reduce the position's leverage below 1"
    }
    if (positionForOrder.size.sub(order.sizeDelta).lt(expandDecimals(5, USD_DECIMALS))) {
      return 'Order cannot be executed as the remaining position would be smaller than $5.00'
    }
  }
}

export function arrayURLFetcher(...urlArr) {
  const fetcher = (url) => fetch(url).then((res) => res.json())
  return Promise.all(urlArr.map(fetcher))
}

export function arrayTextURLFetcher(...urlArr) {
  const fetcher = (url) => fetch(url).then((res) => res.text())
  return Promise.all(urlArr.map(fetcher))
}

export function shouldShowRedirectModal(timestamp) {
  const thirtyDays = 1000 * 60 * 60 * 24 * 30
  const expiryTime = timestamp + thirtyDays
  return !isValidTimestamp(timestamp) || Date.now() > expiryTime
}

export function getVolumeInfo(hourlyVolumes) {
  if (!hourlyVolumes || hourlyVolumes.length === 0) {
    return {}
  }

  const totalByChain = {
    totalVolume: 0,
  }

  hourlyVolumes.forEach((total, index) => {
    if (!isNaN(total)) {
      totalByChain[ACTIVE_CHAIN_IDS[index]] = {
        totalVolume: bigNumberify(total),
      }
      totalByChain.totalVolume = bigNumberify(totalByChain.totalVolume).add(bigNumberify(total))
    }
  })

  return totalByChain
}

export function getObjectKeyFromValue(value, object) {
  return Object.keys(object).find((key) => object[key] === value)
}

export function formatTimeInBarToMs(bar) {
  return {
    ...bar,
    time: bar.time * 1000,
  }
}

export function getCurrentCandleTime(period) {
  // Converts current time to seconds, rounds down to nearest period, adds timezone offset, and converts back to milliseconds
  const periodSeconds = CHART_PERIODS[period]
  return Math.floor(Date.now() / 1000 / periodSeconds) * periodSeconds + timezoneOffset
}
