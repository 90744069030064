import { useEffect, useState } from 'react'
import { setTraderReferralCodeByUser, validateReferralCodeExists } from 'utils/api/referrals'
import { REFERRAL_CODE_REGEX } from './referralsHelper'
import { useChainId, useDebounce } from 'utils'
import { useWeb3 } from 'hooks/useWeb3'
import FieldInput from 'components/FieldInput'
import { PButton } from 'components/Button'
import Card from 'components/Card'
import { GA_EVENT_KEY } from 'components/Analytic/constants'
import { useAnalytic } from 'components/Analytic'

function JoinReferralCode({ setPendingTxns, pendingTxns, active, connectWallet }) {
  return (
    <Card className="referral-card txt-color-1 dark:txt-color-4 section-center mt-medium w-full">
      <h2 className="title">Enter Referral Code</h2>
      <p className="sub-title">Please input a referral code to benefit from fee discounts.</p>
      <div className="mt-10 mb-5">
        {active ? (
          <ReferralCodeForm setPendingTxns={setPendingTxns} pendingTxns={pendingTxns} />
        ) : (
          <PButton size="xl" type="submit" onClick={connectWallet}>
            Connect Wallet
          </PButton>
        )}
      </div>
    </Card>
  )
}

interface IReferralCodeForm {
  setPendingTxns: (value: any) => void
  pendingTxns: any
  callAfterSuccess?: () => void
  userReferralCodeString?: string
  type?: 'join' | 'edit'
}

export function ReferralCodeForm({
  setPendingTxns,
  pendingTxns,
  callAfterSuccess,
  userReferralCodeString = '',
  type = 'join',
}: IReferralCodeForm) {
  const { account, library } = useWeb3()
  const { chainId } = useChainId()
  const [referralCode, setReferralCode] = useState('')
  const [isValidating, setIsValidating] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [referralCodeExists, setReferralCodeExists] = useState(true)
  const debouncedReferralCode = useDebounce(referralCode, 300)
  const { sendEvent } = useAnalytic()

  function getPrimaryText() {
    const isEdit = type === 'edit'
    if (isEdit && debouncedReferralCode === userReferralCodeString) {
      return 'Same as current active code'
    }
    if (isEdit && isSubmitting) {
      return 'Updating...'
    }

    if (isSubmitting) {
      return 'Adding...'
    }
    if (debouncedReferralCode === '') {
      return 'Enter Referral Code'
    }
    if (isValidating) {
      return 'Checking code...'
    }
    if (!referralCodeExists) {
      return 'Referral Code does not exist'
    }

    return isEdit ? 'Update' : 'Submit'
  }
  function isPrimaryEnabled() {
    if (
      debouncedReferralCode === '' ||
      isSubmitting ||
      isValidating ||
      !referralCodeExists ||
      debouncedReferralCode === userReferralCodeString
    ) {
      return false
    }
    return true
  }

  async function handleSubmit(event) {
    const isEdit = type === 'edit'
    event.preventDefault()
    setIsSubmitting(true)

    try {
      const tx = await setTraderReferralCodeByUser(chainId, referralCode, library, {
        account,
        successMsg: isEdit ? 'Referral code updated!' : 'Referral code added!',
        failMsg: isEdit ? 'Referral code updated failed.' : 'Adding referral code failed.',
        setPendingTxns,
        pendingTxns,
      })
      if (callAfterSuccess) {
        callAfterSuccess()
      }
      const receipt = await tx.wait()

      sendEvent({
        category: GA_EVENT_KEY.REFERRAL.CATEGORY,
        action: GA_EVENT_KEY.REFERRAL.ACTION.SUBMIT_ENTER_CODE,
        label: referralCode,
      })

      if (receipt.status === 1) {
        setReferralCode('')
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsSubmitting(false)
      setIsValidating(false)
    }
  }

  useEffect(() => {
    let cancelled = false
    async function checkReferralCode() {
      if (debouncedReferralCode === '' || !REFERRAL_CODE_REGEX.test(debouncedReferralCode)) {
        setIsValidating(false)
        setReferralCodeExists(false)
        return
      }

      setIsValidating(true)
      const codeExists = await validateReferralCodeExists(debouncedReferralCode, chainId)
      if (!cancelled) {
        setReferralCodeExists(codeExists)
        setIsValidating(false)
      }
    }
    checkReferralCode()
    return () => {
      cancelled = true
    }
  }, [debouncedReferralCode, chainId])

  return (
    <form onSubmit={handleSubmit}>
      <FieldInput
        disabled={isSubmitting}
        type="text"
        placeholder="Enter referral code"
        label="Referral Code"
        value={referralCode}
        onChange={({ target }) => {
          const { value } = target
          setReferralCode(value)
        }}
      />
      <PButton size="xl" type="submit" disabled={!isPrimaryEnabled()}>
        {getPrimaryText()}
      </PButton>
    </form>
  )
}
export default JoinReferralCode
