import './Loader.css'

export default function Loader() {
  return (
    <div className="bouncing-loader">
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}
