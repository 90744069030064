import React from 'react'
import Panel from 'components/Panel'

import { FiExternalLink } from 'react-icons/fi'

const UseFullBox = ({ chainId }: { chainId: number }) => {
  // const getLeaderboardLink = () => {
  //   if (chainId === ARBITRUM) {
  //     return "https://www.gmx.house/arbitrum/leaderboard";
  //   }
  //   if (chainId === AVALANCHE) {
  //     return "https://www.gmx.house/avalanche/leaderboard";
  //   }
  //   return "https://www.gmx.house";
  // };

  return (
    <Panel title="Useful Links">
      <div className="ml-8 mb-2">
        <a
          href="https://tfx-market.gitbook.io/trading"
          target="_blank"
          rel="noopener noreferrer"
          className="font-sm flex items-center underline"
        >
          <FiExternalLink />
          <span className="pl-2">Trading guide</span>
        </a>
      </div>

      <div className="ml-8 mb-2">
        <a
          href="https://leaderboard.tfx.market/linea-testnet/leaderboard"
          target="_blank"
          rel="noopener noreferrer"
          className="font-sm flex items-center underline"
        >
          <FiExternalLink />
          <span className="pl-2">TFX Leaderboard</span>
        </a>
      </div>

      {/* <div className="ml-8 mb-2">
        <a href="https://opbnb-testnet-bridge.bnbchain.org/"
          target="_blank"
          rel="noopener noreferrer"
          className="font-sm flex items-center underline">
          <FiExternalLink />
          <span className="pl-2">Bridge opBNB Testnet</span>
        </a>
      </div> */}

      {/* <div className="ml-8 mb-2">
        <a href={getLeaderboardLink()} target="_blank" rel="noopener noreferrer"
          className="font-sm flex items-center underline">
          <FiExternalLink /> <span className="pl-2">Leaderboard</span>
        </a>
      </div>
      <div className="ml-8 mb-2">
        <a href="https://tfx-market.gitbook.io/trading#backup-rpc-urls" target="_blank" rel="noopener noreferrer"
          className="font-sm flex items-center underline">
          <FiExternalLink /> <span className="pl-2">Speed up page loading</span>
        </a>
      </div> */}
    </Panel>
  )
}

export default UseFullBox
