import Card from 'components/Card'
import AssetDropdown from 'pages/Dashboard/AssetDropdown'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import glp40Icon from 'img/tfx_64x64.svg'
import { BigNumber } from 'ethers'
import { USD_DECIMALS, formatAmount, useChainId } from 'utils'
import { XLPStat } from 'hooks/useStats'
import dayjs from 'dayjs'
import { useCallback, useMemo, useState, useEffect } from 'react'
import { useTheme } from 'hooks/useTheme'
import Loader from 'components/Common/Loader'
import { useGetXLPSupplyAum } from 'hooks/useStats'

type Resource = 'day' | 'week' | 'month'
const PriceChart = ({ price }: { price: BigNumber }) => {
  const tabStyle = 'p-4 cursor-pointer'
  const activeStyle = 'bg-bg-color-5 dark:bg-[#2f8af5] dark:text-slate-200'

  const { chainId } = useChainId()
  const { data } = useGetXLPSupplyAum({ chainId, enabled: true })

  const { theme } = useTheme()
  const [resource, setResource] = useState<Resource>('day')
  const [yDomain, setYDomain] = useState([0.9, 1.0])

  const handleSetResource = (tabName: Resource) => {
    setResource(tabName)
  }

  const chartData = useMemo((): XLPStat[] => {
    if (!data || !data[resource]) return []
    return Object.values(data[resource])
  }, [data, resource])

  const tooltipLabelFormatter = useCallback(
    (label, args) => {
      const formats = {
        day: 'H:mm',
        week: 'M-DD',
        month: 'M-DD',
      }

      if (!label) {
        return ''
      }

      if (label.constructor !== Date) {
        label = new Date(label * 1000)
      }

      const dateFmtString = formats[resource]
      const date = dayjs(label).format(dateFmtString)

      return date
    },
    [resource],
  )

  useEffect(() => {
    if (!data && !data[resource]) {
      return
    }

    // Find the minimum and maximum values of the data
    const _data = Object.values(data[resource]).map((d) => d.xlpPrice)
    const min = Math.min(..._data)
    const max = Math.max(..._data)

    // Set the Y-axis domain to be slightly larger than the data range
    setYDomain([+(min * 0.98).toFixed(2), +(max * 1.01).toFixed(2)])
  }, [data, resource])

  return (
    <Card>
      <div className="flex w-full justify-between items-center flex-wrap">
        <div className="flex gap-6 mb-8">
          <div className="w-[40px]">
            <img src={glp40Icon} style={{ width: '40px' }} alt="glp40Icon" />
          </div>
          <div>
            <div className="text-3xl font-bold flex items-center">
              xLP/USD
              <AssetDropdown assetSymbol="XLP" assetInfo={undefined} />
            </div>
            <div className="text-2xl">${formatAmount(price, USD_DECIMALS, 3, true)}</div>
          </div>
        </div>
        <div>
          <div className="pointer-events-auto flex divide-x divide-slate-400/20 overflow-hidden rounded-md text-xl leading-5 text-slate-700 shadow-sm ring-1 ring-slate-700/10 dark:bg-bg-dark-color-2 dark:text-white">
            <div
              className={`${tabStyle} ${resource === 'day' && activeStyle}`}
              onClick={() => handleSetResource('day')}
            >
              1D
            </div>
            <div
              className={`${tabStyle} ${resource === 'week' && activeStyle}`}
              onClick={() => handleSetResource('week')}
            >
              1W
            </div>
            <div
              className={`${tabStyle} ${resource === 'month' && activeStyle}`}
              onClick={() => handleSetResource('month')}
            >
              1M
            </div>
          </div>
        </div>
      </div>
      {/* Fix: ResponsiveContainer does not respond to resizing from large to small
        https://github.com/recharts/recharts/issues/172 */}
      <div className="w-full h-full min-h-[390px] relative">
        {!chartData?.length && (
          <div className="absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Loader />
          </div>
        )}
        <div className="w-full h-full absolute top-0 left-0">
          {/* ----  */}
          <ResponsiveContainer width="100%" height={340}>
            <AreaChart
              data={chartData}
              margin={{
                top: 10,
                right: 30,
                left: 20,
                bottom: 0,
              }}
            >
              <defs>
                <linearGradient id="colorXlpPrice" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#4797f2" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#2f8af5" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="2 2" stroke={theme === 'dark' ? '#585555' : '#e3dcdc'} />
              <YAxis
                unit="$"
                type="number"
                domain={yDomain}
                stroke={theme === 'dark' ? '#e7e5e5' : '#716e6e'}
                fontSize={13}
                padding={{
                  bottom: 15,
                }}
                tick={{ fill: theme === 'dark' ? '#c5c5c5' : '#888888' }}
              />
              <XAxis
                dataKey="timestamp"
                tickFormatter={tooltipLabelFormatter}
                minTickGap={30}
                fontSize={13}
                tick={{ fill: theme === 'dark' ? '#c5c5c5' : '#888888' }}
                stroke={theme === 'dark' ? '#fff' : '#646464'}
              />

              <Tooltip
                contentStyle={{
                  background: theme === 'dark' ? '#e6e5e5' : '#ffffff',
                  border: 0,
                  opacity: 0.8,
                }}
                labelStyle={{ color: '#000', fontSize: '13px' }}
                itemStyle={{ fontSize: '13px' }}
                labelFormatter={tooltipLabelFormatter}
              />

              <Area
                type="monotone"
                dataKey="xlpPrice"
                strokeWidth={2}
                stroke="#2f8af5"
                fill="url(#colorXlpPrice)"
                fillOpacity={1}
                unit="$"
                name="xLP Price"
                dot={false}
              />
            </AreaChart>
          </ResponsiveContainer>
          <p className="text-xl p-5 text-slate-700 dark:text-[#e6e5e5]">
            xLP price is determined by the total value of assets in index, including profits and losses of open
            positions, divided by xLP total supply.
          </p>
        </div>
      </div>
    </Card>
  )
}

export default PriceChart
