import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'
import { createContext, ReactNode, useCallback, useContext, useEffect } from 'react'

type GAParam = {
  action: string
  category?: string
  label?: string
  value?: number
}

interface GaAnalyticContextProps {
  sendEvent: (params: GAParam) => void
}

export const GaAnalyticContext = createContext<GaAnalyticContextProps>({} as GaAnalyticContextProps)

export const useAnalytic = () => {
  return useContext(GaAnalyticContext)
}

const AnalyticProvider = ({
  children,
  trackingCode,
}: {
  children: ReactNode
  trackingCode: {
    ua?: string
    ga: string
  }
}) => {
  const location = useLocation()

  useEffect(() => {
    if (trackingCode.ga) {
      ReactGA.initialize(trackingCode.ga, {})
    }
  }, [trackingCode])

  useEffect(() => {
    ReactGA.send('pageview')
  }, [location])

  const baseSendEvent = useCallback(
    ({ action, category, label, value }: GAParam) => {
      if (trackingCode.ga) {
        ReactGA.event({
          category: category ?? '',
          action: action,
          label: label,
          value: value,
        })
      }
    },
    [trackingCode],
  )

  const sendEvent = useCallback(
    ({ action, category, label, value }: GAParam) => {
      baseSendEvent({
        action: `${action}`,
        category: `${category ?? ''}`,
        label: label,
        value,
      })
    },
    [baseSendEvent],
  )

  return (
    <GaAnalyticContext.Provider
      value={{
        sendEvent,
      }}
    >
      {children}
    </GaAnalyticContext.Provider>
  )
}

export default AnalyticProvider
