import { ARBITRUM_SEPOLIA, DEV_TESTNET } from 'utils'
import { FEES_5611 } from './FEES_5611'
import { FEES_59140 } from './FEES_59140'

const SECONDS_PER_WEEK = 604800

function createFeeList(data) {
  const list = []
  for (let i = 0; i < data.length; i++) {
    const item = data[i]
    list.push({
      from: item.to - SECONDS_PER_WEEK,
      to: item.to,
      feeUsd: item.feeUsd,
    })
  }
  return list
}

const FEES = {
  [DEV_TESTNET]: createFeeList(FEES_5611),
  [ARBITRUM_SEPOLIA]: createFeeList(FEES_59140),
}

export function getFeeHistory(chainId) {
  return FEES[chainId].concat([]).reverse()
}
