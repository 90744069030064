import { useEffect, useMemo, useState } from 'react'
import './Stats.css'

import {
  CHART_HEIGHT,
  COLORS,
  YAXIS_WIDTH,
  convertToPercents,
  formatNumber,
  tooltipFormatter,
  tooltipFormatterNumber,
  tooltipFormatterPercent,
  tooltipLabelFormatter,
  tooltipLabelFormatterUnits,
  yaxisFormatter,
  yaxisFormatterNumber,
  yaxisFormatterPercent,
} from './helpers'

import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { maxBy, minBy, max } from 'lodash'

import ChartWrapper from './components/ChartWrapper'
import DateRangeSelect from './components/DateRangeSelect'
import FeesChart from './components/FeesChart'
import GenericChart from './components/GenericChart'
import VolumeChart from './components/VolumeChart'

import { ArrowDownIcon, ArrowUpIcon, ReceiptPercentIcon, UsersIcon } from '@heroicons/react/20/solid'
import { getChainDisplayName } from '@tfx/chains'
import { getTokenSymbols } from '@tfx/tokens'
import { Footer } from '@tfx/ui'
import classNames from 'classnames'
import Card from 'components/Card'
import dayjs from 'dayjs'
import { useTheme } from 'hooks/useTheme'
import { BiCoinStack } from 'react-icons/bi'
import { FaChartLine } from 'react-icons/fa'
import { LuCoins } from 'react-icons/lu'
import { ARBITRUM_SEPOLIA, DEV_TESTNET, getExplorerUrl } from 'utils'
import { useChainId } from 'wagmi'
import PoolAmountChart from './components/PoolAmountChart'
import TradersProfitLossChart from './components/TradersProfitLossChart'
import {
  useAumPerformanceData,
  useFeesData,
  useFundingRateData,
  useGlpData,
  useGlpPerformanceData,
  useLastBlock,
  useLastSubgraphBlock,
  useSwapSources,
  useTotalVolumeFromServerBSC,
  useTradersData,
  useUsersData,
  useVolumeData,
} from './dataProvider'

const NOW = Math.floor(Date.now() / 1000)

function Stats(props) {
  const DEFAULT_GROUP_PERIOD = 86400
  const [groupPeriod] = useState(DEFAULT_GROUP_PERIOD)

  const chainId = useChainId()

  const startChainDate = {
    [ARBITRUM_SEPOLIA]: new Date('2024-01-01'),
    [DEV_TESTNET]: new Date('2023-10-15'),
  }

  const [dataRange, setDataRange] = useState({
    // fromValue: moment().subtract(2, 'month').toDate(),
    fromValue: startChainDate[chainId],
    toValue: null,
  })

  const { mode } = props

  const from = dataRange.fromValue ? Math.floor(+new Date(dataRange.fromValue) / 1000) : undefined
  const to = dataRange.toValue ? Math.floor(+new Date(dataRange.toValue) / 1000) : NOW

  const params = { from, to, groupPeriod, chainId }

  const [fundingRateData, fundingRateLoading] = useFundingRateData(params)

  const [volumeData, volumeLoading] = useVolumeData(params)
  const [totalVolume] = useTotalVolumeFromServerBSC(params)
  const totalVolumeDelta = useMemo(() => {
    if (!volumeData || !volumeData.length) {
      return 0
    }
    return volumeData[volumeData.length - 1].all
  }, [volumeData])

  const [feesData, feesLoading] = useFeesData(params)
  const [totalFeesData] = useFeesData(params)
  const [totalFees, totalFeesDelta] = useMemo(() => {
    if (!totalFeesData) {
      return []
    }
    const total = totalFeesData[totalFeesData.length - 1]?.cumulative ?? 0
    const delta = total - (totalFeesData[totalFeesData.length - 2]?.cumulative ?? 0)
    return [total, delta]
  }, [totalFeesData])

  const [glpData, glpLoading] = useGlpData(params)
  const [totalAum, totalAumDelta] = useMemo(() => {
    if (!glpData) {
      return []
    }

    const total = glpData[glpData.length - 1]?.aum ?? 0
    const delta = total - (glpData[glpData.length - 2]?.aum ?? 0)

    return [total, delta]
  }, [glpData])

  const [aumPerformanceData, aumPerformanceLoading] = useAumPerformanceData(params)
  const [glpPerformanceData] = useGlpPerformanceData(glpData, feesData, params)

  const [tradersData, tradersLoading] = useTradersData(params)
  const [openInterest, openInterestDelta] = useMemo(() => {
    if (!tradersData?.data) {
      return []
    }
    const total = tradersData.data[tradersData.data.length - 1]?.openInterest ?? 0
    const delta = total - (tradersData.data[tradersData.data.length - 2]?.openInterest ?? 0)
    return [total, delta]
  }, [tradersData])

  const [swapSources, swapSourcesLoading] = useSwapSources(params)
  const swapSourcesKeys = Object.keys(
    (swapSources || []).reduce((memo, el) => {
      Object.keys(el).forEach((key) => {
        if (key === 'all' || key === 'timestamp') return
        memo[key] = true
      })
      return memo
    }, {}),
  )

  const [usersData, usersLoading] = useUsersData(params)
  const [totalUsers, totalUsersDelta] = useMemo(() => {
    if (!usersData) {
      return [null, null]
    }
    const total = usersData[usersData.length - 1]?.uniqueCountCumulative ?? 0
    const prevTotal = usersData[usersData.length - 2]?.uniqueCountCumulative ?? 0
    const delta = total && prevTotal ? total - prevTotal : 0
    return [total, delta]
  }, [usersData])

  const [lastSubgraphBlock, , lastSubgraphBlockError] = useLastSubgraphBlock(params.chainId)
  const [lastBlock] = useLastBlock(params.chainId)

  const isObsolete = lastSubgraphBlock && lastBlock && lastBlock.timestamp - lastSubgraphBlock.timestamp > 3600

  const [isExperiment, setIsExperiment] = useState(false)
  useEffect(() => {
    setIsExperiment(!!window.localStorage.getItem('experiment'))
  }, [setIsExperiment])

  const { theme } = useTheme()

  const onDateRangeChange = (dates) => {
    const [start, end] = dates
    setDataRange({ fromValue: start, toValue: end })
  }

  const dateRangeOptions = [
    {
      label: 'Last Month',
      id: 1,
    },
    {
      label: 'Last 2 Months',
      id: 2,
    },
    {
      label: 'Last 3 Months',
      id: 3,
    },
    {
      label: 'All time',
      id: 4,
    },
  ]

  const symbols = getTokenSymbols(chainId)

  const stats = [
    {
      name: 'Total Volume',
      stat: totalVolume ? formatNumber(totalVolume ?? 0, { currency: true }) : 0,
      icon: BiCoinStack,
      change: formatNumber(totalVolumeDelta, {
        currency: true,
        compact: true,
      }),
      changeValue: totalVolumeDelta,
    },
    {
      name: 'Total Fees',
      stat: formatNumber(totalFees ?? 0, { currency: true }),
      icon: ReceiptPercentIcon,
      change: totalFeesDelta
        ? formatNumber(totalFeesDelta, {
            currency: true,
            compact: true,
          })
        : 0,
      changeValue: totalFeesDelta,
    },
    {
      name: 'xLP Pool',
      stat: formatNumber(totalAum ?? 0, { currency: true }),
      icon: LuCoins,
      change: totalAumDelta
        ? formatNumber(totalAumDelta, {
            currency: true,
            compact: true,
          })
        : 0,
      changeValue: totalAumDelta,
    },
    {
      name: 'Total Users',
      stat: formatNumber(totalUsers ?? 0),
      icon: UsersIcon,
      change: totalUsersDelta ? formatNumber(totalUsersDelta) : 0,
      changeValue: totalUsersDelta,
    },
    {
      name: 'Open Interest',
      stat: formatNumber(openInterest ?? 0, { currency: true }),
      icon: FaChartLine,
      change: openInterestDelta
        ? formatNumber(openInterestDelta, {
            currency: true,
            compact: true,
          })
        : 0,
      changeValue: openInterestDelta,
    },
  ]

  const [minChartAumnSupply, maxChartAumnSupply] = useMemo(() => {
    const domainRatio = 0.1
    if (!glpData) {
      return [0, 0]
    }
    const maxSupply = maxBy(glpData, (data) => data.glpSupply).glpSupply
    const minSupply = minBy(glpData, (data) => data.glpSupply).glpSupply
    const maxAum = maxBy(glpData, (data) => data.aum).aum
    const minAum = minBy(glpData, (data) => data.aum).aum
    const maxChart = Math.max(maxSupply, maxAum)
    const minChart = Math.min(minAum, minSupply)
    return [minChart * domainRatio, maxChart * domainRatio]
  }, [glpData])

  const [maxDomainBorrowRate] = useMemo(() => {
    if (!fundingRateData) {
      return [0]
    }
    const maxVal = max(
      fundingRateData.map((data) =>
        max(
          Object.keys(data)
            .filter((key) => key !== 'timestamp')
            .map((symbol) => data[symbol]),
        ),
      ),
    )
    return [Math.ceil(maxVal)]
  }, [fundingRateData])

  return (
    <div className="page-layout pt-0 pb-8 w-full max-w-[1500px] m-auto">
      <div className="relative dark:bg-gray-900 pt-24 sm:pt-32 z-10">
        <div className="max-w-full px-6 lg:px-8">
          <div className="flex items-end flex-wrap gap-4">
            <div className="flex-auto justify-between">
              <h2 className="Page-title text-4xl font-bold tracking-tight sm:text-6xl">
                Analytics / {getChainDisplayName(chainId)}
              </h2>
              {lastSubgraphBlock && lastBlock && (
                <p className="Page-description text-xl leading-8 ">
                  {isObsolete && 'Data is obsolete. '}
                  Updated {dayjs((lastSubgraphBlock.timestamp as number) * 1000).fromNow()}
                  &nbsp;at block{' '}
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={`${getExplorerUrl(chainId)}block/${lastSubgraphBlock.number}`}
                  >
                    {lastSubgraphBlock.number}
                  </a>
                </p>
              )}
              {lastSubgraphBlockError && (
                <p className="mt-6 text-lg leading-8 text-yellow-700">Subgraph data is temporarily unavailable.</p>
              )}
            </div>

            <div>
              <DateRangeSelect
                options={dateRangeOptions}
                startDate={dataRange.fromValue}
                endDate={dataRange.toValue}
                onChange={onDateRangeChange}
              />
            </div>
          </div>

          <Card className="px-8 py-12 mt-4">
            <div className="grid grid-cols-1 gap-6 lg:mx-0 lg:max-w-none sm:grid-cols-2 lg:grid-cols-5 lg:gap-8">
              {stats.map((item, i) => (
                <div key={`stat-${item.name}`} className={`relative overflow-hidden`}>
                  <dt>
                    <div className="absolute rounded-md bg-bg-color-4 py-9 p-3">
                      <item.icon className="h-8 w-8 text-white" aria-hidden="true" />
                    </div>
                    <p className="ml-16 truncate text-xl font-medium text-gray-500 dark:text-white">{item.name}</p>
                  </dt>
                  <dd className="ml-16 flex flex-col items-baseline">
                    <p className="text-5xl font-semibold text-gray-900 dark:text-white">{item.stat}</p>
                    <p
                      className={classNames(
                        Number(item.changeValue) < 0 ? 'text-red-600' : 'text-green-600',
                        'ml-2 flex items-baseline text-xl font-semibold',
                      )}
                    >
                      {Number(item.changeValue) < 0 ? (
                        <ArrowDownIcon className="h-5 w-5 flex-shrink-0 self-center text-red-500" aria-hidden="true" />
                      ) : (
                        <ArrowUpIcon className="h-5 w-5 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
                      )}
                      <span className="sr-only"> {Number(item.changeValue) < 0 ? 'Decreased' : 'Increased'} by </span>
                      {item.change}
                    </p>
                  </dd>
                </div>
              ))}
            </div>
          </Card>
        </div>
      </div>

      <div className="page-layout px-6 pt-0 grid grid-cols-1 lg:grid-cols-2 gap-10 mt-10">
        <div className="chart-cell">
          <VolumeChart
            data={volumeData}
            loading={volumeLoading}
            chartHeight={CHART_HEIGHT}
            yaxisWidth={YAXIS_WIDTH}
            xaxisTickFormatter={tooltipLabelFormatter}
            yaxisTickFormatter={yaxisFormatter}
            tooltipLabelFormatter={tooltipLabelFormatter}
            tooltipFormatter={tooltipFormatter}
          />
        </div>
        <div className="chart-cell">
          <FeesChart data={feesData} loading={feesLoading} chartHeight={CHART_HEIGHT} yaxisWidth={YAXIS_WIDTH} />
        </div>
        <div className="chart-cell">
          <ChartWrapper
            title="AUM & xLP Supply"
            loading={glpLoading}
            data={glpData}
            csvFields={[{ key: 'aum' }, { key: 'glpSupply' }]}
          >
            <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
              <LineChart data={glpData} syncId="syncGlp">
                <CartesianGrid strokeDasharray="2 2" stroke={theme === 'dark' ? '#585555' : '#e3dcdc'} />
                <XAxis
                  dataKey="timestamp"
                  tickFormatter={tooltipLabelFormatter}
                  minTickGap={30}
                  fontSize={13}
                  tick={{ fill: theme === 'dark' ? '#c5c5c5' : '#888888' }}
                  stroke={theme === 'dark' ? '#fff' : '#646464'}
                />

                <YAxis
                  dataKey="glpSupply"
                  tickFormatter={yaxisFormatter}
                  width={YAXIS_WIDTH}
                  stroke={theme === 'dark' ? '#e7e5e5' : '#716e6e'}
                  tick={{ fill: theme === 'dark' ? '#c5c5c5' : '#888888' }}
                  fontSize={13}
                  padding={{
                    bottom: 15,
                  }}
                  domain={[`dataMin - ${minChartAumnSupply}`, `dataMax + ${maxChartAumnSupply}`]}
                />

                <Tooltip
                  formatter={tooltipFormatterNumber}
                  labelFormatter={tooltipLabelFormatter}
                  contentStyle={{
                    background: theme === 'dark' ? '#e6e5e5' : '#ffffff',
                    border: 0,
                    opacity: 0.8,
                  }}
                  labelStyle={{ color: '#000', fontSize: '13px' }}
                  itemStyle={{ fontSize: '13px' }}
                />
                <Legend />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  strokeWidth={2}
                  unit="$"
                  dot={false}
                  dataKey="aum"
                  // stackId="a"
                  name="AUM"
                  stroke={COLORS[0]}
                />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  strokeWidth={2}
                  dot={false}
                  dataKey="glpSupply"
                  // stackId="a"
                  name="xLP Supply"
                  stroke={COLORS[1]}
                />
              </LineChart>
            </ResponsiveContainer>
          </ChartWrapper>
        </div>
        <div className="chart-cell">
          <PoolAmountChart from={from} to={to} syncId="syncGlp" chainId={params.chainId} />
        </div>
        <div className="chart-cell">
          <ChartWrapper
            title="xLP Performance"
            loading={glpLoading}
            data={glpPerformanceData}
            csvFields={[
              { key: 'syntheticPrice' },
              { key: 'xlpPrice' },
              { key: 'glpPlusFees' },
              { key: 'lpBtcPrice' },
              { key: 'lpEthPrice' },
              { key: 'lpBnbPrice' },
              { key: 'performanceSyntheticCollectedFees' },
              { key: 'indexBtcCount' },
              { key: 'indexEthCount' },
              { key: 'indexAvaxCount' },
              { key: 'indexBnbCount' },
              { key: 'indexStableCount' },
              { key: 'BTC_WEIGHT' },
              { key: 'ETH_WEIGHT' },
              { key: 'AVAX_WEIGHT' },
              { key: 'BNB_WEIGHT' },
              { key: 'STABLE_WEIGHT' },
            ]}
          >
            <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
              <LineChart data={glpPerformanceData} syncId="syncGlp">
                <CartesianGrid strokeDasharray="2 2" stroke={theme === 'dark' ? '#585555' : '#e3dcdc'} />
                <XAxis
                  dataKey="timestamp"
                  tickFormatter={tooltipLabelFormatter}
                  minTickGap={30}
                  fontSize={13}
                  tick={{ fill: theme === 'dark' ? '#c5c5c5' : '#888888' }}
                  stroke={theme === 'dark' ? '#fff' : '#646464'}
                />

                <YAxis
                  dataKey="performanceSyntheticCollectedFees"
                  domain={[0, 'auto']}
                  unit="%"
                  tickFormatter={yaxisFormatterNumber}
                  width={YAXIS_WIDTH}
                  stroke={theme === 'dark' ? '#e7e5e5' : '#716e6e'}
                  fontSize={13}
                  padding={{
                    bottom: 15,
                  }}
                  tick={{ fill: theme === 'dark' ? '#c5c5c5' : '#888888' }}
                />
                <Tooltip
                  formatter={tooltipFormatterNumber}
                  labelFormatter={tooltipLabelFormatter}
                  contentStyle={{
                    background: theme === 'dark' ? '#e6e5e5' : '#ffffff',
                    border: 0,
                    opacity: 0.8,
                  }}
                  labelStyle={{ color: '#000', fontSize: '13px' }}
                  itemStyle={{ fontSize: '13px' }}
                />
                <Legend />
                <Line
                  dot={false}
                  isAnimationActive={false}
                  type="monotone"
                  unit="%"
                  dataKey="performanceLpBtcCollectedFees"
                  name="% LP BTC-USDC (w/ fees)"
                  stroke={COLORS[2]}
                />
                <Line
                  dot={false}
                  isAnimationActive={false}
                  type="monotone"
                  unit="%"
                  dataKey="performanceLpEthCollectedFees"
                  name="% LP ETH-USDC (w/ fees)"
                  stroke={COLORS[4]}
                />
                <Line
                  dot={false}
                  isAnimationActive={false}
                  type="monotone"
                  unit="%"
                  dataKey="performanceSyntheticCollectedFees"
                  name="% Index (w/ fees)"
                  stroke={COLORS[0]}
                />
              </LineChart>
            </ResponsiveContainer>
            <div className="text-xl pl-10">
              <p>
                <span style={{ color: COLORS[0] }}>% of Index (with fees)</span> is xLP with fees / Index Price * 100.
                Index is a basket of 25% BTC, 25% ETH, 50% USDC rebalanced once&nbsp;a&nbsp;day
                <br />
                <span style={{ color: COLORS[4] }}>% of LP ETH-USDC (with fees)</span> is xLP Price with fees / LP
                ETH-USDC * 100
                <br />
              </p>
            </div>
          </ChartWrapper>
        </div>
        <div className="chart-cell">
          <ChartWrapper
            title="xLP Price Comparison"
            loading={glpLoading}
            data={glpPerformanceData}
            csvFields={[
              { key: 'syntheticPrice' },
              { key: 'xlpPrice' },
              { key: 'glpPlusFees' },
              { key: 'lpBtcPrice' },
              { key: 'lpEthPrice' },
              { key: 'performanceSyntheticCollectedFees' },
              { key: 'indexBtcCount' },
              { key: 'indexEthCount' },
              { key: 'indexAvaxCount' },
              { key: 'indexBnbCount' },
              { key: 'indexStableCount' },
              { key: 'BTC_WEIGHT' },
              { key: 'ETH_WEIGHT' },
              { key: 'AVAX_WEIGHT' },
              { key: 'BNB_WEIGHT' },
              { key: 'STABLE_WEIGHT' },
            ]}
          >
            <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
              <LineChart data={glpPerformanceData} syncId="syncGlp">
                <CartesianGrid strokeDasharray="2 2" stroke={theme === 'dark' ? '#585555' : '#e3dcdc'} />
                <XAxis
                  dataKey="timestamp"
                  tickFormatter={tooltipLabelFormatter}
                  minTickGap={30}
                  fontSize={13}
                  tick={{ fill: theme === 'dark' ? '#c5c5c5' : '#888888' }}
                  stroke={theme === 'dark' ? '#fff' : '#646464'}
                />
                <YAxis
                  dataKey="xlpPrice"
                  domain={[0.4, 1.7]}
                  tickFormatter={yaxisFormatterNumber}
                  width={YAXIS_WIDTH}
                  stroke={theme === 'dark' ? '#e7e5e5' : '#716e6e'}
                  fontSize={13}
                  padding={{
                    bottom: 15,
                  }}
                  tick={{ fill: theme === 'dark' ? '#c5c5c5' : '#888888' }}
                />
                <Tooltip
                  formatter={tooltipFormatterNumber}
                  labelFormatter={tooltipLabelFormatter}
                  contentStyle={{
                    background: theme === 'dark' ? '#e6e5e5' : '#ffffff',
                    border: 0,
                    opacity: 0.8,
                  }}
                  labelStyle={{ color: '#000', fontSize: '13px' }}
                  itemStyle={{ fontSize: '13px' }}
                />
                <Legend />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  unit="$"
                  strokeWidth={1}
                  dot={false}
                  dataKey="syntheticPrice"
                  name="Index Price"
                  stroke={COLORS[2]}
                />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  unit="$"
                  strokeWidth={1}
                  dot={false}
                  dataKey="xlpPrice"
                  name="xLP Price"
                  stroke={COLORS[1]}
                />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  unit="$"
                  strokeWidth={2}
                  dot={false}
                  dataKey="glpPlusFees"
                  name="xLP w/ fees"
                  stroke={COLORS[3]}
                />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  unit="$"
                  strokeWidth={1}
                  dot={false}
                  dataKey="lpBtcPrice"
                  name="LP BTC-USDC"
                  stroke={COLORS[2]}
                />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  unit="$"
                  strokeWidth={1}
                  dot={false}
                  dataKey="lpEthPrice"
                  name="LP ETH-USDC"
                  stroke={COLORS[4]}
                />
              </LineChart>
            </ResponsiveContainer>
            <div className="text-xl pl-10">
              <p>
                <span style={{ color: COLORS[3] }}>xLP with fees</span> is based on xLP share of fees received
                <br />
                <span style={{ color: COLORS[2] }}>Index Price</span> is a basket of 25% BTC, 25% ETH, 50% USDC
                rebalanced once&nbsp;a&nbsp;day
              </p>
            </div>
          </ChartWrapper>
        </div>
        {isExperiment && (
          <div className="chart-cell experiment">
            <ChartWrapper title="Performance vs. Index" loading={glpLoading}>
              <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
                <LineChart data={glpPerformanceData} syncId="syncGlp">
                  <CartesianGrid strokeDasharray="2 2" stroke={theme === 'dark' ? '#585555' : '#e3dcdc'} />
                  <XAxis
                    dataKey="timestamp"
                    tickFormatter={tooltipLabelFormatter}
                    minTickGap={30}
                    fontSize={13}
                    tick={{ fill: theme === 'dark' ? '#c5c5c5' : '#888888' }}
                    stroke={theme === 'dark' ? '#fff' : '#646464'}
                  />
                  <YAxis
                    dataKey="performanceSyntheticCollectedFees"
                    domain={[80, 120]}
                    unit="%"
                    tickFormatter={yaxisFormatterNumber}
                    width={YAXIS_WIDTH}
                    stroke={theme === 'dark' ? '#e7e5e5' : '#716e6e'}
                    fontSize={13}
                    padding={{
                      bottom: 15,
                    }}
                    tick={{ fill: theme === 'dark' ? '#c5c5c5' : '#888888' }}
                  />
                  <Tooltip
                    formatter={tooltipFormatterNumber}
                    labelFormatter={tooltipLabelFormatter}
                    contentStyle={{
                      background: theme === 'dark' ? '#e6e5e5' : '#ffffff',
                      border: 0,
                      opacity: 0.8,
                    }}
                    labelStyle={{ color: '#000', fontSize: '13px' }}
                    itemStyle={{ fontSize: '13px' }}
                  />
                  <Legend />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceSyntheticCollectedFees"
                    name="Collected Fees"
                    stroke={COLORS[0]}
                  />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceSyntheticDistributedUsd"
                    name="Distributed Usd"
                    stroke={COLORS[1]}
                  />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceSyntheticDistributedEth"
                    name="Distributed Eth"
                    stroke={COLORS[2]}
                  />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceSynthetic"
                    name="No Fees"
                    stroke={COLORS[3]}
                  />
                </LineChart>
              </ResponsiveContainer>
            </ChartWrapper>
          </div>
        )}
        {isExperiment && (
          <div className="chart-cell experiment">
            <ChartWrapper title="Performance vs. ETH LP" loading={glpLoading}>
              <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
                <LineChart data={glpPerformanceData} syncId="syncGlp">
                  <CartesianGrid strokeDasharray="2 2" stroke={theme === 'dark' ? '#585555' : '#e3dcdc'} />
                  <XAxis
                    dataKey="timestamp"
                    tickFormatter={tooltipLabelFormatter}
                    minTickGap={30}
                    fontSize={13}
                    tick={{ fill: theme === 'dark' ? '#c5c5c5' : '#888888' }}
                    stroke={theme === 'dark' ? '#fff' : '#646464'}
                  />
                  <YAxis
                    dataKey="performanceLpEthCollectedFees"
                    domain={[80, 120]}
                    unit="%"
                    tickFormatter={yaxisFormatterNumber}
                    width={YAXIS_WIDTH}
                    stroke={theme === 'dark' ? '#e7e5e5' : '#716e6e'}
                    fontSize={13}
                    padding={{
                      bottom: 15,
                    }}
                    tick={{ fill: theme === 'dark' ? '#c5c5c5' : '#888888' }}
                  />
                  <Tooltip
                    formatter={tooltipFormatterNumber}
                    labelFormatter={tooltipLabelFormatter}
                    contentStyle={{
                      background: theme === 'dark' ? '#e6e5e5' : '#ffffff',
                      border: 0,
                      opacity: 0.8,
                    }}
                    labelStyle={{ color: '#000', fontSize: '13px' }}
                    itemStyle={{ fontSize: '13px' }}
                  />
                  <Legend />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceLpEthCollectedFees"
                    name="Collected Fees"
                    stroke={COLORS[0]}
                  />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceLpEthDistributedUsd"
                    name="Distributed Usd"
                    stroke={COLORS[1]}
                  />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceLpEthDistributedEth"
                    name="Distributed Eth"
                    stroke={COLORS[2]}
                  />
                  <Line
                    isAnimationActive={false}
                    dot={false}
                    type="monotone"
                    unit="%"
                    strokeWidth={2}
                    dataKey="performanceLpEth"
                    name="No Fees"
                    stroke={COLORS[3]}
                  />
                </LineChart>
              </ResponsiveContainer>
            </ChartWrapper>
          </div>
        )}
        <div className="chart-cell">
          {tradersData?.data && (
            <ChartWrapper
              title="Traders Net PnL"
              loading={tradersLoading}
              data={tradersData?.data}
              csvFields={[
                { key: 'pnl', name: 'Net PnL' },
                { key: 'pnlCumulative', name: 'Cumulative PnL' },
              ]}
            >
              <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
                {tradersData?.data && (
                  <ComposedChart data={tradersData?.data} syncId="tradersId">
                    <CartesianGrid strokeDasharray="2 2" stroke={theme === 'dark' ? '#585555' : '#e3dcdc'} />
                    <XAxis
                      dataKey="timestamp"
                      tickFormatter={tooltipLabelFormatter}
                      minTickGap={30}
                      fontSize={13}
                      tick={{ fill: theme === 'dark' ? '#c5c5c5' : '#888888' }}
                      stroke={theme === 'dark' ? '#fff' : '#646464'}
                    />
                    <YAxis
                      domain={[
                        -tradersData?.stats.maxAbsCumulativePnl * 1.05,
                        tradersData?.stats.maxAbsCumulativePnl * 1.05,
                      ]}
                      orientation="right"
                      yAxisId="right"
                      tickFormatter={yaxisFormatter}
                      width={YAXIS_WIDTH}
                      stroke={theme === 'dark' ? '#e7e5e5' : '#716e6e'}
                      fontSize={13}
                      padding={{
                        bottom: 15,
                      }}
                      tick={{ fill: theme === 'dark' ? '#c5c5c5' : '#888888' }}
                    />
                    <YAxis
                      domain={[-tradersData?.stats.maxAbsPnl * 1.05, tradersData?.stats.maxAbsPnl * 1.05]}
                      tickFormatter={yaxisFormatter}
                      width={YAXIS_WIDTH}
                      stroke={theme === 'dark' ? '#e7e5e5' : '#716e6e'}
                      fontSize={13}
                      padding={{
                        bottom: 15,
                      }}
                      tick={{ fill: theme === 'dark' ? '#c5c5c5' : '#888888' }}
                    />
                    <Tooltip
                      formatter={tooltipFormatter}
                      labelFormatter={tooltipLabelFormatter}
                      contentStyle={{
                        background: theme === 'dark' ? '#e6e5e5' : '#ffffff',
                        border: 0,
                        opacity: 0.8,
                      }}
                      labelStyle={{ color: '#000', fontSize: '13px' }}
                      itemStyle={{ fontSize: '13px' }}
                    />
                    <Legend />
                    <Bar type="monotone" fill={mode === 'dark' ? '#FFFFFF' : '#000000'} dataKey="pnl" name="Net PnL">
                      {(tradersData?.data || []).map((item, i) => {
                        return <Cell key={`cell-${i}`} fill={item.pnl > 0 ? '#22c761' : '#f93333'} />
                      })}
                    </Bar>
                    <Line
                      type="monotone"
                      strokeWidth={2}
                      stroke={COLORS[4]}
                      dataKey="currentPnlCumulative"
                      name="Cumulative PnL"
                      yAxisId="right"
                    />
                  </ComposedChart>
                )}
              </ResponsiveContainer>
              <div className="text-xl pl-10">
                <p>Considers settled (closed) positions, Fees are not factored into PnL</p>
              </div>
            </ChartWrapper>
          )}
        </div>
        <div className="chart-cell">
          <TradersProfitLossChart
            syncId="tradersId"
            loading={tradersLoading}
            tradersData={tradersData}
            yaxisWidth={YAXIS_WIDTH}
            chartHeight={CHART_HEIGHT}
          />
        </div>
        <div className="chart-cell">
          {tradersData?.data && (
            <GenericChart
              loading={tradersLoading}
              title="Open Interest"
              data={tradersData?.data.map((item) => ({
                all: item.openInterest,
                ...item,
              }))}
              controls={{
                convertToPercents: convertToPercents,
              }}
              yaxisDataKey="all"
              items={[
                { key: 'shortOpenInterest', name: 'Short', color: '#f93333' },
                { key: 'longOpenInterest', name: 'Long', color: '#22c761' },
              ]}
              type="Bar"
            />
          )}
        </div>
        <div className="chart-cell">
          <GenericChart
            loading={fundingRateLoading}
            title="Borrowing Rate Annualized"
            data={fundingRateData}
            yaxisDataKey="ETH"
            yaxisTickFormatter={yaxisFormatterPercent}
            tooltipFormatter={tooltipFormatterPercent}
            items={Object.values(symbols).map((symbol) => ({ key: symbol }))}
            type="Line"
            yaxisDomain={[0, maxDomainBorrowRate]}
            isCoinChart={true}
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            syncId="syncGlp"
            loading={aumPerformanceLoading}
            title="AUM Performance Annualized"
            data={aumPerformanceData}
            yaxisDataKey="apr"
            yaxisTickFormatter={yaxisFormatterPercent}
            tooltipFormatter={tooltipFormatterPercent}
            items={[{ key: 'apr', name: 'APR', color: COLORS[0] }]}
            description="Formula = Daily Fees / xLP Pool * 365 days * 100"
            type="Composed"
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            syncId="syncGlp"
            loading={aumPerformanceLoading}
            title="AUM Daily Usage"
            data={aumPerformanceData}
            yaxisDataKey="usage"
            yaxisTickFormatter={yaxisFormatterPercent}
            tooltipFormatter={tooltipFormatterPercent}
            items={[{ key: 'usage', name: 'Daily Usage', color: COLORS[4] }]}
            description="Formula = Daily Volume / xLP Pool * 100"
            type="Composed"
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            syncId="syncGlp"
            loading={usersLoading}
            title="Unique Users"
            data={usersData}
            truncateYThreshold={6500}
            yaxisDataKey="uniqueSum"
            yaxisTickFormatter={yaxisFormatterNumber}
            tooltipFormatter={tooltipFormatterNumber}
            tooltipLabelFormatter={tooltipLabelFormatterUnits}
            items={[
              { key: 'uniqueSwapCount', name: 'Swaps' },
              { key: 'uniqueMarginCount', name: 'Margin trading' },
              { key: 'uniqueMintBurnCount', name: 'Mint & Burn xLP' },
            ]}
            type="Composed"
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            syncId="syncGlp"
            loading={usersLoading}
            title="New Users"
            data={usersData?.map((item) => ({ ...item, all: item.newCount }))}
            truncateYThreshold={6000}
            yaxisDataKey="uniqueSum"
            rightYaxisDataKey="uniqueCountCumulative"
            yaxisTickFormatter={yaxisFormatterNumber}
            tooltipFormatter={tooltipFormatterNumber}
            tooltipLabelFormatter={tooltipLabelFormatterUnits}
            items={[
              { key: 'newSwapCount', name: 'Swap' },
              { key: 'newMarginCount', name: 'Margin trading' },
              { key: 'newMintBurnCount', name: 'Mint & Burn' },
              {
                key: 'cumulativeNewUserCount',
                name: 'Cumulative',
                type: 'Line',
                yAxisId: 'right',
                strokeWidth: 2,
                color: COLORS[4],
              },
            ]}
            type="Composed"
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            syncId="syncGlp"
            loading={usersLoading}
            title="New vs. Existing Users"
            data={usersData?.map((item) => ({
              ...item,
              all: item.uniqueCount,
            }))}
            rightYaxisDomain={[0, 100]}
            truncateYThreshold={7000}
            yaxisDataKey="uniqueCount"
            rightYaxisDataKey="oldPercent"
            yaxisTickFormatter={yaxisFormatterNumber}
            tooltipFormatter={tooltipFormatterNumber}
            tooltipLabelFormatter={tooltipLabelFormatterUnits}
            items={[
              { key: 'newCount', name: 'New' },
              { key: 'oldCount', name: 'Existing' },
              {
                key: 'oldPercent',
                name: 'Existing %',
                yAxisId: 'right',
                type: 'Line',
                strokeWidth: 2,
                color: COLORS[4],
                unit: '%',
              },
            ]}
            type="Composed"
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            syncId="syncGlp"
            loading={usersLoading}
            title="User Actions"
            data={(usersData || []).map((item) => ({
              ...item,
              all: item.actionCount,
            }))}
            truncateYThreshold={25000}
            yaxisDataKey="actionCount"
            yaxisTickFormatter={yaxisFormatterNumber}
            tooltipFormatter={tooltipFormatterNumber}
            tooltipLabelFormatter={tooltipLabelFormatterUnits}
            items={[
              { key: 'actionSwapCount', name: 'Swaps' },
              { key: 'actionMarginCount', name: 'Margin trading' },
              { key: 'actionMintBurnCount', name: 'Mint & Burn xLP' },
            ]}
            type="Composed"
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            loading={swapSourcesLoading}
            title="Swap Sources"
            data={swapSources}
            items={swapSourcesKeys.map((key) => ({ key }))}
          />
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default Stats
