import { ARBITRUM_SEPOLIA, DEV_TESTNET } from 'utils'
import {
  arbitrumSepoliaGraphClient,
  arbitrumSepoliaReferralsGraphClient,
  devTestnetGraphClient,
  devTestnetReferralsGraphClient,
  subgraphArbitrumSepoliaCore,
  subgraphDevTestnetCore,
} from './api/common'

export function getReferralGraphClient(chainId) {
  if (chainId === DEV_TESTNET) {
    return devTestnetReferralsGraphClient
  }
  if (chainId === ARBITRUM_SEPOLIA) {
    return arbitrumSepoliaReferralsGraphClient
  }

  throw new Error(`Unsupported chain ${chainId}`)
}

export function getGraphClient(chainId) {
  if (chainId === DEV_TESTNET) {
    return devTestnetGraphClient
  }

  if (chainId === ARBITRUM_SEPOLIA) {
    return arbitrumSepoliaGraphClient
  }

  throw new Error(`Unsupported chain ${chainId}`)
}

export function getGraphPathClient(chainId) {
  if (chainId === DEV_TESTNET) {
    return subgraphDevTestnetCore
  }

  if (chainId === ARBITRUM_SEPOLIA) {
    return subgraphArbitrumSepoliaCore
  }

  throw new Error(`Unsupported chain ${chainId}`)
}
