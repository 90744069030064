import './RedirectModal.css'
import Modal from '../Modal/Modal'
import Checkbox from '../Checkbox/Checkbox'
import Button from 'components/Button'

export function RedirectPopupModal({
  redirectModalVisible,
  setRedirectModalVisible,
  setRedirectPopupTimestamp,
  setShouldHideRedirectModal,
  shouldHideRedirectModal,
}) {
  const onClickAgree = () => {
    if (shouldHideRedirectModal) {
      setRedirectPopupTimestamp(Date.now())
    }
    setRedirectModalVisible()
  }

  return (
    <Modal
      className="RedirectModal"
      isVisible={redirectModalVisible}
      setIsVisible={setRedirectModalVisible}
      label="Welcome"
    >
      You are leaving TFX.market and will be redirected to a third party, independent website.
      <br />
      <br />
      The website is a community deployed and maintained instance of the open source{' '}
      <a href="https://github.com/TemplarDAO" target="_blank" rel="noopener noreferrer" className="underline">
        TFX front end
      </a>
      , hosted and served on the distributed, peer-to-peer{' '}
      <a href="https://ipfs.io/" target="_blank" rel="noopener noreferrer" className="underline">
        IPFS network
      </a>
      .
      <br />
      <br />
      Alternative links can be found in the{' '}
      <a
        href="https://tfx-market.gitbook.io/tfx/references/useful-links"
        target="_blank"
        rel="noopener noreferrer"
        className="underline"
      >
        docs
      </a>
      .
      <br />
      <br />
      By clicking Agree you accept the{' '}
      <a
        href="https://tfx.market/#/terms-and-conditions"
        target="_blank"
        rel="noopener noreferrer"
        className="underline"
      >
        T&Cs
      </a>{' '}
      and{' '}
      <a href="https://tfx.market/#/referral-terms" target="_blank" rel="noopener noreferrer" className="underline">
        Referral T&Cs
      </a>
      .
      <br />
      <br />
      <div className="mb-sm mt-2">
        <Checkbox isChecked={shouldHideRedirectModal} setIsChecked={setShouldHideRedirectModal}>
          Don't show this message again for 30 days.
        </Checkbox>
      </div>
      <Button className="App-cta Exchange-swap-button" onClick={onClickAgree}>
        Agree
      </Button>
    </Modal>
  )
}
