import { useState } from 'react'
import { ethers } from 'ethers'
import { callContract } from 'utils/api'

import RewardRouter from 'abis/RewardRouterV3.json'
import { PButton } from 'components/Button'

const ClaimModal = (props) => {
  const { setIsVisible, rewardRouterAddress, library, chainId, setPendingTxns } = props

  const [isClaiming, setIsClaiming] = useState(false)

  const isPrimaryEnabled = () => {
    return !isClaiming
  }

  const getPrimaryText = () => {
    if (isClaiming) {
      return `Claiming...`
    }
    return 'Claim'
  }

  const onClickPrimary = () => {
    setIsClaiming(true)

    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter, library)
    callContract(chainId, contract, 'handleRewards', [true], {
      sentMsg: 'Claim submitted.',
      failMsg: 'Claim failed.',
      successMsg: 'Claim completed!',
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false)
      })
      .finally(() => {
        setIsClaiming(false)
      })
  }

  return (
    <div className="Exchange-swap-button-container mt-5">
      <PButton onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
        {getPrimaryText()}
      </PButton>
    </div>
  )
}

export default ClaimModal
