import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ethers } from 'ethers'
import { useEffect, useMemo, useRef, useState } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { Redirect, Route, HashRouter as Router, Switch, useHistory, useLocation } from 'react-router-dom'
import { ParallaxProvider } from 'react-scroll-parallax'
import { StickyContainer } from 'react-sticky'
import { SWRConfig } from 'swr'

import { useConstants } from 'hooks/useConstant'

import {
  BASIS_POINTS_DIVISOR,
  DEFAULT_SLIPPAGE_AMOUNT,
  getAppBaseUrl,
  helperToast,
  isLocal,
  shouldShowRedirectModal,
  useChainId,
  useLocalStorageSerializeKey,
} from 'utils'

import {
  DISABLE_ORDER_VALIDATION_KEY,
  IS_PNL_IN_LEVERAGE_KEY,
  REFERRAL_CODE_KEY,
  REFERRAL_CODE_QUERY_PARAM,
  SHOULD_SHOW_POSITION_LINES_KEY,
  SHOW_PNL_AFTER_FEES_KEY,
  SLIPPAGE_BPS_KEY,
} from 'config/localStorage'

import Actions from 'pages/Actions/Actions'
import Dashboard from 'pages/Dashboard'
import { Exchange } from 'pages/Exchange/Exchange'
import Explorer from 'pages/Explorer'
import OrdersOverview from 'pages/OrdersOverview/OrdersOverview'
import Pool from 'pages/Pool'
import PositionsOverview from 'pages/PositionsOverview/PositionsOverview'
import Referrals from 'pages/Referrals/Referrals'
import ReferralTerms from 'pages/ReferralTerms/ReferralTerms'
import Stats from 'pages/Stats/Stats'
import TermsAndConditions from 'pages/TermsAndConditions/TermsAndConditions'

import Checkbox from 'components/Checkbox/Checkbox'
import Modal from 'components/Modal/Modal'

import 'styles/App.css'
import 'styles/AppOrder.css'
import 'styles/Font.css'
import 'styles/Input.css'
import 'styles/Shared.css'

import SEO from 'components/Common/SEO'
import useRouteQuery from 'hooks/useRouteQuery'
import { decodeReferralCode, encodeReferralCode } from 'utils/api/referrals'

import {
  darkTheme as rainbowDarkTheme,
  RainbowKitProvider,
  lightTheme as rainbowLightTheme,
  useConnectModal,
} from '@rainbow-me/rainbowkit'
import AnalyticProvider, { useAnalytic } from 'components/Analytic'
import { GA_EVENT_KEY } from 'components/Analytic/constants'
import { PButton } from 'components/Button'
import ErrorFallback from 'components/ErrorFallback'
import FieldInput from 'components/FieldInput'
import { Navbar } from 'components/Layout/Navbar'
import { RedirectPopupModal } from 'components/ModalViews/RedirectModal'
import PendingStepsContextProvider from 'components/ToastPendingStep'
import { useDayJs } from 'hooks/useDay'
import { PendingTxnsProvider, usePendingTxns } from 'hooks/usePendingTxns'
import { ThemeProvider, useTheme } from 'hooks/useTheme'
import { useWeb3 } from 'hooks/useWeb3'
import { wagmiClientConfig } from 'hooks/wagami'
import PageNotFound from 'pages/PageNotFound/PageNotFound'
import { useLocalStorage } from 'react-use'
import { useHasOutdatedUi } from 'utils/api'
import { REDIRECT_POPUP_TIMESTAMP_KEY } from 'utils/constants'
import { useAccount, WagmiConfig } from 'wagmi'

import useScrollToTop from 'hooks/useScrollToTop'

if ('ethereum' in window) {
  window.ethereum.autoRefreshOnNetworkChange = false
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY ?? '',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
})

function FullApp() {
  const exchangeRef = useRef()
  const buyGlpRef = useRef()
  const { connector, library, active } = useWeb3()
  const { chainId } = useChainId()
  const location = useLocation()
  const history = useHistory()

  useDayJs()

  const [activatingConnector, setActivatingConnector] = useState()
  const { pendingTxns, setPendingTxns } = usePendingTxns()

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined)
    }
  }, [activatingConnector, connector, chainId])

  const query = useRouteQuery()
  const { theme, toggleTheme } = useTheme()

  const { data: constants, isLoading: isConstantsLoading } = useConstants(chainId, library)

  useEffect(() => {
    let referralCode = query.get(REFERRAL_CODE_QUERY_PARAM)
    if (!referralCode || referralCode.length === 0) {
      const params = new URLSearchParams(window.location.search)
      referralCode = params.get(REFERRAL_CODE_QUERY_PARAM)
    }

    if (referralCode && referralCode.length <= 20) {
      const encodedReferralCode = encodeReferralCode(referralCode)
      if (encodeReferralCode !== ethers.constants.HashZero) {
        localStorage.setItem(REFERRAL_CODE_KEY, encodedReferralCode)
        const queryParams = new URLSearchParams(location.search)
        if (queryParams.has('ref')) {
          queryParams.delete('ref')
          history.replace({
            search: queryParams.toString(),
          })
        }
      }
    }
  }, [query, history, location])

  const { openConnectModal: connectWallet } = useConnectModal()
  const [redirectModalVisible, setRedirectModalVisible] = useState(false)
  const [shouldHideRedirectModal, setShouldHideRedirectModal] = useState(false)
  const [redirectPopupTimestamp, setRedirectPopupTimestamp, removeRedirectPopupTimestamp] =
    useLocalStorage(REDIRECT_POPUP_TIMESTAMP_KEY)

  const [isSettingsVisible, setIsSettingsVisible] = useState(false)
  const [savedSlippageAmount, setSavedSlippageAmount] = useLocalStorageSerializeKey(
    [chainId, SLIPPAGE_BPS_KEY],
    DEFAULT_SLIPPAGE_AMOUNT,
  )
  const [slippageAmount, setSlippageAmount] = useState(0)
  const [isPnlInLeverage, setIsPnlInLeverage] = useState(false)
  const [shouldDisableOrderValidation, setShouldDisableOrderValidation] = useState(false)
  const [showPnlAfterFees, setShowPnlAfterFees] = useState(false)

  const [savedIsPnlInLeverage, setSavedIsPnlInLeverage] = useLocalStorageSerializeKey(
    [chainId, IS_PNL_IN_LEVERAGE_KEY],
    false,
  )

  const [savedShowPnlAfterFees, setSavedShowPnlAfterFees] = useLocalStorageSerializeKey(
    [chainId, SHOW_PNL_AFTER_FEES_KEY],
    false,
  )
  const [savedShouldDisableOrderValidation, setSavedShouldDisableOrderValidation] = useLocalStorageSerializeKey(
    [chainId, DISABLE_ORDER_VALIDATION_KEY],
    false,
  )

  const [savedShouldShowPositionLines, setSavedShouldShowPositionLines] = useLocalStorageSerializeKey(
    [chainId, SHOULD_SHOW_POSITION_LINES_KEY],
    false,
  )

  const openSettings = () => {
    const slippage = parseInt(savedSlippageAmount)
    setSlippageAmount((slippage / BASIS_POINTS_DIVISOR) * 100)
    setIsPnlInLeverage(savedIsPnlInLeverage)
    setShowPnlAfterFees(savedShowPnlAfterFees)
    setShouldDisableOrderValidation(savedShouldDisableOrderValidation)
    setIsSettingsVisible(true)
  }

  const { sendEvent } = useAnalytic()

  const saveAndCloseSettings = () => {
    const slippage = parseFloat(slippageAmount)
    if (isNaN(slippage)) {
      helperToast.error('Invalid slippage value')
      return
    }
    if (slippage > 5) {
      helperToast.error('Slippage should be less than 5%')
      return
    }

    const basisPoints = (slippage * BASIS_POINTS_DIVISOR) / 100
    if (parseInt(basisPoints) !== parseFloat(basisPoints)) {
      helperToast.error('Max slippage precision is 0.01%')
      return
    }

    sendEvent({
      category: GA_EVENT_KEY.SETTING.CATEGORY,
      action: GA_EVENT_KEY.SETTING.ACTION.CLICK_SAVE,
      label: `isPnlInLeverage:${isPnlInLeverage},showPnlAfterFees:${showPnlAfterFees}`,
      value: slippageAmount,
    })

    setSavedIsPnlInLeverage(isPnlInLeverage)
    setSavedShowPnlAfterFees(showPnlAfterFees)
    setSavedShouldDisableOrderValidation(shouldDisableOrderValidation)
    setSavedSlippageAmount(basisPoints)
    setIsSettingsVisible(false)
  }

  const localStorageCode = window.localStorage.getItem(REFERRAL_CODE_KEY)
  const baseUrl = getAppBaseUrl()
  let appRedirectUrl = baseUrl
  if (localStorageCode && localStorageCode.length > 0 && localStorageCode !== ethers.constants.HashZero) {
    const decodedRefCode = decodeReferralCode(localStorageCode)
    if (decodedRefCode) {
      appRedirectUrl = `${appRedirectUrl}?ref=${decodedRefCode}`
    }
  }

  const showRedirectModal = () => {
    if (shouldShowRedirectModal(redirectPopupTimestamp)) {
      setRedirectModalVisible(true)
    }
  }

  useAccount({
    onConnect({ isReconnected }) {
      if (!isReconnected) {
        showRedirectModal()
      }
    },
  })

  if (isConstantsLoading && !constants) return <>Loading ....</>

  return (
    <RainbowKitProvider
      modalSize="compact"
      theme={
        theme === 'dark'
          ? rainbowDarkTheme({ accentColor: '#2563eb' })
          : rainbowLightTheme({
              accentColor: '#E0E2E3',
              accentColorForeground: '#181A1D',
            })
      }
    >
      <StickyContainer>
        <Navbar
          theme={theme}
          toggleTheme={toggleTheme}
          active={active}
          showRedirectModal={showRedirectModal}
          openSettings={openSettings}
          redirectPopupTimestamp={redirectPopupTimestamp}
        />
        <Switch>
          <Route exact path="/">
            <Redirect to="/dashboard" />
          </Route>
          <Route exact path="/trade">
            <Exchange
              ref={exchangeRef}
              savedShowPnlAfterFees={savedShowPnlAfterFees}
              savedIsPnlInLeverage={savedIsPnlInLeverage}
              setSavedIsPnlInLeverage={setSavedIsPnlInLeverage}
              savedSlippageAmount={savedSlippageAmount}
              setPendingTxns={setPendingTxns}
              pendingTxns={pendingTxns}
              savedShouldShowPositionLines={savedShouldShowPositionLines}
              setSavedShouldShowPositionLines={setSavedShouldShowPositionLines}
              connectWallet={connectWallet}
              savedShouldDisableOrderValidation={savedShouldDisableOrderValidation}
            />
          </Route>
          <Route exact path="/dashboard">
            <Dashboard />
          </Route>
          <Route exact path="/pool">
            <Pool
              ref={buyGlpRef}
              savedSlippageAmount={savedSlippageAmount}
              setPendingTxns={setPendingTxns}
              connectWallet={connectWallet}
              pendingTxns={pendingTxns}
            />
          </Route>
          <Route exact path="/actions">
            <Actions />
          </Route>
          <Route exact path="/actions/:account">
            <Actions />
          </Route>
          <Route exact path="/orders_overview">
            <OrdersOverview />
          </Route>
          <Route exact path="/positions_overview">
            <PositionsOverview />
          </Route>
          <Route exact path="/referral-terms">
            <ReferralTerms />
          </Route>
          <Route exact path="/terms-and-conditions">
            <TermsAndConditions />
          </Route>
          <Route exact path="/explorer">
            <Explorer />
          </Route>
          <Route exact path="/stats">
            <Stats />
          </Route>
          {isLocal() && (
            <>
              <Route exact path="/referrals">
                <Referrals pendingTxns={pendingTxns} connectWallet={connectWallet} setPendingTxns={setPendingTxns} />
              </Route>
              <Route exact path="/referrals/:account">
                <Referrals pendingTxns={pendingTxns} connectWallet={connectWallet} setPendingTxns={setPendingTxns} />
              </Route>
            </>
          )}

          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </StickyContainer>

      <RedirectPopupModal
        redirectModalVisible={redirectModalVisible}
        setRedirectModalVisible={setRedirectModalVisible}
        appRedirectUrl={appRedirectUrl}
        setRedirectPopupTimestamp={setRedirectPopupTimestamp}
        setShouldHideRedirectModal={setShouldHideRedirectModal}
        shouldHideRedirectModal={shouldHideRedirectModal}
        removeRedirectPopupTimestamp={removeRedirectPopupTimestamp}
      />

      <Modal
        className="App-settings"
        isVisible={isSettingsVisible}
        setIsVisible={setIsSettingsVisible}
        label="Settings"
      >
        <FieldInput
          label="Allowed Slippage"
          type="number"
          className="App-slippage-tolerance-input"
          min="0"
          value={slippageAmount}
          onChange={(e) => setSlippageAmount(e.target.value)}
          subfix="%"
        />
        <div className="Exchange-settings-row text-xl">
          <Checkbox isChecked={showPnlAfterFees} setIsChecked={setShowPnlAfterFees}>
            Display PnL after fees
          </Checkbox>
        </div>
        <div className="Exchange-settings-row text-xl">
          <Checkbox isChecked={isPnlInLeverage} setIsChecked={setIsPnlInLeverage}>
            Include PnL in leverage display
          </Checkbox>
        </div>

        {isLocal() && (
          <div className="Exchange-settings-row text-xl">
            <Checkbox isChecked={shouldDisableOrderValidation} setIsChecked={setShouldDisableOrderValidation}>
              Disable order validations
            </Checkbox>
          </div>
        )}

        <div className="w-full text-center mt-8">
          <PButton size="xl" onClick={saveAndCloseSettings}>
            Save
          </PButton>
        </div>
      </Modal>
    </RainbowKitProvider>
  )
}

function App() {
  useScrollToTop()

  useHasOutdatedUi()

  const queryClient = new QueryClient()

  const trackingCode = useMemo(() => {
    return {
      ua: process.env.REACT_APP_GG_ANALYTIC_UA,
      ga: process.env.REACT_APP_GG_ANALYTIC_GA,
    }
  }, [])

  return (
    <SWRConfig value={{ refreshInterval: 5000 }}>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <WagmiConfig config={wagmiClientConfig}>
          <QueryClientProvider client={queryClient}>
            <PendingTxnsProvider>
              <PendingStepsContextProvider>
                <ThemeProvider>
                  <HelmetProvider>
                    <SEO>
                      <ParallaxProvider>
                        <Router>
                          <AnalyticProvider trackingCode={trackingCode}>
                            <FullApp />
                          </AnalyticProvider>
                        </Router>
                      </ParallaxProvider>
                    </SEO>
                  </HelmetProvider>
                </ThemeProvider>
              </PendingStepsContextProvider>
            </PendingTxnsProvider>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </WagmiConfig>
      </Sentry.ErrorBoundary>
    </SWRConfig>
  )
}

export default App
