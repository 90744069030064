import { useEffect, useState, forwardRef } from 'react'
import { useHistory } from 'react-router-dom'
import Swap from './components/Swap'
// import { Footer } from '@tfx/ui'
import SwapContent from './components/SwapContent'

import { Footer } from '@tfx/ui'

const Pool = forwardRef((props, ref) => {
  const history = useHistory()
  const [isBuying, setIsBuying] = useState(true)

  useEffect(() => {
    const hash = history.location.hash.replace('#', '')
    const buying = hash === 'redeem' ? false : true
    setIsBuying(buying)
  }, [history.location.hash])

  return (
    <div className="default-container page-layout">
      <SwapContent />
      <Swap {...props} isBuying={isBuying} setIsBuying={setIsBuying} />
      <Footer isPaddingX={false} />
    </div>
  )
})

export default Pool
