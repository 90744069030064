import { Fragment, useEffect, useMemo, useState } from 'react'

import Slider, { SliderTooltip } from 'rc-slider'
import 'rc-slider/assets/index.css'
import Modal from '../Modal/Modal'
import Tooltip from '../Tooltip/Popper'

import { useAccount } from 'wagmi'

import cx from 'classnames'
import { ethers } from 'ethers'
import useSWR from 'swr'

import { BsArrowRight } from 'react-icons/bs'
import { IoMdSwap } from 'react-icons/io'

import { getContract } from '@tfx/addresses'
import { getLeverage, getLiquidationPrice, getPositionKey } from '@tfx/tfx-sdk'
import { getConstant } from 'config/chains'
import { useConstants } from 'hooks/useConstant'
import {
  adjustForDecimals,
  approveTokens,
  BASIS_POINTS_DIVISOR,
  bigNumberify,
  calculatePositionDelta,
  DEFAULT_HIGHER_SLIPPAGE_AMOUNT,
  DUST_BNB,
  expandDecimals,
  fetcher,
  formatAmount,
  formatAmountFree,
  getExchangeRate,
  getMostAbundantStableToken,
  getNextFromAmount,
  getNextToAmount,
  getTokenInfo,
  getUsd,
  helperToast,
  isTriggerRatioInverted,
  LEVERAGE_ORDER_OPTIONS,
  LIMIT,
  LONG,
  MARKET,
  parseValue,
  PRECISION,
  replaceNativeTokenAddress,
  SHORT,
  shouldRaiseGasError,
  STOP,
  SWAP,
  SWAP_OPTIONS,
  SWAP_ORDER_OPTIONS,
  USD_DECIMALS,
  USDG_ADDRESS,
  USDX_DECIMALS,
  useLocalStorageByChainId,
  useLocalStorageSerializeKey,
  usePrevious,
} from 'utils'
import * as Api from 'utils/api'

import Checkbox from '../Checkbox/Checkbox'
import Tab from '../Tab/Tab'
import ConfirmationBox from './ConfirmationBox'
import ExchangeInfoRow from './ExchangeInfoRow'
import OrdersToa from './OrdersToa'
import TokenSelector from './TokenSelector'

import { getToken, getTokenBySymbol, getTokens, getWhitelistedTokens } from '@tfx/tokens'
import PositionRouter from 'abis/PositionRouter.json'
import Router from 'abis/Router.json'
import Token from 'abis/Token.json'
import WETH from 'abis/WETH.json'

import { useUserReferralCode } from 'utils/api/referrals'

import { useAnalytic } from 'components/Analytic'
import { GA_EVENT_KEY } from 'components/Analytic/constants'
import { PButton } from 'components/Button'
import FieldInput from 'components/FieldInput'
import { usePendingSteps } from 'components/ToastPendingStep'
import { MdSwapHoriz, MdTrendingDown, MdTrendingUp } from 'react-icons/md'
import MarketBox from './MarketBox'
import SwapInfoBox from './SwapInfoBox'
import UseFullBox from './UserFullBox'

const SWAP_ICONS = {
  [LONG]: MdTrendingUp,
  [SHORT]: MdTrendingDown,
  [SWAP]: MdSwapHoriz,
}
const { AddressZero } = ethers.constants

const leverageSliderHandle = (props) => {
  const { value, dragging, index, ...restProps } = props
  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${parseFloat(value).toFixed(2)}x`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Slider.Handle value={value} {...restProps} />
    </SliderTooltip>
  )
}

function getNextAveragePrice({ size, sizeDelta, hasProfit, delta, nextPrice, isLong }) {
  if (!size || !sizeDelta || !delta || !nextPrice) {
    return
  }
  const nextSize = size.add(sizeDelta)
  let divisor
  if (isLong) {
    divisor = hasProfit ? nextSize.add(delta) : nextSize.sub(delta)
  } else {
    divisor = hasProfit ? nextSize.sub(delta) : nextSize.add(delta)
  }
  if (!divisor || divisor.eq(0)) {
    return
  }
  const nextAveragePrice = nextPrice.mul(nextSize).div(divisor)
  return nextAveragePrice
}

export default function SwapBox(props) {
  const {
    pendingPositions,
    setPendingPositions,
    infoTokens,
    active,
    library,
    account,
    fromTokenAddress,
    setFromTokenAddress,
    toTokenAddress,
    setToTokenAddress,
    swapOption,
    setSwapOption,
    positionsMap,
    pendingTxns,
    setPendingTxns,
    tokenSelection,
    setTokenSelection,
    setIsConfirming,
    isConfirming,
    isPendingConfirmation,
    setIsPendingConfirmation,
    flagOrdersEnabled,
    chainId,
    nativeTokenAddress,
    savedSlippageAmount,
    totalTokenWeights,
    usdxSupply,
    orders,
    savedIsPnlInLeverage,
    orderBookApproved,
    positionRouterApproved,
    isWaitingForPluginApproval,
    approveOrderBook,
    approvePositionRouter,
    setIsWaitingForPluginApproval,
    isWaitingForPositionRouterApproval,
    setIsWaitingForPositionRouterApproval,
    isPluginApproving,
    isPositionRouterApproving,
    savedShouldDisableOrderValidation,
    minExecutionFee,
    minExecutionFeeUSD,
    minExecutionFeeErrorMessage,
  } = props

  const { chain } = useAccount()

  const [fromValue, setFromValue] = useState('')
  const [toValue, setToValue] = useState('')
  const [anchorOnFromAmount, setAnchorOnFromAmount] = useState(true)
  const [isApproving, setIsApproving] = useState(false)
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [modalError, setModalError] = useState(false)
  const [isHigherSlippageAllowed, setIsHigherSlippageAllowed] = useState(false)
  const { attachedOnChain, userReferralCode } = useUserReferralCode(library, chainId, account)
  const { sendEvent } = useAnalytic()

  const { setPendingStepWorkflow } = usePendingSteps()

  let allowedSlippage = savedSlippageAmount
  if (isHigherSlippageAllowed) {
    allowedSlippage = DEFAULT_HIGHER_SLIPPAGE_AMOUNT
  }

  const defaultCollateralSymbol = getConstant(chainId, 'defaultCollateralSymbol')
  // TODO hack with useLocalStorageSerializeKey
  const [shortCollateralAddress, setShortCollateralAddress] = useLocalStorageByChainId(
    chainId,
    'Short-Collateral-Address',
    getTokenBySymbol(chainId, defaultCollateralSymbol).address,
  )
  const isLong = swapOption === LONG
  const isShort = swapOption === SHORT
  const isSwap = swapOption === SWAP

  function getTokenLabel() {
    switch (true) {
      case isLong:
        return 'Long'
      case isShort:
        return 'Short'
      case isSwap:
        return 'Receive'
      default:
        return ''
    }
  }
  const [leverageOption, setLeverageOption] = useLocalStorageSerializeKey(
    [chainId, 'Exchange-swap-leverage-option'],
    '2',
  )
  const [isLeverageSliderEnabled, setIsLeverageSliderEnabled] = useLocalStorageSerializeKey(
    [chainId, 'Exchange-swap-leverage-slider-enabled'],
    true,
  )

  const hasLeverageOption = isLeverageSliderEnabled && !isNaN(parseFloat(leverageOption))

  const [ordersToaOpen, setOrdersToaOpen] = useState(false)

  let [orderOption, setOrderOption] = useLocalStorageSerializeKey([chainId, 'Order-option'], MARKET)
  if (!flagOrdersEnabled) {
    orderOption = MARKET
  }

  const onOrderOptionChange = (option) => {
    sendEvent({
      category: GA_EVENT_KEY.TRADE.CATEGORY,
      action: GA_EVENT_KEY.TRADE.ACTION.CHANGE_ORDER_OPTION,
      label: option,
    })
    setOrderOption(option)
  }

  const isMarketOrder = orderOption === MARKET
  const orderOptions = isSwap ? SWAP_ORDER_OPTIONS : LEVERAGE_ORDER_OPTIONS
  const orderOptionLabels = { [STOP]: 'Trigger' }

  const [triggerPriceValue, setTriggerPriceValue] = useState('')
  const triggerPriceUsd = isMarketOrder ? 0 : parseValue(triggerPriceValue, USD_DECIMALS)

  const onTriggerPriceChange = (evt) => {
    setTriggerPriceValue(evt.target.value || '')
  }

  const onTriggerRatioChange = (evt) => {
    setTriggerRatioValue(evt.target.value || '')
  }

  let positionKey
  if (isLong) {
    positionKey = getPositionKey(account, toTokenAddress, toTokenAddress, true, nativeTokenAddress)
  }
  if (isShort) {
    positionKey = getPositionKey(account, shortCollateralAddress, toTokenAddress, false, nativeTokenAddress)
  }

  const existingPosition = positionKey ? positionsMap[positionKey] : undefined
  const hasExistingPosition = existingPosition && existingPosition.size && existingPosition.size.gt(0)

  const whitelistedTokens = getWhitelistedTokens(chainId)
  const tokens = getTokens(chainId)

  const fromTokens = tokens
  const stableTokens = tokens.filter((token) => token.isStable)
  const indexTokens = whitelistedTokens.filter((token) => !token.isStable && !token.isWrapped)
  const shortableTokens = indexTokens.filter((token) => token.isShortable)

  let toTokens = tokens
  if (isLong) {
    toTokens = indexTokens
  }
  if (isShort) {
    toTokens = shortableTokens
  }

  const needOrderBookApproval = !isMarketOrder && !orderBookApproved
  const prevNeedOrderBookApproval = usePrevious(needOrderBookApproval)

  const needPositionRouterApproval = (isLong || isShort) && isMarketOrder && !positionRouterApproved
  const prevNeedPositionRouterApproval = usePrevious(needPositionRouterApproval)

  useEffect(() => {
    if (!needOrderBookApproval && prevNeedOrderBookApproval && isWaitingForPluginApproval) {
      setIsWaitingForPluginApproval(false)
      helperToast.success(<div>Orders enabled!</div>)
    }
  }, [needOrderBookApproval, prevNeedOrderBookApproval, setIsWaitingForPluginApproval, isWaitingForPluginApproval])

  useEffect(() => {
    if (!needPositionRouterApproval && prevNeedPositionRouterApproval && isWaitingForPositionRouterApproval) {
      setIsWaitingForPositionRouterApproval(false)
      helperToast.success(<div>Leverage enabled!</div>)
    }
  }, [
    needPositionRouterApproval,
    prevNeedPositionRouterApproval,
    setIsWaitingForPositionRouterApproval,
    isWaitingForPositionRouterApproval,
  ])

  const routerAddress = getContract(chainId, 'Router')
  const tokenAllowanceAddress = fromTokenAddress === AddressZero ? nativeTokenAddress : fromTokenAddress
  const { data: tokenAllowance } = useSWR(
    active && [active, chainId, tokenAllowanceAddress, 'allowance', account, routerAddress],
    {
      fetcher: fetcher(library, Token),
    },
  )

  const { data: constants } = useConstants(chainId, library)
  const marginFeeBasisPoints = constants.MARGIN_FEE_BASIS_POINTS
  const stableSwapFeeBasisPoints = constants.STABLE_SWAP_FEE_BASIS_POINTS
  const swapFeeBasisPoints = constants.SWAP_FEE_BASIS_POINTS
  const stableTaxBasisPoints = constants.STABLE_TAX_BASIS_POINTS
  const taxBasisPoints = constants.TAX_BASIS_POINTS

  const fromToken = getToken(chainId, fromTokenAddress)
  const toToken = getToken(chainId, toTokenAddress)
  const shortCollateralToken = getTokenInfo(infoTokens, shortCollateralAddress)

  const fromTokenInfo = getTokenInfo(infoTokens, fromTokenAddress)
  const toTokenInfo = getTokenInfo(infoTokens, toTokenAddress)

  const fromBalance = chain && fromTokenInfo ? fromTokenInfo.balance : bigNumberify(0)
  const toBalance = chain && toTokenInfo ? toTokenInfo.balance : bigNumberify(0)

  const fromAmount = parseValue(fromValue, fromToken && fromToken.decimals)
  const toAmount = parseValue(toValue, toToken && toToken.decimals)

  const isPotentialWrap = (fromToken.isNative && toToken.isWrapped) || (fromToken.isWrapped && toToken.isNative)
  const isWrapOrUnwrap = isSwap && isPotentialWrap
  const needApproval =
    fromTokenAddress !== AddressZero && tokenAllowance && fromAmount && fromAmount.gt(tokenAllowance) && !isWrapOrUnwrap
  const prevFromTokenAddress = usePrevious(fromTokenAddress)
  const prevNeedApproval = usePrevious(needApproval)
  const prevToTokenAddress = usePrevious(toTokenAddress)

  const fromUsdMin = getUsd(fromAmount, fromTokenAddress, false, infoTokens)
  const toUsdMax = getUsd(toAmount, toTokenAddress, true, infoTokens, orderOption, triggerPriceUsd)

  const indexTokenAddress = toTokenAddress === AddressZero ? nativeTokenAddress : toTokenAddress
  const collateralTokenAddress = isLong ? indexTokenAddress : shortCollateralAddress
  const collateralToken = getToken(chainId, collateralTokenAddress)

  const [triggerRatioValue, setTriggerRatioValue] = useState('')

  const triggerRatioInverted = useMemo(() => {
    return isTriggerRatioInverted(fromTokenInfo, toTokenInfo)
  }, [toTokenInfo, fromTokenInfo])

  const maxToTokenOut = useMemo(() => {
    const value = toTokenInfo.availableAmount?.gt(toTokenInfo.poolAmount?.sub(toTokenInfo.bufferAmount))
      ? toTokenInfo.poolAmount?.sub(toTokenInfo.bufferAmount)
      : toTokenInfo.availableAmount

    if (!value) {
      return bigNumberify(0)
    }

    return value.gt(0) ? value : bigNumberify(0)
  }, [toTokenInfo])

  const maxToTokenOutUSD = useMemo(() => {
    return getUsd(maxToTokenOut, toTokenAddress, false, infoTokens)
  }, [maxToTokenOut, toTokenAddress, infoTokens])

  const maxFromTokenInUSD = useMemo(() => {
    const value = fromTokenInfo.maxUsdgAmount
      ?.sub(fromTokenInfo.usdgAmount)
      .mul(expandDecimals(1, USD_DECIMALS))
      .div(expandDecimals(1, USDX_DECIMALS))

    if (!value) {
      return bigNumberify(0)
    }

    return value.gt(0) ? value : bigNumberify(0)
  }, [fromTokenInfo])

  const maxFromTokenIn = useMemo(() => {
    if (!fromTokenInfo.maxPrice) {
      return bigNumberify(0)
    }
    return maxFromTokenInUSD?.mul(expandDecimals(1, fromTokenInfo.decimals)).div(fromTokenInfo.maxPrice).toString()
  }, [maxFromTokenInUSD, fromTokenInfo])

  let maxSwapAmountUsd = bigNumberify(0)

  if (maxToTokenOutUSD && maxFromTokenInUSD) {
    maxSwapAmountUsd = maxToTokenOutUSD.lt(maxFromTokenInUSD) ? maxToTokenOutUSD : maxFromTokenInUSD
  }

  const triggerRatio = useMemo(() => {
    if (!triggerRatioValue) {
      return bigNumberify(0)
    }
    let ratio = parseValue(triggerRatioValue, USD_DECIMALS)
    if (ratio.eq(0)) {
      return bigNumberify(0)
    }
    if (triggerRatioInverted) {
      ratio = PRECISION.mul(PRECISION).div(ratio)
    }
    return ratio
  }, [triggerRatioValue, triggerRatioInverted])

  useEffect(() => {
    if (
      fromToken &&
      fromTokenAddress === prevFromTokenAddress &&
      !needApproval &&
      prevNeedApproval &&
      isWaitingForApproval
    ) {
      setIsWaitingForApproval(false)
      helperToast.success(<div>{fromToken.symbol} approved!</div>)
    }
  }, [
    fromTokenAddress,
    prevFromTokenAddress,
    needApproval,
    prevNeedApproval,
    setIsWaitingForApproval,
    fromToken.symbol,
    isWaitingForApproval,
    fromToken,
  ])

  useEffect(() => {
    if (!toTokens.find((token) => token.address === toTokenAddress) && toTokens.length) {
      setToTokenAddress(swapOption, toTokens[0].address)
    }
  }, [swapOption, toTokens, toTokenAddress, setToTokenAddress])

  useEffect(() => {
    if (swapOption !== SHORT) {
      return
    }
    if (toTokenAddress === prevToTokenAddress) {
      return
    }
    for (let i = 0; i < stableTokens.length; i++) {
      const stableToken = stableTokens[i]
      const key = getPositionKey(account, stableToken.address, toTokenAddress, false, nativeTokenAddress)
      const position = positionsMap[key]
      if (position && position.size && position.size.gt(0)) {
        setShortCollateralAddress(position.collateralToken.address)
        return
      }
    }
  }, [
    account,
    toTokenAddress,
    prevToTokenAddress,
    swapOption,
    positionsMap,
    stableTokens,
    nativeTokenAddress,
    shortCollateralAddress,
    setShortCollateralAddress,
  ])

  useEffect(() => {
    const updateSwapAmounts = () => {
      if (anchorOnFromAmount) {
        if (!fromAmount) {
          setToValue('')
          return
        }

        if (toToken) {
          const { amount: nextToAmount } = getNextToAmount(
            chainId,
            fromAmount,
            fromTokenAddress,
            toTokenAddress,
            infoTokens,
            undefined,
            !isMarketOrder && triggerRatio,
            usdxSupply,
            totalTokenWeights,
            isSwap,
            stableSwapFeeBasisPoints,
            swapFeeBasisPoints,
            stableTaxBasisPoints,
            taxBasisPoints,
          )

          const nextToValue = formatAmountFree(nextToAmount, toToken.decimals, toToken.decimals)
          setToValue(nextToValue)
        }
        return
      }

      if (!toAmount) {
        setFromValue('')
        return
      }
      if (fromToken) {
        const { amount: nextFromAmount } = getNextFromAmount(
          chainId,
          toAmount,
          fromTokenAddress,
          toTokenAddress,
          infoTokens,
          undefined,
          !isMarketOrder && triggerRatio,
          usdxSupply,
          totalTokenWeights,
          isSwap,
          stableSwapFeeBasisPoints,
          swapFeeBasisPoints,
          stableTaxBasisPoints,
          taxBasisPoints,
        )
        const nextFromValue = formatAmountFree(nextFromAmount, fromToken.decimals, fromToken.decimals)
        setFromValue(nextFromValue)
      }
    }

    const updateLeverageAmounts = () => {
      if (!hasLeverageOption) {
        return
      }
      if (anchorOnFromAmount) {
        if (!fromAmount) {
          setToValue('')
          return
        }

        const toTokenInfo = getTokenInfo(infoTokens, toTokenAddress)

        if (toTokenInfo && toTokenInfo.maxPrice && fromUsdMin && fromUsdMin.gt(0)) {
          const leverageMultiplier = parseInt(leverageOption * BASIS_POINTS_DIVISOR)
          const toTokenPriceUsd =
            !isMarketOrder && triggerPriceUsd && triggerPriceUsd.gt(0) ? triggerPriceUsd : toTokenInfo.maxPrice

          const { feeBasisPoints } = getNextToAmount(
            chainId,
            fromAmount,
            fromTokenAddress,
            collateralTokenAddress,
            infoTokens,
            undefined,
            undefined,
            usdxSupply,
            totalTokenWeights,
            isSwap,
            stableSwapFeeBasisPoints,
            swapFeeBasisPoints,
            stableTaxBasisPoints,
            taxBasisPoints,
          )

          let fromUsdMinAfterFee = fromUsdMin
          if (feeBasisPoints) {
            fromUsdMinAfterFee = fromUsdMin.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR)
          }

          const toNumerator = fromUsdMinAfterFee.mul(leverageMultiplier).mul(BASIS_POINTS_DIVISOR)
          const toDenominator = bigNumberify(marginFeeBasisPoints)
            .mul(leverageMultiplier)
            .add(bigNumberify(BASIS_POINTS_DIVISOR).mul(BASIS_POINTS_DIVISOR))

          const nextToUsd = toNumerator.div(toDenominator)

          const nextToAmount = nextToUsd.mul(expandDecimals(1, toToken.decimals)).div(toTokenPriceUsd)

          const nextToValue = formatAmountFree(nextToAmount, toToken.decimals, toToken.decimals)
          setToValue(nextToValue)
        }
        return
      }

      if (!toAmount) {
        setFromValue('')
        return
      }

      const fromTokenInfo = getTokenInfo(infoTokens, fromTokenAddress)
      if (fromTokenInfo && fromTokenInfo.minPrice && toUsdMax && toUsdMax.gt(0)) {
        const leverageMultiplier = parseInt(leverageOption * BASIS_POINTS_DIVISOR)

        const baseFromAmountUsd = toUsdMax.mul(BASIS_POINTS_DIVISOR).div(leverageMultiplier)

        let fees = toUsdMax.mul(marginFeeBasisPoints).div(BASIS_POINTS_DIVISOR)

        const { feeBasisPoints } = getNextToAmount(
          chainId,
          fromAmount,
          fromTokenAddress,
          collateralTokenAddress,
          infoTokens,
          undefined,
          undefined,
          usdxSupply,
          totalTokenWeights,
          isSwap,
          stableSwapFeeBasisPoints,
          swapFeeBasisPoints,
          stableTaxBasisPoints,
          taxBasisPoints,
        )

        if (feeBasisPoints) {
          const swapFees = baseFromAmountUsd.mul(feeBasisPoints).div(BASIS_POINTS_DIVISOR)
          fees = fees.add(swapFees)
        }

        const nextFromUsd = baseFromAmountUsd.add(fees)

        const nextFromAmount = nextFromUsd.mul(expandDecimals(1, fromToken.decimals)).div(fromTokenInfo.minPrice)

        const nextFromValue = formatAmountFree(nextFromAmount, fromToken.decimals, fromToken.decimals)

        setFromValue(nextFromValue)
      }
    }

    if (isSwap) {
      updateSwapAmounts()
    }

    if (isLong || isShort) {
      updateLeverageAmounts()
    }
  }, [
    anchorOnFromAmount,
    fromAmount,
    toAmount,
    fromToken,
    toToken,
    fromTokenAddress,
    toTokenAddress,
    infoTokens,
    isSwap,
    isLong,
    isShort,
    leverageOption,
    fromUsdMin,
    toUsdMax,
    isMarketOrder,
    triggerPriceUsd,
    triggerRatio,
    hasLeverageOption,
    usdxSupply,
    totalTokenWeights,
    chainId,
    collateralTokenAddress,
    indexTokenAddress,
  ])

  let entryMarkPrice
  let exitMarkPrice
  if (toTokenInfo) {
    entryMarkPrice = swapOption === LONG ? toTokenInfo.maxPrice : toTokenInfo.minPrice
    exitMarkPrice = swapOption === LONG ? toTokenInfo.minPrice : toTokenInfo.maxPrice
  }

  let leverage = bigNumberify(0)
  if (fromUsdMin && toUsdMax && fromUsdMin.gt(0)) {
    const fees = toUsdMax.mul(marginFeeBasisPoints).div(BASIS_POINTS_DIVISOR)
    if (fromUsdMin.sub(fees).gt(0)) {
      leverage = toUsdMax.mul(BASIS_POINTS_DIVISOR).div(fromUsdMin.sub(fees))
    }
  }

  let nextAveragePrice = isMarketOrder ? entryMarkPrice : triggerPriceUsd
  if (hasExistingPosition) {
    let nextDelta, nextHasProfit

    if (isMarketOrder) {
      nextDelta = existingPosition.delta
      nextHasProfit = existingPosition.hasProfit
    } else {
      const data = calculatePositionDelta(triggerPriceUsd || bigNumberify(0), existingPosition)
      nextDelta = data.delta
      nextHasProfit = data.hasProfit
    }

    nextAveragePrice = getNextAveragePrice({
      size: existingPosition.size,
      sizeDelta: toUsdMax,
      hasProfit: nextHasProfit,
      delta: nextDelta,
      nextPrice: isMarketOrder ? entryMarkPrice : triggerPriceUsd,
      isLong,
    })
  }

  const liquidationPrice = getLiquidationPrice({
    isLong,
    size: hasExistingPosition ? existingPosition.size : bigNumberify(0),
    collateral: hasExistingPosition ? existingPosition.collateral : bigNumberify(0),
    averagePrice: nextAveragePrice,
    entryFundingRate: hasExistingPosition ? existingPosition.entryFundingRate : bigNumberify(0),
    cumulativeFundingRate: hasExistingPosition ? existingPosition.cumulativeFundingRate : bigNumberify(0),
    sizeDelta: toUsdMax,
    collateralDelta: fromUsdMin,
    increaseCollateral: true,
    increaseSize: true,
    marginFeeBasisPoints: hasExistingPosition ? existingPosition.marginFeeBasisPoints : 0,
    liquidationFee: hasExistingPosition ? existingPosition.liquidationFee : bigNumberify(0),
    maxLeverage: hasExistingPosition ? existingPosition.maxLeverage : 1,
  })

  const existingLiquidationPrice = existingPosition ? getLiquidationPrice(existingPosition) : undefined
  let displayLiquidationPrice = liquidationPrice ? liquidationPrice : existingLiquidationPrice

  if (hasExistingPosition) {
    const collateralDelta = fromUsdMin ? fromUsdMin : bigNumberify(0)
    const sizeDelta = toUsdMax ? toUsdMax : bigNumberify(0)
    leverage = getLeverage({
      size: existingPosition.size,
      sizeDelta,
      collateral: existingPosition.collateral,
      collateralDelta,
      increaseCollateral: true,
      entryFundingRate: existingPosition.entryFundingRate,
      cumulativeFundingRate: existingPosition.cumulativeFundingRate,
      increaseSize: true,
      hasProfit: existingPosition.hasProfit,
      delta: existingPosition.delta,
      includeDelta: savedIsPnlInLeverage,
      marginFeeBasisPoints: existingPosition.marginFeeBasisPoints,
    })
  } else if (hasLeverageOption) {
    leverage = bigNumberify(parseInt(leverageOption * BASIS_POINTS_DIVISOR))
  }

  const getSwapError = () => {
    if (fromTokenAddress === toTokenAddress) {
      return ['Select different tokens']
    }

    if (!isMarketOrder) {
      if ((toToken.isStable || toToken.isUsdg) && (fromToken.isStable || fromToken.isUsdg)) {
        return ['Select different tokens']
      }

      if (fromToken.isNative && toToken.isWrapped) {
        return ['Select different tokens']
      }

      if (toToken.isNative && fromToken.isWrapped) {
        return ['Select different tokens']
      }
    }

    if (!fromAmount || fromAmount.eq(0)) {
      return ['Enter an amount']
    }
    if (!toAmount || toAmount.eq(0)) {
      return ['Enter an amount']
    }

    const fromTokenInfo = getTokenInfo(infoTokens, fromTokenAddress)
    if (!fromTokenInfo || !fromTokenInfo.minPrice) {
      return ['Incorrect network']
    }
    if (fromTokenInfo && fromTokenInfo.balance && fromAmount && fromAmount.gt(fromTokenInfo.balance)) {
      return [`Insufficient ${fromTokenInfo.symbol} balance`]
    }

    const toTokenInfo = getTokenInfo(infoTokens, toTokenAddress)

    if (!isMarketOrder) {
      if (!triggerRatioValue || triggerRatio.eq(0)) {
        return ['Enter a price']
      }

      const currentRate = getExchangeRate(fromTokenInfo, toTokenInfo)
      if (currentRate && currentRate.lt(triggerRatio)) {
        return [`Price ${triggerRatioInverted ? 'below' : 'above'} Mark Price`]
      }
    }

    const BASSIS_POINT = 1000
    const availableAmount = toTokenInfo.availableAmount.mul(BASSIS_POINT - 10).div(BASSIS_POINT)

    if (
      !isWrapOrUnwrap &&
      toToken &&
      toTokenAddress !== USDG_ADDRESS &&
      toTokenInfo &&
      toTokenInfo.availableAmount &&
      toAmount.gt(availableAmount)
    ) {
      return ['Insufficient liquidity']
    }

    if (
      !isWrapOrUnwrap &&
      toAmount &&
      toTokenInfo.bufferAmount &&
      toTokenInfo.poolAmount &&
      toTokenInfo.bufferAmount.gt(toTokenInfo.poolAmount.sub(toAmount))
    ) {
      return ['Insufficient liquidity']
    }

    if (
      fromUsdMin &&
      fromTokenInfo.maxUsdgAmount &&
      fromTokenInfo.maxUsdgAmount.gt(0) &&
      fromTokenInfo.usdgAmount &&
      fromTokenInfo.maxPrice
    ) {
      const usdgFromAmount = adjustForDecimals(fromUsdMin, USD_DECIMALS, USDX_DECIMALS)
      const nextUsdgAmount = fromTokenInfo.usdgAmount.add(usdgFromAmount)

      if (nextUsdgAmount.gt(fromTokenInfo.maxUsdgAmount)) {
        return [`${fromTokenInfo.symbol} pool exceeded`]
      }
    }

    return [false]
  }

  const getLeverageError = () => {
    if (!toAmount || toAmount.eq(0)) {
      return ['Enter an amount']
    }

    let toTokenInfo = getTokenInfo(infoTokens, toTokenAddress)
    if (toTokenInfo && toTokenInfo.isStable) {
      return [`${swapOption === LONG ? 'Longing' : 'Shorting'} ${toTokenInfo.symbol} not supported`]
    }

    const fromTokenInfo = getTokenInfo(infoTokens, fromTokenAddress)
    if (fromTokenInfo && fromTokenInfo.balance && fromAmount && fromAmount.gt(fromTokenInfo.balance)) {
      return [`Insufficient ${fromTokenInfo.symbol} balance`]
    }

    if (leverage && leverage.eq(0)) {
      return ['Enter an amount']
    }
    if (!isMarketOrder && (!triggerPriceValue || triggerPriceUsd.eq(0))) {
      return ['Enter a price']
    }

    if (!hasExistingPosition && fromUsdMin && fromUsdMin.lt(expandDecimals(10, USD_DECIMALS))) {
      return ['Min order: 10 USD']
    }

    if (leverage && leverage.lt(1.1 * BASIS_POINTS_DIVISOR)) {
      return ['Min leverage: 1.1x']
    }

    if (leverage && leverage.gt(100.0 * BASIS_POINTS_DIVISOR)) {
      return ['Max leverage: 100.0x']
    }

    if (!isMarketOrder && entryMarkPrice && triggerPriceUsd && !savedShouldDisableOrderValidation) {
      if (isLong && entryMarkPrice.lt(triggerPriceUsd)) {
        return ['Price above Mark Price']
      }
      if (!isLong && entryMarkPrice.gt(triggerPriceUsd)) {
        return ['Price below Mark Price']
      }
    }

    if (isLong) {
      let requiredAmount = toAmount
      if (fromTokenAddress !== toTokenAddress || (fromTokenAddress === AddressZero && toTokenAddress === AddressZero)) {
        const { amount: swapAmount } = getNextToAmount(
          chainId,
          fromAmount,
          fromTokenAddress,
          toTokenAddress,
          infoTokens,
          undefined,
          undefined,
          usdxSupply,
          totalTokenWeights,
          isSwap,
          stableSwapFeeBasisPoints,
          swapFeeBasisPoints,
          stableTaxBasisPoints,
          taxBasisPoints,
        )
        requiredAmount = requiredAmount.add(swapAmount)

        if (toToken && toTokenAddress !== USDG_ADDRESS) {
          if (!toTokenInfo.availableAmount) {
            return ['Liquidity data not loaded']
          }
          if (toTokenInfo.availableAmount && requiredAmount.gt(toTokenInfo.availableAmount)) {
            return ['Insufficient liquidity']
          }
        }

        if (
          toTokenInfo.poolAmount &&
          toTokenInfo.bufferAmount &&
          toTokenInfo.bufferAmount.gt(toTokenInfo.poolAmount.sub(swapAmount))
        ) {
          return ['Insufficient liquidity', true, 'BUFFER']
        }

        if (
          fromUsdMin &&
          fromTokenInfo.maxUsdgAmount &&
          fromTokenInfo.maxUsdgAmount.gt(0) &&
          fromTokenInfo.minPrice &&
          fromTokenInfo.usdgAmount
        ) {
          const usdgFromAmount = adjustForDecimals(fromUsdMin, USD_DECIMALS, USDX_DECIMALS)
          const nextUsdgAmount = fromTokenInfo.usdgAmount.add(usdgFromAmount)
          if (nextUsdgAmount.gt(fromTokenInfo.maxUsdgAmount)) {
            return [`${fromTokenInfo.symbol} pool exceeded, try different token`, true, 'MAX_USDG']
          }
        }
      }

      if (toTokenInfo && toTokenInfo.maxPrice) {
        const sizeUsd = toAmount.mul(toTokenInfo.maxPrice).div(expandDecimals(1, toTokenInfo.decimals))
        if (toTokenInfo.maxAvailableLong && sizeUsd.gt(toTokenInfo.maxAvailableLong)) {
          return [`Max ${toTokenInfo.symbol} long exceeded`]
        }
      }
    }

    if (isShort) {
      let stableTokenAmount = bigNumberify(0)
      if (fromTokenAddress !== shortCollateralAddress && fromAmount && fromAmount.gt(0)) {
        const { amount: nextToAmount } = getNextToAmount(
          chainId,
          fromAmount,
          fromTokenAddress,
          shortCollateralAddress,
          infoTokens,
          undefined,
          undefined,
          usdxSupply,
          totalTokenWeights,
          isSwap,
          stableSwapFeeBasisPoints,
          swapFeeBasisPoints,
          stableTaxBasisPoints,
          taxBasisPoints,
        )
        stableTokenAmount = nextToAmount
        if (stableTokenAmount.gt(shortCollateralToken.availableAmount)) {
          return [`Insufficient liquidity, change "Profits In"`]
        }

        if (
          shortCollateralToken.bufferAmount &&
          shortCollateralToken.poolAmount &&
          shortCollateralToken.bufferAmount.gt(shortCollateralToken.poolAmount.sub(stableTokenAmount))
        ) {
          // suggest swapping to collateralToken
          return [`Insufficient liquidity, change "Profits In"`, true, 'BUFFER']
        }

        if (
          fromTokenInfo.maxUsdgAmount &&
          fromTokenInfo.maxUsdgAmount.gt(0) &&
          fromTokenInfo.minPrice &&
          fromTokenInfo.usdgAmount
        ) {
          const usdgFromAmount = adjustForDecimals(fromUsdMin, USD_DECIMALS, USDX_DECIMALS)
          const nextUsdgAmount = fromTokenInfo.usdgAmount.add(usdgFromAmount)
          if (nextUsdgAmount.gt(fromTokenInfo.maxUsdgAmount)) {
            return [`${fromTokenInfo.symbol} pool exceeded, try different token`, true, 'MAX_USDG']
          }
        }
      }
      if (
        !shortCollateralToken ||
        !fromTokenInfo ||
        !toTokenInfo ||
        !toTokenInfo.maxPrice ||
        !shortCollateralToken.availableAmount
      ) {
        return ['Fetching token info...']
      }

      const sizeUsd = toAmount.mul(toTokenInfo.maxPrice).div(expandDecimals(1, toTokenInfo.decimals))
      const sizeTokens = sizeUsd
        .mul(expandDecimals(1, shortCollateralToken.decimals))
        .div(shortCollateralToken.minPrice)

      if (!toTokenInfo.maxAvailableShort) {
        return ['Liquidity data not loaded']
      }

      if (
        toTokenInfo.maxGlobalShortSize &&
        toTokenInfo.maxGlobalShortSize.gt(0) &&
        toTokenInfo.maxAvailableShort &&
        sizeUsd.gt(toTokenInfo.maxAvailableShort)
      ) {
        return [`Max ${toTokenInfo.symbol} short exceeded`]
      }

      stableTokenAmount = stableTokenAmount.add(sizeTokens)
      if (stableTokenAmount.gt(shortCollateralToken.availableAmount)) {
        return [`Insufficient liquidity, change "Profits In"`]
      }
    }

    return [false]
  }

  const getToLabel = () => {
    if (isSwap) {
      return 'Receive'
    }
    if (isLong) {
      return 'Long'
    }
    return 'Short'
  }

  const getError = () => {
    if (isSwap) {
      return getSwapError()
    }
    return getLeverageError()
  }

  const renderOrdersToa = () => {
    if (!ordersToaOpen) {
      return null
    }

    return (
      <OrdersToa
        setIsVisible={setOrdersToaOpen}
        approveOrderBook={approveOrderBook}
        isPluginApproving={isPluginApproving}
      />
    )
  }

  const renderErrorModal = () => {
    const inputCurrency = fromToken.address === AddressZero ? 'ETH' : fromToken.address
    let outputCurrency
    if (isLong) {
      outputCurrency = toToken.address === AddressZero ? 'ETH' : toToken.address
    } else {
      outputCurrency = shortCollateralToken.address
    }
    let externalSwapUrl = `https://app.uniswap.org/#/swap?inputCurrency=${inputCurrency}&outputCurrency=${outputCurrency}`
    let externalSwapName = 'Uniswap'
    const label =
      modalError === 'BUFFER' ? `${shortCollateralToken.symbol} Required` : `${fromToken.symbol} Capacity Reached`
    const swapTokenSymbol = isLong ? toToken.symbol : shortCollateralToken.symbol
    return (
      <Modal isVisible={!!modalError} setIsVisible={setModalError} label={label} className="Error-modal font-base">
        <div>You need to select {swapTokenSymbol} as the "Pay" token to initiate this trade.</div>
        <br />
        {isShort && (
          <div>
            Alternatively you can select a different "Profits In" token.
            <br />
            <br />
          </div>
        )}
        <a href={externalSwapUrl} target="_blank" rel="noreferrer">
          Buy {swapTokenSymbol} on {externalSwapName}
        </a>
      </Modal>
    )
  }

  const isPrimaryEnabled = () => {
    if (isStopOrder) {
      return true
    }
    if (!active) {
      return true
    }

    const [error, modal] = getError()
    if (error && !modal) {
      return false
    }
    if (needOrderBookApproval && isWaitingForPluginApproval) {
      return false
    }
    if ((needApproval && isWaitingForApproval) || isApproving) {
      return false
    }
    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return false
    }
    if (isPositionRouterApproving) {
      return false
    }
    if (isApproving) {
      return false
    }
    if (isSubmitting) {
      return false
    }
    if (!chain) {
      return false
    }

    return true
  }

  const getPrimaryText = () => {
    if (isStopOrder) {
      return 'Open a position'
    }
    if (!active) {
      return 'Connect Wallet'
    }
    if (!chain) {
      return 'Incorrect Network'
    }
    const [error, modal] = getError()
    if (error && !modal) {
      return error
    }

    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return 'Enabling Leverage...'
    }
    if (isPositionRouterApproving) {
      return 'Enabling Leverage...'
    }
    if (needPositionRouterApproval) {
      return 'Enable Leverage'
    }

    if (needApproval && isWaitingForApproval) {
      return 'Waiting for Approval'
    }
    if (isApproving) {
      return `Approving ${fromToken.symbol}...`
    }
    if (needApproval) {
      return `Approve ${fromToken.symbol}`
    }

    if (needOrderBookApproval && isWaitingForPluginApproval) {
      return 'Enabling Orders...'
    }
    if (isPluginApproving) {
      return 'Enabling Orders...'
    }
    if (needOrderBookApproval) {
      return 'Enable Orders'
    }

    if (!isMarketOrder) return `Create ${orderOption.charAt(0) + orderOption.substring(1).toLowerCase()} Order`

    if (isSwap) {
      if (toUsdMax && toUsdMax.lt(fromUsdMin.mul(95).div(100))) {
        return 'High Slippage, Swap Anyway'
      }
      return 'Swap'
    }

    if (isLong) {
      const indexTokenInfo = getTokenInfo(infoTokens, toTokenAddress)
      if (indexTokenInfo && indexTokenInfo.minPrice) {
        const { amount: nextToAmount } = getNextToAmount(
          chainId,
          fromAmount,
          fromTokenAddress,
          indexTokenAddress,
          infoTokens,
          undefined,
          undefined,
          usdxSupply,
          totalTokenWeights,
          isSwap,
          stableSwapFeeBasisPoints,
          swapFeeBasisPoints,
          stableTaxBasisPoints,
          taxBasisPoints,
        )
        const nextToAmountUsd = nextToAmount
          .mul(indexTokenInfo.minPrice)
          .div(expandDecimals(1, indexTokenInfo.decimals))
        if (fromTokenAddress === USDG_ADDRESS && nextToAmountUsd.lt(fromUsdMin.mul(98).div(100))) {
          return 'High USDX Slippage, Long Anyway'
        }
      }
      return `Long ${toToken.symbol}`
    }

    return `Short ${toToken.symbol}`
  }

  const onSelectFromToken = (token) => {
    sendEvent({
      category: GA_EVENT_KEY.TRADE.CATEGORY,
      action: GA_EVENT_KEY.TRADE.ACTION.CHANGE_FROM_TOKEN,
      label: token,
    })
    setFromTokenAddress(swapOption, token.address)
    setIsWaitingForApproval(false)

    if (isShort && token.isStable) {
      setShortCollateralAddress(token.address)
    }
  }

  const onSelectShortCollateralAddress = (token) => {
    setShortCollateralAddress(token.address)
  }

  const onSelectToToken = (token) => {
    sendEvent({
      category: GA_EVENT_KEY.TRADE.CATEGORY,
      action: GA_EVENT_KEY.TRADE.ACTION.CHANGE_TO_TOKEN,
      label: token,
    })
    setToTokenAddress(swapOption, token.address)
  }

  const onFromValueChange = (e) => {
    setAnchorOnFromAmount(true)
    setFromValue(e.target.value)
  }

  const onToValueChange = (e) => {
    setAnchorOnFromAmount(false)
    setToValue(e.target.value)
  }

  const switchTokens = () => {
    if (fromAmount && toAmount) {
      if (anchorOnFromAmount) {
        setToValue(formatAmountFree(fromAmount, fromToken.decimals, 8))
      } else {
        setFromValue(formatAmountFree(toAmount, toToken.decimals, 8))
      }
      setAnchorOnFromAmount(!anchorOnFromAmount)
    }
    setIsWaitingForApproval(false)

    const updatedTokenSelection = JSON.parse(JSON.stringify(tokenSelection))
    updatedTokenSelection[swapOption] = {
      from: toTokenAddress,
      to: fromTokenAddress,
    }
    setTokenSelection(updatedTokenSelection)
  }

  const wrap = async () => {
    setIsSubmitting(true)

    const contract = new ethers.Contract(nativeTokenAddress, WETH, library)
    Api.callContract(chainId, contract, 'deposit', [], {
      value: fromAmount,
      sentMsg: 'Swap submitted.',
      successMsg: `Swapped ${formatAmount(fromAmount, fromToken.decimals, 4, true)} ${
        fromToken.symbol
      } for ${formatAmount(toAmount, toToken.decimals, 4, true)} ${toToken.symbol}!`,
      failMsg: 'Swap failed.',
      setPendingTxns,
    }).finally(() => {
      setIsSubmitting(false)
    })
  }

  const unwrap = async () => {
    setIsSubmitting(true)

    const contract = new ethers.Contract(nativeTokenAddress, WETH, library)
    Api.callContract(chainId, contract, 'withdraw', [fromAmount], {
      sentMsg: 'Swap submitted!',
      failMsg: 'Swap failed.',
      successMsg: `Swapped ${formatAmount(fromAmount, fromToken.decimals, 4, true)} ${
        fromToken.symbol
      } for ${formatAmount(toAmount, toToken.decimals, 4, true)} ${toToken.symbol}!`,
      setPendingTxns,
    }).finally(() => {
      setIsSubmitting(false)
    })
  }

  const swap = async () => {
    if (fromToken.isNative && toToken.isWrapped) {
      wrap()
      return
    }

    if (fromTokenAddress.isWrapped && toToken.isNative) {
      unwrap()
      return
    }

    setIsSubmitting(true)
    let path = [fromTokenAddress, toTokenAddress]
    if (anchorOnFromAmount) {
      const { path: multiPath } = getNextToAmount(
        chainId,
        fromAmount,
        fromTokenAddress,
        toTokenAddress,
        infoTokens,
        undefined,
        undefined,
        usdxSupply,
        totalTokenWeights,
        isSwap,
        stableSwapFeeBasisPoints,
        swapFeeBasisPoints,
        stableTaxBasisPoints,
        taxBasisPoints,
      )
      if (multiPath) {
        path = multiPath
      }
    } else {
      const { path: multiPath } = getNextFromAmount(
        chainId,
        toAmount,
        fromTokenAddress,
        toTokenAddress,
        infoTokens,
        undefined,
        undefined,
        usdxSupply,
        totalTokenWeights,
        isSwap,
        stableSwapFeeBasisPoints,
        swapFeeBasisPoints,
        stableTaxBasisPoints,
        taxBasisPoints,
      )
      if (multiPath) {
        path = multiPath
      }
    }

    let method
    let contract
    let value
    let params
    let minOut
    if (shouldRaiseGasError(getTokenInfo(infoTokens, fromTokenAddress), fromAmount)) {
      setIsSubmitting(false)
      setIsPendingConfirmation(true)
      helperToast.error(
        `Leave at least ${formatAmount(DUST_BNB, 18, 3)} ${getConstant(chainId, 'nativeTokenSymbol')} for gas`,
      )
      return
    }

    if (!isMarketOrder) {
      minOut = toAmount
      Api.createSwapOrder(chainId, library, path, fromAmount, minOut, triggerRatio, nativeTokenAddress, {
        sentMsg: 'Swap Order submitted!',
        successMsg: 'Swap Order created!',
        failMsg: 'Swap Order creation failed.',
        pendingTxns,
        setPendingTxns,
      })
        .then(() => {
          setIsConfirming(false)
        })
        .finally(() => {
          setIsSubmitting(false)
          setIsPendingConfirmation(false)
        })
      return
    }

    path = replaceNativeTokenAddress(path, nativeTokenAddress)
    method = 'swap'
    value = bigNumberify(0)
    if (toTokenAddress === AddressZero) {
      method = 'swapTokensToETH'
    }

    minOut = toAmount.mul(BASIS_POINTS_DIVISOR - allowedSlippage).div(BASIS_POINTS_DIVISOR)
    params = [path, fromAmount, minOut, account]
    if (fromTokenAddress === AddressZero) {
      method = 'swapETHToTokens'
      value = fromAmount
      params = [path, minOut, account]
    }
    contract = new ethers.Contract(routerAddress, Router, library)

    Api.callContract(chainId, contract, method, params, {
      value,
      sentMsg: `Swap ${!isMarketOrder ? ' order ' : ''} submitted!`,
      successMsg: `Requested to swap ${formatAmount(fromAmount, fromToken.decimals, 4, true)} ${
        fromToken.symbol
      } for ${formatAmount(toAmount, toToken.decimals, 4, true)} ${toToken.symbol}!`,
      failMsg: 'Swap failed.',
      setPendingStepWorkflow,
    })
      .then(async () => {
        setIsConfirming(false)
      })
      .finally(() => {
        setIsSubmitting(false)
        setIsPendingConfirmation(false)
      })
  }

  const createIncreaseOrder = () => {
    let path = [fromTokenAddress]

    if (path[0] === USDG_ADDRESS) {
      if (isLong) {
        const stableToken = getMostAbundantStableToken(chainId, infoTokens)
        path.push(stableToken.address)
      } else {
        path.push(shortCollateralAddress)
      }
    }

    const minOut = 0
    const indexToken = getToken(chainId, indexTokenAddress)
    const successMsg = `
      Requested to  created limit order for ${indexToken.symbol} ${isLong ? 'Long' : 'Short'}: ${formatAmount(
        toUsdMax,
        USD_DECIMALS,
        2,
      )} USD!
    `

    return Api.createIncreaseOrder(
      chainId,
      library,
      nativeTokenAddress,
      path,
      fromAmount,
      indexTokenAddress,
      minOut,
      toUsdMax,
      collateralTokenAddress,
      isLong,
      triggerPriceUsd,
      {
        setPendingStepWorkflow,
        sentMsg: 'Limit order submitted!',
        successMsg,
        failMsg: 'Limit order creation failed.',
      },
    )
      .then(() => {
        setIsConfirming(false)
      })
      .finally(() => {
        setIsSubmitting(false)
        setIsPendingConfirmation(false)
      })
  }

  let referralCode = ethers.constants.HashZero

  if (!attachedOnChain && userReferralCode) {
    referralCode = userReferralCode
  }

  const increasePosition = async () => {
    setIsSubmitting(true)
    const tokenAddress0 = fromTokenAddress === AddressZero ? nativeTokenAddress : fromTokenAddress
    const indexTokenAddress = toTokenAddress === AddressZero ? nativeTokenAddress : toTokenAddress
    let path = [indexTokenAddress] // assume long
    if (toTokenAddress !== fromTokenAddress) {
      path = [tokenAddress0, indexTokenAddress]
    }

    if (fromTokenAddress === AddressZero && toTokenAddress === nativeTokenAddress) {
      path = [nativeTokenAddress]
    }

    if (fromTokenAddress === nativeTokenAddress && toTokenAddress === AddressZero) {
      path = [nativeTokenAddress]
    }

    if (isShort) {
      path = [shortCollateralAddress]
      if (tokenAddress0 !== shortCollateralAddress) {
        path = [tokenAddress0, shortCollateralAddress]
      }
    }

    const refPrice = isLong ? toTokenInfo.maxPrice : toTokenInfo.minPrice
    const priceBasisPoints = isLong ? BASIS_POINTS_DIVISOR + allowedSlippage : BASIS_POINTS_DIVISOR - allowedSlippage
    const priceLimit = refPrice.mul(priceBasisPoints).div(BASIS_POINTS_DIVISOR)

    const boundedFromAmount = fromAmount ? fromAmount : bigNumberify(0)

    if (fromAmount && fromAmount.gt(0) && fromTokenAddress === USDG_ADDRESS && isLong) {
      const { amount: nextToAmount, path: multiPath } = getNextToAmount(
        chainId,
        fromAmount,
        fromTokenAddress,
        indexTokenAddress,
        infoTokens,
        undefined,
        undefined,
        usdxSupply,
        totalTokenWeights,
        isSwap,
        stableSwapFeeBasisPoints,
        swapFeeBasisPoints,
        stableTaxBasisPoints,
        taxBasisPoints,
      )
      if (nextToAmount.eq(0)) {
        helperToast.error('Insufficient liquidity')
        return
      }
      if (multiPath) {
        path = replaceNativeTokenAddress(multiPath)
      }
    }

    let params = [
      path, // _path
      indexTokenAddress, // _indexToken
      boundedFromAmount, // _amountIn
      0, // _minOut
      toUsdMax, // _sizeDelta
      isLong, // _isLong
      priceLimit, // _acceptablePrice
      minExecutionFee, // _executionFee
      referralCode, // _referralCode
    ]

    let method = 'createIncreasePosition'
    let value = minExecutionFee
    if (fromTokenAddress === AddressZero) {
      method = 'createIncreasePositionETH'
      value = boundedFromAmount.add(minExecutionFee)
      params = [
        path, // _path
        indexTokenAddress, // _indexToken
        0, // _minOut
        toUsdMax, // _sizeDelta
        isLong, // _isLong
        priceLimit, // _acceptablePrice
        minExecutionFee, // _executionFee
        referralCode, // _referralCode
      ]
    }

    if (shouldRaiseGasError(getTokenInfo(infoTokens, fromTokenAddress), fromAmount)) {
      setIsSubmitting(false)
      setIsPendingConfirmation(false)
      helperToast.error(
        `Leave at least ${formatAmount(DUST_BNB, 18, 3)} ${getConstant(chainId, 'nativeTokenSymbol')} for gas`,
      )
      return
    }

    const contractAddress = getContract(chainId, 'PositionRouter')
    const contract = new ethers.Contract(contractAddress, PositionRouter, library)
    const indexToken = getTokenInfo(infoTokens, indexTokenAddress)
    const tokenSymbol = indexToken.isWrapped ? getConstant(chainId, 'nativeTokenSymbol') : indexToken.symbol
    const successMsg = `Requested increase of ${tokenSymbol} ${isLong ? 'Long' : 'Short'} by ${formatAmount(
      toUsdMax,
      USD_DECIMALS,
      2,
    )} USD.`

    Api.callContract(chainId, contract, method, params, {
      value,
      setPendingStepWorkflow,
      sentMsg: `${isLong ? 'Long' : 'Short'} submitted.`,
      failMsg: `${isLong ? 'Long' : 'Short'} failed.`,
      successMsg,
    })
      .then(async () => {
        setIsConfirming(false)

        const key = getPositionKey(account, path[path.length - 1], indexTokenAddress, isLong)
        let nextSize = toUsdMax
        if (hasExistingPosition) {
          nextSize = existingPosition.size.add(toUsdMax)
        }

        pendingPositions[key] = {
          updatedAt: Date.now(),
          pendingChanges: {
            size: nextSize,
          },
        }

        setPendingPositions({ ...pendingPositions })
      })
      .finally(() => {
        setIsSubmitting(false)
        setIsPendingConfirmation(false)
      })
  }

  const onSwapOptionChange = (opt) => {
    sendEvent({
      action: GA_EVENT_KEY.TRADE.ACTION.CHANGE_TRADE_TAB,
      category: GA_EVENT_KEY.TRADE.CATEGORY,
      label: opt,
    })
    setSwapOption(opt)
    if (orderOption === STOP) {
      setOrderOption(MARKET)
    }
    setAnchorOnFromAmount(true)
    setFromValue('')
    setToValue('')
    setTriggerPriceValue('')
    setTriggerRatioValue('')

    if (opt === SHORT && infoTokens) {
      const fromToken = getToken(chainId, tokenSelection[opt].from)
      if (fromToken && fromToken.isStable) {
        setShortCollateralAddress(fromToken.address)
      } else {
        const stableToken = getMostAbundantStableToken(chainId, infoTokens)
        setShortCollateralAddress(stableToken.address)
      }
    }
  }

  const onConfirmationClick = () => {
    if (!active) {
      props.connectWallet()
      return
    }

    if (needOrderBookApproval) {
      approveOrderBook()
      return
    }

    setIsPendingConfirmation(true)

    if (isSwap) {
      swap()
      return
    }

    if (orderOption === LIMIT) {
      createIncreaseOrder()
      return
    }

    increasePosition()
  }

  function approveFromToken() {
    approveTokens({
      setIsApproving,
      library,
      tokenAddress: fromToken.address,
      spender: routerAddress,
      chainId: chainId,
      onApproveSubmitted: () => {
        setIsWaitingForApproval(true)
      },
      infoTokens,
      getTokenInfo,
      pendingTxns,
      setPendingTxns,
    })
  }

  const onClickPrimary = () => {
    if (isStopOrder) {
      setOrderOption(MARKET)
      return
    }

    if (!active) {
      props.connectWallet()
      return
    }

    if (needPositionRouterApproval) {
      approvePositionRouter({
        sentMsg: 'Enable leverage sent.',
        failMsg: 'Enable leverage failed.',
      })
      return
    }

    if (needApproval) {
      approveFromToken()
      return
    }

    if (needOrderBookApproval) {
      setOrdersToaOpen(true)
      return
    }

    const [, modal, errorCode] = getError()

    if (modal) {
      setModalError(errorCode)
      return
    }

    if (isSwap) {
      if (fromTokenAddress === AddressZero && toTokenAddress === nativeTokenAddress) {
        wrap()
        return
      }

      if (fromTokenAddress === nativeTokenAddress && toTokenAddress === AddressZero) {
        unwrap()
        return
      }
    }

    setIsConfirming(true)
    setIsHigherSlippageAllowed(false)
  }

  const isStopOrder = orderOption === STOP
  const showFromAndToSection = !isStopOrder
  const showTriggerPriceSection = !isSwap && !isMarketOrder && !isStopOrder
  const showTriggerRatioSection = isSwap && !isMarketOrder && !isStopOrder

  let fees
  let feesUsd
  let feeBps
  let swapFees
  let positionFee
  if (isSwap) {
    if (fromAmount) {
      const { feeBasisPoints } = getNextToAmount(
        chainId,
        fromAmount,
        fromTokenAddress,
        toTokenAddress,
        infoTokens,
        undefined,
        undefined,
        usdxSupply,
        totalTokenWeights,
        isSwap,
        stableSwapFeeBasisPoints,
        swapFeeBasisPoints,
        stableTaxBasisPoints,
        taxBasisPoints,
      )
      if (feeBasisPoints !== undefined) {
        fees = fromAmount.mul(feeBasisPoints).div(BASIS_POINTS_DIVISOR)
        const feeTokenPrice =
          fromTokenInfo.address === USDG_ADDRESS ? expandDecimals(1, USD_DECIMALS) : fromTokenInfo.maxPrice
        feesUsd = fees.mul(feeTokenPrice).div(expandDecimals(1, fromTokenInfo.decimals))
      }
      feeBps = feeBasisPoints
    }
  } else if (toUsdMax) {
    positionFee = toUsdMax.mul(marginFeeBasisPoints).div(BASIS_POINTS_DIVISOR)
    feesUsd = positionFee

    const { feeBasisPoints } = getNextToAmount(
      chainId,
      fromAmount,
      fromTokenAddress,
      collateralTokenAddress,
      infoTokens,
      undefined,
      undefined,
      usdxSupply,
      totalTokenWeights,
      isSwap,
      stableSwapFeeBasisPoints,
      swapFeeBasisPoints,
      stableTaxBasisPoints,
      taxBasisPoints,
    )
    if (feeBasisPoints) {
      swapFees = fromUsdMin.mul(feeBasisPoints).div(BASIS_POINTS_DIVISOR)
      feesUsd = feesUsd.add(swapFees)
    }
    feeBps = feeBasisPoints
  }

  const leverageMarks = {
    2: '2x',
    5: '5x',
    10: '10x',
    15: '15x',
    20: '20x',
    25: '25x',
    30: '30x',
    35: '35x',
    40: '40x',
    45: '45x',
    50: '50x',
    // 55: "55x",
    // 60: "90x",
    // 65: "95x",
    // 70: "70x",
    // 75: "75x",
    // 80: "80x",
    // 85: "85x",
    // 90: "90x",
    // 95: "95x",
    // 100: "100x",
  }

  if (!fromToken || !toToken) {
    return null
  }

  let hasZeroBorrowFee = false
  let borrowFeeText
  if (isLong && toTokenInfo && toTokenInfo.fundingRate) {
    borrowFeeText = formatAmount(toTokenInfo.fundingRate, 4, 4) + '% / 1h'
    if (toTokenInfo.fundingRate.eq(0)) {
      // hasZeroBorrowFee = true
    }
  }
  if (isShort && shortCollateralToken && shortCollateralToken.fundingRate) {
    borrowFeeText = formatAmount(shortCollateralToken.fundingRate, 4, 4) + '% / 1h'
    if (shortCollateralToken.fundingRate.eq(0)) {
      // hasZeroBorrowFee = true
    }
  }

  function setFromValueToMaximumAvailable() {
    if (!fromToken || !fromBalance) {
      return
    }

    sendEvent({
      category: GA_EVENT_KEY.TRADE.CATEGORY,
      action: GA_EVENT_KEY.TRADE.ACTION.CLICK_FROM_MAX_VALUE,
    })

    const maxAvailableAmount = fromToken.isNative ? fromBalance.sub(bigNumberify(DUST_BNB).mul(2)) : fromBalance
    setFromValue(formatAmountFree(maxAvailableAmount, fromToken.decimals, fromToken.decimals))
    setAnchorOnFromAmount(true)
  }

  function shouldShowMaxButton() {
    if (!fromToken || !fromBalance) {
      return false
    }
    const maxAvailableAmount = fromToken.isNative ? fromBalance.sub(bigNumberify(DUST_BNB).mul(2)) : fromBalance
    return fromValue !== formatAmountFree(maxAvailableAmount, fromToken.decimals, fromToken.decimals)
  }

  return (
    <>
      <div className="Exchange-swap-box bg-white rounded-[1.75rem] border dark:bg-bg-dark-color-2 dark:border-bg-dark-color-2">
        <div className="relative rounded-2xl px-6 py-8">
          <div>
            <Tab
              icons={SWAP_ICONS}
              options={SWAP_OPTIONS}
              option={swapOption}
              onChange={onSwapOptionChange}
              className="Exchange-swap-option-tabs"
            />
            {flagOrdersEnabled && (
              <Tab
                options={orderOptions}
                optionLabels={orderOptionLabels}
                type="inline"
                option={orderOption}
                onChange={onOrderOptionChange}
                isBg={false}
              />
            )}
          </div>
          {showFromAndToSection && (
            <Fragment>
              <div className="Exchange-swap-section">
                <div className="Exchange-swap-section-top">
                  <div className="muted">
                    {fromUsdMin && (
                      <div className="Exchange-swap-usd">
                        Pay: {formatAmount(fromUsdMin, USD_DECIMALS, 2, true)} USD
                      </div>
                    )}
                    {!fromUsdMin && 'Pay'}
                  </div>
                  {fromBalance && (
                    <div className="muted align-right clickable" onClick={setFromValueToMaximumAvailable}>
                      Balance: {formatAmount(fromBalance, fromToken.decimals, 4, true)}
                    </div>
                  )}
                </div>
                <div className="Exchange-swap-section-bottom">
                  <div className="Exchange-swap-input-container">
                    <FieldInput
                      type="number"
                      min="0"
                      placeholder="0.0"
                      className="Exchange-swap-input"
                      value={fromValue}
                      onChange={onFromValueChange}
                    />
                    {shouldShowMaxButton() && (
                      <div className="Exchange-swap-max" onClick={setFromValueToMaximumAvailable}>
                        MAX
                      </div>
                    )}
                  </div>
                  <div>
                    <TokenSelector
                      label="Pay"
                      chainId={chainId}
                      tokenAddress={fromTokenAddress}
                      onSelectToken={onSelectFromToken}
                      tokens={fromTokens}
                      infoTokens={infoTokens}
                      showMintingCap={false}
                      showTokenImgInDropdown={true}
                    />
                  </div>
                </div>
              </div>
              <div className="Exchange-swap-ball-container">
                <div className="Exchange-swap-ball" onClick={switchTokens}>
                  <IoMdSwap className="Exchange-swap-ball-icon" />
                </div>
              </div>
              <div className="Exchange-swap-section">
                <div className="Exchange-swap-section-top">
                  <div className="muted">
                    {toUsdMax && (
                      <div className="Exchange-swap-usd">
                        {getToLabel()}: {formatAmount(toUsdMax, USD_DECIMALS, 2, true)} USD
                      </div>
                    )}
                    {!toUsdMax && getToLabel()}
                  </div>
                  {toBalance && isSwap && (
                    <div className="muted align-right">
                      Balance: {formatAmount(toBalance, toToken.decimals, 4, true)}
                    </div>
                  )}
                  {(isLong || isShort) && hasLeverageOption && (
                    <div className="muted align-right">Leverage: {parseFloat(leverageOption).toFixed(2)}x</div>
                  )}
                </div>
                <div className="Exchange-swap-section-bottom">
                  <div>
                    <FieldInput
                      type="number"
                      min="0"
                      placeholder="0.0"
                      className="Exchange-swap-input"
                      value={toValue}
                      onChange={onToValueChange}
                    />
                  </div>
                  <div>
                    <TokenSelector
                      label={getTokenLabel()}
                      chainId={chainId}
                      tokenAddress={toTokenAddress}
                      onSelectToken={onSelectToToken}
                      tokens={toTokens}
                      infoTokens={infoTokens}
                      showTokenImgInDropdown={true}
                    />
                  </div>
                </div>
              </div>
            </Fragment>
          )}
          {showTriggerRatioSection && (
            <div className="Exchange-swap-section">
              <div className="Exchange-swap-section-top">
                <div className="muted">Price</div>
                {fromTokenInfo && toTokenInfo && (
                  <div
                    className="muted align-right clickable"
                    onClick={() => {
                      setTriggerRatioValue(
                        formatAmountFree(
                          getExchangeRate(fromTokenInfo, toTokenInfo, triggerRatioInverted),
                          USD_DECIMALS,
                          10,
                        ),
                      )
                    }}
                  >
                    {formatAmount(getExchangeRate(fromTokenInfo, toTokenInfo, triggerRatioInverted), USD_DECIMALS, 4)}
                  </div>
                )}
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <FieldInput
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input small"
                    value={triggerRatioValue}
                    onChange={onTriggerRatioChange}
                  />
                </div>
                {(() => {
                  if (!toTokenInfo) return
                  if (!fromTokenInfo) return
                  const [tokenA, tokenB] = triggerRatioInverted
                    ? [toTokenInfo, fromTokenInfo]
                    : [fromTokenInfo, toTokenInfo]
                  return (
                    <div className="PositionEditor-token-symbol">
                      {tokenA.symbol}&nbsp;per&nbsp;{tokenB.symbol}
                    </div>
                  )
                })()}
              </div>
            </div>
          )}
          {showTriggerPriceSection && (
            <div className="Exchange-swap-section">
              <div className="Exchange-swap-section-top">
                <div className="muted">Price</div>
                <div
                  className="muted align-right clickable"
                  onClick={() => {
                    setTriggerPriceValue(formatAmountFree(entryMarkPrice, USD_DECIMALS, 2))
                  }}
                >
                  Mark: {formatAmount(entryMarkPrice, USD_DECIMALS, 2, true)}
                </div>
              </div>
              <div className="Exchange-swap-section-bottom">
                <div className="Exchange-swap-input-container">
                  <FieldInput
                    type="number"
                    min="0"
                    placeholder="0.0"
                    className="Exchange-swap-input"
                    value={triggerPriceValue}
                    onChange={onTriggerPriceChange}
                  />
                </div>
                <div className="PositionEditor-token-symbol">USD</div>
              </div>
            </div>
          )}
          {isSwap && (
            <div className="Exchange-swap-box-info">
              <ExchangeInfoRow label="Fees">
                <div>
                  {!fees && '-'}
                  {fees && (
                    <div>
                      {formatAmount(feeBps, 2, 2, false)}%&nbsp; ({formatAmount(fees, fromToken.decimals, 4, true)}{' '}
                      {fromToken.symbol}: ${formatAmount(feesUsd, USD_DECIMALS, 2, true)})
                    </div>
                  )}
                </div>
              </ExchangeInfoRow>
            </div>
          )}
          {(isLong || isShort) && !isStopOrder && (
            <div className="Exchange-leverage-box">
              <div className="Exchange-leverage-slider-settings">
                <Checkbox
                  isChecked={isLeverageSliderEnabled}
                  setIsChecked={(val) => {
                    sendEvent({
                      category: GA_EVENT_KEY.TRADE.CATEGORY,
                      action: GA_EVENT_KEY.TRADE.ACTION.CHECK_LEVERAGE_SLIDER,
                    })
                    setIsLeverageSliderEnabled(val)
                  }}
                >
                  <span className="muted">Leverage slider</span>
                </Checkbox>
              </div>
              {isLeverageSliderEnabled && (
                <div
                  className={cx('Exchange-leverage-slider', 'App-slider', {
                    positive: isLong,
                    negative: isShort,
                  })}
                >
                  <Slider
                    min={1.1}
                    max={50.0}
                    step={0.1}
                    marks={leverageMarks}
                    handle={leverageSliderHandle}
                    onAfterChange={(value) => {
                      sendEvent({
                        category: GA_EVENT_KEY.TRADE.CATEGORY,
                        action: GA_EVENT_KEY.TRADE.ACTION.MOVE_LEVERAGE_SLIDER_END,
                        value: value,
                      })
                    }}
                    onChange={(value) => setLeverageOption(value)}
                    value={leverageOption}
                    defaultValue={leverageOption}
                  />
                </div>
              )}
              {isShort && (
                <div className="Exchange-info-row">
                  <div className="Exchange-info-label">Profits In</div>
                  <div className="align-right">
                    <TokenSelector
                      label="Profits In"
                      chainId={chainId}
                      tokenAddress={shortCollateralAddress}
                      onSelectToken={onSelectShortCollateralAddress}
                      tokens={stableTokens}
                      showTokenImgInDropdown={true}
                    />
                  </div>
                </div>
              )}
              {isLong && (
                <div className="Exchange-info-row">
                  <div className="Exchange-info-label">Profits In</div>
                  <div className="align-right strong">{toToken.symbol}</div>
                </div>
              )}
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">Leverage</div>
                <div className="align-right">
                  {hasExistingPosition && toAmount && toAmount.gt(0) && (
                    <div className="inline-flex items-center muted">
                      {formatAmount(existingPosition.leverage, 4, 2)}x
                      <BsArrowRight className="transition-arrow" />
                    </div>
                  )}
                  {toAmount && leverage && leverage.gt(0) && `${formatAmount(leverage, 4, 2)}x`}
                  {!toAmount && leverage && leverage.gt(0) && `-`}
                  {leverage && leverage.eq(0) && `-`}
                </div>
              </div>

              <div className="Exchange-info-row">
                <div className="Exchange-info-label">Entry Price</div>
                <div className="align-right">
                  {hasExistingPosition && toAmount && toAmount.gt(0) && (
                    <div className="inline-flex items-center muted">
                      ${formatAmount(existingPosition.averagePrice, USD_DECIMALS, 2, true)}
                      <BsArrowRight className="transition-arrow" />
                    </div>
                  )}
                  {nextAveragePrice && `$${formatAmount(nextAveragePrice, USD_DECIMALS, 2, true)}`}
                  {!nextAveragePrice && `-`}
                </div>
              </div>
              <div className="Exchange-info-row">
                <div className="Exchange-info-label">Liq. Price</div>
                <div className="align-right">
                  {hasExistingPosition && toAmount && toAmount.gt(0) && (
                    <div className="inline-flex items-center muted">
                      ${formatAmount(existingLiquidationPrice, USD_DECIMALS, 2, true)}
                      <BsArrowRight className="transition-arrow" />
                    </div>
                  )}
                  {toAmount &&
                    displayLiquidationPrice &&
                    `$${formatAmount(displayLiquidationPrice, USD_DECIMALS, 2, true)}`}
                  {!toAmount && displayLiquidationPrice && `-`}
                  {!displayLiquidationPrice && `-`}
                </div>
              </div>
              <ExchangeInfoRow label="Fees">
                <div>
                  {!feesUsd && '-'}
                  {feesUsd && (
                    <Tooltip
                      handle={`$${formatAmount(feesUsd, USD_DECIMALS, 2, true)}`}
                      renderContent={() => {
                        return (
                          <>
                            {swapFees && (
                              <div>
                                {collateralToken.symbol} is required for collateral. <br />
                                <br />
                                Swap {fromToken.symbol} to {collateralToken.symbol} Fee: $
                                {formatAmount(swapFees, USD_DECIMALS, 2, true)}
                                <br />
                                <br />
                              </div>
                            )}
                            <div>
                              Position Fee ({marginFeeBasisPoints / 100}% of position size): $
                              {formatAmount(positionFee, USD_DECIMALS, 2, true)}
                            </div>
                          </>
                        )
                      }}
                    />
                  )}
                </div>
              </ExchangeInfoRow>
            </div>
          )}

          {isStopOrder && (
            <div className="text-xl p-6 mb-8 mt-2 rounded-2xl border bg-bg-color-1 dark:bg-bg-dark-color-2">
              Take-profit and stop-loss orders can be set after opening a position. <br />
              <br />
              There will be a "Close" button on each position row, clicking this will display the option to set trigger
              orders. <br />
              <br />
              For screenshots and more information, please see the{' '}
              <a
                href="https://tfx-market.gitbook.io/tfx/trading#stop-loss-take-profit-orders"
                target="_blank"
                rel="noopener noreferrer"
              >
                docs
              </a>
              .
            </div>
          )}

          <div className="Exchange-swap-button-container">
            <PButton size="xl" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
              {getPrimaryText()}
            </PButton>
          </div>
        </div>

        {renderErrorModal()}
        {renderOrdersToa()}

        {isConfirming && (
          <ConfirmationBox
            library={library}
            isHigherSlippageAllowed={isHigherSlippageAllowed}
            setIsHigherSlippageAllowed={setIsHigherSlippageAllowed}
            orders={orders}
            isSwap={isSwap}
            isLong={isLong}
            isMarketOrder={isMarketOrder}
            orderOption={orderOption}
            isShort={isShort}
            fromToken={fromToken}
            fromTokenInfo={fromTokenInfo}
            toToken={toToken}
            toTokenInfo={toTokenInfo}
            toAmount={toAmount}
            fromAmount={fromAmount}
            feeBps={feeBps}
            onConfirmationClick={onConfirmationClick}
            setIsConfirming={setIsConfirming}
            hasExistingPosition={hasExistingPosition}
            shortCollateralAddress={shortCollateralAddress}
            shortCollateralToken={shortCollateralToken}
            leverage={leverage}
            existingPosition={existingPosition}
            existingLiquidationPrice={existingLiquidationPrice}
            displayLiquidationPrice={displayLiquidationPrice}
            nextAveragePrice={nextAveragePrice}
            triggerPriceUsd={triggerPriceUsd}
            triggerRatio={triggerRatio}
            fees={fees}
            feesUsd={feesUsd}
            isSubmitting={isSubmitting}
            isPendingConfirmation={isPendingConfirmation}
            fromUsdMin={fromUsdMin}
            toUsdMax={toUsdMax}
            collateralTokenAddress={collateralTokenAddress}
            infoTokens={infoTokens}
            chainId={chainId}
            setPendingTxns={setPendingTxns}
            pendingTxns={pendingTxns}
            minExecutionFee={minExecutionFee}
            minExecutionFeeUSD={minExecutionFeeUSD}
            minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
          />
        )}
      </div>

      {isSwap && (
        <SwapInfoBox
          fromTokenInfo={fromTokenInfo}
          toTokenInfo={toTokenInfo}
          toToken={toToken}
          fromToken={fromToken}
          maxSwapAmountUsd={maxSwapAmountUsd}
          maxFromTokenIn={maxFromTokenIn}
          maxFromTokenInUSD={maxFromTokenInUSD}
          maxToTokenOut={maxToTokenOut}
          maxToTokenOutUSD={maxToTokenOutUSD}
          isMarketOrder={isMarketOrder}
        />
      )}

      <MarketBox
        isLong={isLong}
        isShort={isShort}
        toToken={toToken}
        entryMarkPrice={entryMarkPrice}
        savedSlippageAmount={savedSlippageAmount}
        exitMarkPrice={exitMarkPrice}
        borrowFeeText={borrowFeeText}
        hasZeroBorrowFee={hasZeroBorrowFee}
        toTokenInfo={toTokenInfo}
      />

      <UseFullBox chainId={chainId} />
    </>
  )
}
