import { useToaster } from 'react-hot-toast/headless'
import { Toast } from 'react-hot-toast'
import PendingStepToaster from 'components/Toaster/PendingStepToaster'
import GeneralToaster from 'components/Toaster/GeneralToaster'

const Toaster = () => {
  const { toasts, handlers } = useToaster({
    duration: Infinity,
  })

  const { startPause, endPause, updateHeight } = handlers

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 15,
        right: 15,
        zIndex: 999,
      }}
      onMouseEnter={startPause}
      onMouseLeave={endPause}
    >
      {toasts.map((t: Toast) => {
        if (t.type === 'error' || t.type === 'success') {
          return <GeneralToaster t={t} key={t.id} updateHeight={updateHeight} />
        }
        return <PendingStepToaster t={t} key={t.id} updateHeight={updateHeight} />
      })}
    </div>
  )
}

export default Toaster
